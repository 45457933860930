import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder,UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { User } from '@app/_models';
import { AccountService } from '@app/_services';
import { AlertService } from '@app/_services/alert.service';
import { ConfigDataService } from '@app/_services/config-data.service';
import { DealerService } from '@app/_services/dealer-service';
import { first } from 'rxjs/operators';
import {Payments} from '../../_models/payment';

@Component({
  selector: 'app-add-payment',
  templateUrl: './add-payment.component.html',

})
export class AddPaymentComponent implements OnInit {
  id:string;
  paymentForm: UntypedFormGroup;
  paymentDetailForm: UntypedFormGroup;
  numberofRecords = [10,20,50,100,200,500,1000,2000,5000,10000];
  isPaymentShow = false;
  submitted=false;
  loading=false;
  isBalanceUpdated=false;
  payment:Payments;
  paymentDetails: Payments[];
  isPaymentAddFormShow = false;
  isPaymentDetailShow = false;
  currentDate = new Date();
  user:User;
  transactionTypes = [{"id":"AddPayment","text":"Add Payment to Dealer Balance"}, {"id":"Payout","text":"Payout to dealer"}]

  constructor(
    private route: ActivatedRoute,
    private dealerService: DealerService,
    private alertService: AlertService,
    private formBuilder: UntypedFormBuilder,
    private configDataService: ConfigDataService,
    private accountService: AccountService

  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.paymentForm = this.formBuilder.group({
      paymentAmount: ['', Validators.required],
      comments: ['',Validators.required],
          });   
    
    this.paymentDetailForm = this.formBuilder.group({
      recordCount: [10, Validators.required],
                });   
        }
  get pmntForm() { return this.paymentForm.controls; }
  get pmntDtlForm() { return this.paymentDetailForm.controls; }

  paymentShowToggle(){
    this.isPaymentShow = !this.isPaymentShow;
  }
  paymentPlanFormToggle(){
    this.isPaymentAddFormShow = !this.isPaymentAddFormShow;
    this.isBalanceUpdated = false;
    if (this.isPaymentAddFormShow)
      this.isPaymentDetailShow = false;

  }
  onPaymentSubmit(){
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.paymentForm.invalid) {
      return;
    }

    this.loading = true;
    this.user = this.accountService.userValue;
    this.payment = new Payments(this.paymentForm.value)
    this.payment.dealerId = this.id;
    this.payment.paymentDate = this.currentDate.getFullYear() + "-" + ('0' + (this.currentDate.getMonth() + 1)).slice(-2) + "-" + ('0' + this.currentDate.getDate()).slice(-2);
    this.payment.userId = this.user.id;
    this.payment.userName = this.user.username;
    this.dealerService.addDealerPayment(this.payment)
    .pipe(first())
    .subscribe({
        next: () => {
          this.alertService.success("payment added successfully. please refresh browser to see updated balance");
          this.loading = false;
          this.isBalanceUpdated = true;
        },
        error: error => {
            this.alertService.error(error);
            this.loading = false;

        }
    });
    this.submitted=false;
  }

  onReset(){
    window.location.reload();
  }
  paymentDetailFormToggle(){
    this.isPaymentDetailShow = !this.isPaymentDetailShow;
    if (this.isPaymentDetailShow)
      this.isPaymentAddFormShow = false;
  }

  getPaymentDetail(){
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.paymentDetailForm.invalid) {
      return;
    }

    this.loading = true;
    this.dealerService.getDealerPayment({"id": this.id, "recordCount" : this.paymentDetailForm.get('recordCount').value})
    .pipe(first())
    .subscribe({
        next: (x) => {
          this.paymentDetails = x;
          this.loading = false;
        },
        error: error => {
            this.alertService.error(error);
            this.loading = false;

        }
    });
    this.submitted=false;

  }
}
