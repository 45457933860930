import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlanMaintainComponent } from './plan-maintain/plan-maintain.component';
import { ConfigMaintainLayoutComponent } from './config-maintain-layout/config-maintain-layout.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfigMaintainRoutingModule } from './config-maintain-routing.module';



@NgModule({
  declarations: [
    PlanMaintainComponent,
    ConfigMaintainLayoutComponent
  ],
  imports: [
    CommonModule,
    ConfigMaintainRoutingModule,
    ReactiveFormsModule,
    HttpClientModule
  ]
})
export class ConfigMaintainModule { }
