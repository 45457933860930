import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderBase } from '../base/header-base.interface';
import { ConfigDataService } from '@app/_services/config-data.service';

@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterModule]
})
export class AdminHeaderComponent implements HeaderBase {
  @Input() user: any;
  @Input() dealer: any;
   masterPolicyMaintain: any[] = [];
   seriesMaintain: any[] = [];
   setupMaintain: any[] = [];
  constructor(private configService: ConfigDataService) { 
        this.configService.SeriesMaintain$.subscribe((x) => this.seriesMaintain = x);
        this.configService.MasterPolicyMaintain$.subscribe((x) => this.masterPolicyMaintain = x);
        this.configService.SetupMaintain$.subscribe((x) => this.setupMaintain = x);
  }
  collapseNavbar(): void {
    // Close the navbar when a link is clicked (mobile view)
    const navbarToggler = document.querySelector('.navbar-toggler') as HTMLElement;
    const navbarCollapse = document.querySelector('.navbar-collapse') as HTMLElement;
    
    if (window.innerWidth < 992 && navbarCollapse.classList.contains('show')) {
      navbarToggler.click();
    }
  }

  logout(): void {
    const event = new CustomEvent('logout');
    window.dispatchEvent(event);
  }

  getInitials(): string {
    return this.user && this.user.firstName && this.user.lastName ? 
      this.user.firstName.charAt(0) + this.user.lastName.charAt(0) : 'AA';
  }
}
