import { Component, OnInit,  } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup,  Validators } from '@angular/forms';
import { AccountService } from '@app/_services/account.service';
import { AlertService } from '@app/_services/alert.service';
import { ConfigDataService } from '@app/_services/config-data.service'
import { MakeModel } from '@app/_models/makeModel';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { StateCity } from '@app/_models/stateCity';
import { PlanDetails } from '@app/_models/planDetails';
import { User } from '@app/_models';
import { DealerService } from '@app/_services/dealer-service';
import { Dealer } from '@app/_models/dealer';
import { Certificate } from '@app/_models/certificate';
import { CertificateService } from '@app/_services/certificate.service'
import { first } from 'rxjs/internal/operators/first';
import { ActivatedRoute, Router } from '@angular/router';
import { DealerModelConfig } from '@app/_models/dealerModelConfig';
import { DealerModelPrice } from '@app/_models/dealerModelPrice';


@Component({
  selector: 'app-edit-certificate',
  templateUrl: './edit-certificate.component.html',
  styleUrls: ['./edit-certificate.component.less']
})
export class EditCertificateComponent implements OnInit {
  certficateForm: UntypedFormGroup;
  certificate: Certificate;
  showGstAmount = true;
  loading = false;
  ageOrDobValue = ["dob","age"];
  isBalanceLow = false;
  submitted = false;
  dealer = new Dealer();
  makeModel: MakeModel[];
  configMakeModel: MakeModel[];
  models: string[];
  cities: string[];
  stateCity: StateCity[];
  plans: PlanDetails[];
  maxAmount = 99999;
  minAmount = 0;
  selectedMakeModel: MakeModel;
  selectedPlan: PlanDetails;
  startDateModel: NgbDateStruct;
  dobString: string;
  customerDob: string;
  runningBalance: number;
  types = ["New", "Old"];
  genders = ["Male", "Female","NA"];
  clientTypes = ["Individual", "Corporate"];
  years: number[];
  relationships = ["Spouse","Husband","Wife", "Father", "Mother", "Son", "Daughter", "Brother", "Sister","Grandson","Granddaughter", "Other"];
  registrtionNumberValidation = [];
  certificateResponse: Certificate;
  planAmount: any;
  user: User;
  gSTAmount: number;
  gstPercentage = 0.18;
  isCorporate = true;
  User: User;
  planGSTAmount: any;
  planTotalAmount: any;
  currentDate = new Date();
  certificateCreated = false;
  issueDateString: string;
  issueDate = new Date();
  startDate = new Date();
  endDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
  minDobDate = new Date(new Date().setFullYear(new Date().getFullYear() - 70));
  maxDobDate = new Date(new Date().setFullYear(new Date().getFullYear() - 15));
  minDobDateModel =  {"year":2010, "month": 1,"day": 1};
  maxDobDateModel =  {"year":2010, "month": 1,"day": 1};
  newYears = [this.currentDate.getFullYear(), this.currentDate.getFullYear() - 1];
  dealarConfigFound: boolean = false;
  dealerConfig: DealerModelConfig;
  selectDealerModelPrice: DealerModelPrice;
  allDealerConfig: DealerModelConfig[] =[];

  endorseRequest: boolean;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private accountService: AccountService,
    private alertService: AlertService,
    private configDataService: ConfigDataService,
    private dealerService: DealerService,
    private certificateService: CertificateService,
    private route: ActivatedRoute,
    private router: Router,

  ) {

  }

  ngOnInit(): void {
    this.endorseRequest = this.router.url.includes('endorseRequest');
    this.certificate = this.certificateService.PrintCertificate;
    this.certificateResponse = this.certificateService.PrintCertificate;

    this.startDateModel =  {"year":2010, "month": 1,"day": 1};
    
    if(this.certificate.ageOrDob && this.certificate.ageOrDob == "age")
    {
      this.startDateModel =  {"year":2010, "month": 1,"day": 1};
    }
    else
    {
    this.startDateModel.year = parseInt(this.certificate.dob.split('-')[0]);
    this.startDateModel.day = parseInt(this.certificate.dob.split('-')[2]);
    this.startDateModel.month = parseInt(this.certificate.dob.split('-')[1]);
  }

    this.minDobDateModel.year = this.minDobDate.getFullYear();
    this.minDobDateModel.day = this.minDobDate.getDate();
    this.minDobDateModel.month = this.minDobDate.getMonth() + 1;

    this.maxDobDateModel.year = this.maxDobDate.getFullYear();
    this.maxDobDateModel.day = this.maxDobDate.getDate();
    this.maxDobDateModel.month = this.maxDobDate.getMonth() + 1;

    this.configDataService.StateCity$.subscribe((x) => {
      if (x.find(a => a.state == this.certificate.state))
      {
        if(this.certificate.city && x.find(a => a.state == this.certificate.state).city.includes(this.certificate.city))
        {
          this.stateCity = x;
        }
        else
        {
          x.find(a => a.state == this.certificate.state).city.push(this.certificate.city);
        }
      }
      else
      {
        x.push({state: this.certificate.state, city: [this.certificate.city]});
      }
      this.stateCity = x;
    });
    this.configDataService.MakeModel$.subscribe((x)=>{
      this.configMakeModel = x;
    }
    );


    this.user = this.accountService.userValue;
    this.certficateForm = this.formBuilder.group({
      vehicleMake: ['', Validators.required],
      model: ['', Validators.required],
      type: ['', Validators.required],
      variant: [''],
      vehicleYear: [''],
      registrationNumber: [''],
      chasisNumber: ['', Validators.required],
      engineNumber: ['', Validators.required],
      clientType:['',Validators.required],
      firstName: ['', Validators.required],
      middleName: [''],
      lastName: [''],
      email: [''],
      contactNumber: ['', [Validators.required, Validators.minLength(6)]],
      employeeContactNumber: ['', [Validators.required, Validators.minLength(6)]],
      gender: ['', Validators.required],
      dob: [this.startDateModel, Validators.required],
      ageOrDob: [this.ageOrDobValue[0]],
      age: ['', [Validators.required, Validators.min(15),Validators.max(80)]],
      gstin: [''],
      address: ['', Validators.required],
      state: ['', Validators.required],
      city: ['', Validators.required],
      nomineeName: ['', Validators.required],
      nomineeRelationship: ['', Validators.required],
      nomineeGender: ['', Validators.required],
      nomineeAge: ['', [Validators.required, Validators.min(1),Validators.max(100)]],
      planId: ['', Validators.required],
      planAmount: ['']
      , planGSTAmount: ['']
      , planTotalAmount: ['', [Validators.required,
      (control: AbstractControl) => Validators.max(this.maxAmount)(control)
        , (control: AbstractControl) => Validators.min(this.minAmount)(control)]]
      , startDate: ['', Validators.required]
      , endDate: ['']
      , issueDate: ['']
      , balance: ['']
      , userId: ['']
      , certificateNumber: ['']
      , policyNumber: ['']
      , endorsementReason: ['']
      , endorsementCount: ['']
      ,vehicleType:['Two Wheeler']
      ,notificationEmail:['',[Validators.email,Validators.required]]
    });

    if (this.endorseRequest){
      this.certficateForm.controls['notificationEmail'].setValidators([Validators.required,Validators.email]);
      this.certficateForm.controls['notificationEmail'].updateValueAndValidity();
      this.certficateForm.patchValue(this.certificate);
    }
    else
    {
      this.certficateForm.controls['notificationEmail'].clearValidators();
      this.certficateForm.controls['notificationEmail'].updateValueAndValidity();
      if (this.certificate.endorseRequestCert?.vehicleMake){
        this.certficateForm.patchValue(new Certificate(this.certificate.endorseRequestCert));
        
      }
      else
      {
        this.certficateForm.patchValue(this.certificate);
      }
    }
    this.certficateForm.controls['planAmount'].disable();
    this.certficateForm.controls['planGSTAmount'].disable();
    this.certficateForm.controls['planTotalAmount'].disable();
    // this.certficateForm.controls['startDate'].disable();
    this.certficateForm.controls['endDate'].disable();
    this.certficateForm.controls['issueDate'].disable();
    this.certficateForm.controls['balance'].disable();
    this.configDataService.getDealerModelConfig(this.user.dealerId).pipe(first()).subscribe({
      next: (x) => {
        this.allDealerConfig = x;
        this.dealarConfigFound = false;

      this.dealerService.getByDealerId(this.certificate.dealerId).subscribe(async (x) => {
        this.dealer = x;
        this.makeModel = x.dealerMake;
        if (this.dealerConfig && this.dealerConfig.dealerModelPrices.length > 0) {
          this.dealarConfigFound = true;
          this.getMakeModelFromDealerConfig();
        } else {
          this.dealarConfigFound = false;
          await this.setDefaultMakeModel();
        }
        if (this.certificate.endorseRequestCert?.vehicleMake)
          {
            this.certficateForm.controls['vehicleMake'].setValue(this.certificate.endorseRequestCert.vehicleMake);
            this.certficateForm.controls['type'].setValue(this.certificate.endorseRequestCert.type);
            this.certficateForm.controls['gender'].setValue(this.certificate.endorseRequestCert.gender);
            this.certficateForm.controls['nomineeGender'].setValue(this.certificate.endorseRequestCert.nomineeGender);
            this.certficateForm.controls['nomineeRelationship'].setValue(this.certificate.endorseRequestCert.nomineeRelationship);
            this.certficateForm.controls['state'].setValue(this.certificate.endorseRequestCert.state);
            this.certficateForm.controls['clientType'].setValue(this.certificate.endorseRequestCert.clientType);
    
          }
          else
          {
            this.certficateForm.controls['vehicleMake'].setValue(this.certificate.vehicleMake);
            this.certficateForm.controls['type'].setValue(this.certificate.type);
            this.certficateForm.controls['gender'].setValue(this.certificate.gender);
            this.certficateForm.controls['nomineeGender'].setValue(this.certificate.nomineeGender);
            this.certficateForm.controls['nomineeRelationship'].setValue(this.certificate.nomineeRelationship);
            this.certficateForm.controls['state'].setValue(this.certificate.state);
            this.certficateForm.controls['clientType'].setValue(this.certificate.clientType);
    
          }
        this.certficateForm.controls['balance'].setValue(this.dealer.dealerBalance);

      }
      );
    },
    error: async error => {
      // this.alertService.error(error);
      this.allDealerConfig = [];
      this.certficateForm.controls['type'].setValue(this.types[0]);
      await this.setDefaultMakeModel()
    
      // this.setDefaultMakeModel();
    }});

      // this.plans = this.user.dealerPlan;
      // this.makeModel = this.user.dealerMake;

    this.certficateForm.controls['userId'].setValue(this.user.id);
    this.certficateForm.get('planAmount').disable;

    this.certficateForm.get('clientType').valueChanges
    .subscribe(value => {
      if (value == "Individual")
      {
        this.isCorporate = false;
        // this.certficateForm.controls['nomineeGender'].setValue(this.genders[1]);
        this.certficateForm.get('gender').setValidators(Validators.required);
        this.certficateForm.get('dob').setValidators(Validators.required);
        this.certficateForm.get('nomineeRelationship').setValidators(Validators.required);
        this.certficateForm.get('employeeContactNumber').clearValidators();        
        this.plans = this.dealer.dealerPlan;
      }
      else
      {
        this.isCorporate = true;
        // this.certficateForm.controls['nomineeGender'].setValue(this.genders[0]);
        this.certficateForm.get('gender').clearValidators();
        this.certficateForm.get('dob').clearValidators();
        this.certficateForm.get('nomineeRelationship').clearValidators();
        this.certficateForm.get('employeeContactNumber').setValidators([Validators.required, Validators.minLength(6)]);
        this.plans = this.dealer.dealerPlan.filter(x => x.policyType.includes("RSA") || x.policyType.includes("DOC"));

      }
      this.certficateForm.controls['gender'].updateValueAndValidity();
      this.certficateForm.controls['dob'].updateValueAndValidity();
      this.certficateForm.controls['nomineeRelationship'].updateValueAndValidity();
      this.certficateForm.controls['employeeContactNumber'].updateValueAndValidity();
    });
    this.certficateForm.controls['dob'].setValue(this.startDateModel);

    this.certficateForm.get('model').valueChanges
      .subscribe(value => {
        if (value == "Others") {
          this.certficateForm.get('variant').setValidators(Validators.required);
        }
        else {
          this.certficateForm.get('variant').clearValidators();
        }
        this.certficateForm.controls['variant'].updateValueAndValidity();
      });

    this.certficateForm.get('type').valueChanges
      .subscribe(value => {
        if (value == "Old") {
          // this.certficateForm.controls['vehicleYear'].setValue(this.currentDate.getFullYear());
          this.certficateForm.get('registrationNumber').setValidators(Validators.required);
          this.years = [this.currentDate.getFullYear(), this.currentDate.getFullYear() - 1
            , this.currentDate.getFullYear() - 2
            , this.currentDate.getFullYear() - 3
            , this.currentDate.getFullYear() - 4
            , this.currentDate.getFullYear() - 5
            , this.currentDate.getFullYear() - 6
            , this.currentDate.getFullYear() - 7
            , this.currentDate.getFullYear() - 8
            , this.currentDate.getFullYear() - 9
            , this.currentDate.getFullYear() - 10
            , this.currentDate.getFullYear() - 11
            , this.currentDate.getFullYear() - 12
            , this.currentDate.getFullYear() - 13
            , this.currentDate.getFullYear() - 14
          ];
          this.certficateForm.controls['startDate'].enable();
        }

        else {
          this.years = [this.currentDate.getFullYear(), this.currentDate.getFullYear() - 1, this.currentDate.getFullYear() - 2];
          this.certficateForm.get('registrationNumber').clearValidators();
          this.certficateForm.controls['startDate'].disable();
        }
        this.certficateForm.controls['registrationNumber'].updateValueAndValidity();
        this.certficateForm.controls['startDate'].updateValueAndValidity();
        //  this.certficateForm.controls['vehicleYear'].setValue(this.years[0]);
      });



    this.certficateForm.get('state').valueChanges.subscribe((value) => {
      if (value != undefined) {
        this.cities = this.stateCity.filter((x) => x.state == value)[0].city;
      }
    })
    this.certficateForm.get('vehicleMake').valueChanges.subscribe((value) => {
      if (value != undefined) {
        if (this.makeModel)
          {
            this.selectedMakeModel = this.makeModel.find(a => a.make == value);
            if (this.selectedMakeModel)
            {
              this.certficateForm.controls['vehicleType'].setValue(this.selectedMakeModel.productType?this.selectedMakeModel.productType:"Two Wheeler");
              this.models =  this.selectedMakeModel.model;
            }
            else
            {
              this.certficateForm.controls['vehicleType'].setValue("Two Wheeler");
              this.models =  ["Others",this.certificate.model];
            }
          }
          else
          {
        this.configDataService.MakeModel$.subscribe(
          (x) => {this.selectedMakeModel = x.find(a => a.make == value);
          if (this.selectedMakeModel)
            {
              this.certficateForm.controls['vehicleType'].setValue(this.selectedMakeModel.productType?this.selectedMakeModel.productType:"Two Wheeler");
              this.models =  this.selectedMakeModel.model;
            }
            else
            {
              this.certficateForm.controls['vehicleType'].setValue("Two Wheeler");
              this.models =  ["Others",this.certificate.model];

            }
          }
        )
      }
    }
    })

    this.certficateForm.get('dob').valueChanges.subscribe((value) => {
      if (value != undefined) {
        this.customerDob = value.year + "-" + ('0' + (value.month)).slice(-2)+  "-" + ('0' + (value.day)).slice(-2)  ;
      }
    })

  }


  get f() { return this.certficateForm.controls; }
  getMakeModelFromDealerConfig() {
    this.makeModel = [...new Set(this.dealerConfig.dealerModelPrices.map(a => a.make))].map(a=> {
      return {
        make: a
      , model: [...new Set(this.dealerConfig.dealerModelPrices.filter(b => b.make == a).map(r=> r.model))]
      , productType: "Two Wheeler"
    }});
  }
  async setDefaultMakeModel(){
    this.configDataService.MakeModel$.subscribe(x => {
      this.makeModel = x;
    })
  }


  onEndorseClick() {
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();
    // stop here if form is invalid
    if (this.certficateForm.invalid) {
      return;
    }
    this.loading = true;
    var issueCertificate: Certificate;
    issueCertificate = new Certificate(this.certficateForm.value);
    issueCertificate.certificateNumber = this.certificate.certificateNumber;
    if (!issueCertificate.email)
        issueCertificate.email = " ";
    
    if (this.isCorporate)
    { 
      issueCertificate.gender = "Organisation"
      issueCertificate.dob = "NA"
      issueCertificate.nomineeRelationship = "NA"   
    }
    else{
      issueCertificate.employeeContactNumber = "NA";
      this.customerDob  = this.convertTextDate(this.certficateForm.get('dob').value);
      issueCertificate.dob = this.customerDob;
      }

    if (issueCertificate.registrationNumber.trim().length == 0) {
      issueCertificate.registrationNumber = "New";
    }
    if (this.endorseRequest){
    this.certificateService.endorseRequestCertificate(issueCertificate)
      .pipe(first())
      .subscribe({
        next: (x) => {
          this.certificateResponse = new Certificate(x);
          this.certficateForm.controls['endorsementReason'].setValue(this.certificateResponse.endorsementReason);
          this.certficateForm.controls['endorsementCount'].setValue(this.certificateResponse.endorsementCount);
          this.startDateModel =  {"year":2010, "month": 1,"day": 1};

          if(this.certificateResponse.ageOrDob && this.certificateResponse.ageOrDob == "age")
    {
      this.startDateModel =  {"year":2010, "month": 1,"day": 1};
    }
    else
    {
          this.startDateModel.year = parseInt(this.certificateResponse.dob.split('-')[0]);
          this.startDateModel.day = parseInt(this.certificateResponse.dob.split('-')[2]);
          this.startDateModel.month = parseInt(this.certificateResponse.dob.split('-')[1]);
          }
          this.certficateForm.patchValue(this.certificateResponse);
          this.certficateForm.controls['dob'].setValue(this.startDateModel);
      
          // this.certificateCreated = true;

          this.alertService.success('Endorsement request created successfully', { keepAfterRouteChange: true });
          //introduce delay to allow the alert to show
          setTimeout(() => {
            this.loading = false;
            this.backToPrintCertificate();
          }, 3000);
          // this.onPdfClick();
        },
        error: error => {
          this.alertService.error(error);
          this.loading = false;
        }
      });
    }
    else {
    this.certificateService.editCertificate(issueCertificate)
    .pipe(first())
    .subscribe({
      next: (x) => {
        this.certificateResponse = new Certificate(x);
        this.certficateForm.controls['endorsementReason'].setValue(this.certificateResponse.endorsementReason);
        this.certficateForm.controls['endorsementCount'].setValue(this.certificateResponse.endorsementCount);
        this.startDateModel =  {"year":2010, "month": 1,"day": 1};
        if(this.certificateResponse.ageOrDob && this.certificateResponse.ageOrDob == "age")
    {
      this.startDateModel =  {"year":2010, "month": 1,"day": 1};
    }
    else
    {this.startDateModel.year = parseInt(this.certificateResponse.dob.split('-')[0]);
        this.startDateModel.day = parseInt(this.certificateResponse.dob.split('-')[2]);
        this.startDateModel.month = parseInt(this.certificateResponse.dob.split('-')[1]);
    }
        this.certficateForm.patchValue(this.certificateResponse);
        this.certficateForm.controls['dob'].setValue(this.startDateModel);
    
        this.certificateCreated = true;

        this.alertService.success('Endorsement request completed successfully', { keepAfterRouteChange: true });
        setTimeout(() => {
          this.loading = false;
          this.backToPrintCertificate();
        }, 3000);
      // this.onPdfClick();
      },
      error: error => {
        this.alertService.error(error);
        this.loading = false;
      }
    });

  }
    return;
  }
  convertTextDate(value: NgbDateStruct) {
    if (value) {
      this.dobString = value.year.toString();
      if (value.month < 10) {
        this.dobString = this.dobString + "-0" + value.month;
      }
      else {
        this.dobString = this.dobString + "-" + value.month;
      }
      if (value.day < 10) {
        this.dobString = this.dobString + "-0" + value.day;
      }
      else {
        this.dobString = this.dobString + "-" + value.day;
      }
    }
    return this.dobString;
  }
  onReset() {
    this.certificateCreated = false;
    this.submitted = false;
    this.alertService.clear();
    window.location.reload();
  }
  onPdfClick() {
    this.certificateService.PrintCertificate = this.certificateResponse;
    this.alertService.clear();
    switch (true)
    {
      case this.certificateResponse.policyType.startsWith("RSA (CAR)") :
        this.router.navigate(['../print/RSAPv'], { relativeTo: null });
        break;
      case this.certificateResponse.policyType.startsWith("CPA + RSA + DOC") :
        this.router.navigate(['../print/RSANewDoc'], { relativeTo: null });
        break;
      case this.certificateResponse.policyType.toUpperCase().startsWith("RSA EV") :
        this.router.navigate(['../print/RSAEv'], { relativeTo: null });
        break;
      case this.certificateResponse.policyType.includes("RSA"):
        this.router.navigate(['../print/RSANew'], { relativeTo: null });
        break;
      case this.certificateResponse.policyType.includes("CPA + DOC") || (this.certificateResponse.gstShow && this.certificateResponse.gstShow == "N" && this.certificateResponse.policyType.includes("PA")):
        this.router.navigate(['../print/CpaDoc'], { relativeTo: null });
        break;

      case this.certificateResponse.policyType.includes("FINANCE"):
        this.router.navigate(['../print/Finance'], { relativeTo: null });
        break;
      default:
        this.router.navigate(['../print/PaNew'], { relativeTo: null });
        break;

    }
  }
  backToPrintCertificate(){
    this.router.navigate(['../reports/printCertificate'], { relativeTo: null });
  }
}
