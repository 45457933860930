import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { User } from '@app/_models';
import { Dealer } from '@app/_models/dealer';
import { ReportSearch } from '@app/_models/reportSearch';
import { AccountService } from '@app/_services';
import { AlertService } from '@app/_services/alert.service';
import { DealerService } from '@app/_services/dealer-service';
import { InvoiceService } from '@app/_services/invoice.service';
import { SearchService } from '@app/_services/search.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';
import { AgGridAngular } from 'ag-grid-angular';
import { GridOptions } from 'ag-grid-community';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-invoice-report',
  templateUrl: './invoice-report.component.html'
})
export class InvoiceReportComponent implements OnInit {
  @ViewChild('aggrid') aggrid: AgGridAngular;
  certificateReportForm: UntypedFormGroup;
  submitted=false;
  months= ["01","02","03","04","05","06","07","08","09","10","11","12"];
  years = [new Date().getFullYear(), new Date().getFullYear() - 1];
  gridOptions: GridOptions;
  loading= false;
  gridReady = true;
  reportSearch: ReportSearch;
  reportCertificateData : any[];
  reportCreated = false;
  dealer : Dealer;
  searchRegex : RegExp;


  user: User;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private accountService: AccountService,
    private dealerService: DealerService,
    private invoiceService: InvoiceService

  ) { }

  ngOnInit(): void {
    this.user = this.accountService.userValue;
    this.gridOptions = <GridOptions>{}; 
    this.certificateReportForm = this.formBuilder.group({
      selectedYear: [this.years[0]],
      selectedMonth: [this.months[0]],
      dealerName: [''],
          });
        
    this.gridOptions.defaultColDef = {
          resizable: true,      
        };
      
    this.gridOptions.columnDefs = [
             {headerName: "Serial No", valueGetter: "node.rowIndex + 1"}
            ,{headerName: "Dealer Id", field: "dealerId",sortable: true, filter:true }
            ,{headerName: "Dealer Name", field: "dealerName",sortable: true, filter:true }
            ,{headerName: "Invoice Number", field: "dealerInvoiceNumber",sortable: true, filter:true }
            ,{headerName: "Invoice Status", field: "invoiceStatus",sortable: true, filter:true }
            ,{headerName: "Invoice Generated Date", field: "invoiceGeneratedDate",sortable: true, filter:true }
            ,{headerName: "Invoice Paid Date", field: "invoicePaidDate",sortable: true, filter:true }
            ,{headerName: "Current Balance", field: "dealerCurrentBalance",sortable: true, filter:true }
            ,{headerName: "Invoice Month Balance", field: "dealerMonthEndBalance",sortable: true, filter:true }
            ,{headerName: "Total Receivable", field: "dealerTotalReceivable",sortable: true, filter:true }
            ,{headerName: "Total GST Receivable", field: "dealerTotalGstReceivable",sortable: true, filter:true }
            ,{headerName: "Paid Receivable", field: "dealerTotalReceivablePaid",sortable: true, filter:true }
            ,{headerName: "Paid GST Receivable", field: "dealerTotalGstReceivablePaid",sortable: true, filter:true }
            ,{headerName: "Pending Receivable", field: "dealerTotalReceivablePending",sortable: true, filter:true }
            ,{headerName: "Pending GST Receivable", field: "dealerTotalGstReceivablePending",sortable: true, filter:true }
            ,{headerName: "Invoice Type", field: "invoiceType",sortable: true, filter:true }
  ];
          this.gridOptions.rowData = [];
          this.gridReady = true;
          
          this.certificateReportForm.get('dealerName').valueChanges
          .subscribe(value => {
            if ((value != undefined) && (this.reportCertificateData)) {
              this.searchRegex = new RegExp(value,"i");
              this.aggrid.api.setRowData(this.reportCertificateData.filter(x => x.dealerName.match(this.searchRegex) ));              
            }
          });
           
        }
            // convenience getter for easy access to form fields
            get f() { return this.certificateReportForm.controls; }

            onSubmit(){
              this.submitted = true;
              this.loading = true;
              this.reportCreated = false;
              if (this.certificateReportForm.invalid) {
                this.loading = false;
                return;
              }
              
              this.alertService.clear();
              if (this.user.role !="admin")
              {
              this.alertService.error("Unauthorized access");
              this.submitted = false;
              this.loading = false;
              return;              
              }
              var invoiceNumber = this.certificateReportForm.get("selectedYear").value + "-" + this.certificateReportForm.get("selectedMonth").value 
              + "-NotSubmitted";

              this.invoiceService.searchInvoice({invoiceId: invoiceNumber})
              // this.dealerService.getAllDealer()
              .pipe(first())
              .subscribe({
                next: (x) => {
                  console.log("invoiceNumber: " + invoiceNumber)
                  this.reportCertificateData = x.invoices
                  this.reportCertificateData = this.reportCertificateData.map((invoice) => {
                    var dealer = x.dealer.find(y => y.dealerId == invoice.dealerId);
                    return {
                      "dealerId": invoice.dealerId,
                      "dealerName": dealer.dealerName,
                      "dealerInvoiceNumber" : invoice.invoiceNumber, 
                      "dealerCurrentBalance" : dealer.dealerBalance,
                      "dealerMonthEndBalance" : invoice.dealerLastBalance, 
                      "dealerTotalReceivable" : invoice.dealerTotalPayment, 
                      "dealerTotalGstReceivable" : invoice.dealerTotalGstPayment, 
                      "dealerTotalReceivablePaid" : invoice.dealerTotalRefund, 
                      "dealerTotalGstReceivablePaid" : invoice.dealerTotalGstRefund, 
                      "dealerTotalReceivablePending" : invoice.dealerPendingRefund, 
                      "dealerTotalGstReceivablePending" : invoice.dealerGstPendingRefund, 
                      "invoiceStatus" : invoice.invoiceStatus, 
                      "invoiceGeneratedDate" : invoice.invoiceGeneratedDate, 
                      "invoicePaidDate" : invoice.invoicePaidDate, 
                      "invoiceType" : invoice.invoiceType, 
                    }
                  }
                  );

                    // x.map((y) => {
                  //   if (y.dealerInvoice) {
                  //     if (y.dealerInvoice[invoiceNumber])
                  //   return {
                  //   "dealerId": y.dealerId,
                  //   "dealerName": y.dealerName,
                  //   "dealerInvoiceNumber" : y.dealerInvoice[invoiceNumber].invoiceNumber, 
                  //   "dealerCurrentBalance" : y.dealerBalance,
                  //   "dealerMonthEndBalance" : y.dealerInvoice[invoiceNumber].dealerLastBalance, 
                  //   "dealerTotalReceivable" : y.dealerInvoice[invoiceNumber].dealerTotalPayment, 
                  //   "dealerTotalGstReceivable" : y.dealerInvoice[invoiceNumber].dealerTotalGstPayment, 
                  //   "dealerTotalReceivablePaid" : y.dealerInvoice[invoiceNumber].dealerTotalRefund, 
                  //   "dealerTotalGstReceivablePaid" : y.dealerInvoice[invoiceNumber].dealerTotalGstRefund, 
                  //   "dealerTotalReceivablePending" : y.dealerInvoice[invoiceNumber].dealerPendingRefund, 
                  //   "dealerTotalGstReceivablePending" : y.dealerInvoice[invoiceNumber].dealerGstPendingRefund, 
                  //   "invoiceStatus" : y.dealerInvoice[invoiceNumber].invoiceStatus, 
                  //   "invoiceGeneratedDate" : y.dealerInvoice[invoiceNumber].invoiceGeneratedDate, 
                  //   "invoicePaidDate" : y.dealerInvoice[invoiceNumber].invoicePaidDate, 
                  //   "invoiceType" : y.dealerInvoice[invoiceNumber].invoiceType, 
                  // }
                  // else return {
                  //   "dealerCurrentBalance": undefined,
                  // }
                // }
                // else return {
                //   "dealerCurrentBalance": undefined,
                // }
                //   }).filter(x => (x.dealerCurrentBalance != undefined));
                  // console.log("output: " + JSON.stringify(x));
                  this.aggrid.api.setRowData(this.reportCertificateData);
                  this.loading = false;
                },
                error: error => {
                  this.alertService.error(error)
                  this.loading = false;
                }
              })  
                  
            }  
            onReset(){
              this.reportCreated = false;
              this.submitted = false;
              this.loading = false;

            }
            exportToExcel(){
              const params = {
                columnGroups: true,
                allColumns: true,
                fileName: 'excelReport',
           //     columnSeparator: document.querySelector("#columnSeparator").value
              };
              this.aggrid.api.exportDataAsCsv(params);
              // this.aggrid.api.exportDataAsExcel(params);
            }
                 

}
