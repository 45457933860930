import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Certificate } from '@app/_models/certificate';
import { CertificateResponse } from '@app/_models/certificateResponse';
import { environment } from '@environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CertificateService {
  private certificateSubject: BehaviorSubject<Certificate>;
  currentCertificate: Observable<Certificate>;

  constructor(private router: Router,
    private http: HttpClient) { 
      this.certificateSubject = new BehaviorSubject<Certificate>(new Certificate());
      this.currentCertificate = this.certificateSubject.asObservable();
    }
    public get currentCertificateValue(): Certificate {
      return this.certificateSubject.value;
  }
    setCertificate(certificate: Certificate){
      this.PrintCertificate = certificate;
      this.certificateSubject.next(certificate);
    } 
    public PrintCertificate: Certificate;

    issueBulkCertificate(certificates: Certificate[]){
      return this.http.post<CertificateResponse>(`${environment.apiUrl}/certificate/issueBulkCertificate`, certificates);
    }
    issueCertificate(certificate: Certificate){
    console.log("certificate: " + certificate);
    return this.http.post<CertificateResponse>(`${environment.apiUrl}/certificate/issueCertificate`, certificate);
  }
  editCertificate(certificate: Certificate){
    // console.log("certificate: " + certificate);
    return this.http.post<Certificate>(`${environment.apiUrl}/certificate/editCertificate`, certificate);
  }
  rsaProvideCertificate(certificate: Certificate){
    return this.http.post<Certificate>(`${environment.apiUrl}/certificate/rsaProvideCertificate`, certificate);
  }
  cancelCertificate(certificate: Certificate){
    console.log("certificate: " + certificate);
    return this.http.get(`${environment.apiUrl}/certificate/cancelCertificate/${certificate.id}`)

  }
  requestOnCertificate(certificate: Certificate){
    return this.http.post<Certificate>(`${environment.apiUrl}/certificate/requestOnCertificate`, certificate);
  }
  endorseRequestCertificate(certificate: Certificate){
    return this.http.post<Certificate>(`${environment.apiUrl}/certificate/endorseRequestCertificate`, certificate);
  }

}
