<div class="loader-overlay" *ngIf="isLoading">
    <div class="spinner-container">
      <mat-progress-spinner
        mode="indeterminate"
        diameter="90"
        [color]="spinnerColor">
      </mat-progress-spinner>
    </div>
  </div>
  
  <div class="p-4" *ngIf="!isLoading">
    <!-- Existing content -->
    <div class="row">
      <div class="form-group">
        <button (click)="pdfPrint()" class="btn btn-sm btn-success mb-2">
          Download Certificate
        </button>
      </div>
    </div>
    <div class="row">
      <iframe id="myFrame" [src]="pdfSrc" style="width: 80%; height: 80vh;"></iframe>
    </div>
  </div>