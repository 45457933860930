<h1>Dealer Plan Report</h1>
<form [formGroup]="certificateReportForm" (ngSubmit)="onSubmit()">

  <!-- First Row: Date selection and Aggregation Type -->
  <div class="filter-row">
    <div class="filter-item">
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Date Selection</mat-label>
        <mat-select formControlName="dateSelectionType">
          <mat-option value="month">Select Month</mat-option>
          <mat-option value="range">Select Date Range</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="filter-item" *ngIf="certificateReportForm.get('dateSelectionType').value === 'month'">
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Month</mat-label>
        <input matInput
               [matDatepicker]="pickerMonth"
               formControlName="issueMonth"
               readonly  
               (monthSelected)="chosenMonthHandler($event, pickerMonth)"
               (dateChange)="onMonthChanged($event)">
        <mat-datepicker-toggle matSuffix [for]="pickerMonth"></mat-datepicker-toggle>
        <mat-datepicker #pickerMonth
                        startView="multi-year"
                        (monthSelected)="chosenMonthHandler($event, pickerMonth)"
                        panelClass="month-picker">
        </mat-datepicker>
      </mat-form-field>
    </div>

    <div class="filter-item" *ngIf="certificateReportForm.get('dateSelectionType').value === 'range'">
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Issue Date Range</mat-label>
        <mat-date-range-input [rangePicker]="rangePicker">
          <input matStartDate placeholder="From Date" formControlName="issueDateFrom">
          <input matEndDate placeholder="To Date" formControlName="issueDateTo">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle>
        <mat-date-range-picker #rangePicker></mat-date-range-picker>
      </mat-form-field>
    </div>

    <div class="filter-item">
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Aggregation Type</mat-label>
        <mat-select formControlName="aggregationType">
          <mat-option 
            *ngFor="let aggregationType of ['', 'IssueDate', 'IssueDateHorizontal']"
            [value]="aggregationType"
          >
            {{ aggregationType }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <!-- Second Row: Dealer/CPA/Policy controls -->
  <div class="filter-row">
    <div class="filter-item" *ngIf="user.role == 'admin' || user.role == 'employee'">
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Dealer Id</mat-label>
        <input matInput formControlName="dealerId">
        <mat-error *ngIf="submitted && f.dealerId.errors?.['required']">
          Dealer Id is required
        </mat-error>
      </mat-form-field>
    </div>

    <div class="filter-item" *ngIf="user.role == 'admin' || user.role == 'employee'">
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Dealer Group Name</mat-label>
        <input matInput formControlName="dealerGroupName">
      </mat-form-field>
    </div>

    <div class="filter-item" *ngIf="user.role == 'admin' || user.role == 'employee'">
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Dealer State</mat-label>
        <input matInput formControlName="dealerState">
      </mat-form-field>
    </div>

    <div class="filter-item" *ngIf="user.role == 'admin'">
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>CPA Policy Company</mat-label>
        <mat-select formControlName="cpaPolicyCompany">
          <mat-option *ngFor="let cpaPolicyCompny of cpaPolicyCompanies" [value]="cpaPolicyCompny">
            {{ cpaPolicyCompny }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="filter-item" *ngIf="user.role == 'admin'">
      <mat-form-field appearance="fill" class="full-width">
        <mat-label>Has Policy</mat-label>
        <mat-select formControlName="hasPolicy">
          <mat-option *ngFor="let hasPolicyFlag of booleanArray" [value]="hasPolicyFlag">
            {{ hasPolicyFlag }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <!-- Buttons -->
  <div class="button-row">
    <button mat-raised-button color="primary" [disabled]="loading" type="submit">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      Search
    </button>
    <button mat-raised-button color="accent" type="reset" (click)="onReset()">
      Reset
    </button>
    <button mat-raised-button color="primary" (click)="exportToExcel()">
      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
      Export to Excel
    </button>
  </div>

  <!-- AG Grid -->
  <div class="grid-row">
    <ag-grid-angular
      #aggrid
      style="width: 100%; height: 600px;"
      [gridOptions]="gridOptions"
      class="ag-theme-alpine"
    >
    </ag-grid-angular>
  </div>

</form>