<!-- Assignment Stats Summary Cards (Surprise Enhancement) -->
<!-- <div *ngIf="!loading" class="assignment-stats" [class.preload]="preload">
  <div class="stat-card">
    <div class="stat-value">{{getAssignmentStats().totalDealers}}</div>
    <div class="stat-label">Total Dealers</div>
    <mat-icon class="stat-icon">business</mat-icon>
  </div>
  <div class="stat-card">
    <div class="stat-value">{{getAssignmentStats().assignedCount}}</div>
    <div class="stat-label">Assigned Dealers</div>
    <div class="progress-bar">
      <div class="progress" [style.width.%]="getAssignmentStats().assignmentPercentage"></div>
    </div>
    <mat-icon class="stat-icon">check_circle</mat-icon>
  </div>
  <div class="stat-card">
    <div class="stat-value">{{getAssignmentStats().unassignedCount}}</div>
    <div class="stat-label">Unassigned Dealers</div>
    <mat-icon class="stat-icon danger">error</mat-icon>
  </div>
</div> -->

<div class="filter-toolbar">
  <mat-form-field appearance="outline">
    <mat-label>Filter Dealers</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Dealer ID, Name, City, State...">
    <mat-icon matSuffix>search</mat-icon>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Assignment Status</mat-label>
    <mat-select [(ngModel)]="assignmentFilter" (selectionChange)="applyFilters()">
      <mat-option value="all">All Dealers</mat-option>
      <mat-option value="assigned">Assigned Only</mat-option>
      <mat-option value="unassigned">Unassigned Only</mat-option>
    </mat-select>
  </mat-form-field>
  
  <mat-form-field appearance="outline">
    <mat-label>Filter by State</mat-label>
    <mat-select [(ngModel)]="stateFilter" (selectionChange)="applyFilters()">
      <mat-option value="all">All States</mat-option>
      <mat-option *ngFor="let state of getUniqueStates()" [value]="state">{{state}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Filter by Employee</mat-label>
    <mat-select [(ngModel)]="employeeFilter" (selectionChange)="applyFilters()">
      <mat-option value="all">All Employees</mat-option>
      <mat-option *ngFor="let emp of employees" [value]="emp.username">
        {{emp.firstName}} {{emp.lastName}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <button mat-raised-button color="primary" (click)="onExportData()" [disabled]="loading">
    <mat-icon>file_download</mat-icon> Export to Excel
  </button>
</div>

<!-- Loading Indicator -->
<div *ngIf="loading" class="spinner-container">
  <mat-progress-spinner mode="indeterminate" diameter="40"></mat-progress-spinner>
</div>

<!-- Dealer Grid with AG Grid -->
<div class="grid-container" *ngIf="!loading" [class.preload]="preload">
  <ag-grid-angular
    class="ag-theme-alpine"
    [rowData]="filteredDealers"
    [columnDefs]="columnDefs"
    [gridOptions]="gridOptions"
    [pagination]="true"
    [paginationPageSize]="10"
    (gridReady)="onGridReady($event)"
    (cellClicked)="onCellClicked($event)"
    domLayout="normal">
  </ag-grid-angular>
</div>

<!-- Empty State Message -->
<div *ngIf="!loading && filteredDealers.length === 0" class="empty-state" [class.preload]="preload">
  <mat-icon>business_off</mat-icon>
  <h3>No dealers match your filters</h3>
  <p>Try adjusting your filter criteria to see results</p>
  <button mat-button color="primary" (click)="assignmentFilter='all'; stateFilter='all'; employeeFilter='all'; applyFilters()">
    Clear Filters
  </button>
</div>
