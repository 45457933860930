<ul class="navbar-nav me-auto mb-2 mb-lg-0">
  <li class="nav-item">
    <a class="nav-link active" aria-current="page" routerLink="/" routerLinkActive="active" 
       [routerLinkActiveOptions]="{exact: true}" (click)="collapseNavbar()">
       <i class="fa fa-home me-1"></i>Home
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLink="/reports/heroDealerSummaryReport" 
       routerLinkActive="active" (click)="collapseNavbar()">
       <i class="fa fa-file-text me-1"></i>Dealer Summary Report
    </a>
  </li>
</ul>
