export class RsaProvided{
    problemStatement:string;
    resolutionComment:string;
    city:string;
    callbackNumber:number;
    callerName:string;
    rsaProvidedDate:Date;
    rsaProvideInstanceNumber:number;

    public constructor(init?: Partial<RsaProvided>) {
        Object.assign(this, init);
    }   

}