<div class="filter-toolbar">
  <mat-form-field appearance="outline">
    <mat-label>Filter Employees</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Name, Username...">
    <mat-icon matSuffix>search</mat-icon>
  </mat-form-field>
  
  <button mat-raised-button color="primary" (click)="onExportData()" [disabled]="loading">
    <mat-icon>file_download</mat-icon> Export to Excel
  </button>
</div>

<!-- Loading Indicator -->
<div *ngIf="loading" class="spinner-container">
  <mat-progress-spinner mode="indeterminate" diameter="40"></mat-progress-spinner>
</div>

<!-- Employee Grid with AG Grid -->
<div class="grid-container" *ngIf="!loading">
  <ag-grid-angular
    class="ag-theme-alpine"
    [rowData]="filteredEmployees"
    [columnDefs]="columnDefs"
    [gridOptions]="gridOptions"
    [pagination]="true"
    [paginationPageSize]="10"
    (gridReady)="onGridReady($event)"
    (cellClicked)="onCellClicked($event)"
    domLayout="normal">
  </ag-grid-angular>
</div>
