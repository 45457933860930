import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { RequestHistory } from '@app/_models/requestHistory';
import { CommonModule, DatePipe } from '@angular/common';
import { Subject } from 'rxjs';
import { CertificateService } from '@app/_services/certificate.service';
import { Certificate } from '@app/_models/certificate';

@Component({
  selector: 'app-show-request-history',
  standalone: true,
  imports: [DatePipe,CommonModule],
  templateUrl: './show-request-history.component.html',
  styleUrl: './show-request-history.component.less',
  exportAs: 'showRequestHistory'
})
export class ShowRequestHistoryComponent implements OnInit{
  @Input() printCertificate: Certificate; // specify the correct type instead of 'any'

  private _requestHistory = new Subject<RequestHistory[]>();
  requestHistory$ = this._requestHistory.asObservable();
  requestHistory: RequestHistory[] = [];
  certificate:Certificate

  constructor(private datePipe: DatePipe, private certificateService:CertificateService, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.certificateService.currentCertificate.subscribe((data) => {
      if (data) {
        if (this.printCertificate) {
          data = this.printCertificate;
        }
      this.requestHistory = data.requestHistory;
      console.log("requestHistory: " + JSON.stringify(this.requestHistory));
      this.updateRequestHistory(this.requestHistory);
      this.certificate = data;
      this.cdr.detectChanges();
      }
  })
  }
  updateRequestHistory(newRequestHistory: RequestHistory[]) {
    this._requestHistory.next(newRequestHistory);
  }
}
