import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { UsersRoutingModule } from './users-routing.module';
import { LayoutComponent } from './layout.component';
import { ListComponent } from './list.component';
import { AddEditComponent } from './add-edit.component';
import { AddDealerComponent } from './add-dealer.component';
import { DealerListComponent } from './dealer-list.component';
import { AddEditPlanDetailsComponent } from './add-edit-plan-details/add-edit-plan-details.component';
import { AddPaymentComponent } from './add-payment/add-payment.component';
import { ViewDealerComponent } from './view-dealer/view-dealer.component';
import { AddPayoutComponent } from './add-payout/add-payout.component';
import { DealerPaymentComponent } from './dealer-payment/dealer-payment.component';
import { DealerPayoutComponent } from './dealer-payout/dealer-payout.component';
import { AgGridModule } from 'ag-grid-angular';
import { PayoutCertificateReportComponent } from './dealer-payout/payout-certificate-report/payout-certificate-report.component';
import { PrintInvoiceComponent } from './dealer-payout/print-invoice/print-invoice.component';
import { DealerPaymentGatewayComponent } from './dealer-payment-gateway/dealer-payment-gateway.component';
import { PackagePaymentComponent } from './package-payment/package-payment.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        UsersRoutingModule,
        AgGridModule
    ],
    declarations: [
        LayoutComponent,
        ListComponent,
        AddEditComponent,
        AddDealerComponent,
        DealerListComponent,
        AddEditPlanDetailsComponent,
        AddPaymentComponent,
        ViewDealerComponent,
        AddPayoutComponent,
        DealerPaymentComponent,
        DealerPayoutComponent,
        PayoutCertificateReportComponent,
        PrintInvoiceComponent,
        PackagePaymentComponent,
        // DealerPaymentGatewayComponent
    ],
    // exports:[DealerPaymentGatewayComponent]
})
export class UsersModule { }