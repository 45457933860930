import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { User } from '@app/_models';
import { Certificate } from '@app/_models/certificate';
import { AccountService, AlertService } from '@app/_services';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import { AgGridAngular } from 'ag-grid-angular';
import { GridOptions } from 'ag-grid-community';
import { first } from 'rxjs/internal/operators/first';
import {ReportSearch} from '../../_models/reportSearch'
import {SearchService} from '../../_services/search.service'

@Component({
  selector: 'app-rsa-certificate-report',
  templateUrl: './rsa-certificate-report.component.html',
  styleUrls: ['./rsa-certificate-report.component.less']
})
export class RsaCertificateReportComponent implements OnInit {
  @ViewChild('aggrid') aggrid: AgGridAngular;
  private gridApi;
  certificateReportForm: UntypedFormGroup;
  submitted=false;
  issueDateFrom: NgbDateStruct;
  issueDateTo: NgbDateStruct;
  gridOptions: GridOptions;
  loading= false;
  gridReady = true;
  reportSearch: ReportSearch;
  issueDateFromSearch : string;
  issueDateToSearch : string;
  reportCertificateData : Certificate[];
  user: User;

  

  constructor(        
    private formBuilder: UntypedFormBuilder,
    private searchService: SearchService, 
    private alertService: AlertService,
    private accountService: AccountService
    ) { }

  ngOnInit()  {
    this.user = this.accountService.userValue;
    this.gridOptions = <GridOptions>{}; 
    this.certificateReportForm = this.formBuilder.group({
      certificateNumber: [''],
      policyNumber: [''],
      issueDateFrom: [''],
      issueDateTo: [''],
      dealerId: [''],
          });
    if (this.user.role == "admin")
    this.gridOptions.columnDefs = [
      {headerName: "Serial No", valueGetter: "node.rowIndex + 1"}
      ,{headerName: "Certificate Number", field: "certificateNumber",sortable: true, filter:true }
      ,{headerName: "Policy Start Date", field: "startDate",sortable: true, filter:true }
      ,{headerName: "Policy End Date", field: "endDate",sortable: true, filter:true }
      ,{headerName: "First Name", field: "firstName",sortable: true, filter:true }
      ,{headerName: "Last Name", field: "lastName",sortable: true, filter:true }
      ,{headerName: "Customer City", field: "city",sortable: true, filter:true }
      ,{headerName: "Customer State", field: "state",sortable: true, filter:true }
      ,{headerName: "Engine Number", field: "engineNumber",sortable: true, filter:true }
      ,{headerName: "Chassis Number", field: "chasisNumber",sortable: true, filter:true }
      ,{headerName: "Vehicle Registration Number", field: "registrationNumber",sortable: true, filter:true }
      ,{headerName: "Brand", field: "vehicleMake",sortable: true, filter:true }
      ,{headerName: "Model", field: "model",sortable: true, filter:true }
      ,{headerName: "Manufacture Year", field: "vehicleYear",sortable: true, filter:true }
    ]

    // this.gridOptions.enableCharts = true;
    // this.gridOptions.enableRangeSelection = true;
    this.gridOptions.rowData = [];
    this.gridReady = true;
    
    this.certificateReportForm.get('issueDateFrom').valueChanges
    .subscribe(value => {
      if (value != undefined) {
        this.issueDateFromSearch =  value.year + '-' + ('0' + value.month).slice(-2) + '-' + ('0' + value.day).slice(-2);
      }
    });

    this.certificateReportForm.get('issueDateTo').valueChanges
    .subscribe(value => {
      if (value != undefined) {
        this.issueDateToSearch =  value.year + '-' + ('0' + value.month).slice(-2) + '-' + ('0' + value.day).slice(-2);
      }
      console.log("issueDateToSearch: " + this.issueDateToSearch)
    });

  }
      // convenience getter for easy access to form fields
      get f() { return this.certificateReportForm.controls; }

  onSubmit(){
    this.submitted = true;
    this.loading = true;
    this.alertService.clear();
    console.log("issueDateTo: " + this.issueDateFromSearch)

  //   if (this.certificateReportForm.invalid) {
  //     return;
  // }

    if (this.user.id == undefined)
    {
      this.alertService.error("User Credential Missing, Please login again and try");
      return
    }

    this.reportSearch = new ReportSearch();
    this.reportSearch.userId = this.user.id;
    
    if (this.issueDateFromSearch == undefined)
    {
      this.reportSearch.issueDateFrom = "0001-01-01";
    }
    else
    this.reportSearch.issueDateFrom = this.issueDateFromSearch;

    if (this.issueDateToSearch == undefined)
    {
      this.reportSearch.issueDateTo = "9999-99-99";
    }
    else
    this.reportSearch.issueDateTo = this.issueDateToSearch;


    this.reportSearch.certificateNumber = this.certificateReportForm.get('certificateNumber').value;
    this.reportSearch.policyNumber = this.certificateReportForm.get('policyNumber').value;
      this.searchService.getRsaCertificateReport(this.reportSearch)
      .pipe(first())
      .subscribe({
        next: (x) => {
          this.reportCertificateData = x;
          // this.gridOptions.rowData = x;
          this.aggrid.api.setRowData(x);
          console.log("x: " + JSON.stringify(x))
          this.gridReady = true;
          this.loading = false;
        },
        error: error => {
          this.alertService.error(error)
          this.loading = false;
        }
      })      
    return;
  }
  onReset(){

  }
  exportToExcel(){
    const params = {
      columnGroups: true,
      allColumns: true,
      fileName: 'excelRsaReport',
 //     columnSeparator: document.querySelector("#columnSeparator").value
    };
    this.aggrid.api.exportDataAsCsv(params);
    // this.aggrid.api.exportDataAsExcel(params);
  }
}
