<div class="employee-dashboard">
  <!-- Stats Section -->
  <!-- <div class="row mb-4">
    <div class="col-12">
      <h2 class="section-title">Performance Overview</h2>
    </div>
    <div class="col-md-3 col-sm-6 mb-3" *ngFor="let stat of statistics">
      <div class="stat-card" [style.border-left-color]="stat.color">
        <div class="card-body">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <div class="stat-value" *ngIf="!stat.loading">
                {{ stat.value | number }}
              </div>
              <div class="stat-value-loading" *ngIf="stat.loading">
                <div class="loading-placeholder"></div>
              </div>
              <div class="stat-title">{{ stat.title }}</div>
            </div>
            <div class="stat-icon" [style.background-color]="stat.color">
              <i class="fa" [ngClass]="stat.icon"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  
  <!-- Quick Actions -->
  <div class="row mb-4">
    <div class="col-12">
      <h2 class="section-title">Quick Actions</h2>
    </div>
    <div class="col-md-3 col-sm-6 mb-3" *ngFor="let link of quickLinks">
      <a [routerLink]="link.route" class="quick-link-card">
        <div class="icon-circle" [style.background-color]="link.color">
          <i class="fa" [ngClass]="link.icon"></i>
        </div>
        <div class="link-title">{{ link.title }}</div>
      </a>
    </div>
  </div>
  
  <!-- Assigned Dealers & Pending Requests -->
  <div class="row">
    <!-- Assigned Dealers -->
    <!-- <div class="col-md-6 mb-4">
      <div class="card h-100">
        <div class="card-header d-flex justify-content-between align-items-center">
          <h5 class="mb-0">Assigned Dealers</h5>
          <a routerLink="/reports/dealerReport" class="small text-decoration-none">View All</a>
        </div>
        <div class="card-body p-0">
          <div class="table-responsive">
            <table class="table dealer-table">
              <thead>
                <tr>
                  <th>Dealer Name</th>
                  <th>Location</th>
                  <th>Certificates</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let dealer of assignedDealers">
                  <td>
                    <div class="d-flex align-items-center">
                      <div class="dealer-avatar">{{ dealer.name.charAt(0) }}</div>
                      <div>{{ dealer.name }}</div>
                    </div>
                  </td>
                  <td>{{ dealer.location }}</td>
                  <td>{{ dealer.certificates }}</td>
                  <td>
                    <span class="badge" [ngClass]="dealer.status === 'active' ? 'bg-success' : 'bg-secondary'">
                      {{ dealer.status }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div> -->
    
    <!-- Pending Requests -->
    <!-- <div class="col-md-6 mb-4">
      <div class="card h-100">
        <div class="card-header d-flex justify-content-between align-items-center">
          <h5 class="mb-0">Pending Requests</h5>
          <div>
            <a routerLink="/reports/activeEndorsement" class="small text-decoration-none me-2">Endorsements</a>
            <a routerLink="/reports/activeCancel" class="small text-decoration-none">Cancellations</a>
          </div>
        </div>
        <div class="card-body p-0">
          <ul class="request-list">
            <li *ngFor="let request of pendingRequests" class="request-item">
              <div [ngClass]="'request-icon ' + getRequestTypeClass(request.type)">
                <i class="fa" [ngClass]="getRequestTypeIcon(request.type)"></i>
              </div>
              <div class="request-content">
                <div class="request-details">
                  <div>
                    <span class="request-id">{{ request.id }}</span>
                    <span class="request-dealer">{{ request.dealer }}</span>
                  </div>
                  <span [ngClass]="getUrgencyClass(request.urgency)">{{ request.urgency }}</span>
                </div>
                <div class="request-date">
                  <i class="fa fa-calendar-o me-1"></i> {{ request.date | date:'dd-MM-yyyy' }}
                </div>
              </div>
            </li>
            <li class="text-center p-3" *ngIf="pendingRequests.length === 0">
              <p class="mb-0 text-muted">No pending requests</p>
            </li>
          </ul>
        </div>
        <div class="card-footer text-center">
          <a routerLink="/reports/activeEndorsement" class="btn btn-sm btn-outline-primary">View All Requests</a>
        </div>
      </div>
    </div>
  </div> -->
</div>
