import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { MakeModel } from '@app/_models/makeModel';
import { StateCity } from '@app/_models/stateCity';
import { PlanDetails } from '@app/_models/planDetails';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { map, shareReplay, take } from 'rxjs/operators';
import { PrintInstruction } from '@app/_models/printInstruction';
import { DealerModelConfig } from '@app/_models/dealerModelConfig';

@Injectable({
  providedIn: 'root'
})
export class ConfigDataService {

  FinanceCompanyList$ = this.getFinanceCompanyList()
  .pipe(
    shareReplay(1)
  );

   MakeModel$ = this.getMakeModel()
   .pipe(
     shareReplay(1)
   );

   StateCity$ = this.getStateCity()
   .pipe(
     shareReplay(1)
   );
   PlanDetails$ = this.getPlanDetails()
   .pipe(
     shareReplay(1)
   );

   PrintInstruction$ = this.getPrintInstructionList()
   .pipe(
     shareReplay(1)
   );
   RSAEvFeatures$ = this.getRSAEvFeatures()
   .pipe(
     shareReplay(1)
   );
  // MakeModel$ = this.MakeModels$.subscribe();

  constructor(private http: HttpClient) { }

  getInvoiceId() {
    return this.http.get<Number>(`${environment.apiUrl}/counter/InvoiceId`);
  }

  getTodaysDate(): Observable<Date>{
    return this.http.get<Date>(`${environment.apiUrl}/counter/Date`)
  }

  getGST(): number{
    return .18;
  }
  getMakeModel(): Observable<MakeModel[]>{
     return this.http.get<MakeModel[]>(`${environment.apiUrl}/counter/config/Model`)
  }

  getStateCity(): Observable<StateCity[]>{
    return this.http.get<StateCity[]>(`${environment.apiUrl}/counter/config/State`);
  }

  getPlanDetails(): Observable<PlanDetails[]>{
    return this.http.get<PlanDetails[]>(`${environment.apiUrl}/counter/config/Plan`);
  }

  getFinanceCompanyList(): Observable<string[]>{
    return this.http.get<string[]>(`${environment.apiUrl}/counter/config/Finance`);
  }

  getPrintInstructionList(): Observable<PrintInstruction[]>{
    return this.http.get<PrintInstruction[]>(`${environment.apiUrl}/counter/config/PrintInstruction`);
  }
  getRSAEvFeatures(): Observable<any[]>{
    return this.http.get<any[]>(`${environment.apiUrl}/counter/config/RSAEvFeatures`);
  }
  getConfig(keyValue: string) {
    return this.http.get(`${environment.apiUrl}/counter/config/${keyValue}`);
  }

  getConfigCounter(configId:any): Observable<any>{
    return this.http.get(`${environment.apiUrl}/counter/getConfigCounter/${configId}`);
  }

  getStringCacheConfig(keyValue: string): Observable<string>{
    return this.http.get<string>(`${environment.apiUrl}/counter/getStringCacheConfig/${keyValue}`);
  }
  resetStringCacheConfig(inputData: any): Observable<string>{
    if (inputData.configType.toUpperCase() == "COUNTER")
    return this.http.post<string>(`${environment.apiUrl}/counter/setConfigCounter/`,inputData);
    else
    {
    if (inputData.configType.toUpperCase() == "ARRAY")
    {
      if(inputData.keyValue != "Plan")
      inputData.ResultValue = JSON.parse(inputData.ResultValue);
    }
    return this.http.post<string>(`${environment.apiUrl}/counter/resetStringCacheConfig/`,inputData);
  }
  }

  getRoles(): Observable<string[]>{
    return of ( ["admin","executive","dealerOwner","manager","employee","rsaOperation"
  ])
  }
  getSpecialRoles(): Observable<string[]>{
    return of ( ["CanViewDealerBalanceReport","ViewNetAmountGST","CannotViewDealerBalance","CanViewOldCertificate","ExcelUpload","CanRenewAllPolicies","canSeeAge","canSeeNewIndiaLogo",
    "cannotSeeIffcoTokioLogo","CanSeePolicyBalance","Can View Policy Balance Details Report","Can View Package Balance Detail Report","Show Total Package Balance","CanSeeOnlyNewPolicies"])
  }
  getDealerModelConfig(dealerId: string): Observable<DealerModelConfig[]>{
    return this.http.get<DealerModelConfig[]>(`${environment.apiUrl}/counter/dealerConfig/DealerModelConfig/${dealerId}`);
  }
  getMasterPolicyMaintain(): Observable<any[]>{
    return this.http.get<any[]>(`${environment.apiUrl}/counter/config/MasterPolicyMaintain`);
  }
  getSeriesMaintain(): Observable<any[]>{
    return this.http.get<any[]>(`${environment.apiUrl}/counter/config/SeriesMaintain`);
  }
  getSetupMaintain(): Observable<any[]>{
    return this.http.get<any[]>(`${environment.apiUrl}/counter/config/SetupMaintain`);
  }
  SeriesMaintain$ = this.getSeriesMaintain()
  .pipe(
    shareReplay(1)
  );
  SetupMaintain$ = this.getSetupMaintain()
  .pipe(
    shareReplay(1)
  );
  MasterPolicyMaintain$ = this.getMasterPolicyMaintain()
  .pipe(
    shareReplay(1)
  );
//   seriesMaintain = [
//     {
//       "key": "iffcotokioCpaId",
//       "heading": "Iffco Tokio CPA Series",
//       "configType": "Counter",
//       "url": "/configUpdate/iffcotokioCpaId"
//     }
//       ,
//       {
//         "key": "iffcotokioCpaIdNew",
//         "heading": "New Iffco Tokio CPA Series",
//         "configType": "Counter",
//         "url": "/configUpdate/iffcotokioCpaIdNew"
//       }
//         ,    {
//       "key": "CertificateId",
//       "heading": "New India Certificate Series",
//       "configType": "Counter",
//       "url": "/configUpdate/CertificateId"
//     }
//     ,
//     {
//       "key": "AplCertificateId",
//       "heading": "New India Finance Series",
//       "configType": "Counter",
//       "url": "/configUpdate/AplCertificateId"
//     }
//     ,
//     {
//       "key": "AplCertificateIdIFT",
//       "heading": "Iffco Tokio Finance Series",
//       "configType": "Counter",
//       "url": "/configUpdate/AplCertificateIdIFT"
//     }
//     ,
//     {
//       "key": "RsaEvCertificateId",
//       "heading": "RSA EV Series",
//       "configType": "Counter",
//       "url": "/configUpdate/RsaEvCertificateId"
//     }
//       ,
//     {
//       "key": "RsaCertificateId",
//       "heading": "RSA Series",
//       "configType": "Counter",
//       "url": "/configUpdate/RsaCertificateId"
//     }
//     ,
//     {
//       "key": "RsaCarCertificateId",
//       "heading": "RSA Car Series",
//       "configType": "Counter",
//       "url": "/configUpdate/RsaCarCertificateId"
//     }
//     ,
//     {
//       "key": "fourWheelerFinanceCounterId",
//       "heading": "New India Four Wheeler Finance Series",
//       "configType": "Counter",
//       "url": "/configUpdate/fourWheelerFinanceCounterId"
//     }
//     ,
//     {
//       "key": "fourWheelerCounterId",
//       "heading": "New India Four Wheeler Series",
//       "configType": "Counter",
//       "url": "/configUpdate/fourWheelerCounterId"
//     }
// ];
//   setupMaintain = [
//     {
//       "key": "RSAEvFeatures",
//       "heading": "RSA EV Plan Features",
//       "configType": "Array",
//       "url": "/configUpdate/RSAEvFeatures"
//     }
//       ,
//     {
//       "key": "Model",
//       "heading": "Models",
//       "configType": "Array",
//       "url": "/configUpdate/Model"
//     }
//     ,
//     {
//       "key": "State",
//       "heading": "States and Cities",
//       "configType": "Array",
//       "url": "/configUpdate/State"
//     }
//     ,
//     {
//       "key": "Plan",
//       "heading": "Plans",
//       "configType": "Array",
//       "url": "/configMaintain/Plan"
//     }
//     ,
//     {
//       "key": "PrintInstruction",
//       "heading": "Print Instructions",
//       "configType": "Array",
//       "url": "/configUpdate/PrintInstruction"
//     }
//     ,
//     {
//       "key": "Finance",
//       "heading": "Finance Company",
//       "configType": "Array",
//       "url": "/configUpdate/Finance"
//     }
//     ,
//     {
//       "key": "SeriesMaintain",
//       "heading": "Define New Series",
//       "configType": "Array",
//       "url": "/configUpdate/SeriesMaintain"
//     }
//     ,
//     {
//       "key": "MasterPolicyMaintain",
//       "heading": "Master Policy Maintain",
//       "configType": "Array",
//       "url": "/configUpdate/MasterPolicyMaintain"
//     },
//     {
//       "key": "SetupMaintain",
//       "heading": "Define New Configuration",
//       "configType": "Array",
//       "url": "/configUpdate/SetupMaintain"
//     }
  
//   ];
//   masterPolicyMaintain = [{
//     "key": "MasterPolicyNumber",
//     "heading": "New India CPA Policy",
//     "configType": "String",
//     "url": "/configUpdate/MasterPolicyNumber"
//   },
//   {
//     "key": "financeMasterPolicyNumber",
//     "heading": "New India Finance Master Policy",
//     "configType": "String",
//     "url": "/configUpdate/financeMasterPolicyNumber"
//   },
//   {
//     "key": "financeMasterPolicyNumberIFT",
//     "heading": "Iffco Tokio Finance Master Policy",
//     "configType": "String",
//     "url": "/configUpdate/financeMasterPolicyNumberIFT"
//   },
//   {
//     "key": "fourWheelerMasterPolicyNumber",
//     "heading": "New India Four Wheeler Master Policy",
//     "configType": "String",
//     "url": "/configUpdate/fourWheelerMasterPolicyNumber"
//   },
//   {
//     "key": "fourWheelerMasterFinancePolicyNumber",
//     "heading": "New India Four Wheeler Master Finance Policy",
//     "configType": "String",
//     "url": "/configUpdate/fourWheelerMasterFinancePolicyNumber"
//   }
//     ,
//   {
//     "key": "iffcotokioCpaPolicy",
//     "heading": "Iffco Tokio CPA Policy",
//     "configType": "String",
//     "url": "/configUpdate/iffcotokioCpaPolicy"
//   },
//   {
//     "key": "iffcotokioCpaPolicyNew",
//     "heading": "New Iffco Tokio CPA Policy",
//     "configType": "String",
//     "url": "/configUpdate/iffcotokioCpaPolicyNew"
//   }
//   ];
}
