import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AccountService } from '@app/_services';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { DealerDashboardComponent } from './dealer-dashboard/dealer-dashboard.component';
import { EmployeeDashboardComponent } from './employee-dashboard/employee-dashboard.component';
import { User } from '@app/_models';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  standalone: true,
  imports: [
    CommonModule, 
    RouterModule,
    AdminDashboardComponent,
    DealerDashboardComponent,
    EmployeeDashboardComponent
  ]
})
export class DashboardComponent implements OnInit {
  user: User;
  greeting: string;
  currentTime = new Date();
  
  constructor(private accountService: AccountService) {}

  ngOnInit(): void {
    this.user = this.accountService.userValue;
    this.setGreeting();
    
    // Update time every minute
    setInterval(() => {
      this.currentTime = new Date();
      this.setGreeting();
    }, 60000);
    
    // Subscribe to user changes
    this.accountService.user.subscribe(user => {
      this.user = user;
    });
  }

  private setGreeting(): void {
    const hour = this.currentTime.getHours();
    if (hour < 12) {
      this.greeting = 'Good Morning';
    } else if (hour < 18) {
      this.greeting = 'Good Afternoon';
    } else {
      this.greeting = 'Good Evening';
    }
  }
  
  getUserDisplayName(): string {
    return this.user?.firstName ? `${this.user.firstName} ${this.user.lastName}` : 'there';
  }
}
