<div class="report-container">
  <header class="report-header">
    <h2><i class="material-icons">analytics</i> Dealer Inactivity Analysis</h2>
  </header>
  
  <mat-card class="filter-card">
    <mat-card-content>
      <form [formGroup]="reportForm" class="flex-container" (ngSubmit)="generateReport()">
        <div class="form-row">
          <mat-form-field appearance="outline">
            <mat-label>Activity Duration (months)</mat-label>
            <mat-select formControlName="activityDuration">
              <mat-option *ngFor="let month of months" [value]="month">{{ month }}</mat-option>
            </mat-select>
            <mat-hint>Select time period to analyze</mat-hint>
          </mat-form-field>
      
          <mat-form-field appearance="outline">
            <mat-label>Inactivity Period (days)</mat-label>
            <mat-select formControlName="inactivityPeriod">
              <mat-option *ngFor="let day of days" [value]="day">{{ day }}</mat-option>
            </mat-select>
            <mat-hint>Minimum days without activity</mat-hint>
          </mat-form-field>
      
          <mat-form-field appearance="outline">
            <mat-label>Percentage Decrease in Business</mat-label>
            <input matInput type="number" formControlName="percentageDecrease" min="0" max="100" placeholder="100">
            <mat-hint>Threshold for business decline</mat-hint>
            <span matSuffix>%</span>
          </mat-form-field>
        </div>
      
        <div class="form-actions">
          <button mat-raised-button color="primary" type="submit" [disabled]="loading">
            <i class="material-icons">query_stats</i> Generate Report
          </button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>

  <div class="loading-indicator">
    <mat-progress-bar *ngIf="loading" mode="indeterminate" color="accent"></mat-progress-bar>
  </div>
  
  <div class="results-container" *ngIf="dealers && dealers.length > 0">
    <div class="grid-chart-container">
      <div class="grid-container">
        <div class="grid-header">
          <h3>Dealer Activity Results</h3>
          <div class="form-actions export-actions">
            <button mat-raised-button color="primary" (click)="exportToExcel()">
              <i class="material-icons">file_download</i> <span class="button-text">Export To Excel</span>
            </button>
          </div>
        </div>
        
        <ag-grid-angular class="ag-theme-alpine dealers-grid"
          [rowData]="dealers"
          [columnDefs]="columnDefs"
          [defaultColDef]="defaultColDef"
          [pagination]="true"
          [paginationPageSize]="100"
          [suppressColumnVirtualisation]="false"
          [domLayout]="isMobile ? 'autoHeight' : 'normal'"
          (gridReady)="onGridReady($event)"
          (rowClicked)="onRowClick($event)">
        </ag-grid-angular>
      </div>
    
      <div class="chart-container">
        <h3>Activity Trend <small *ngIf="selectedDealerId">for Dealer {{selectedDealerId}}</small></h3>
        <div class="chart-placeholder" *ngIf="!selectedDealerId">
          <p><i class="material-icons">touch_app</i> Click on a dealer to view activity trend</p>
        </div>
        <ag-charts 
          *ngIf="selectedDealerId && chartOptions"
          [options]="chartOptions"
          class="activity-chart">
        </ag-charts>
      </div>
    </div>
  </div>

  <div class="no-data-message" *ngIf="!loading && (!dealers || dealers.length <= 1 || dealers[0].dealerId === '')">
    <mat-card>
      <mat-card-content>
        <i class="material-icons">info</i>
        <p>No data available. Please generate a report to see results.</p>
      </mat-card-content>
    </mat-card>
  </div>
</div>
