import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { User } from '@app/_models';
import { Dealer } from '@app/_models/dealer';
import { PrintInstruction } from '@app/_models/printInstruction';
import { ReportSearch } from '@app/_models/reportSearch';
import { AccountService } from '@app/_services';
import { AlertService } from '@app/_services/alert.service';
import { ConfigDataService } from '@app/_services/config-data.service';
import { DealerService } from '@app/_services/dealer-service';
import { SearchService } from '@app/_services/search.service';
import { AgGridAngular } from 'ag-grid-angular';
import { GridOptions } from 'ag-grid-community';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-dealer-aggregated-report',
  standalone: true,
  imports: [AgGridAngular,
    MatInputModule,
    MatSelectModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatOptionModule,
    MatButtonModule,
    CommonModule,
    ReactiveFormsModule
  ],
  templateUrl: './dealer-aggregated-report.component.html',
  styleUrls: ['./dealer-aggregated-report.component.less']
})
export class DealerAggregatedReportComponent implements OnInit {
  @ViewChild('aggrid') aggrid: AgGridAngular;
  certificateReportForm: UntypedFormGroup;
  submitted = false;
  loading = false;

  // For month/range date usage
  issueDateFromSearch: string;
  issueDateToSearch: string;

  // AG Grid
  gridOptions: GridOptions;
  gridReady = true;

  // Supporting data
  booleanArray = ["", "Yes", "No"];
  cpaPolicyCompanies: string[];
  selectedPrintInstruction: PrintInstruction[];
  policyTypes: string[] = [];
  searchResult: any[];

  // Additional references
  user: User;
  reportSearch: ReportSearch;
  OutputArray:any[] = [];
  stateRegexp: RegExp;
  idRegexp: RegExp;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private searchService: SearchService,
    private alertService: AlertService,
    private accountService: AccountService,
    private dealerService: DealerService,
    private configDataService: ConfigDataService
  ) { }

  ngOnInit(): void {
    // Get current user
    this.user = this.accountService.userValue;

    // Initialize grid options
    this.gridOptions = <GridOptions>{};
    this.gridOptions.columnDefs = [
      { headerName: "Dealer Id", field: "dealerId", sortable: true, filter: true },
      { headerName: "Dealer Name", field: "dealerName", sortable: true, filter: true },
      { headerName: "Dealer City", field: "dealerCity", sortable: true, filter: true },
      { headerName: "Plan Type", field: "dealerPlanType", sortable: true, filter: true },
      { headerName: "Issue Date", field: "issueDate", sortable: true, filter: true },
      { headerName: "Total", field: "totalPolicyNumber", sortable: true, filter: true },
      { headerName: "CPA Policy Sold", field: "cpaPolicyNumber", sortable: true, filter: true },
      { headerName: "RSA Policy Sold", field: "rsaPolicyNumber", sortable: true, filter: true },
      { headerName: "DOC Policy Sold", field: "docPolicyNumber", sortable: true, filter: true },
      { headerName: "Finance Policy Sold", field: "financePolicyNumber", sortable: true, filter: true }
    ];
    this.gridOptions.rowData = [];

    // Build form with new "dateSelectionType" and "issueMonth"
    this.certificateReportForm = this.formBuilder.group({
      dateSelectionType: ['range'],   // 'range' or 'month'
      issueMonth: [null],            // used if user picks "month"
      issueDateFrom: [''],           // used if user picks "range"
      issueDateTo: [''],             // used if user picks "range"
      aggregationType: [''],
      dealerGroupName: [''],
      dealerState: [''],
      dealerId: [''],
      hasPolicy: [''],
      cpaPolicyCompany: ['']
    });

    // Fetch instructions for CPA policy
    this.configDataService.PrintInstruction$.subscribe((x) => {
      this.selectedPrintInstruction = x;
      this.cpaPolicyCompanies = Array.from(new Set(this.selectedPrintInstruction.map(a => a.policyCompany)));
      this.cpaPolicyCompanies.push("All");
      this.certificateReportForm.get('cpaPolicyCompany').setValue("All");
    });

    // Watch for "hasPolicy" changes to do row-level filtering (IssueDateHorizontal case)
    this.certificateReportForm.get('hasPolicy').valueChanges.subscribe(value => {
      if (value !== undefined) {
        const aggType = this.certificateReportForm.get('aggregationType').value;
        if (aggType === 'IssueDateHorizontal' && this.OutputArray.length > 0) {
          if (value !== '') {
            this.aggrid.api.setRowData(this.OutputArray.filter(a => a.hasPolicy === value));
          } else {
            this.aggrid.api.setRowData(this.OutputArray);
          }
        }
      }
    });

    // Watch for cpaPolicyCompany changes to dynamically filter policy types
    this.certificateReportForm.get('cpaPolicyCompany').valueChanges.subscribe(value => {
      if (value !== undefined && this.searchResult && this.searchResult.length > 0) {
        if (value !== "All") {
          // Only the instructions that match chosen policy company
          this.policyTypes = this.selectedPrintInstruction
            .filter(a => a.policyCompany === value)
            .map(a => a.policyType);
        } else {
          // All instructions
          this.policyTypes = this.selectedPrintInstruction.map(a => a.policyType);
        }
        // Re-filter the latest data
        const resultData = this.searchResult[0].filter(a => this.policyTypes.includes(a.policyType));
        this.massageData(resultData, this.reportSearch);
      }
    });
  }

  // convenience getter
  get f() { return this.certificateReportForm.controls; }

  /**
   * Submit handler for Search
   */
  onSubmit(): void {
    this.submitted = true;
    this.loading = true;
    this.alertService.clear();

    // Guard: Only for admin/employee
    if (this.user.role !== "admin" && this.user.role !== "employee") {
      this.alertService.error("Unauthorized access");
      this.submitted = false;
      this.loading = false;
      return;
    }

    // Prepare search object
    this.reportSearch = new ReportSearch();
    this.reportSearch.dealerGroupName = this.f.dealerGroupName.value;
    this.reportSearch.dealerState = this.f.dealerState.value;
    this.reportSearch.dealerId = this.f.dealerId.value;
    this.reportSearch.cpaPolicyCompany = this.f.cpaPolicyCompany.value;

    // Choose from/to date based on user selection
    if (this.certificateReportForm.get('dateSelectionType').value === 'month') {
      // If user selected a month
      const chosenMonth: Date = this.f.issueMonth.value;
      if (chosenMonth) {
        const startOfMonth = new Date(chosenMonth.getFullYear(), chosenMonth.getMonth(), 1);
        const endOfMonth = new Date(chosenMonth.getFullYear(), chosenMonth.getMonth() + 1, 0);
        this.issueDateFromSearch = this.formatDate(startOfMonth);
        this.issueDateToSearch = this.formatDate(endOfMonth);
      } else {
        // fallback if not chosen
        this.issueDateFromSearch = "0001-01-01";
        this.issueDateToSearch = "9999-12-31";
      }
    } else {
      // If user selected a range
      const fromVal = this.f.issueDateFrom.value;
      const toVal = this.f.issueDateTo.value;

      this.issueDateFromSearch = fromVal ? this.formatDate(new Date(fromVal)) : "0001-01-01";
      this.issueDateToSearch = toVal ? this.formatDate(new Date(toVal)) : "9999-12-31";
    }

    // Now set the search object
    this.reportSearch.issueDateFrom = this.issueDateFromSearch;
    this.reportSearch.issueDateTo = this.issueDateToSearch;

    // Aggregation Type
    const agg = this.f.aggregationType.value;
    // Original logic: if it "includes" IssueDate, set it to "IssueDate"
    this.reportSearch.aggregationType = agg.includes("IssueDate") ? "IssueDate" : agg;

    // Call service
    this.searchService.getDealerAggregatedReport(this.reportSearch)
      .pipe(first())
      .subscribe({
        next: (res) => {
          // store raw data for possible re-filtering
          this.searchResult = [res];

          let dataToProcess = res;
          // If user picked a specific cpaPolicyCompany
          if (this.reportSearch.cpaPolicyCompany && this.reportSearch.cpaPolicyCompany !== "All") {
            const filteredPolicyTypes = this.selectedPrintInstruction
              .filter(a => a.policyCompany === this.reportSearch.cpaPolicyCompany)
              .map(a => a.policyType);

            dataToProcess = dataToProcess.filter(a => filteredPolicyTypes.includes(a.policyType));
          }

          // Process the data for the grid
          this.massageData(dataToProcess, this.reportSearch);
          this.loading = false;
        },
        error: error => {
          this.alertService.error(error);
          this.loading = false;
        }
      });
  }

  /**
   * Utility to format JS date as "YYYY-MM-DD"
   */
  private formatDate(dt: Date): string {
    if (!dt) return '';
    const mm = ('0' + (dt.getMonth() + 1)).slice(-2);
    const dd = ('0' + dt.getDate()).slice(-2);
    return `${dt.getFullYear()}-${mm}-${dd}`;
  }

  /**
   * Month chosen handler (for the Material datepicker)
   */
  chosenMonthHandler(normalizedMonth: Date, datepicker: any) {
    // Once user picks a month in the multi-year view, set and close
    this.certificateReportForm.get('issueMonth').setValue(normalizedMonth);
    datepicker.close();
  }

  /**
   * Reset button
   */
  onReset(): void {
    this.certificateReportForm.reset({
      dateSelectionType: 'range',
      issueMonth: null,
      issueDateFrom: '',
      issueDateTo: '',
      aggregationType: '',
      dealerGroupName: '',
      dealerState: '',
      dealerId: '',
      hasPolicy: '',
      cpaPolicyCompany: 'All'
    });
  }

  /**
   * Export to CSV or Excel
   */
  exportToExcel(): void {
    const params = {
      columnGroups: true,
      allColumns: true,
      fileName: 'excelReport'
    };
    this.aggrid.api.exportDataAsCsv(params);
  }

  /**
   * -----------------------------------------------------
   *       DATA MASSAGING / GRID POPULATION LOGIC
   * -----------------------------------------------------
   */

  massageData(data: any[], reportSearch: ReportSearch): void {
    // If the user selected an aggregation type that includes "IssueDateHorizontal"
    // we handle that separately. Otherwise, your original approach:
    if (this.certificateReportForm.get('aggregationType').value === 'IssueDateHorizontal') {
      // find min/max from data
      if (!data || data.length === 0) {
        this.aggrid.api.setRowData([]);
        return;
      }
      // compute min & max date from data (strings in "YYYY-MM-DD" form)
      const sortedDates = data.map(x => x.issueDateString).sort();
      const minIssueDate = sortedDates[0];
      const maxIssueDate = sortedDates[sortedDates.length - 1];
      // get the actual JS date versions
      this.createHorizontalReport(data, minIssueDate, maxIssueDate);
      return;
    }

    // Otherwise, use your original daily approach
    let maxIssueDate = "0001-01-01";
    let minIssueDate = "9999-12-31";

    if (data && data.length > 0) {
      // find min & max from the data
      const sorted = data.map(x => x.issueDateString).sort();
      minIssueDate = sorted[0];
      maxIssueDate = sorted[sorted.length - 1];
    }

    // now we fetch dealers & populate
    this.dealerService.getAll()
      .pipe(first())
      .subscribe({
        next: (dealers) => {
          // Filter dealers if user typed something
          if (reportSearch.dealerGroupName || reportSearch.dealerState || reportSearch.dealerId) {
            const groupReg = new RegExp(reportSearch.dealerGroupName, 'i');
            this.stateRegexp = new RegExp(reportSearch.dealerState, 'i');
            this.idRegexp = new RegExp(reportSearch.dealerId, 'i');
            dealers = dealers.filter(d => {
              return groupReg.test(d.dealerGroupName)
                && this.stateRegexp.test(d.dealerState)
                && this.idRegexp.test(d.dealerId);
            });
          }

          // If we do daily expansion
          if (reportSearch.aggregationType === "IssueDate") {
            // Then we create an array with each day from min -> max for each dealer
            const expandedArray = dealers
              .filter(x => x.dealerTotalPayment > 0)
              .map(x => {
                const block:any[] = [];
                for (let d = new Date(minIssueDate); d <= new Date(maxIssueDate); d.setDate(d.getDate() + 1)) {
                  block.push({
                    dealerId: x.dealerId,
                    dealerName: x.dealerName,
                    dealerPlanType: x.dealerPlanType,
                    dealerCity: x.dealerCity,
                    issueDate:
                      d.getFullYear() +
                      "-" +
                      ('0' + (d.getMonth() + 1)).slice(-2) +
                      "-" +
                      ('0' + d.getDate()).slice(-2),
                    cpaPolicyNumber: 0,
                    rsaPolicyNumber: 0,
                    docPolicyNumber: 0,
                    financePolicyNumber: 0,
                    totalPolicyNumber: 0
                  });
                }
                return block;
              });
            // Flatten
            const flatExpanded = ([] as any[]).concat(...expandedArray);
            // Now fill with the actual data
            this.populateDealerData(data, flatExpanded);
          } else {
            // No daily expansion, just one row per dealer
            // create a minimal row for each dealer
            const noDailyRows = dealers.map(x => {
              return [{
                dealerId: x.dealerId,
                dealerName: x.dealerName,
                dealerCity: x.dealerCity,
                dealerPlanType: x.dealerPlanType,
                issueDate: "",
                cpaPolicyNumber: 0,
                rsaPolicyNumber: 0,
                docPolicyNumber: 0,
                financePolicyNumber: 0,
                totalPolicyNumber: 0
              }];
            });
            const flattenRows = ([] as any[]).concat(...noDailyRows);
            this.populateDealerData(data, flattenRows);
          }
        },
        error: err => {
          this.alertService.error(err);
          this.loading = false;
        }
      });
  }

  /**
   * If "IssueDateHorizontal" is selected:
   * Build columns for each date between minIssueDate -> maxIssueDate
   * and generate row data that has an entry for each date's policy count
   */
  createHorizontalReport(rawData: any[], minIssueDate: string, maxIssueDate: string): void {
    // 1) We need a set of unique dealers
    // 2) For each day from min->max, we sum # of policies that day
    // 3) Build dynamic columns

    // For safety, fetch the dealers from the service
    this.dealerService.getAll()
      .pipe(first())
      .subscribe({
        next: (dealers) => {
          // Possibly filter dealers as well
          if (this.reportSearch.dealerGroupName || this.reportSearch.dealerState || this.reportSearch.dealerId) {
            const groupReg = new RegExp(this.reportSearch.dealerGroupName, 'i');
            this.stateRegexp = new RegExp(this.reportSearch.dealerState, 'i');
            this.idRegexp = new RegExp(this.reportSearch.dealerId, 'i');
            dealers = dealers.filter(d => {
              return groupReg.test(d.dealerGroupName)
                && this.stateRegexp.test(d.dealerState)
                && this.idRegexp.test(d.dealerId);
            });
          }

          // Turn min/max into JS Dates
          const startDate = new Date(minIssueDate);
          const endDate = new Date(maxIssueDate);

          // Build the row data for each dealer
          this.OutputArray = dealers.map(dealer => {
            const row = {
              dealerId: dealer.dealerId,
              dealerName: dealer.dealerName,
              dealerCity: dealer.dealerCity,
              lastIssueDate: "No Policy Issued",
              totalPolicy: 0,
              hasPolicy: this.booleanArray[2] // default "No"
            };

            // Walk from start -> end
            for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
              const yyyymmdd = this.formatDate(d);
              // sum policies for this dealer on that day
              const dayCount = rawData
                .filter(s => s.dealerId === dealer.dealerId && s.issueDateString === yyyymmdd)
                .reduce((acc, policy) => acc + (policy.numberOfPolicySold || 0), 0);

              row[yyyymmdd] = dayCount;
              // track lastIssueDate, hasPolicy, total
              if (dayCount > 0) {
                row.hasPolicy = this.booleanArray[1]; // "Yes"
                row.lastIssueDate = yyyymmdd;
                row.totalPolicy += dayCount;
              }
            }
            return row;
          });

          // Build dynamic column definitions
          const columnDefs = [
            { headerName: "Dealer Id", field: "dealerId", sortable: true, filter: true },
            { headerName: "Dealer Name", field: "dealerName", sortable: true, filter: true },
            { headerName: "Dealer City", field: "dealerCity", sortable: true, filter: true },
            { headerName: "Last Policy Issue Date", field: "lastIssueDate", sortable: true, filter: true },
            { headerName: "Total", field: "totalPolicy", sortable: true, filter: true }
          ];

          // Add a column for each day in the range
          for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
            const dateStr = this.formatDate(d);
            columnDefs.push({
              headerName: dateStr,
              field: dateStr,
              sortable: true,
              filter: true,
              // Optional cell styling logic
            });
          }

          // Apply column defs & row data to the grid
          this.aggrid.api.setColumnDefs(columnDefs);

          // Filter by "hasPolicy" if needed
          const hasPolicyVal = this.certificateReportForm.get("hasPolicy").value;
          if (hasPolicyVal !== '') {
            this.aggrid.api.setRowData(this.OutputArray.filter(a => a.hasPolicy === hasPolicyVal));
          } else {
            this.aggrid.api.setRowData(this.OutputArray);
          }
          this.loading = false;
        },
        error: err => {
          this.alertService.error(err);
          this.loading = false;
        }
      });
  }

  /**
   * This method merges the raw policy data into the "DealerData" array
   * for standard (non-horizontal) reports
   */
  populateDealerData(policyData: any[], DealerData: any[]): void {
    // Sort the data so we can group properly
    policyData = policyData.sort((x, y) => {
      return (x.dealerId + x.IssueDate) > (y.dealerId + y.IssueDate) ? 1 : -1;
    });

    // Partition by policyType
    const cpa = policyData.filter(x => !(x.policyType.includes('RSA') || x.policyType.includes('DOC') || x.policyType.includes("FINANCE")))
      .map(x => ({ dealerId: x.dealerId, issueDate: x.issueDateString || "", policyNumber: x.numberOfPolicySold, policyType: "CPA" }));

    const rsa = policyData.filter(x => x.policyType.includes('RSA') && !x.policyType.includes('DOC') && !x.policyType.includes('FINANCE'))
      .map(x => ({ dealerId: x.dealerId, issueDate: x.issueDateString || "", policyNumber: x.numberOfPolicySold, policyType: "RSA" }));

    const doc = policyData.filter(x => x.policyType.includes("DOC"))
      .map(x => ({ dealerId: x.dealerId, issueDate: x.issueDateString || "", policyNumber: x.numberOfPolicySold, policyType: "DOC" }));

    const finance = policyData.filter(x => x.policyType.includes("FINANCE"))
      .map(x => ({ dealerId: x.dealerId, issueDate: x.issueDateString || "", policyNumber: x.numberOfPolicySold, policyType: "FINANCE" }));

    // If DealerData is not empty, use that; else deduplicate the policyData
    let allRows = DealerData.length > 0
      ? DealerData
      : policyData.filter((thing, index, self) =>
          index === self.findIndex((t) => (
            t.dealerId === thing.dealerId && t.issueDateString === thing.issueDateString
          ))
        );

    // We want to track totals
    const totalRow = {
      issueDate: "Total",
      cpaPolicyNumber: 0,
      rsaPolicyNumber: 0,
      docPolicyNumber: 0,
      financePolicyNumber: 0,
      totalPolicyNumber: 0
    };

    allRows = allRows.map(x => {
      // ensure fields exist
      x.cpaPolicyNumber = x.cpaPolicyNumber || 0;
      x.rsaPolicyNumber = x.rsaPolicyNumber || 0;
      x.docPolicyNumber = x.docPolicyNumber || 0;
      x.financePolicyNumber = x.financePolicyNumber || 0;
      x.totalPolicyNumber = x.totalPolicyNumber || 0;

      const date = x.issueDate;

      // find matches from each partition
      const cpaMatch = cpa.filter(p => p.dealerId === x.dealerId && p.issueDate === date);
      const rsaMatch = rsa.filter(p => p.dealerId === x.dealerId && p.issueDate === date);
      const docMatch = doc.filter(p => p.dealerId === x.dealerId && p.issueDate === date);
      const finMatch = finance.filter(p => p.dealerId === x.dealerId && p.issueDate === date);

      // sum them
      const cpaCount = cpaMatch.reduce((a, b) => a + (b.policyNumber || 0), 0);
      const rsaCount = rsaMatch.reduce((a, b) => a + (b.policyNumber || 0), 0);
      const docCount = docMatch.reduce((a, b) => a + (b.policyNumber || 0), 0);
      const finCount = finMatch.reduce((a, b) => a + (b.policyNumber || 0), 0);

      x.cpaPolicyNumber += cpaCount;
      x.rsaPolicyNumber += rsaCount;
      x.docPolicyNumber += docCount;
      x.financePolicyNumber += finCount;
      x.totalPolicyNumber += (cpaCount + rsaCount + docCount + finCount);

      // add to total row
      totalRow.cpaPolicyNumber += cpaCount;
      totalRow.rsaPolicyNumber += rsaCount;
      totalRow.docPolicyNumber += docCount;
      totalRow.financePolicyNumber += finCount;
      totalRow.totalPolicyNumber += (cpaCount + rsaCount + docCount + finCount);

      return x;
    });

    // push the total row
    allRows.push(totalRow);

    // set row data to the grid
    this.aggrid.api.setRowData(allRows);
  }
  onMonthChanged(event: any) {
    // Called on generic dateChange event
    console.log('Month changed:', event.value);
  }
}
