import { Component, Input, OnChanges, SimpleChanges, AfterViewInit, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HighchartsChartModule } from 'highcharts-angular';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-year-on-year-chart',
  standalone: true,
  imports: [CommonModule, HighchartsChartModule],
  template: `
    <div [attr.id]="chartId" style="width: 100%; height: 400px;"></div>
  `
})
export class YearOnYearChartComponent implements OnChanges, AfterViewInit {
  @Input() chartId: string;
  @Input() chartTitle: string;
  @Input() chartType: 'line' | 'column' | 'bar' | 'pie' = 'line';
  @Input() currentYearData: any[];
  @Input() previousYearData: any[];
  @Input() xAxisField: string;
  @Input() yAxisField: string;
  @Input() groupByField?: string;
  
  private chartRendered = false;
  highcharts: typeof Highcharts = Highcharts;
  
  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    // Check if data is already available when view is initialized
    if (this.currentYearData && this.previousYearData && !this.chartRendered) {
      setTimeout(() => this.renderChart(), 100);
    }
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if ((changes['currentYearData'] || changes['previousYearData'])) {
      // Check if we have valid data
      if (this.currentYearData?.length && this.previousYearData?.length) {
        // If view is already initialized, render chart with a small delay
        setTimeout(() => this.renderChart(), 100);
      } else {
        console.warn(`Skipping chart render for ${this.chartId} - missing data`);
        console.log('currentYearData:', this.currentYearData);
        console.log('previousYearData:', this.previousYearData);
      }
    }
  }
  
  private renderChart(): void {
    // Check if element exists in DOM
    if (!document.getElementById(this.chartId)) {
      console.warn(`Element #${this.chartId} not found in DOM`);
      return;
    }
    
    // Ensure we have data to display
    if (!this.currentYearData?.length || !this.previousYearData?.length) {
      console.warn(`No data to display for chart ${this.chartId}`);
      return;
    }
    
    // Process data based on chart type
    let currentSeries: any[];
    let previousSeries: any[];
    let categories: string[];
    
    if (this.groupByField) {
      // Group data by the specified field
      const currentGrouped = this.groupData(this.currentYearData, this.groupByField, this.yAxisField);
      const previousGrouped = this.groupData(this.previousYearData, this.groupByField, this.yAxisField);
      
      // Prepare series data
      currentSeries = Object.entries(currentGrouped).map(([key, value]) => ({
        name: `${key} (Current)`,
        data: value
      }));
      
      previousSeries = Object.entries(previousGrouped).map(([key, value]) => ({
        name: `${key} (Previous)`,
        data: value,
        dashStyle: 'Dash'
      }));
      
      // Get unique x-axis values
      const allXValues = [...new Set([
        ...this.currentYearData.map(item => item[this.xAxisField]),
        ...this.previousYearData.map(item => item[this.xAxisField])
      ])].sort();
      
      categories = allXValues;
    } else {
      // Simple comparison between current and previous year
      currentSeries = [{
        name: 'Current Year',
        data: this.currentYearData.map(item => this.getValue(item, this.yAxisField))
      }];
      
      previousSeries = [{
        name: 'Previous Year',
        data: this.previousYearData.map(item => this.getValue(item, this.yAxisField)),
        dashStyle: 'Dash'
      }];
      
      categories = this.currentYearData.map(item => item[this.xAxisField]);
    }
    
    try {
      // When logging for debugging
      console.log(`Rendering chart ${this.chartId} with data:`, {
        currentYearData: this.currentYearData,
        previousYearData: this.previousYearData,
        xAxisField: this.xAxisField,
        yAxisField: this.yAxisField
      });

      // Create chart options
      const options: Highcharts.Options = {
        chart: {
          type: this.chartType,
          renderTo: this.chartId
        },
        title: {
          text: this.chartTitle
        },
        xAxis: {
          categories: categories
        },
        yAxis: {
          title: {
            text: this.yAxisField === 'count' ? 'Number of Policies' : this.yAxisField
          }
        },
        tooltip: {
          shared: true,
          // For counts, use 0 decimals and no prefix
          valueDecimals: this.yAxisField === 'count' ? 0 : 2,
          valuePrefix: this.yAxisField.toLowerCase().includes('amount') ? '₹' : '',
        },
        plotOptions: {
          series: {
            marker: {
              enabled: true
            }
          }
        },
        series: [...currentSeries, ...previousSeries]
      };
      
      // Check if data has the expected properties
      if (this.currentYearData.length > 0) {
        const sampleItem = this.currentYearData[0];
        if (!(this.xAxisField in sampleItem)) {
          console.warn(`Warning: xAxisField "${this.xAxisField}" not found in data`);
        }
        if (!(this.yAxisField in sampleItem)) {
          console.warn(`Warning: yAxisField "${this.yAxisField}" not found in data`);
        }
      }

      Highcharts.chart(this.chartId, options);
      this.chartRendered = true;
    } catch (error) {
      console.error('Error rendering chart:', error);
    }
  }
  
  private groupData(data: any[], groupField: string, valueField: string): Record<string, number[]> {
    const result: Record<string, Record<string, number>> = {};
    
    // Group data by the specified field
    data.forEach(item => {
      const group = item[groupField];
      const xValue = item[this.xAxisField];
      const value = this.getValue(item, valueField);
      
      if (!result[group]) {
        result[group] = {};
      }
      
      result[group][xValue] = value;
    });
    
    // Convert to arrays
    const categories = [...new Set(data.map(item => item[this.xAxisField]))].sort();
    const seriesData: Record<string, number[]> = {};
    
    Object.entries(result).forEach(([group, values]) => {
      seriesData[group] = categories.map(category => values[category] || 0);
    });
    
    return seriesData;
  }
  
  private getValue(item: any, field: string): number {
    if (field.includes('.')) {
      const parts = field.split('.');
      let value = item;
      
      for (const part of parts) {
        if (value === undefined) return 0;
        value = value[part];
      }
      
      return value || 0;
    }
    
    return item[field] || 0;
  }
}
