import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { IssueCertLayoutComponent } from './issue-cert-layout/issue-cert-layout.component';
import { AddCertificateComponent } from './add-certificate/add-certificate.component';
import { issueCertRouting } from './issuecertificate-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {  NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateFRParserFormatter } from "./ngb-date-fr-parser-formatter"
import { EditCertificateComponent } from './edit-certificate/edit-certificate.component';
import { DealerPaymentGatewayComponent } from '../users/dealer-payment-gateway/dealer-payment-gateway.component';
import { BulkIndividualComponent } from './bulk-individual/bulk-individual.component';
import { RsaServiceProvidedComponent } from './rsa-service-provided/rsa-service-provided.component';
import { ShowRequestHistoryComponent } from '@app/_components/show-request-history/show-request-history.component';



@NgModule({
  declarations: [IssueCertLayoutComponent, AddCertificateComponent,EditCertificateComponent, DealerPaymentGatewayComponent,BulkIndividualComponent,RsaServiceProvidedComponent
  ],
  providers: [{provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter}],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    issueCertRouting,
    NgbModule,
    ShowRequestHistoryComponent
    // UsersModule
    // DealerPaymentGatewayComponent

  ],
  exports: [IssueCertLayoutComponent],
  bootstrap: [IssueCertLayoutComponent]
})
export class IssueCertificateModule {
}
