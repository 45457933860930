import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';

interface InsightMetric {
  title: string;
  current: number;
  previous: number;
  percentChange: number;
  isPositive: boolean;
}

@Component({
  selector: 'app-sales-insights',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div class="row">
      <div *ngFor="let metric of metrics" class="col-md-3 mb-4">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{ metric.title }}</h5>
            <h3 class="card-subtitle mb-2">{{ formatValue(metric.current) }}</h3>
            <p class="card-text" [ngClass]="{'text-success': metric.isPositive, 'text-danger': !metric.isPositive}">
              <i [class]="metric.isPositive ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"></i>
              {{ metric.percentChange.toFixed(1) }}% vs Last Year
              <span class="text-muted">({{ formatValue(metric.previous) }})</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [`
    .card {
      border-radius: 10px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
    .card-subtitle {
      font-weight: bold;
    }
  `]
})
export class SalesInsightsComponent implements OnChanges {
  @Input() currentYearData: any[] = [];
  @Input() previousYearData: any[] = [];
  
  metrics: InsightMetric[] = [];
  
  ngOnChanges(changes: SimpleChanges): void {
    if ((changes['currentYearData'] || changes['previousYearData']) && 
        this.currentYearData?.length && this.previousYearData?.length) {
      this.calculateInsights();
    }
  }
  
  private calculateInsights(): void {
    // Calculate total policies
    const currentPolicyCount = this.sumField(this.currentYearData, 'numberOfPolicySold');
    const previousPolicyCount = this.sumField(this.previousYearData, 'numberOfPolicySold');
    const policyCountPercentChange = this.calculatePercentChange(currentPolicyCount, previousPolicyCount);
    
    // Calculate policies by type - CPA (Hospital Advantage)
    const currentCPACount = this.sumField(this.currentYearData, 'numberOfCpaPolicySold');
    const previousCPACount = this.sumField(this.previousYearData, 'numberOfCpaPolicySold');
    const cpaPercentChange = this.calculatePercentChange(currentCPACount, previousCPACount);
    
    // Calculate policies by type - RSA
    const currentRSACount = this.sumField(this.currentYearData, 'numberOfRsaPolicySold') || 0;
    const previousRSACount = this.sumField(this.previousYearData, 'numberOfRsaPolicySold') || 0;
    const rsaPercentChange = this.calculatePercentChange(currentRSACount, previousRSACount);
    
    // Calculate policies by type - DOC (Flexible)
    const currentDOCCount = this.sumField(this.currentYearData, 'numberOfDocPolicySold') || 0;
    const previousDOCCount = this.sumField(this.previousYearData, 'numberOfDocPolicySold') || 0;
    const docPercentChange = this.calculatePercentChange(currentDOCCount, previousDOCCount);
    
    // Update metrics
    this.metrics = [
      {
        title: 'Total Policies Sold',
        current: currentPolicyCount,
        previous: previousPolicyCount,
        percentChange: policyCountPercentChange,
        isPositive: policyCountPercentChange > 0
      },
      {
        title: 'HA Policies',
        current: currentCPACount,
        previous: previousCPACount,
        percentChange: cpaPercentChange,
        isPositive: cpaPercentChange > 0
      },
      {
        title: 'RSA Policies',
        current: currentRSACount,
        previous: previousRSACount,
        percentChange: rsaPercentChange,
        isPositive: rsaPercentChange > 0
      },
      {
        title: 'Flexible Policies',
        current: currentDOCCount,
        previous: previousDOCCount,
        percentChange: docPercentChange,
        isPositive: docPercentChange > 0
      }
    ];
  }
  
  private sumField(data: any[], field: string): number {
    return data.reduce((sum, item) => sum + (item[field] || 0), 0);
  }
  
  private calculatePercentChange(current: number, previous: number): number {
    if (previous === 0) return current > 0 ? 100 : 0;
    return ((current - previous) / previous) * 100;
  }
  
  formatValue(value: number): string {
    // No currency formatting for policy counts, just format the number
    return value.toLocaleString('en-IN');
  }
}
