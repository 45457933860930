import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { User } from '@app/_models';
import { Certificate } from '@app/_models/certificate';
import { AccountService } from '@app/_services';
import { CertificateService } from '@app/_services/certificate.service';

@Component({ templateUrl: 'home.component.html' })
export class HomeComponent {
    user: User;

    constructor(private accountService: AccountService) {
        this.user = this.accountService.userValue;
}

}