<div class="container-fluid">
  <div class="row mb-4">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h2>Policy Sales Dashboard</h2>
        </div>
        <div class="card-body">
          <app-date-range-selector 
            [loading]="loading" 
            [submitted]="submitted" 
            (dateRangeSelected)="onDateRangeSelected($event)">
          </app-date-range-selector>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="loading" class="text-center my-5">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>

  <div *ngIf="!loading && currentYearAggregateData.length">
    <!-- Key Insights -->
    <div class="row mb-4">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h4>Sales Performance Summary</h4>
          </div>
          <div class="card-body">
            <app-sales-insights 
              [currentYearData]="currentYearAggregateData" 
              [previousYearData]="previousYearAggregateData">
            </app-sales-insights>
          </div>
        </div>
      </div>
    </div>

    <!-- Filters -->
    <div class="row mb-4">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <h4>Filter Data</h4>
          </div>
          <div class="card-body">
            <form [formGroup]="filterForm" class="form-row">
              <div class="form-group col-md-3">
                <label for="dealerName">Dealer Name</label>
                <input type="text" formControlName="dealerName" class="form-control">
              </div>
              <div class="form-group col-md-3" *ngIf="user.role === 'admin'">
                <label for="dealerId">Dealer ID</label>
                <input type="text" formControlName="dealerId" class="form-control">
              </div>
              <div class="form-group col-md-3">
                <label for="policyType">Policy Type</label>
                <select formControlName="policyType" class="form-control">
                  <option value="">All Types</option>
                  <option value="PA">PA</option>
                  <option value="FP">FP</option>
                  <!-- Add other policy types as needed -->
                </select>
              </div>
              <div class="form-group col-md-3">
                <label for="vehicleMake">Vehicle Make</label>
                <select formControlName="vehicleMake" class="form-control">
                  <option value="">All Makes</option>
                  <option *ngFor="let make of uniqueVehicleMakes" [value]="make">{{ make }}</option>
                </select>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Charts -->
    <div class="row mb-4">
      <div class="col-md-6">
        <div class="card h-100">
          <div class="card-header">
            <h4>Policy Sales by Type</h4>
          </div>
          <div class="card-body">
            <app-year-on-year-chart 
              chartId="policyTypeChart"
              chartTitle="Policy Count by Type"
              chartType="column"
              [currentYearData]="policyTypesData.current"
              [previousYearData]="policyTypesData.previous"
              xAxisField="policyType"
              yAxisField="count">
            </app-year-on-year-chart>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card h-100">
          <div class="card-header">
            <h4>Policy Sales by Vehicle Make</h4>
          </div>
          <div class="card-body">
            <app-year-on-year-chart 
              chartId="vehicleMakeChart"
              chartTitle="Top Vehicle Makes by Policy Count"
              chartType="bar"
              [currentYearData]="vehicleMakesData.current"
              [previousYearData]="vehicleMakesData.previous"
              xAxisField="vehicleMake"
              yAxisField="count">
            </app-year-on-year-chart>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <h4>Monthly Policy Sales Trend</h4>
          </div>
          <div class="card-body">
            <app-year-on-year-chart 
              chartId="monthlyTrendChart"
              chartTitle="Monthly Policy Count Comparison"
              chartType="line"
              [currentYearData]="monthlyTrendData.current"
              [previousYearData]="monthlyTrendData.previous"
              xAxisField="month"
              yAxisField="count">
            </app-year-on-year-chart>
          </div>
        </div>
      </div>
    </div>

    <!-- Add a new chart section for dealer comparison -->
    <div class="row mb-4">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <h4>Dealer Performance Comparison</h4>
          </div>
          <div class="card-body">
            <div id="dealerComparisonChart" class="chart-container" style="min-height: 400px;"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <h4>Policy Type Distribution</h4>
          </div>
          <div class="card-body">
            <div id="policyDistributionChart" class="chart-container" style="min-height: 400px;"></div>
          </div>
        </div>
      </div>
    </div>
    
    <!-- Legacy charts container - needed for backwards compatibility -->
    <div style="display: none;">
      <div id="barChart"></div>
      <div id="totalLineChart"></div>
      <div id="lineChart"></div>
    </div>
  </div>
</div>
