import { Component, OnInit } from '@angular/core';
import { User } from '@app/_models';
import { Certificate } from '@app/_models/certificate';
import { Dealer } from '@app/_models/dealer';
import { AccountService, AlertService } from '@app/_services';
import { CertificateService } from '@app/_services/certificate.service';
import { DealerService } from '@app/_services/dealer-service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-cpa-doc-policy',
  templateUrl: './cpa-doc-policy.component.html',
  styleUrls: ['./cpa-doc-policy.component.less']
})
export class CpaDocPolicyComponent implements OnInit {
  certificate: any;
  currentDate = new Date();
  n: string[];
  amountInWords: String;
  showGstAmount = true;
  printCpaPolicy = true;
  user: User;

  constructor(private certificateService: CertificateService, private dealerService: DealerService, private accountService: AccountService, private alertService: AlertService) { }

  ngOnInit(): void {
    this.certificate = new Certificate(this.certificateService.PrintCertificate)
    this.user = this.accountService.userValue;
    if (this.user.specialRoleAccess.includes('ViewNetAmountGST')) {
      this.dealerService.getByDealerId(this.certificate.dealerId)
        .pipe(first())
        .subscribe({
          next: (x) => {
            if (x.dealerGst && x.dealerGst == "Net")
            {
              this.amountInWords = this.inWords(this.certificate.NetPlanTotalAmount);
              this.certificate.planAmount = this.certificate.NetPlanAmount;
              if (this.certificate.IgstAmount > 0)
                {
                  this.certificate.IgstAmount = this.certificate.NetPlanGSTAmount;
                }
              else
              {
                this.certificate.CgstAmount = this.certificate.NetPlanGSTAmount / 2;
                this.certificate.SgstAmount = this.certificate.NetPlanGSTAmount / 2;
              }
this.certificate.planTotalAmount = Math.round(this.certificate.NetPlanTotalAmount);
            }
            else
            {
            this.amountInWords = this.inWords(this.certificate.planTotalAmount);
          }
        },
          error: error => {
            this.alertService.error(error)
          }
        })
    }
    else {
      this.amountInWords = this.inWords(this.certificate.planTotalAmount);
    }
    this.showGstAmount = this.certificate.gstApplicable ? this.certificate.gstApplicable == "N" ? false : true : true;
    this.showGstAmount = this.showGstAmount == false ? false : this.certificate.gstShow ? this.certificate.gstShow == "N" ? false : true : true;
    this.printCpaPolicy = this.certificate.printCpaPolicy ? this.certificate.printCpaPolicy == "Yes" ? true : false : true;


  }

  inWords(num) {
    num = Math.round (num);
    var a = ['', 'one ', 'two ', 'three ', 'four ', 'five ', 'six ', 'seven ', 'eight ', 'nine ', 'ten ', 'eleven ', 'twelve ', 'thirteen ', 'fourteen ', 'fifteen ', 'sixteen ', 'seventeen ', 'eighteen ', 'nineteen '];
    var b = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
    if ((num = num.toString()).length > 9) return 'overflow';
    this.n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!this.n) return; var str = '';
    console.log("n" + this.n);
    str += (Number(this.n[1]) != 0) ? (a[Number(this.n[1])] || b[this.n[1][0]] + ' ' + a[this.n[1][1]]) + 'crore ' : '';
    str += (Number(this.n[2]) != 0) ? (a[Number(this.n[2])] || b[this.n[2][0]] + ' ' + a[this.n[2][1]]) + 'lakh ' : '';
    str += (Number(this.n[3]) != 0) ? (a[Number(this.n[3])] || b[this.n[3][0]] + ' ' + a[this.n[3][1]]) + 'thousand ' : '';
    str += (Number(this.n[4]) != 0) ? (a[Number(this.n[4])] || b[this.n[4][0]] + ' ' + a[this.n[4][1]]) + 'hundred ' : '';
    str += (Number(this.n[5]) != 0) ? ((str != '') ? 'and ' : '') + (a[Number(this.n[5])] || b[this.n[5][0]] + ' ' + a[this.n[5][1]]) + 'only ' : '';
    return str;
  }

}
