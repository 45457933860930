import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { AppComponent } from './app.component';
import { AlertComponent } from './_components';
import { HomeComponent } from './home';;
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MultiSelectDropdownComponent } from './_helpers/multi-select-dropdown/multi-select-dropdown.component';
import { PolicyNumberMaintainComponent } from './home/policy-number-maintain/policy-number-maintain.component';
import { AccountModule } from './account/account.module';
import { UsersModule } from './users/users.module';
import { ReportsModule } from './reports/reports.module';
import { IssueCertificateModule } from './issue-certificate/IssueCertificateModule';
import { PrintModule } from './print/print.module';
import { AnayticModule } from './analytics/anaytic.module';
import { QRCodeModule } from 'angularx-qrcode';;
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { ConfigMaintainModule } from './config-maintain/config-maintain.module';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { HeaderComponent } from './components/header/header.component';

// Import all role-based header components
import { AdminHeaderComponent } from './components/header/admin-header/admin-header.component';
import { EmployeeHeaderComponent } from './components/header/employee-header/employee-header.component';
import { DealerOwnerHeaderComponent } from './components/header/dealer-owner-header/dealer-owner-header.component';
import { RsaOperationHeaderComponent } from './components/header/rsa-operation-header/rsa-operation-header.component';
import { HeroReportHeaderComponent } from './components/header/hero-report-header/hero-report-header.component';
import { ExecutiveHeaderComponent } from './components/header/executive-header/executive-header.component';

@NgModule({ 
    declarations: [
        AppComponent,
        AlertComponent,
        HomeComponent,
        MultiSelectDropdownComponent,
        PolicyNumberMaintainComponent
    ],
    bootstrap: [AppComponent], 
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        AppRoutingModule,
        QRCodeModule,
        AccountModule,
        UsersModule,
        ReportsModule,
        IssueCertificateModule,
        PrintModule,
        AnayticModule,
        NgbModule,
        ConfigMaintainModule,
        BrowserAnimationsModule,
        HeaderComponent,
        AdminHeaderComponent,
        EmployeeHeaderComponent,
        DealerOwnerHeaderComponent,
        RsaOperationHeaderComponent,
        HeroReportHeaderComponent,
        ExecutiveHeaderComponent
    ], 
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimationsAsync(),
    ] 
})
export class AppModule { };