import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AgGridModule } from 'ag-grid-angular';
import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import { Employee, Dealer } from '../models/employee-dealer.models';

@Component({
  selector: 'app-employee-management',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    AgGridModule
  ],
  templateUrl: './employee-management.component.html',
  styleUrl: './employee-management.component.less'
})
export class EmployeeManagementComponent implements OnInit {
  @Input() employees: Employee[] = [];
  @Input() dealers: Dealer[] = [];
  @Input() loading = false;
  
  @Output() editEmployee = new EventEmitter<Employee>();
  @Output() exportData = new EventEmitter<void>();
  @Output() showDetails = new EventEmitter<Employee>();
  
  private gridApi!: GridApi;
  filteredEmployees: Employee[] = [];
  
  // AG Grid column definitions
  columnDefs: ColDef[] = [
    { 
      field: 'username', 
      headerName: 'Username', 
      sortable: true, 
      filter: true,
      flex: 1
    },
    { 
      headerName: 'Name', 
      valueGetter: (params) => `${params.data.firstName} ${params.data.lastName}`,
      sortable: true, 
      filter: true,
      flex: 2
    },
    { 
      field: 'employeeDealerList',
      headerName: 'Dealers', 
      sortable: true,
      valueGetter: (params) => params.data.employeeDealerList ? params.data.employeeDealerList.length : 0,
      cellRenderer: (params) => {
        return `<button class="icon-button detail-icon" data-action="toggle"> <span class="badge">${params.value}</span>
                
                  <span class="material-icon visibility-icon">Dealers</span>
                </button>`;
      },
      flex: 1
    },
    { 
      headerName: 'Actions', 
      cellRenderer: (params) => {
        return `<div class="action-cell">
                  <button class="icon-button edit-icon" data-action="edit">
                    <mat-icon>edit</mat-icon>
                  </button>
                </div>`;
      },
      width: 100,
      suppressSizeToFit: true
    }
  ];
  
  // Grid options
  gridOptions = {
    rowHeight: 48,
    headerHeight: 56,
    suppressRowClickSelection: true,
    rowSelection: 'single',
    animateRows: true,
    defaultColDef: {
      resizable: true
    }
  };
  
  ngOnInit(): void {
    this.filteredEmployees = [...this.employees];
  }
  
  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    
    // Ensure grid columns fit the available width
    setTimeout(() => {
      params.api.sizeColumnsToFit();
    }, 100);
  }
  
  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.gridApi?.setQuickFilter(filterValue);
  }
  
  onCellClicked(params: any): void {
    const actionTarget = params.event.target.closest('[data-action]');
    if (actionTarget) {
      const action = actionTarget.getAttribute('data-action');
      
      if (action === 'toggle') {
        this.showDetails.emit(params.data);
      } else if (action === 'edit') {
        this.editEmployee.emit(params.data);
      }
    }
  }
  
  onExportData(): void {
    this.exportData.emit();
  }
}
