


<div class="container">
    <form [formGroup]="certficateForm" (ngSubmit)="onSubmit()">

        <nav aria-label="breadcrumb" >
            <ol class="breadcrumb" >
                <li class="breadcrumb-item active" aria-current="page" >
                    <i class="fa fa-book" style="margin-right: 10px; "></i>
                <p class="h5"> Template Details</p></li>
            </ol>
          </nav>
        <div class="row">
        <div class="col" *ngIf="user.specialRoleAccess && user.specialRoleAccess.includes('canSeeAge')">
            <h4 >Download <strong>Alliance Template</strong> from  <a target="_self" href="../../../assets/bulkIndividualAge.xlsx" download="Bulk Individual.xlsx"><span>here</span></a>
            </h4>
            <h4 *ngIf="showExcel=='Hero'">Download <strong> Hero Vehicle Sales GST Invoice Template </strong> from  <a target="_self" href="../../../assets/Hero DSL.xlsx" download="Bulk Individual.xlsx"><span>here</span></a>
            </h4>
            <!-- <h4 *ngIf="showExcel=='Honda'">Download Template from  <a target="_self" href="../../../assets/bulkIndividualHondaAge.xlsx" download="Bulk Individual.xlsx"><span>here</span></a>
            </h4>
            <h4 *ngIf="showExcel=='Bajaj'">Download Template from  <a target="_self" href="../../../assets/bulkIndividualBajajAge.xlsx" download="Bulk Individual.xlsx"><span>here</span></a>
            </h4> -->
        </div>
        <div class="col" *ngIf="!(user.specialRoleAccess && user.specialRoleAccess.includes('canSeeAge'))">
            <h4 >Download <strong>Alliance Template</strong> from  <a target="_self" href="../../../assets/bulkIndividual.xlsx" download="Bulk Individual.xlsx"><span>here</span></a>
            </h4>
            <h4 *ngIf="showExcel=='Hero'">Download <strong> Hero Vehicle Sales GST Invoice Template </strong> from  <a target="_self" href="../../../assets/HeroDSLDOB.xlsx" download="Bulk Individual.xlsx"><span>here</span></a>
            </h4>
            <!-- <h4 *ngIf="showExcel=='Honda'">Download Template from  <a target="_self" href="../../../assets/bulkIndividualHonda.xlsx" download="Bulk Individual.xlsx"><span>here</span></a>
            </h4>
            <h4 *ngIf="showExcel=='Bajaj'">Download Template from  <a target="_self" href="../../../assets/bulkIndividualBajaj.xlsx" download="Bulk Individual.xlsx"><span>here</span></a>
            </h4> -->
        </div>
        <div class="col">
        </div>
    </div>
    <nav aria-label="breadcrumb" >
        <ol class="breadcrumb" >
            <li class="breadcrumb-item active" aria-current="page" >
                <i class="fa fa-book" style="margin-right: 10px; "></i>
            <p class="h5"> Plan Details</p></li>
        </ol>
      </nav>
      <div class="form-row">

        <div class="form-group col">
            <label for="planId">Plan</label>
            <select class="form-select" formControlName="planId" [ngClass]="{ 'is-invalid': submitted && f.planId.errors }" >
                <option *ngFor="let plan of plans " [ngValue]="plan.planId">
                  {{ plan.planId }}
                </option>
              </select>
            <div *ngIf="submitted && f.planId.errors" class="invalid-feedback">
                <div *ngIf="f.planId.errors.required">Plan is required</div>
            </div>
        </div>
        <div class="form-group col" *ngIf="showFinance">
            <label for="financeCompanyName">Finance Company Name</label>
            <input type="text" formControlName="financeCompanyName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.financeCompanyName.errors }" />
            <div class="suggestions" >
                <div  *ngIf="f.financeCompanyName.value != selectedFinance">
                <option (click)="financeSelected(fin)"  *ngFor="let fin of selectedFinanceCompanies" >{{fin}}</option>
            </div>
            </div>
            <div *ngIf="submitted && f.financeCompanyName.errors" class="invalid-feedback">
                <div *ngIf="f.financeCompanyName.errors.required">Finance Company Name is required</div>
            </div>
        </div>
        <div class="form-group col" *ngIf="showFinance">
            <label for="financeCoverAmount">Finance Cover Amount</label>
            <input type="number" formControlName="financeCoverAmount" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.financeCoverAmount.errors }" />
            <div *ngIf="submitted && f.financeCoverAmount.errors" class="invalid-feedback">
                <div *ngIf="f.financeCoverAmount.errors">Finance Cover amount is required and cant be more than cover limit of {{selectedPlan.planCPACoverAmount}}</div>
            </div>
       </div>
        <div class="form-group col" *ngIf="showGstAmount">
            <label for="planAmount">Plan Amount</label>
            <input type="number" formControlName="planAmount" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.planAmount.errors }" />
        </div>
        <div class="form-group col" *ngIf="showGstAmount">
            <label for="planGSTAmount">GST Amount</label>
            <input type="number" formControlName="planGSTAmount" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.planGSTAmount.errors }" />
        </div>
        <div class="form-group col">
            <label for="planTotalAmount">Total Amount</label>
            <input type="number" formControlName="planTotalAmount" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.planTotalAmount.errors }" />
            <div *ngIf="submitted && f.planTotalAmount.errors" class="invalid-feedback">
                <div *ngIf="f.planTotalAmount.errors">Approved amount range is from {{minAmount}} to {{maxAmount}}</div>
            </div>
        </div>
    </div>

    <div class="form-row">

        <div class="form-group col">
            <label for="startDate">Start Date</label>
            <div class="input-group">
                <input class="form-control" formControlName="startDate"  placeholder="DD/MM/YYYY" [minDate]="startDateModel" ngbDatepicker #d1="ngbDatepicker" [ngClass]="{ 'is-invalid': submitted && f.startDate.errors }">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary fa fa-calendar" (click)="d1.toggle()" type="button"></button>
                </div>
              </div>            
              <div *ngIf="submitted && f.startDate.errors" class="invalid-feedback">
                <div *ngIf="f.startDate.errors.required">Start Date is required</div>
            </div>
            <!-- <label for="startDate">Start Date</label>
            <input type="text" formControlName="startDate" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.startDate.errors }" /> -->
        </div>
        <div class="form-group col">
            <label for="endDate">End Date</label>
            <input type="text" formControlName="endDate" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.endDate.errors }" />
        </div>
        <div class="form-group col">
            <label for="issueDate">Issue Date</label>
            <input type="text" formControlName="issueDate" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.issueDate.errors }" />
        </div>
        <div class="form-group col" *ngIf="!(user.specialRoleAccess && user.specialRoleAccess.includes('CannotViewDealerBalance'))">
            <label for="balance">Balance</label>
            <input type="text" formControlName="balance" class="form-control" [ngClass]="{ 'is-invalid': isBalanceLow }" />
            <div *ngIf="isBalanceLow">Balance is running low. Please add money before proceeding</div>
        </div>
    </div>

    <nav aria-label="breadcrumb" >
        <ol class="breadcrumb" >
            <li class="breadcrumb-item active" aria-current="page" >
            <i class="fa fa-file" style="margin-right: 10px; "></i>
            <p class="h5"> File Details</p></li>
        </ol>
      </nav>
      <div class="form-row">
        <div class="form-group col">
        <label for="uploadFile">Upload File</label>
        <input type="file" formControlName="uploadFile" (change)="onFileChange($event)" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.uploadFile.errors }" />
        <div *ngIf="submitted && f.uploadFile.errors" class="invalid-feedback">
            <div *ngIf="f.uploadFile.errors.required">File is required</div>
        </div>

    </div>
        <div class="form-group col">
        </div>
    </div>

    <div class="form-group">
        <button [disabled]="loading || isBalanceLow || error" class="btn btn-primary" >
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Submit
        </button><a>&nbsp;&nbsp;</a>
        <!-- <button  class="btn btn-secondary mr-1" type="reset" (click)="onReset()">
            New Form
        </button> -->
        <a>&nbsp;&nbsp;</a>
        <!-- <button *ngIf="certificateCreated" class="btn btn-success mr-1" type="GenerateCertificate" (click)="onPdfClick()">
            Generate Certificate
        </button> -->
        <a routerLink="/bulkIndividual" class="btn btn-link">Cancel</a>
    </div>

    </form>
</div>  