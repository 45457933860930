import { Certificate } from "./certificate"
import { Dealer } from "./dealer"

export class CertificateResponse{
    certificate: Certificate;
    dealer:Dealer;

    public constructor(init?: Partial<CertificateResponse>) {
        Object.assign(this, init);
    }   

}
    