<div class="container-fluid" [ngClass]="{ 'bg-light': user }" style="overflow:scroll">
    <app-header *ngIf="user"
                [user]="user"
                [dealer]="dealer"
                [showReceivableTab]="showReceivableTab">
    </app-header>
  
    <!-- main app container -->
    <div id="appContainer">
      <alert></alert>
      <router-outlet></router-outlet>
      <alert></alert>
    </div>
  
    <!-- credits -->
    <div class="text-center mt-4">
      <p>
        For any support reach out at <a href="">support&#64;allianceassure.in</a>
      </p>
      <p>
        <img src="../assets/AllianceLogo.png" height="80px">
        <a href="https://AllianceAssure.com" target="_top">AllianceAssure.com</a>
      </p>
      <br>
      <small>
        <a href="https://allianceassure.com/terms-conditions/">TERMS AND CONDITIONS </a>&nbsp;<a href="https://allianceassure.com/privacy-policy/">| PRIVACY POLICY |</a>&nbsp;<a style="color: #0d6efd;text-decoration-line:underline" (click)="cancellationPolicyToggle()"> CANCELLATION/REFUND POLICY |</a>&nbsp; <a href="https://allianceassure.com/contact/"> CONTACT US |</a>&nbsp;<a href="https://allianceassure.com/"> ABOUT US |</a>
      </small>
      <br>
      <small *ngIf="showCancellationPolicy">
        THERE IS NO CANCELLATION OPTION FOR THE END USERS AFTER PAYMENT IS MADE. IN CASE OF DUPLICATE PAYMENT, KINDLY APPROACH US AT SUPPORT&#64;ALLIANCEASSURE.IN .FOR REFUND WITH PROOF OF THE TRANSACTION REFERENCE/ YOUR BANK STATEMENT. REFUND WILL BE PROCESSED WITHIN 10-15 WORKING DAYS, RESPECTIVE PAYMENT GATEWAY WILL SEND BACK TO THE ISSUING BANK [USER’S CARD BANKER] IN BATCHES FOR PROCESSING, WHICH SHOULD APPROXIMATELY TAKE 10-15 WORKING DAYS, DEPENDING ON ISSUING BANKS POLICIES. HOWEVER, ALLIANCE ASSURE PVT LTD. IS NOT RESPONSIBLE FOR ANY DELAY ON THE BANK SIDE.
      </small>
    </div>
</div>