<div class="p-4">
    <button [disabled]="isLoading" (click)="invoiceGenerate()" class="btn btn-sm btn-success mb-2">Download Invoice
        <span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-1"></span>
    </button><a>&nbsp;&nbsp;</a>
    <div class="spinner-grow text-info" role="status" *ngIf="isLoading">
        <span class="sr-only">Loading...</span>
    </div>
</div>
<div id="combinedPage">
    <div class="container-fluid">
        <div class="row">
            <div id="printPage" style="max-width: 1122px;max-height: 1584px;" >
                <div class="container-fluid" style="margin-top: 30px;">
                    <div class="row">
                        <div class="col-md-6">
                        </div>
                        <div class="col col-md-6 top-right">
                                <h3 style="text-align:right">INVOICE-{{printInvoice.invoiceNumber}}</h3>
                                <p style="text-align:right; align-content: left;">{{printInvoice.invoiceGeneratedDate |
                                    date:'dd-MMM-yyyy'}}</p>
                        </div>
                    </div>
                </div>
                <hr>
                <div class="container-fluid ">
                    <div class="row" style="width: 1075px; line-height: 15px;" >
                        <div class="col-md-5 from" *ngIf="printInvoice.invoiceType != 'B2B'">
                            <p ><b>From : {{dealer.dealerBankAccountOwner ? dealer.dealerBankAccountOwner: dealer.dealerName}}</b></p>
                            <p>{{dealer.dealerAddress}}</p>
                            <p>{{dealer.dealerCity + "," }}</p>
                            <p>{{dealer.dealerState}}</p>
                            <p>GSTIN: {{dealer.dealerGstId}}</p>
                            <p>PAN: {{dealer.dealerBankOwnerPan}}</p>
                        </div>
                        <div class="col-md-4 to" *ngIf="printInvoice.invoiceType != 'B2B'">
                            <p ><b>To : Alliance Assure Private Limited</b></p>
                            <p>D61,HBH Galaxy,</p>
                            <p>Sector 43 ,Golf Course Road </p>
                            <p>Gurugram , Haryana - 122009 </p>
                            <p>Email: payment&#64;allianceassure.in</p>
                            <p>GSTIN:06AAUCA6872D1ZC</p>
                            <p>PAN:AAUCA6872D</p>
                        </div>

                        <div class="col-md-5 from" *ngIf="printInvoice.invoiceType == 'B2B'">
                            <p ><b>From : Alliance Assure Private Limited</b></p>
                            <p>D61,HBH Galaxy,</p>
                            <p>Sector 43 ,Golf Course Road </p>
                            <p>Gurugram , Haryana - 122009 </p>
                            <p>Email: payment&#64;allianceassure.in</p>
                            <p>GSTIN:06AAUCA6872D1ZC</p>
                            <p>PAN:AAUCA6872D</p>
                        </div>

                        <div class="col-md-4 to" *ngIf="printInvoice.invoiceType == 'B2B'">
                            <p ><b>To : {{dealer.dealerBankAccountOwner ? dealer.dealerBankAccountOwner: dealer.dealerName}}</b></p>
                            <p>{{dealer.dealerAddress}}</p>
                            <p>{{dealer.dealerCity + "," }}</p>
                            <p>{{dealer.dealerState}}</p>
                            <p>GSTIN: {{dealer.dealerGstId}}</p>
                            <p>PAN: {{dealer.dealerBankOwnerPan}}</p>
                        </div>


                        <div class="col-md-3 text-right payment-details">
                            <p ><b>Payment details</b></p>
                            <p>Mode of Payment: NEFT</p>
                            <p>Reverse Charges: No </p>
                            <p *ngIf="dealer.dealerGstId">Invoice Amount: Rs. {{(totalAmount + cGstAmount + cGstAmount + iGstAmount).toFixed(0) + ".00"}}</p>
                            <p *ngIf="!dealer.dealerGstId">Total Amount: Rs. {{(totalAmount).toFixed(0) + ".00"}}</p>
                        </div>
                    </div>
                </div>
                <div class="container-fluid ">
                    <div class="row">
                            <table class="table table-bordered" >
                                <thead>
                                    <tr>
                                        <th class="text-center" style="width:5%">SerialNo</th>
                                        <th style="width:65%">Particulars</th>
                                        <th class="text-right" style="width:15%">HSN/SAC Code</th>
                                        <th class="text-right" style="width:15%">Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="text-center">1</td>
                                        <td *ngIf="dealer.invoiceType != 'Marketing'">Commission on Assistance Certificates Sold (Total Nos: {{dealer.dealerTotalCertSold}})</td>
                                        <td *ngIf="dealer.invoiceType == 'Marketing' && printInvoice.invoiceType == 'B2B'">Assistance Services (RSA) provided for below sale: <br>
                                            <ul>
                                                <li *ngFor="let dealerSummary of dealerSummaries">
                                                    {{dealerSummary.planId + " - " + dealerSummary.numberOfPolicySold + " for total amount = " + dealerSummary.totalPlanSoldAmount }}
                                                </li>
                                            </ul>
                                             </td>
                                        <td class="text-center" *ngIf="dealer.invoiceType != 'Marketing'">996211</td>
                                        <td *ngIf="dealer.invoiceType == 'Marketing' && printInvoice.invoiceType != 'B2B'">Marketing, Brand Promotion and Advertisement Expenses</td>
                                        <td class="text-center" *ngIf="dealer.invoiceType == 'Marketing' && printInvoice.invoiceType != 'B2B'">998361</td>
                                        <td class="text-center" *ngIf=" dealer.invoiceType == 'Marketing' && printInvoice.invoiceType == 'B2B'">998399</td>
                                        <td class="text-right">Rs. {{totalAmount}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">2</td>
                                        <td></td>
                                        <td class="text-right"></td>
                                        <td class="text-right"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">3</td>
                                        <td></td>
                                        <td class="text-right"></td>
                                        <td class="text-right"></td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">4</td>
                                        <td></td>
                                        <td class="text-right"></td>
                                        <td class="text-right"></td>
                                    </tr>
                                </tbody>
                            </table>
                    </div>
                </div>

                <div class="container-fluid">
                    <div class="row" style="line-height: 15px;">
                            <div class="col-md-6 text-left pull-left invoice-total" *ngIf="!(dealer.invoiceType == 'Marketing' && printInvoice.invoiceType == 'B2B')">
                                <strong>Bank Details:</strong><br>
                                <p>Account Name:{{dealer.dealerBankAccountOwner}}</p>
                                <p>Account Number:{{dealer.dealerBankAccountNumber}}</p>
                                <p>IFSC Code: {{dealer.dealerBankIfscCode}}</p>
                                <p>PAN: {{dealer.dealerBankOwnerPan}}</p>
                            </div>
                            <div class="col-md-6 text-left pull-left invoice-total" *ngIf="(dealer.invoiceType == 'Marketing' && printInvoice.invoiceType == 'B2B')">
                                <strong>Bank Details:</strong><br>
                                <p>Account Name:Alliance Assure Pvt Limited</p>
                                <p>Account Number:739305500017</p>
                                <p>IFSC Code: ICIC0007393</p>
                            </div>
                            <div class="col-md-6 text-right pull-right invoice-total">
                                <p>Total Amount Before TAX : Rs. {{totalAmount}}</p>
                                <p *ngIf="dealer.dealerGstId">Add: CGST 9% : Rs. {{cGstAmount}}</p>
                                <p *ngIf="dealer.dealerGstId"> Add: SGST 9% : Rs. {{cGstAmount}}</p>
                                <p *ngIf="dealer.dealerGstId">Add: IGST 18% : Rs. {{iGstAmount}}</p>
                                <p><b *ngIf="dealer.dealerGstId">Total Amount (Rounded) : Rs. {{(totalAmount + cGstAmount + cGstAmount + iGstAmount).toFixed(0) + ".00"}}</b>
                                    <b *ngIf="!dealer.dealerGstId">Total Amount (Rounded) : Rs. {{(totalAmount).toFixed(0) + ".00"}}</b>
                                </p>
                            </div>
                        </div>
                        <hr>

                        <div class="container-fluid">
                            <div class="row" style="text-align:right; font-size: small;">
                                <div class="col">
                                    <strong class="text-right" *ngIf="printInvoice.invoiceType == 'B2B'"> For {{dealer.dealerName}} </strong><br>
                                    <strong class="text-right" *ngIf="printInvoice.invoiceType != 'B2B'"> For {{dealer.dealerBankAccountOwner ? dealer.dealerBankAccountOwner: dealer.dealerName}} </strong><br>
                                    <img height="80px" width="200px" style="align-self: right; "><br>
                                    <strong class="text-right" style="padding-right: 0px;"> Authorised Signatory
                                    </strong>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </div>




</div>
