<nav aria-label="breadcrumb" (click)="paymentShowToggle()">
    <ol class="breadcrumb" >
      <li class="breadcrumb-item active" aria-current="page" >
        <i class="fa fa-plus" style="margin-right: 10px; " *ngIf="!isPaymentShow"></i>
        <i class="fa fa-minus" style="margin-right: 10px; " *ngIf="isPaymentShow"></i>
        <p class="h5"> Payment Details</p></li>
    </ol>
  </nav>
  <div *ngIf = "isPaymentShow">
    <a (click)="paymentPlanFormToggle()" class="btn btn-sm btn-success mb-2">Create Payment</a>&nbsp; 
    <a (click)="paymentDetailFormToggle()" class="btn btn-sm btn-primary mb-2">Show Past Transactions</a> 
      <div *ngIf="isPaymentAddFormShow">
          <form [formGroup]="paymentForm" (ngSubmit)="onPaymentSubmit()">
              <div class="form-row">
                <div class="form-group col">
                  <label for="paymentAmount">Payment Amount</label>
                  <input type="number" formControlName="paymentAmount" class="form-control" [ngClass]="{ 'is-invalid': submitted && pmntForm.paymentAmount.errors }" />
                  <div *ngIf="submitted && pmntForm.paymentAmount.errors" class="invalid-feedback">
                      <div *ngIf="pmntForm.paymentAmount.errors.required">Payment Amount is required</div>
                  </div>
              </div>
              <div class="form-group col">
                      <label for="comments">Comments</label>
                      <input type="text" formControlName="comments" class="form-control" [ngClass]="{ 'is-invalid': submitted && pmntForm.comments.errors }" />
                      <div *ngIf="submitted && pmntForm.comments.errors" class="invalid-feedback">
                          <div *ngIf="pmntForm.comments.errors.required">Comments is required</div>
                      </div>
                  </div>
              </div>
              <div class="form-group">
                <button [disabled]="loading" class="btn btn-primary">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Save
                </button><a>&nbsp;&nbsp;</a>
                <button  class="btn btn-secondary mr-1" type="reset" (click)="onReset()">
                  Refresh Page
              </button>
                <a (click)="paymentPlanFormToggle()" class="btn btn-link" >Cancel</a>
      
            </div>
            <div class="form-group">
              <p *ngIf="isBalanceUpdated"> Balance Updated!!! Please refresh page to see new balance.</p>
          </div>

          </form>
      </div>
      <div *ngIf="isPaymentDetailShow">
        <form [formGroup]="paymentDetailForm" (ngSubmit)="getPaymentDetail()">
          <div class="form-row">
            <div class="form-group col">
              <label for="certificateNumber">Number of Records</label>
              <select class="form-select" formControlName="recordCount" [ngClass]="{ 'is-invalid': submitted && pmntDtlForm.recordCount.errors }" >
                <option *ngFor="let recordCount of numberofRecords" [ngValue]="recordCount">
                  {{ recordCount }}
                </option>
              </select>
            <div *ngIf="submitted && pmntDtlForm.recordCount.errors" class="invalid-feedback">
                <div *ngIf="pmntDtlForm.recordCount.errors.required">Record Count is required</div>
            </div>
            </div>
          </div>
          <div class="form-row">
              <div class="form-group">
                <button [disabled]="loading" class="btn btn-primary">
                  <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                  Get Payment Details
                </button><a>&nbsp;&nbsp;</a>
              </div>
            </div>
          </form>
          <table class="table table-striped">
            <thead>
                <tr>
                    <th style="width: 30%">Payment Date</th>
                    <th style="width: 30%">Payment Amount</th>
                    <th style="width: 30%">Comments</th>
                    <th style="width: 30%">Payment UserId</th>
                    <th style="width: 30%">Dealer Balance After</th>
                    <th style="width: 30%">Payment Username</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let paymentDtl of paymentDetails">
                    <td>{{paymentDtl.paymentDate}}</td>
                    <td>{{paymentDtl.paymentAmount}}</td>
                    <td>{{paymentDtl.comments}}</td>
                    <td>{{paymentDtl.userId}}</td>
                    <td>{{paymentDtl.dealerBalanceAfter}}</td>
                    <td>{{paymentDtl.userName}}</td>
                </tr>
                <tr *ngIf="!paymentDetails">
                    <td colspan="4" class="text-center">
                        <span class="spinner-border spinner-border-lg align-center"></span>
                    </td>
                </tr>
            </tbody>
        </table>
          </div>
          
      </div>