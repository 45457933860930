<h1 >Dealer Report</h1>
    <div class="form-row">
        <div class="form-group">
        <button (click)="onSubmit()" [disabled]="loading" class="btn btn-primary" >
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Search
        </button><a>&nbsp;&nbsp;</a>
        <button (click)="exportToExcel()" class="btn btn-primary" >
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Export to Excel
        </button><a>&nbsp;&nbsp;</a>
    </div>
    </div>
    <div class="form-row">
        <div class="form-group col">
        
        <ag-grid-angular #aggrid
    style="width: 100%; height: 600px;"
    [gridOptions] = "gridOptions"
    class="ag-theme-alpine"
>
</ag-grid-angular>
</div>
    </div>


