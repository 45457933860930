import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Alert } from '@app/_models';
import { ReportSearch } from '@app/_models/reportSearch';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { first } from 'rxjs/internal/operators/first';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root'
})
export class ChartsService {

  constructor(private router: Router, private alertService:AlertService,
    private http: HttpClient) { }
    savedReportSearch =  new ReportSearch();
    chartData :any[];

      setChartData(inputChartData:any[]){
        this.chartData = inputChartData;
      }

     getSummedupData(reportSearch: ReportSearch): Observable<any>{

      if (!this.savedReportSearch.issueDateFrom) this.savedReportSearch.issueDateFrom = " ";
      if (!this.savedReportSearch.issueDateTo) this.savedReportSearch.issueDateTo = " ";


      this.savedReportSearch = new ReportSearch(reportSearch);
      return this.http.post<any[]>(`${environment.apiUrl}/charts/getCertificateAggregatedData`, reportSearch);
  
    }

}
