<nav class="navbar navbar-expand-lg sticky-top shadow-sm role-{{ userType }}">
  <div class="container-fluid">
    <!-- Logo section - common for all roles -->
    <a class="navbar-brand" href="#">
      <img src="../assets/AllianceLogo.png" alt="Alliance Logo" height="40px">
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" 
            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" 
            aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <!-- ROLE-SPECIFIC NAVIGATION MENUS -->
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <!-- Admin Header -->
      <app-admin-header *ngIf="userType === 'admin'"
                        [user]="user"
                        [dealer]="dealer">
      </app-admin-header>

      <!-- Employee Header -->
      <app-employee-header *ngIf="userType === 'employee'"
                          [user]="user"
                          [dealer]="dealer">
      </app-employee-header>

      <!-- Dealer Owner Header -->
      <app-dealer-owner-header *ngIf="userType === 'dealerOwner'"
                              [user]="user"
                              [dealer]="dealer"
                              [showReceivableTab]="showReceivableTab">
      </app-dealer-owner-header>

      <!-- RSA Operation Header -->
      <app-rsa-operation-header *ngIf="userType === 'rsaOperation'"
                              [user]="user"
                              [dealer]="dealer">
      </app-rsa-operation-header>

      <!-- Hero Report Header -->
      <app-hero-report-header *ngIf="userType === 'heroReport'"
                             [user]="user"
                             [dealer]="dealer">
      </app-hero-report-header>

      <!-- Executive Header (Default) -->
      <app-executive-header *ngIf="userType === 'executive'"
                          [user]="user"
                          [dealer]="dealer"
                          [showReceivableTab]="showReceivableTab">
      </app-executive-header>
    </div>

    <!-- USER PROFILE SECTION - common for all roles -->
    <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle d-flex align-items-center" href="#" 
           id="userDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          <div class="avatar-circle me-2">
            <span class="initials">{{getInitials()}}</span>
          </div>
          <span class="d-none d-md-inline user-name">{{user.firstName + " " + user.lastName}}</span>
        </a>
        <ul class="dropdown-menu dropdown-menu-end shadow" aria-labelledby="userDropdown">
          <li class="dropdown-header">
            <strong>{{user.firstName + " " + user.lastName}}</strong>
            <small class="d-block text-muted">{{user.role | titlecase}}</small>
          </li>
          <li><hr class="dropdown-divider"></li>
          <li>
            <a class="dropdown-item" href="#" (click)="logout(); $event.preventDefault()">
              <i class="fa fa-sign-out me-2"></i>Logout
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</nav>
