<div class="printPage" id="printRsaForm" style="font-family: Arial, Helvetica, sans-serif">
    <div class="page" >
        <div class="subpage" >
            <div class="container-fluid" >
                <div class="row" style="text-align: left; margin-top: 35px;">
                    <div class="col-md-8"> 
                        <img src="../assets/AllianceLogo.png" height="60px" style="align-self: left;">
                    </div>
                    <div class="col-md-4"> 
                        <div class="container-fluid">
                            <div class="row">
                                <small class="text-right">{{currentDate}} </small>
                            </div>        
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid" >
                <div class="row" style="text-align: left;background: rgb(36,43,134)">
                    <div class="col"> 
                        <h4 class="text-center" style="color: bisque;">  Alliance Assure - Certificate of Assistance </h4>
                    </div>
                </div>
            </div>
            <div class="container-fluid" >
                <div class="row" style="text-align: center;">
                    <div class="col"> 
                        <strong class="text-center" >  CERTIFICATE NUMBER - {{certificate.certificateNumber}} </strong>
                    </div>
                </div>
            </div>
            <div class="container-fluid" style="margin-top: 6px;" >
                <div class="row" style="text-align: center;">
                    <div class="col"> 
                        <table class="table table-condensed" style="text-align: left; border: 1px solid black;">
                            <tbody style="font-size: small;font-family: arial, helvetica, sans-serif; font-weight: 600;">
                                <tr>
                                <td>
                                    <address>
                                        <strong>Corporate Address: Alliance Assure Private Limited</strong><br>
                                        #29, HSVP Shopping Complex, Sector 39,Gurugram (Haryana)-122003<br>
                                        PH: 1800 212 7559, CIN: U74999HR2021PTC092448, GSTIN:06AAUCA6872D1ZC
                                    </address>
                                    </td>
                            <td >
                                <address>
                                    <strong>For Assistance, Please contact us at: </strong><br>
                                    Email ID: <a href="mailto::%20support@allianceassure.in"><strong>: support&#64;allianceassure.in</strong></a>
                                </address>
                           </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong style="font-size:small" *ngIf="showGstAmount">Tax Invoice Number: {{certificate.certificateNumber}}</strong>
                                    <strong style="font-size:small" *ngIf="!showGstAmount">Certificate Number: {{certificate.certificateNumber}}</strong>
                                </td>
                                <td>
                                    
                                        <strong >Product Plan: {{certificate.planTotalAmount}}</strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong> Effective Period: {{certificate.issueDate | date:'dd-MM-yyyy hh:mm:ss a':'+0530'}} - {{certificate.endDate |date:'dd-MM-yyyy'}} MIDNIGHT</strong>
                                </td>
                                <td>
                                    <strong> IMD Code: {{certificate.dealerId}} </strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong> Name of Certificate Holder: {{certificate.firstName + " " + " " + certificate.lastName}}</strong>
                                </td>
                                <td>
                                    <strong> Dealer Name: {{certificate.dealerId}}</strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong *ngIf="!(certificate.ageOrDob && certificate.ageOrDob == 'age')"> DOB: {{certificate.dob}}</strong>
                                    <strong *ngIf="(certificate.ageOrDob && certificate.ageOrDob == 'age')"> Age: {{certificate.age}}</strong>
                                </td>
                                <td>
                                    <strong> Gender: {{certificate.gender}} </strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong> Mobile: {{certificate.contactNumber}}</strong>
                                </td>
                                <td>
                                    <strong> EmailId: {{certificate.email}} </strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong> Address: {{certificate.address|slice:0:150}}</strong>
                                </td>
                                <td>
                                    <strong> City / District: {{certificate.city}} </strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong> State: {{certificate.state}}</strong>
                                </td>
                                <td>
                                    <strong> Vehicle Type: &nbsp; {{certificate.vehicleType? certificate.vehicleType: "Two Wheeler"}} </strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong> OEM: {{certificate.vehicleMake}} </strong>
                                </td>
                                <td>
                                    <strong> Model/Variant: {{certificate.model}} / {{certificate.variant}}</strong>
                                </td>
                           </tr>
                            <tr>
                                <td>
                                    <strong> Vehicle Registration Number: {{certificate.registrationNumber}}</strong>
                                </td>
                                <td>
                                    <strong> Manufacturing Year: {{certificate.vehicleYear}} </strong>
                                </td>
                           </tr>
                            <tr>
                                <td>
                                    <strong> Engine Number: {{certificate.engineNumber}}</strong>
                                </td>
                                <td>
                                    <strong> Chasis Number: {{certificate.chasisNumber}} </strong>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                   </div>
                </div>
            </div>
            <div class="container-fluid" style="margin-top: 10px;">
                <div class="row" style="text-align: left;">
                    <div class="col"> 
                        <table class="table table-condensed" style="text-align: left; border: 1px solid black;margin-bottom: 2px;">
                            <tbody style="font-size: smaller;font-family: arial, helvetica, sans-serif; font-weight: 600;">
                                <tr>
                                <td>
                                    <div class="row">
                                    <div class="container-fluid col-md-6" style="text-align: left;">
                        <strong style="align-self: left;"> Plan Features </strong> </div> <div class="container-fluid col-md-6" style="text-align: right;"><strong style="text-align:right">Service Provider: Alliance Assure </strong>
                    </div> </div></td></tr></tbody> </table> 
                    </div>
                </div>
            </div>
            <div class="container-fluid" style="line-height: 15px;">
                <div class="row" >
                    <div class="col"> 
                        <small class="text-center" style="font-size: small;font-family: arial, helvetica, sans-serif; font-weight: 600; " > Message relay to relatives/colleagues/emergency numbers , Giving the contact details of nearest doctor to Certificate Holder , Arranging for ambulance/hospital for Certificate Holder in case of accident of above said vehicle , Locating nearest police station , Hospital Appointment ,Diagnostic test assistance , 24*7 Response centre , Hotel Accommodation.Only assistance services, actual cost to be borne by the customer. </small><br>
            
                                               
                    </div>
                </div>
            </div>
            <div class="container-fluid" >
                <div class="row" >
                    <div class="col-md-12" style="align-self: center;"> 
                        <table class="table table-condensed">
                            <tbody style="font-size: small; text-align: center;" *ngIf = "showGstAmount">
                                <tr>
                                    <td>
                                        <strong>Plan Amount (Rs)</strong>
                                    </td>
                                    <td>
                                        <strong>CGST(9%)</strong>
                                    </td>
                                    <td>
                                        <strong>SGST(9%)</strong>
                                    </td>
                                    <td>
                                        <strong>IGST(18%)</strong>
                                    </td>
                                    <td>
                                        <strong>Total Amount (Rs)</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>{{certificate.planAmount}}</strong>
                                    </td>
                                    <td>
                                        <strong>{{certificate.CgstAmount}}</strong>
                                    </td>
                                    <td>
                                        <strong>{{certificate.SgstAmount}}</strong>
                                    </td>
                                    <td>
                                        <strong>{{certificate.IgstAmount}}</strong>
                                    </td>
                                    <td>
                                        <strong>{{certificate.planTotalAmount}}</strong>
                                    </td>
                                </tr>
                            </tbody>
                      
                        </table>
                    </div>
                </div>
            </div>
            <div class="container-fluid" >
                <div class="row" style="text-align:right; font-size: small;">
                    <div class="col"> 
                        <strong class="text-right"> For Alliance Assure Private Limited </strong><br>
                        <img src="../assets/signature.png" height="80px" width="200px" style="align-self: right; margin-right: 20px;"><br>
                        <strong class="text-right" style="padding-right: 60px;"> Authorised Signatory </strong>
                    </div>
                </div>
            </div>
            <div class="container-fluid" >
                
                    <hr size="50" style="text-align: left;background: rgb(1, 2, 14)">
                
            </div>
            <div class="container-fluid" >
                <div class="row" style="text-align: left; margin-top: 40px;">
                    <div class="col-md-10" *ngIf="certificate.policyType.includes('Ifco')">
                        <img src="../assets/iffcotokio.png" height="100px" style="align-self: left;">
                    </div>
                    <div class="col-md-2"> 
                        <qrcode [qrdata]="certificate.certificateNumber" [width]="100" [errorCorrectionLevel]="'M'"></qrcode>
                    </div>
                </div>
            </div>
            <div class="container-fluid" >
                <div class="row" style="text-align: left;background: rgb(36,43,134)">
                    <div class="col"> 
                        <h4 class="text-center" style="color: rgb(248, 248, 248);">   Policy Coverages </h4>
                    </div>
                </div>
            </div>

          

            <div class="container-fluid" style="line-height: 13px; margin-top: 25px;" >
                <div class="row" >
                    <div class="col"> 
                        <small class="text-center" style="font-size: small;font-family: arial, helvetica, sans-serif; font-weight: 600; font-weight: 600;" > Rs.{{certificate.planCPACoverAmount| number}}/- (Accidental Death). Cover is valid Mr./Mrs. <strong>{{certificate.firstName +  " " + certificate.lastName  | uppercase}}</strong> as the insured is covered under the road assistance plan.</small>
                       
                    </div>
                </div>
            </div>

            <div class="container-fluid" style="line-height: 13px;  margin-top: 25px;" >
                <div class="row" >
                    <div class="col"> 
                        
                        <small class="text-center" style="font-size: small;font-family: arial, helvetica, sans-serif; font-weight: 600; font-weight: 600;" > ASSIGNMENT (If derired) Note : If policy benefits to be assigned, please complete the following : </small>
                    </div>
                </div>
            </div>
            <div class="container-fluid" style="line-height: 13px;  margin-top: 25px;" >
                <div class="row" >
                    <div class="col"> 
                        
                       <small class="text-center" style="font-size: small;font-family: arial, helvetica, sans-serif; font-weight: 900; " > DECLARATION FOR ASSIGNMENT </small>
                       <small class="text-center" style="font-size: small;font-family: arial, helvetica, sans-serif; font-weight: 600; margin-top: 25px" *ngIf="certificate.policyType == 'FINANCE'">  I <strong>{{certificate.firstName + " " + certificate.lastName}}</strong> do hereby assign the money payable by the New India Assurance Company Limited in the event of my death to my <strong>{{certificate.nomineeName + "(" + certificate.nomineeRelationship + ") Rs. " + certificate.nomineeCoverAmount  + " & " + certificate.financeCompanyName + "(Finance Company) Rs. " + certificate.financeCoverAmount  | uppercase}}</strong> (mention, relationship shall be sufficient discharge to the discharge to the Company.</small>
                       <small class="text-center" style="font-size: small;font-family: arial, helvetica, sans-serif; font-weight: 600; margin-top: 25px" *ngIf="certificate.policyType == 'FINANCE Ifco'">  I <strong>{{certificate.firstName + " "  + certificate.lastName}}</strong> do hereby assign the money payable by the Iffco Tokio General Insurance Co. LTD in the event of my death to my <strong>{{certificate.nomineeName + "(" + certificate.nomineeRelationship + ") Rs. " + certificate.nomineeCoverAmount  + " & " + certificate.financeCompanyName + "(Finance Company) Rs. " + certificate.financeCoverAmount  | uppercase}}</strong> (mention, relationship shall be sufficient discharge to the discharge to the Company.</small>
                       <small class="text-center" style="font-size: small;font-family: arial, helvetica, sans-serif; font-weight: 600; margin-top: 25px" *ngIf="(certificate.policyType == 'NON-FINANCE Ifco')">  I <strong>{{certificate.firstName +  " " + certificate.lastName}}</strong> do hereby assign the money payable by the Iffco Tokio General Insurance Co. LTD in the event of my death to my <strong>{{certificate.nomineeName + "(" + certificate.nomineeRelationship + ") Rs. " + certificate.nomineeCoverAmount | uppercase}}</strong> (mention, relationship shall be sufficient discharge to the discharge to the Company.</small>
                       <small class="text-center" style="font-size: small;font-family: arial, helvetica, sans-serif; font-weight: 600; margin-top: 25px" *ngIf="(certificate.policyType == 'NON-FINANCE')">  I <strong>{{certificate.firstName + " " + certificate.lastName}}</strong> do hereby assign the money payable by the New India Assurance Company Limited in the event of my death to my <strong>{{certificate.nomineeName + "(" + certificate.nomineeRelationship + ") Rs. " + certificate.nomineeCoverAmount | uppercase}}</strong> (mention, relationship shall be sufficient discharge to the discharge to the Company.</small>
                    </div>
                </div>
            </div>

            <div class="container-fluid" style="line-height: 13px;  margin-top: 40px;">
                <div class="row" >
                    <div class="col"> 
                        
                       <small class="text-center" style="font-size: small;font-family: arial, helvetica, sans-serif; font-weight: 900; " > SIGNATURE </small>
                      
                    </div>
                </div>
            </div>
            <div class="container-fluid" style="margin-top: 15px;">
                <div class="row" style="text-align: left;">
                    <div class="col"> 
                        <table class="table table-condensed" style="text-align: left; border: 1px solid black;margin-bottom: 2px;">
                            <tbody style="font-size: smaller;font-family: arial, helvetica, sans-serif; font-weight: 600;">
                                <tr>
                                <td>
                        <strong>Restriction of Coverage</strong>
                        </td></tr></tbody> </table>  
                    </div>
                </div>
            </div>
            <div class="container-fluid" style="line-height: 13px;  margin-top: 10px;">
                <div class="row" >
                    <div class="col"> 
                        
                      
                       <small class="text-center" style="font-size: small;font-family: arial, helvetica, sans-serif; font-weight: 600; margin-top: 25px" >  Accidental Death: It is strictly restricted to Death due to accident, while the insured (owner) of vehicle driving the vehicle and vehicle is registered with Alliance Assure.
                        Coverage is applicable to persons with age up to 70 years.
                       <a *ngIf="certificate.planCPACoverAmount >= 500000"> For SI more than 5Lakh gainful employment proof is required to submit while intimation of claim.</a>
                        </small>
                    </div>
                </div>
            </div>
            <div class="container-fluid" style="margin-top: 15px;">
                <div class="row" style="text-align: left;">
                    <div class="col"> 
                        <table class="table table-condensed" style="text-align: left; border: 1px solid black;margin-bottom: 2px;">
                            <tbody style="font-size: smaller;font-family: arial, helvetica, sans-serif; font-weight: 600;">
                                <tr>
                                <td>
                        <strong>Coverage in Brief</strong>
                        </td></tr></tbody> </table>  
                    </div>
                </div>
            </div>
            <div class="container-fluid" style="line-height: 13px;  margin-top: 10px;">
                <div class="row" >
                    <div class="col"> 
                        
                      
                       <small class="text-center" style="font-size: small;font-family: arial, helvetica, sans-serif; font-weight: 600; margin-top: 25px" >  If the Insured person meets with an accident and sustains any bodily injury during the policy period which directly and independently of all other causes result in death / permanent total disablement within 12 months from the date of accident resulting solely and directly from accident then the company shall pay to the insured the sum set in the schedule to the insureds persons nominee,beneficiary or legal representative. Accidental Death: 100°/» of cumulative Sum Insured (CSI). Permanent Total Disability: •Loss of Two Limbs/ Two Eyes or One Limb and One Eye: 100% of CSI •Loss of One Limb or One Eye : 50°/» of CSI •Permanent Total Disablement from Injuries other than those named above: 100°/» of CSI. 

                        </small>
                    </div>
                </div>
            </div>




        </div>
    </div>    
</div>    