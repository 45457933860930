<div class="container-fluid" [ngClass]="{ 'bg-light': user }" style="overflow:scroll">
    <nav class="navbar navbar-expand-lg navbar-dark " *ngIf="user">
      <div class="container-fluid">
        <a class="navbar-brand d-none d-lg-block" href="#">
          <img src="../assets/AllianceLogo.png" height="40px">
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent" *ngIf="user.role != 'rsaOperation'">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <a class="nav-item nav-link active" aria-current="page" routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="collapseNavbar()">Home</a>
            </li>
            <li class="nav-item" *ngIf="user.role == 'admin'">
              <a class="nav-item nav-link" routerLink="/users" routerLinkActive="active" (click)="collapseNavbar()">Dealer</a>
            </li>
            <li class="nav-item" *ngIf="user.role == 'dealerOwner'">
              <a class="nav-link" routerLink="/users/viewDealer" routerLinkActive="active" (click)="collapseNavbar()">Dealer</a>
            </li>
            <li class="nav-item" *ngIf="user.role == 'dealerOwner' && showReceivableTab">
              <a class="nav-item nav-link" routerLink="/users/dealerPayout/" routerLinkActive="active" (click)="collapseNavbar()">Receivable</a>
            </li>
            <li class="nav-item">
              <a class="nav-item nav-link" routerLink="/issueCertificate" routerLinkActive="active" (click)="collapseNavbar()">Issue Certificate</a>
            </li>
            <li class="nav-item dropdown" *ngIf="user.role == 'admin' || (user.specialRoleAccess && user.specialRoleAccess.includes('ExcelUpload'))">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Bulk Upload
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <li><a class="dropdown-item" routerLink="/issueCertificate/bulkIndividual" routerLinkActive="active" (click)="collapseNavbar()">Individual Policy</a></li>
              </ul>
            </li>
            <li class="nav-item">
              <a class="nav-item nav-link" routerLink="/reports/printCertificate" routerLinkActive="active" (click)="collapseNavbar()">Print Certificate</a>
            </li>
            <!-- <li class="nav-item" *ngIf="user.role == 'admin'">
              <a class="nav-item nav-link" routerLink="/reports/requestCertificate" routerLinkActive="active" (click)="collapseNavbar()">Active Request</a>
            </li> -->
            <li class="nav-item dropdown" *ngIf="user.role == 'admin'">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownActiveRequest" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Active Request
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdownActiveRequest">
                <li><a class="dropdown-item" routerLink="/reports/activeEndorsement" routerLinkActive="active" (click)="collapseNavbar()">Active Endorsement Request</a></li>
                <li><a class="dropdown-item" routerLink="/reports/activeCancel" routerLinkActive="active" (click)="collapseNavbar()">Active Cancel Request</a></li>
</ul>
</li>
            <li class="nav-item" *ngIf="user.specialRoleAccess && user.specialRoleAccess.includes('CanRenewAllPolicies')">
              <a class="nav-item nav-link" routerLink="/reports/renewCertificate" routerLinkActive="active" (click)="collapseNavbar()">Renew Certificate</a>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownReports" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Reports
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdownReports">
                <li><a class="dropdown-item" routerLink="/reports/certificateReport" routerLinkActive="active" (click)="collapseNavbar()">Certificate Report</a></li>
                <li><a class="dropdown-item" routerLink="/reports/dealerReport" routerLinkActive="active" *ngIf="user.role == 'admin' || user.role == 'employee'" (click)="collapseNavbar()">Dealer Report</a></li>
                <li><a class="dropdown-item" routerLink="/reports/dealerAggregateReport" routerLinkActive="active" *ngIf="user.role == 'admin' || user.role == 'employee'" (click)="collapseNavbar()">Dealer Aggregate Report</a></li>
                <li><a class="dropdown-item" routerLink="/reports/dealerInactivityReport" routerLinkActive="active" *ngIf="user.role == 'admin'" (click)="collapseNavbar()">Dealer Inactivity Report</a></li>
                <li><a class="dropdown-item" routerLink="/reports/dealerSummaryReport" routerLinkActive="active" *ngIf="user.role == 'admin'" (click)="collapseNavbar()">Dealer Summary Report</a></li>
                <li><a class="dropdown-item" routerLink="/reports/managerAggregateReport" routerLinkActive="active" *ngIf="user.role == 'manager' || user.role == 'dealerOwner'" (click)="collapseNavbar()">Aggregate Report</a></li>
                <li><a class="dropdown-item" routerLink="/reports/rsaCertificate" routerLinkActive="active" *ngIf="user.role == 'admin'" (click)="collapseNavbar()">Rsa Certificate</a></li>
                <li><a class="dropdown-item" routerLink="/reports/endorsementReport" routerLinkActive="active" *ngIf="user.role == 'admin'" (click)="collapseNavbar()">Endorsement Report</a></li>
                <li><a class="dropdown-item" routerLink="/reports/dealerBalanceReport" routerLinkActive="active" *ngIf="user.role == 'admin' || (user.specialRoleAccess && user.specialRoleAccess.includes('CanViewDealerBalanceReport'))" (click)="collapseNavbar()">Dealer Balance Details</a></li>
                <li><a class="dropdown-item" routerLink="/reports/transactionReport" routerLinkActive="active" *ngIf="user.role == 'admin'" (click)="collapseNavbar()">Payment Report</a></li>
                <li><a class="dropdown-item" routerLink="/reports/invoiceReport" routerLinkActive="active" *ngIf="user.role == 'admin'" (click)="collapseNavbar()">Invoice Report</a></li>
                <li><a class="dropdown-item" routerLink="/reports/payoutReport" routerLinkActive="active" *ngIf="user.role == 'admin'" (click)="collapseNavbar()">Payout Report</a></li>
                <li><a class="dropdown-item" routerLink="/reports/certificateReport/NewIndiaPolicy" routerLinkActive="active" *ngIf="user.role == 'admin'" (click)="collapseNavbar()">New India Report</a></li>
                <li><a class="dropdown-item" routerLink="/reports/policyBalanceReport" routerLinkActive="active" *ngIf="user.role == 'admin' || (user.specialRoleAccess && user.specialRoleAccess.includes('Can View Policy Balance Details Report') && dealer.dealerCreatePackage)" (click)="collapseNavbar()">Policy Balance Details</a></li>
                <li><a class="dropdown-item" routerLink="/reports/totalBalanceReport" routerLinkActive="active" *ngIf="user.role == 'admin' || (user.specialRoleAccess && user.specialRoleAccess.includes('Can View Package Balance Detail Report') && dealer.dealerCreatePackage)" (click)="collapseNavbar()">Package Balance Details</a></li>
              </ul>
            </li>
            <li class="nav-item dropdown" *ngIf="user.role == 'admin'">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownAnalytics" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Analytics
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdownAnalytics">
                <li><a class="dropdown-item" routerLink="/analytics/numberOfPolicies" routerLinkActive="active" (click)="collapseNavbar()">Number of Policies</a></li>
                <li><a class="dropdown-item" routerLink="/analytics/receivable" routerLinkActive="active" (click)="collapseNavbar()">Receivable</a></li>
              </ul>
            </li>
            <li class="nav-item dropdown" *ngIf="user.role == 'admin'">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownConfigUpdate" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                Configuration Update
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdownConfigUpdate">
                <li *ngFor="let polMaintain of masterPolicyMaintain">
                  <a class="dropdown-item" routerLink={{polMaintain.url}} routerLinkActive="active" (click)="collapseNavbar()">{{polMaintain.heading}}</a>
                </li>
                <div class="dropdown-divider"></div>
                <li *ngFor="let serMaintain of seriesMaintain">
                  <a class="dropdown-item" routerLink={{serMaintain.url}} routerLinkActive="active" (click)="collapseNavbar()">{{serMaintain.heading}}</a>
                </li>
                <div class="dropdown-divider"></div>
                <li *ngFor="let setMaintain of setupMaintain">
                  <a class="dropdown-item" routerLink={{setMaintain.url}} routerLinkActive="active" (click)="collapseNavbar()">{{setMaintain.heading}}</a>
                </li>
              </ul>
            </li>
            <li class="nav-item" *ngIf="user.role == 'manager'">
              <a class="nav-item nav-link" routerLink="/users/userList" routerLinkActive="active" (click)="collapseNavbar()">User Maintenance</a>
            </li>
            <li class="nav-item">
              <a class="nav-item nav-link" (click)="logout()">Logout</a>
            </li>
          </ul>
        </div>
  
        <div class="collapse navbar-collapse" id="navbarSupportedContent" *ngIf="user.role == 'rsaOperation'">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <a class="nav-item nav-link active" aria-current="page" routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" (click)="collapseNavbar()">Home</a>
            </li>
            <li class="nav-item">
              <a class="nav-item nav-link" routerLink="/issueCertificate/rsa" routerLinkActive="active" (click)="collapseNavbar()">RSA</a>
            </li>
            <li class="nav-item" *ngIf="user.specialRoleAccess && user.specialRoleAccess.includes('CanRenewAllPolicies')">
              <a class="nav-item nav-link" routerLink="/reports/renewCertificate" routerLinkActive="active" (click)="collapseNavbar()">Renew Certificate</a>
            </li>
            <li class="nav-item">
              <a class="nav-item nav-link" (click)="logout()">Logout</a>
            </li>
          </ul>
        </div>
  
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <i class="fa fa-user nav-item nav-link" style="margin-top: 5px;"></i>
          </li>
          <li class="nav-item">
            <a class="nav-item nav-link" (click)="logout()">{{" " + user.firstName + " " + user.lastName}}</a>
          </li>
        </ul>
      </div>
    </nav>
  
    <!-- main app container -->
    <div id="appContainer">
      <alert></alert>
      <router-outlet></router-outlet>
      <alert></alert>
    </div>
  
    <!-- credits -->
    <div class="text-center mt-4">
      <p>
        For any support reach out at <a href="">support&#64;allianceassure.in</a>
      </p>
      <p>
        <img src="../assets/AllianceLogo.png" height="80px">
        <a href="https://AllianceAssure.com" target="_top">AllianceAssure.com</a>
      </p>
      <br>
      <small>
        <a href="https://allianceassure.com/terms-conditions/">TERMS AND CONDITIONS </a>&nbsp;<a href="https://allianceassure.com/privacy-policy/">| PRIVACY POLICY |</a>&nbsp;<a style="color: #0d6efd;text-decoration-line:underline" (click)="cancellationPolicyToggle()"> CANCELLATION/REFUND POLICY |</a>&nbsp; <a href="https://allianceassure.com/contact/"> CONTACT US |</a>&nbsp;<a href="https://allianceassure.com/"> ABOUT US |</a>
      </small>
      <br>
      <small *ngIf="showCancellationPolicy">
        THERE IS NO CANCELLATION OPTION FOR THE END USERS AFTER PAYMENT IS MADE. IN CASE OF DUPLICATE PAYMENT, KINDLY APPROACH US AT SUPPORT&#64;ALLIANCEASSURE.IN .FOR REFUND WITH PROOF OF THE TRANSACTION REFERENCE/ YOUR BANK STATEMENT. REFUND WILL BE PROCESSED WITHIN 10-15 WORKING DAYS, RESPECTIVE PAYMENT GATEWAY WILL SEND BACK TO THE ISSUING BANK [USER’S CARD BANKER] IN BATCHES FOR PROCESSING, WHICH SHOULD APPROXIMATELY TAKE 10-15 WORKING DAYS, DEPENDING ON ISSUING BANKS POLICIES. HOWEVER, ALLIANCE ASSURE PVT LTD. IS NOT RESPONSIBLE FOR ANY DELAY ON THE BANK SIDE.
      </small>
    </div>
  </div>