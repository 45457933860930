<div class="dashboard-container">
  <!-- Welcome Section -->
  <div class="welcome-card mb-4">
    <div class="row align-items-center">
      <div class="col-md-8">
        <h1 class="display-4 mb-2">{{ greeting }}, {{ getUserDisplayName() }}!</h1>
        <p class="lead text-muted">{{ currentTime | date:'EEEE, MMMM d, y' }}</p>
      </div>
      <div class="col-md-4 text-end">
        <div class="time-display">
          <span class="current-time">{{ currentTime | date:'h:mm' }}</span>
          <span class="am-pm">{{ currentTime | date:'a' }}</span>
        </div>
      </div>
    </div>
  </div>

  <!-- Role-specific dashboard content -->
  <ng-container [ngSwitch]="user?.role">
    <app-admin-dashboard *ngSwitchCase="'admin'" [user]="user"></app-admin-dashboard>
    <app-employee-dashboard *ngSwitchCase="'employee'" [user]="user"></app-employee-dashboard>
    <app-dealer-dashboard *ngSwitchDefault [user]="user"></app-dealer-dashboard>
  </ng-container>
</div>
