import { Component, OnInit, ViewChild } from '@angular/core';
import { Dealer } from '@app/_models/dealer';
import { User } from '@app/_models/user';
import { AlertService,AccountService } from '@app/_services';
import { DealerService } from '@app/_services/dealer-service';
import { AgGridAngular } from 'ag-grid-angular';
import { GridOptions } from 'ag-grid-community';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-dealer-report',
  templateUrl: './dealer-report.component.html',
  styleUrls: ['./dealer-report.component.less']
})
export class DealerReportComponent implements OnInit {
  @ViewChild('aggrid') aggrid: AgGridAngular;
  submitted=false;
  loading= false;
  gridOptions: GridOptions;
  gridReady = true;
  reportDealerData : Dealer[];
  user: User;

  constructor(
    private alertService: AlertService,
    private dealerService: DealerService,
    private accountService: AccountService

  ) { }

  ngOnInit(): void {
    this.user = this.accountService.userValue;
    this.gridOptions = <GridOptions>{}; 
    this.gridOptions.columnDefs = [
      {headerName: "Dealer Id", field: "dealerId",sortable: true, filter:true }
      ,{headerName: "Dealer Name", field: "dealerName",sortable: true, filter:true }
      ,{headerName: "Dealer State", field: "dealerState",sortable: true, filter:true }
      ,{headerName: "Dealer City", field: "dealerCity",sortable: true, filter:true }
      ,{headerName: "Email", field: "dealerEmail",sortable: true, filter:true }
      ,{headerName: "Contact Number", field: "dealerContactNumber",sortable: true, filter:true }
      ,{headerName: "Limit Remaining",valueGetter: (params)=>{return params.data.dealerBalanceLimit - params.data.dealerBalance}, sortable: true, filter:true }
      ,{headerName: "Balance", field: "dealerBalance",sortable: true, filter:true }
      ,{headerName: "Balance Limit", field: "dealerBalanceLimit",sortable: true, filter:true }
      ,{headerName: "Total Amount", field: "dealerTotalPayment",sortable: true, filter:true }
      ,{headerName: "Total GST", field: "dealerTotalGstPayment",sortable: true, filter:true }
      // ,{headerName: "Total Payout", field: "dealerTotalRefund",sortable: true, filter:true }
      // ,{headerName: "Total GST Paid", field: "dealerTotalGstRefund",sortable: true, filter:true }
      // ,{headerName: "Pending Payout", field: "dealerPendingRefund",sortable: true, filter:true }
      // ,{headerName: "GST Pending Payout", field: "dealerGstPendingRefund",sortable: true, filter:true }
      ,{headerName: "Dealer Type", field: "dealerType",sortable: true, filter:true }        
      ,{headerName: "Plan Type", field: "dealerPlanType",sortable: true, filter:true }        
    ]
 
    
    this.gridOptions.rowData = [];
    this.gridReady = true;

  }
  onSubmit(){
    
    this.alertService.clear();
    if (this.user.role != "admin" && this.user.role != "employee"){
      this.alertService.error("Only Admin are allowed for this feature");
      return;
    }
    this.loading = true;

    this.dealerService.getAll()
    .pipe(first())
    .subscribe({
      next: (x) => {
        this.reportDealerData = x;
        // this.gridOptions.rowData = x;
        this.aggrid.api.setRowData(x);
        console.log("x: " + JSON.stringify(x))
        this.gridReady = true;
        this.loading = false;
      },
      error: error => {
        this.alertService.error(error)
        this.loading = false;
      }
    })  

  }
  exportToExcel(){
    const params = {
      columnGroups: true,
      allColumns: true,
      fileName: 'excelDealerReport',
 //     columnSeparator: document.querySelector("#columnSeparator").value
    };
    this.aggrid.api.exportDataAsCsv(params);
    // this.aggrid.api.exportDataAsExcel(params);
  }

}
