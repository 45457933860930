import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from '@app/_models';
import { Invoice } from '@app/_models/invoice';
import { ReportSearch } from '@app/_models/reportSearch';
import { AccountService } from '@app/_services/account.service';
import { AlertService } from '@app/_services/alert.service';
import { DealerService } from '@app/_services/dealer-service';
import { SearchService } from '@app/_services/search.service';
import { AgGridAngular } from 'ag-grid-angular';
import { GridOptions } from 'ag-grid-community';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-payout-certificate-report',
  templateUrl: './payout-certificate-report.component.html',
  styleUrls: ['./payout-certificate-report.component.less']
})
export class PayoutCertificateReportComponent implements OnInit {
  @Input() invoice:Invoice;
  @ViewChild('aggrid') aggrid: AgGridAngular;
  gridOptionsCertificate: GridOptions;
  gridReadyCertificate = false;
  loading = false;
  isCertificateReportShow=false;
  reportSearch: ReportSearch;
  id: string;
  user:User;



  constructor(
    private route: ActivatedRoute,
    private alertService: AlertService,
    private accountService: AccountService,
    private searchService: SearchService

  ) { }

  ngOnInit(): void {
    this.gridOptionsCertificate = <GridOptions>{}; 
    this.user = this.accountService.userValue;

    if (this.user.role == "admin") {
      this.id = this.route.snapshot.params['id'];
    }
    else {
      this.id = this.user.dealerId;
    }
    
    this.gridOptionsCertificate.columnDefs = [
      {headerName: "Serial No", valueGetter: "node.rowIndex + 1"}
      ,{headerName: "Certificate Number", field: "certificateNumber",sortable: true, filter:true }
      ,{headerName: "Issue Date", field: "issueDate",sortable: true, filter:true }
      ,{headerName: "Plan ", field: "planId",sortable: true, filter:true }
      ,{headerName: "Eligible Amount", field: "eligiblePayoutAmount",sortable: true, filter:true }
      ,{headerName: "Eligible GST Amount", field: "eligiblePayoutGstAmount",sortable: true, filter:true }

    ];
    this.gridOptionsCertificate.rowData = [];
    this.gridReadyCertificate = true;
    this.getCertificateReport(this.invoice);

  }

  getCertificateReport(dealerInvoice){
    this.isCertificateReportShow = true;
    this.reportSearch = new ReportSearch();
    this.loading = true;
    this.reportSearch.dealerId = this.id;
    this.reportSearch.month = dealerInvoice.invoiceMonth;
    this.reportSearch.year = dealerInvoice.invoiceYear;
    var totalPayoutAmount = 0;
    var totalPayoutGstAmount = 0;
    console.log("reportSearch: " + JSON.stringify(this.reportSearch));
    this.searchService.getPayoutDetails(this.reportSearch)
    .pipe(first())
    .subscribe({
      next: (x) => {
        var payoutData = x.map(a => {
          totalPayoutAmount = totalPayoutAmount + a.planAmount - a.NetPlanAmount;
          totalPayoutGstAmount = totalPayoutGstAmount + a.planGSTAmount - a.NetPlanGSTAmount;
          return {
            "certificateNumber": a.certificateNumber,
            "planId": a.planId,
            "issueDate": a.issueDateString, 
            "eligiblePayoutAmount": a.planAmount - a.NetPlanAmount,
            "eligiblePayoutGstAmount": a.planGSTAmount - a.NetPlanGSTAmount
          }
        });
        if (totalPayoutAmount > 0)
        payoutData.push({
          "certificateNumber": "",
          "planId": "Total",
          "issueDate": "", 
          "eligiblePayoutAmount": parseFloat(totalPayoutAmount.toFixed(2)),
          "eligiblePayoutGstAmount": parseFloat(totalPayoutGstAmount.toFixed(2))

        });
        this.aggrid.api.setRowData(payoutData);
        // this.aggrid.api.setRowData(payoutData);
        this.loading = false;
      },
      error: error => {
        this.alertService.error(error);
        this.loading = false;

      }
    });
  }
  certificateExportExcel(){
    const params = {
      columnGroups: true,
      allColumns: true,
      fileName: 'CertificatePayoutReport',
 //     columnSeparator: document.querySelector("#columnSeparator").value
    };
    this.aggrid.api.exportDataAsCsv(params);


  }
}
