import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Dealer } from '@app/_models/dealer';
import { AccountService } from '@app/_services/account.service';
import { ConfigDataService } from '@app/_services/config-data.service';
import { DealerService } from '@app/_services/dealer-service';
import { AlertService } from '@app/_services/alert.service';
import { User } from '@app/_models';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-view-dealer',
  templateUrl: './view-dealer.component.html',
  styleUrls: ['./view-dealer.component.less']
})
export class ViewDealerComponent implements OnInit {
  form: UntypedFormGroup;
  dealer: Dealer;
  submitted = false;
  loading = false;
  user:User;
  showReceivableTab = false;
  showPaymentTab = false;



  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private alertService: AlertService,
    private configDataService: ConfigDataService,
    private dealerService: DealerService

  ) { }

  ngOnInit(): void {
    this.user = this.accountService.userValue;

    this.form = this.formBuilder.group({
      dealerId: [this.user.dealerId, Validators.required],
      dealerName: ['', Validators.required],
      dealerAddress: ['', Validators.required],
      dealerState: ['', Validators.required],
      dealerCity: [''],
      dealerContactNumber: ['', Validators.required],
      dealerEmail: ['', [Validators.required,Validators.email]],
      dealerBalance: ['', Validators.required],
      dealerBalanceLimit: ['', Validators.required],
      dealerMake: ['', Validators.required],
      dealerPlanType: ['', Validators.required],
      dealerTotalPayment: [0],
      dealerTotalGstPayment: [0],
      dealerTotalRefund: [0],
      dealerTotalGstRefund: [0],
      dealerPendingRefund: [0],
      dealerGstPendingRefund: [0],
      dealerType: [''],
      dealerBankAccountOwner: [''],
      dealerBankAccountNumber: [''],
      dealerBankIfscCode: [''],
      dealerBankOwnerPan: [''],
      dealerGstId: [''],
      dealerVpaId: [''],
    });
    this.dealerService.getByDealerId(this.user.dealerId)
    .pipe(first())
    .subscribe(x => {
      this.dealer = x;
      this.showReceivableTab = this.dealer.dealerInvoiceShow ? this.dealer.dealerInvoiceShow == "No"? false: true : true;
      this.showPaymentTab = this.dealer.dealerPaymentShow ? this.dealer.dealerPaymentShow == "Yes"? true:false:false;
      x.dealerInvoiceShow = x.dealerInvoiceShow ? x.dealerInvoiceShow : "Yes";
      this.dealer.dealerPaymentShow = x.dealerPaymentShow ? x.dealerPaymentShow : "No";
      this.form.patchValue(x);
      this.form.controls['dealerBalance'].setValue(this.user.specialRoleAccess.includes("Show Total Package Balance")? this.dealer.dealerBalance + (this.dealer.dealerPolicyBalance ? this.dealer.dealerPolicyBalance : 0):this.dealer.dealerBalance)
    });

    this.form.controls['dealerId'].disable();
    this.form.controls['dealerName'].disable();
    this.form.controls['dealerAddress'].disable();
    this.form.controls['dealerState'].disable();
    this.form.controls['dealerCity'].disable();
    this.form.controls['dealerBalance'].disable();
    this.form.controls['dealerBalanceLimit'].disable();
    this.form.controls['dealerMake'].disable();
    this.form.controls['dealerPlanType'].disable();
    this.form.controls['dealerTotalPayment'].disable();
    this.form.controls['dealerTotalGstPayment'].disable();
    this.form.controls['dealerTotalRefund'].disable();
    this.form.controls['dealerTotalGstRefund'].disable();
    this.form.controls['dealerPendingRefund'].disable();
    this.form.controls['dealerGstPendingRefund'].disable();


  }
  get f() { return this.form.controls; }

  onSubmit(){
    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    this.dealer.dealerContactNumber = this.form.get('dealerContactNumber').value;;
    this.dealer.dealerEmail = this.form.get('dealerEmail').value;
    this.dealer.dealerBankAccountOwner = this.form.get('dealerBankAccountOwner').value;
    this.dealer.dealerBankAccountNumber = this.form.get('dealerBankAccountNumber').value;
    this.dealer.dealerBankIfscCode = this.form.get('dealerBankIfscCode').value;
    this.dealer.dealerBankOwnerPan = this.form.get('dealerBankOwnerPan').value;
    this.dealer.dealerGstId = this.form.get('dealerGstId').value;
    console.log("this.form.value: " + this.form.value);
    this.dealerService.updateByDealer(this.dealer.id, this.dealer)
    .pipe(first())
    .subscribe({
        next: () => {
            this.alertService.success('Update successful', { keepAfterRouteChange: true });
            //this.router.navigate(['../../'], { relativeTo: this.route });
            this.loading = false;
        },
        error: error => {
            this.alertService.error(error);
            this.loading = false;
        }
    });

  
  }
  onReset(){
    window.location.reload();
  }
}
