<h2 class="payment-header">Add Payment</h2>
<form [formGroup]="paymentForm" (ngSubmit)="onSubmit()" class="payment-form">
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>Dealer Id</mat-label>
    <mat-select formControlName="dealerId">
      <mat-option *ngFor="let dealer of dealers" [value]="dealer">
        {{ dealer }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="paymentForm.get('dealerId')?.hasError('required')">
      Dealer Id is required
    </mat-error>
  </mat-form-field>
  
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>Payment Amount</mat-label>
    <input matInput type="number" formControlName="paymentAmount">
    <mat-error *ngIf="paymentForm.get('paymentAmount')?.hasError('required')">
      Payment amount is required
    </mat-error>
    <mat-error *ngIf="paymentForm.get('paymentAmount')?.hasError('min')">
      Payment amount must be a positive number
    </mat-error>
  </mat-form-field>
  
  <mat-form-field appearance="fill" class="full-width">
    <mat-label>Payment Comment</mat-label>
    <textarea matInput formControlName="comments"></textarea>
    <mat-error *ngIf="paymentForm.get('comments')?.hasError('required')">
      Comments are required
    </mat-error>
  </mat-form-field>
  
  <button mat-raised-button color="primary" type="submit" [disabled]="paymentForm.invalid || loading">
    Submit Payment
  </button>
  
  <div *ngIf="loading" class="spinner-container">
    <mat-progress-spinner mode="indeterminate" diameter="50"></mat-progress-spinner>
  </div>
</form>