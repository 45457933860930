import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ReportSearch } from '../_models/reportSearch';
import { Certificate } from '../_models/certificate';
import { environment } from '@environments/environment';
import { CertificateAdmin } from '../_models/certificateAdmin';
import { Payouts } from '@app/_models/payout';
import { Observable, forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private router: Router,
    private http: HttpClient) { }
  
  getEndorsementReport(reportSearch: ReportSearch){
      return this.http.post<Certificate[]>(`${environment.apiUrl}/search/getEndorsementReport`, reportSearch);
  }
  getCertificateReport(reportSearch: ReportSearch){
   return this.http.post<Certificate[]>(`${environment.apiUrl}/search/getCertificateReport`, reportSearch);
  }
   getAdminCertificateReport(reportSearch: ReportSearch){
    return this.http.post<CertificateAdmin[]>(`${environment.apiUrl}/search/getCertificateReport`, reportSearch);
  } 
  getDealerAggregatedReport(reportSearch: ReportSearch){
    return this.http.post<any[]>(`${environment.apiUrl}/search/getDealerAggregatedReport`, reportSearch);
  }
  getDealerSummaryReport(reportSearch: ReportSearch){
    return this.http.post<any[]>(`${environment.apiUrl}/search/getDealerSummaryReport`, reportSearch);
  } 
  getManagerAggregation(reportSearch: ReportSearch){
    return this.http.post<any[]>(`${environment.apiUrl}/search/getManagerAggregation`, reportSearch);
  } 
  getRsaCertificateReport(reportSearch: ReportSearch){
    return this.http.post<any[]>(`${environment.apiUrl}/search/getRsaCertificateReport`, reportSearch);
  } 
  getPayoutDetails(reportSearch: ReportSearch){
    return this.http.post<CertificateAdmin[]>(`${environment.apiUrl}/search/getPayoutDetails`, reportSearch);
  } 
  getDealerBalanceReport(reportSearch: ReportSearch){
    return this.http.post<any[]>(`${environment.apiUrl}/search/getDealerBalanceReport`, reportSearch);
  } 
  getDealerPolicyBalanceReport(reportSearch: ReportSearch){
    return this.http.post<any[]>(`${environment.apiUrl}/search/getDealerPolicyBalanceReport`, reportSearch);
  } 
  getDealerTotalBalanceReport(reportSearch: ReportSearch){
    return this.http.post<any[]>(`${environment.apiUrl}/search/getDealerTotalBalanceReport`, reportSearch);
  } 
  getPaymentReport(reportSearch: ReportSearch){
    return this.http.post<any[]>(`${environment.apiUrl}/search/getPaymentReport`, reportSearch);
  } 
  getPayoutReportDetails(reportSearch: ReportSearch){
    return this.http.post<Payouts[]>(`${environment.apiUrl}/search/getPayoutReportDetails`, reportSearch);
  } 
  getRenewCertificateList(reportSearch: ReportSearch){
    return this.http.post<Certificate[]>(`${environment.apiUrl}/search/getRenewCertificateList`, reportSearch);
  } 
  getInactiveDealers(activityDuration: number, inactivityPeriod: number, percentageDecrease: number): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/search/inactivity-report`, {
      params: {
        activityDuration: activityDuration.toString(),
        inactivityPeriod: inactivityPeriod.toString(),
        percentageDecrease: percentageDecrease.toString()
      }
    });
  }
  getDealerDailyActivity(dealerId: string, months: number): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/search/daily-activity`, {
      params: { months: months.toString(), dealerId: dealerId }
    });
  }

  // New method to get year-on-year summary data
  getYearOnYearSummaryData(reportSearch: ReportSearch): Observable<{current: any[], previous: any[]}> {
    // Create a copy of the search criteria for previous year
    const previousYearSearch = { ...reportSearch };
    
    // Adjust dates for previous year
    if (reportSearch.issueDateFrom && reportSearch.issueDateFrom !== "0001-01-01") {
      const fromDate = new Date(reportSearch.issueDateFrom);
      fromDate.setFullYear(fromDate.getFullYear() - 1);
      previousYearSearch.issueDateFrom = fromDate.toISOString().split('T')[0];
    }
    
    if (reportSearch.issueDateTo && reportSearch.issueDateTo !== "9999-99-99") {
      const toDate = new Date(reportSearch.issueDateTo);
      toDate.setFullYear(toDate.getFullYear() - 1);
      previousYearSearch.issueDateTo = toDate.toISOString().split('T')[0];
    }

    // Make parallel requests for current and previous year data
    return forkJoin({
      current: this.getDealerSummaryReport(reportSearch),
      previous: this.getDealerSummaryReport(previousYearSearch)
    });
  }
}
