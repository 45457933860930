<div class="printPage" id="printRsaForm" style="font-family: Arial, Helvetica, sans-serif">
    <div class="page" style="max-width: 100%; margin-top: 35px;">
        <div class="subpage" >
            <div class="container-fluid" >
                <div class="row" style="text-align: left;">
                    <div class="col-md-8"> 
                        <img src="../assets/AllianceLogo.png" height="60px" style="align-self: left;">
                    </div>
                    <div class="col-md-4"> 
                        <div class="container">
                            <div class="row">
                                <small class="text-right">{{currentDate}} </small>
                            </div>        
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid" >
                <div class="row" style="text-align: left;background: rgb(36,43,134)">
                    <div class="col"> 
                        <h4 class="text-center" style="color: bisque;">  Alliance Assure - Certificate of Assistance </h4>
                    </div>
                </div>
            </div>
            <div class="container-fluid" >
                <div class="row" style="text-align: center;">
                    <div class="col"> 
                        <strong class="text-center" >  CERTIFICATE NUMBER - {{certificate.certificateNumber}} </strong>
                    </div>
                </div>
            </div>
           <div class="container-fluid" style="margin-top: 6px;margin-bottom: 2px;" >
                <div class="row" style="text-align: center;">
                    <div class="col"> 
                        <table class="table table-condensed" style="text-align: left; border: 1px solid black;">
                            <tbody style="font-size: small;font-family: arial, helvetica, sans-serif; font-weight: 600;">
                                <tr>
                                <td >
                                    <address style="padding-bottom: 2px;">
                                        <strong>Corporate Address: Alliance Assure Private Limited</strong><br>
                                        #29, HSVP Shopping Complex, Sector 39,Gurugram (Haryana)-122003<br>
                                        PH: 1800 212 7559, CIN: U74999HR2021PTC092448, GSTIN:06AAUCA6872D1ZC</address></td>
                            <td >
                                <address>
                                    <strong>For Assistance, Please contact us at: </strong><br>
                                    Email ID: <a href="mailto::%20support@allianceassure.in"><strong>: support&#64;allianceassure.in</strong></a><br>
                                    <a *ngIf="!printRsaNumber">Contact No: <strong>1800 212 7559</strong></a>
                                </address>
                           </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong> Tax Invoice Number: {{certificate.certificateNumber}}</strong>
                                </td>
                                <td>
                                    <strong> Plan Type: {{certificate.planTotalAmount}}</strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong> Effective Period: {{certificate.issueDate | date:'dd-MM-yyyy hh:mm:ss a':'+0530'}} - {{certificate.endDate |date:'dd-MM-yyyy'}} MIDNIGHT</strong>
                                </td>
                                <td>
                                    <strong> IMD Code: {{certificate.dealerId}} </strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong> Name of Certificate Holder: {{certificate.firstName + " " + certificate.lastName}}</strong>
                                </td>
                                <td>
                                    <strong> Dealer Name: {{certificate.dealerId}}</strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong *ngIf="!(certificate.ageOrDob && certificate.ageOrDob == 'age')"> DOB: {{certificate.dob}}</strong>
                                    <strong *ngIf="(certificate.ageOrDob && certificate.ageOrDob == 'age')"> Age: {{certificate.age}}</strong>
                                </td>
                                <td>
                                    <strong> Gender: {{certificate.gender}} </strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong> Mobile: {{certificate.contactNumber}}</strong>
                                </td>
                                <td>
                                    <strong> EmailId: {{certificate.email}} </strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong> Address: {{certificate.address|slice:0:150}}</strong>
                                </td>
                                <td>
                                    <strong> City / District: {{certificate.city}} </strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong> State: {{certificate.state}}</strong>
                                </td>
                                <td>
                                    <strong> Vehicle Type: &nbsp; {{certificate.vehicleType? certificate.vehicleType: "Two Wheeler"}} </strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong> OEM: {{certificate.vehicleMake}} </strong>
                                </td>
                                <td>
                                    <strong> Model/Variant: {{certificate.model}} / {{certificate.variant}}</strong>
                                </td>
                           </tr>
                            <tr>
                                <td>
                                    <strong> Vehicle Registration Number: {{certificate.registrationNumber}}</strong>
                                </td>
                                <td>
                                    <strong> Manufacturing Year: {{certificate.vehicleYear}} </strong>
                                </td>
                           </tr>
                            <tr>
                                <td>
                                    <strong> Engine Number: {{certificate.engineNumber}}</strong>
                                </td>
                                <td>
                                    <strong> Chasis Number: {{certificate.chasisNumber}} </strong>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                   </div>
                </div>
            </div>
            <div class="container-fluid"  >
                <div class="row" style="text-align:center; background-color: rgb(36,43,134);">
                    <div class="col" style="text-align: left;"> 
                        <strong class="text-left" style="text-align:left; color: bisque;"> Plan Features</strong>
                    </div>
                    <div class="col" style="text-align: right; padding-right: 50px;"> 
                        <strong class="text-right" style="color: bisque; text-align:right; " *ngIf="printRsaNumber"> Toll Free Road Side Assistance Number: 1800 212 7559</strong>
                    </div>
                </div>
            </div>        
            <div class="container-fluid" style="margin-top: 5px;">
                <div class="row" style="text-align: left;">
                    <div class="col"> 
                        <table class="table table-condensed" style="text-align: left; border: 1px solid black;margin-bottom: 2px;">
                            <tbody style="font-size: smaller;font-family: arial, helvetica, sans-serif; font-weight: 600;">
                                <tr>
                                <td>
                        <strong>Breakdown support</strong>
                        </td></tr></tbody> </table>  
                    </div>
                </div>
            </div>
            <div class="container-fluid" style="line-height: 12px;">
                <div class="row" >
                    <div class="col"> 
                        <small class="text-center" style="font-size: x-small;font-family: arial, helvetica, sans-serif; font-weight: 600; " > In the event Covered Vehicle breaks down due to a minor mechanical / electrical fault / accident and immediate repair on the spot is deemed possible within the scope of services, assist Customer by arranging for a vehicle technician to reach the breakdown location and will bear labour cost and conveyance costs. Cost of Material & Spare Parts if required to repair the vehicle on the spot and any other incidental conveyance to obtain such material & spare parts will be borne by the Customer</small><br>
                    </div>
                </div>
            </div>
            <div class="container-fluid" style="margin-top: 5px;">
                <div class="row" style="text-align: left; margin-top: 10px black;">
                    <div class="col"> 
                        <table class="table table-condensed" style="text-align: left; border: 1px solid black;margin-bottom: 2px;">
                            <tbody style="font-size: smaller;font-family: arial, helvetica, sans-serif; font-weight: 600;">
                                <tr>
                                <td>
            
                        <strong>Alternate Battery or Jump Start</strong>
                        </td></tr></tbody> </table>  
                    </div>
                </div>
            </div>
            <div class="container-fluid" style="line-height: 12px;" >
                <div class="row" >
                    <div class="col"> 
                        <small class="text-center" style="font-size: x-small;font-family: arial, helvetica, sans-serif; font-weight: 600;" >In the event Covered Vehicle is immobilized, while on trip, due to rundown battery, assist the Customer by organizing for a Vehicle technician to jump start the Vehicle with appropriate means and will bear labour and conveyance costs. If the run down battery has to be replaced with a new battery, the cost of such battery replacement and any costs to obtain the battery will be borne by the Customer.</small><br>
                    </div>
                </div>
            </div>
            <div class="container-fluid" style="margin-top: 5px;">
                <div class="row" style="text-align: left; margin-top: 5px black;">
                    <div class="col"> 
                        <table class="table table-condensed" style="text-align: left; border: 1px solid black;margin-bottom: 2px;">
                            <tbody style="font-size: smaller;font-family: arial, helvetica, sans-serif; font-weight: 600;">
                                <tr>
                                <td>
            
                        <strong>Arrangement of fuel</strong>
                        </td></tr></tbody> </table>  
                    </div>
                </div>
            </div>
            <div class="container-fluid" style="line-height: 12px;">
                <div class="row" >
                    <div class="col"> 
                        <small class="text-center" style="font-size: x-small;font-family: arial, helvetica, sans-serif; font-weight: 600;" >In the event Covered Vehicle runs out of fuel and hence is immobilized while on a trip, assist Customer by organizing for a Vehicle technician to supply emergency fuel (up to 2 litres on a chargeable basis) at the location of breakdown and will bear labour and conveyance costs. The cost of the fuel will be borne by the customer.</small><br>
                    </div>
                </div>
            </div>
            <div class="container-fluid" style="margin-top: 5px;">
                <div class="row" style="text-align: left; margin-top: 5px black;">
                    <div class="col"> 
                        <table class="table table-condensed" style="text-align: left; border: 1px solid black;margin-bottom: 2px;">
                            <tbody style="font-size: smaller;font-family: arial, helvetica, sans-serif; font-weight: 600;">
                                <tr>
                                <td>
                        <strong>Tyre Change</strong>
                        </td></tr></tbody> </table>  
                    </div>
                </div>
            </div>
            <div class="container-fluid" style="line-height: 12px;">
                <div class="row" >
                    <div class="col"> 
                        <small class="text-center" style="font-size: x-small;font-family: arial, helvetica, sans-serif;  font-weight: 600;" >In the event Covered Vehicle is immobilized due to a flat tyre, assist the Customer by organizing for a vehicle technician to replace the flat tyre with the spare stepney tyre of the Vehicle at the location of breakdown and will bear labour cost and round-trip conveyance costs of the provider. Material/spare parts if required to repair the Vehicle (including repair of flat spare stepney tyre) will be borne by the Customer. In case the spare tyre is not available in the covered Vehicle, the flat tyre will be taken to the nearest flat tyre repair shop for repairs & re-attached to the Vehicle. All incidental charges for the same shall be borne by the Customer</small><br>
                    </div>
                </div>
            </div>
            <div class="container-fluid" style="margin-top: 5px;">
                <div class="row" style="text-align: left; margin-top: 5px black;">
                    <div class="col"> 
                        <table class="table table-condensed" style="text-align: left; border: 1px solid black;margin-bottom: 2px;">
                            <tbody style="font-size: smaller;font-family: arial, helvetica, sans-serif; font-weight: 600;">
                                <tr>
                                <td>
                        <strong>Arrangement of spare keys</strong>
                        </td></tr></tbody> </table>  
                    </div>
                </div>
            </div>
            <div class="container-fluid" style="line-height: 12px;">
                <div class="row" >
                    <div class="col"> 
                        <small class="text-center" style="font-size: x-small;font-family: arial, helvetica, sans-serif;  font-weight: 600;" >If the keys of the covered vehicle are locked inside the vehicle, broken, lost, or misplaced, (upon the request of the customer) will arrange for the forwarding of another set from his/her place of residence or office by courier / in person by hand-delivery to the location of the vehicle after receiving the requisite authorizations from the Customer with regards to the person designated to hand over the same. The Customer may be requested to submit an identity proof at the time of delivery of the keys</small><br>
                    </div>
                </div>
            </div>
            <div class="container-fluid" style="margin-top: 5px;">
                <div class="row" style="text-align: left; margin-top: 5px black;">
                    <div class="col"> 
                        <table class="table table-condensed" style="text-align: left; border: 1px solid black;margin-bottom: 2px;">
                            <tbody style="font-size: smaller;font-family: arial, helvetica, sans-serif; font-weight: 600;">
                                <tr>
                                <td>
                        <strong>Taxi Arrangements</strong>
                        </td></tr></tbody> </table>  
                    </div>
                </div>
            </div>
            <div class="container-fluid" style="line-height: 12px;">
                <div class="row" >
                    <div class="col"> 
                        <small class="text-center" style="font-size: x-small;font-family: arial, helvetica, sans-serif;  font-weight: 600;" >In the event where the Customer’s vehicle is immobilized due to a breakdown and is towed to the authorized service centre, will assist the Customer by arranging for alternate mode of transport (Taxi) to continue the journey to the destination or return to Customer’s home up to the Covered Distance from the location of the breakdown. Taxi cost will be borne by the customer.</small><br>
                    </div>
                </div>
            </div>
                    
            <div class="container-fluid" style="margin-top: 5px;">
                <div class="row" style="text-align: left;">
                    <div class="col" style="padding-top: 2px;padding-bottom: 2px;"> 
                        <table class="table table-condensed" style="text-align: left; border: 1px solid black;margin-bottom: 2px; padding:2px;">
                            <tbody style="font-size: smaller;font-family: arial, helvetica, sans-serif; font-weight: 600;">
                                <tr>
                                <td>
                        <strong>Message relay to relatives/colleagues/emergency numbers</strong>
                        </td></tr></tbody> </table>  
                    </div>
                </div>
            </div>
            <div class="container-fluid" style="line-height: 12px;">
                <div class="row" >
                    <div class="col"> 
                        <small class="text-center" style="font-size: x-small;font-family: arial, helvetica, sans-serif;  font-weight: 600;" >
                            To take charge of relaying urgent messages relating to the breakdown to the authorized workshop and/or service contacts. When requested, will relay urgent messages on behalf of the Customers to a designated person of their choice.
                        </small><br>
                    </div>
                </div>
            </div>
                    
            <div class="container-fluid" >
                <!-- <div class="container-fluid" style="margin-top: 10px;"> -->
                <div class="row" style="text-align: left; margin-top: 5px black;">
                    <div class="col"> 
                        <table class="table table-condensed" style="text-align: left; border: 1px solid black;margin-bottom: 2px;">
                            <tbody style="font-size: smaller;font-family: arial, helvetica, sans-serif; font-weight: 600;">
                                <tr>
                                <td>
                        <strong>Hotel Arrangements</strong>
                        </td></tr></tbody> </table>  
                    </div>
                </div>
            </div>
                <div class="container-fluid" style="line-height: 12px;">
                <div class="row" >
                    <div class="col"> 
                        <small class="text-center" style="font-size: x-small;font-family: arial, helvetica, sans-serif;  font-weight: 600;" >
                            In the event where the Customer’s vehicle is immobilized due to a breakdown and is towed to the authorized service centre, assist the Customer by arranging for a stay. Hotel cost will be borne by the customer.
                        </small><br>
                    </div>
                </div>
            </div>
                    
            <div class="container-fluid" >
                <!-- <div class="container-fluid" style="margin-top: 10px;"> -->
                <div class="row" style="text-align: left; margin-top: 5px black;">
                    <div class="col"> 
                        <table class="table table-condensed" style="text-align: left; border: 1px solid black;margin-bottom: 2px;">
                            <tbody style="font-size: smaller;font-family: arial, helvetica, sans-serif; font-weight: 600;">
                                <tr>
                                <td>
                                    <strong *ngIf="!certificate.policyType.includes('TowingNonAccident')">Towing of Vehicle on accident</strong>
                                    <strong *ngIf="certificate.policyType.includes('TowingNonAccident')">Towing of Vehicle</strong>
                                    </td></tr></tbody> </table>  
                    </div>
                </div>
            </div>
            <!-- <div class="container-fluid" > -->
                <div class="container-fluid" style="line-height: 12px;">
                <div class="row" >
                    <div class="col"> 
                        <small class="text-center" style="font-size: x-small;font-family: arial, helvetica, sans-serif;  font-weight: 600;" *ngIf="certificate.policyType.includes('TowingNonAccident')">
                            In the event Covered Vehicle suffers an accident or breakdown due to mechanical or electrical fault which cannot be repaired on the spot, Alliance Assure will assist in making arrangement for the Vehicle to be towed to the nearest Authorised Service Center, using tow trucks in the cities & corresponding covered area where available. Toll charges if any to be paid by the customer.(Free Towing To and From upto 40kms)
                        </small>
                        <small class="text-center" style="font-size: x-small;font-family: arial, helvetica, sans-serif;  font-weight: 600;" *ngIf="!certificate.policyType.includes('TowingNonAccident')">
                            In the event Covered Vehicle suffers an accident which cannot be repaired on the spot, Assist in making arrangement for the Vehicle to be towed to the nearest Authorised Service Center, using tow trucks in the cities & corresponding covered area where available. Only in accident cases.(Free Towing To and From upto 40kms)
                        </small>
                        <br>
                    </div>
                </div>
            </div>
            <div class="container-fluid" >
                    
            <!-- <div class="container-fluid" style="margin-top: 10px;"> -->
                <div class="row" style="text-align: left; margin-top: 5px black;">
                    <div class="col"> 
                        <table class="table table-condensed" style="text-align: left; border: 1px solid black;margin-bottom: 2px;">
                            <tbody style="font-size: smaller;font-family: arial, helvetica, sans-serif; font-weight: 600;">
                                <tr>
                                <td>
                        <strong>Ambulance Assistance Service</strong>
                        </td></tr></tbody> </table>  
                    </div>
                </div>
            </div>
            <!-- <div class="container-fluid" > -->
                <div class="container-fluid" style="line-height: 12px;">
                <div class="row" >
                    <div class="col"> 
                        <small class="text-center" style="font-size: x-small;font-family: arial, helvetica, sans-serif;  font-weight: 600;" >
                            PAN India Ambulance service availability with 24x7 dedicated helpline from the incident location till the nearest medical facility. Ambulance cost will be borne by the customer.
                        </small><br>
                    </div>
                </div>
            </div>
            <div class="container-fluid" style="margin-top: 10px;">
                <div class="row" >
                    <div class="col" style="font-size: x-small;font-family: arial, helvetica, sans-serif;  font-weight: 600;"> 
                        <small class="text-center" style="font-size: x-small;font-family: arial, helvetica, sans-serif;  font-weight: 600;">Roadside assistance services will get activated after 7 days from issuing date. Special Conditions (applicable to all coverage):Vehicle to be used for personal purpose not for commercial.<small class="text-center" style="font-size: x-small;font-family: arial, helvetica, sans-serif;  font-weight: 600;" *ngIf="printCpaPolicy">Complimentary Personal Accident Insurance-Yes.</small> T&C applicable -  This Certificate is valid subject to realization of the payment and is effective from the Payment realization date or certificate issue date, whichever is later.For EV RSA services are limited as per T&C </small>
                        <small class="text-center" style="font-size: x-small;font-family: arial, helvetica, sans-serif;  font-weight: 600;" > Limitation as to use: The policy covers use of the vehicle for any purpose other than: a) Hire or Reward b) Carriage of goods(other than samples or personal luggage) c) Organized Racing d) Pace Making e) Speed testing f) Reliability trials g) Any purpose in connection with motor trade.</small>
                        <small class="text-center" style="font-size: x-small;font-family: arial, helvetica, sans-serif;  font-weight: 600;" *ngIf="certificate.rsaNumberOfServices && certificate.rsaNumberOfServices > 0">
                            Total number of {{certificate.rsaNumberOfServices}} services covered under this period of 12 months.
                        </small>
                    </div>
                </div>
            </div>
            <div class="container-fluid" >
                <div class="row" >
                    <div class="col-md-12" style="align-self: center;"> 
                        <table class="table table-condensed">
                            <tbody style="font-size: small; text-align: center;" *ngIf="showGstAmount">
                                <tr>
                                    <td>
                                        <strong>Plan Amount (Rs)</strong>
                                    </td>
                                    <td>
                                        <strong>CGST(9%)</strong>
                                    </td>
                                    <td>
                                        <strong>SGST(9%)</strong>
                                    </td>
                                    <td>
                                        <strong>IGST(18%)</strong>
                                    </td>
                                    <td>
                                        <strong>Total Amount (Rs)</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>{{certificate.planAmount}}</strong>
                                    </td>
                                    <td>
                                        <strong>{{certificate.CgstAmount}}</strong>
                                    </td>
                                    <td>
                                        <strong>{{certificate.SgstAmount}}</strong>
                                    </td>
                                    <td>
                                        <strong>{{certificate.IgstAmount}}</strong>
                                    </td>
                                    <td>
                                        <strong>{{certificate.planTotalAmount}}</strong>
                                    </td>
                                </tr>
                            </tbody>
                        <tbody *ngIf="!showGstAmount">
                            <tr>
                                <td>
                                    <strong>Product Plan</strong>
                                </td>
                                <td>
                                    <strong>PLAN &nbsp; {{certificate.planTotalAmount}}</strong>
                                </td>
                            </tr>
                        </tbody>

                        </table>
                    </div>
                </div>
            </div>
            <div class="container-fluid" >
                <div class="row" style="text-align:right; font-size: small;">
                    <div class="col"> 
                        <strong class="text-right"> For Alliance Assure Private Limited </strong><br>
                        <img src="../assets/signature.png" height="80px" width="200px" style="align-self: right; margin-right: 20px;"><br>
                        <strong class="text-right" style="padding-right: 60px;"> Authorised Signatory </strong>
                    </div>
                </div>
            </div>
            <div class="container-fluid" >
                <div class="row" style="text-align:center; background-color: rgb(36,43,134);">
                    <div class="col"> 
                        <strong class="text-center" style="color: bisque;"> Doctor On Call/Chat* </strong>
                    </div>
                </div>
            </div>
            <div class="container-fluid" >
                <!-- <div class="container-fluid" style="line-height: 10px;margin-top: 5px;"> -->
                <div class="row" >
                    <div class="col" style="font-size: x-small;font-family: arial, helvetica, sans-serif;  font-weight: 600;"> 
                        <small class="text-center" style="font-size: x-small;font-family: arial, helvetica, sans-serif;  font-weight: 600;">
                            •	Free Doctor Chat - Opt for a voice/video call. Get Online Prescriptions from them.
                        </small>
                        <small class="text-center" style="font-size: x-small;font-family: arial, helvetica, sans-serif;  font-weight: 600;">
                            •	Doctor over a Phone Call - You can speak to our specialist over a regular voice call too.
                        </small>
                        <small class="text-center" style="font-size: x-small;font-family: arial, helvetica, sans-serif;  font-weight: 600;" >• Artificial Intelligence Healthcare Assistant - Connect with our easy-to-use Artificial Intelligence-powered healthcare assistant who is always available at your fingertips.
                        </small>
                        <small class="text-center" style="font-size: x-small;font-family: arial, helvetica, sans-serif;  font-weight: 600;">
                            •	Privacy - Share details about your health problem by privately sharing reports, photos and more in our encrypted private chat. Your data is completely safe.
                        </small>
                        <small class="text-center" style="font-size: x-small;font-family: arial, helvetica, sans-serif;  font-weight: 600;">
                            With Visit, you can have a chat with a Visit specialist doctor from over specialties. 
                        </small><br>
                        <small class="text-center" style="font-size: x-small;font-family: arial, helvetica, sans-serif;  font-weight: 600;">
                            *T&C applicable - Only one service under this certificate applicable. Services in partnership with doc providers/own.
                            All Doctor on Call/Chat Services may not be available in your area. Doc chat/call will get activated after 2 days of registration at 1800 212 7559
                        </small><br>
        </div> 
        </div>
            </div>
        

                            
        </div>
    </div>
</div>
    <!--ADD_PAGE-->


