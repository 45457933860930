<div class="printPage" id="printPage" style="font-family: Arial, Helvetica, sans-serif;">
    <div class="page">
        <div class="subpage">
            <div class="container-fluid">
                <div class="row" style="text-align: left;margin-top: 35px;">
                    <div class="col-md-8">
                        <img src="../assets/AllianceLogo.png" height="100px" style="align-self: left;">
                    </div>
                    <div class="col-md-4">
                        <div class="container-fluid">
                            <div class="row">
                                <small class="text-right">{{currentDate}} </small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid">
                <div class="row" style="text-align: left;background: rgb(36,43,134);margin-left:3px;margin-right:3px;">
                    <div class="col">
                        <h4 class="text-center" style="color: bisque;"> Alliance Assure - Certificate of Assistance
                        </h4>
                    </div>
                </div>
            </div>
            <div class="container-fluid">
                <div class="row" style="text-align: center;">
                    <div class="col">
                        <strong class="text-center"> CERTIFICATE NUMBER - {{certificate.certificateNumber}} </strong>
                    </div>
                </div>
            </div>
            <div class="container-fluid" style="margin-top: 10px;">
                <div class="row" style="text-align: center;">
                    <div class="col">
                        <table class="table table-condensed" style="text-align: left; border: 1px solid black;">
                            <tbody style="font-size:medium;">
                                <tr>
                                    <td>
                                        <address>
                                            <strong>Corporate Address: Alliance Assure Private Limited</strong><br>
                                            #29, HSVP Shopping Complex, Sector 39,Gurugram (Haryana)-122003<br>
                                            PH: 1800 212 7559, CIN: U74999HR2021PTC092448, GSTIN:06AAUCA6872D1ZC
                                        </address>
                                    </td>
                                    <td>
                                        <address>
                                            <strong>For Assistance, Please contact us at: </strong><br>
                                            <!-- Toll Free Number:<br> -->
                                            Email ID: <a href="mailto::%20support@allianceassure.in"><strong>:
                                                    support&#64;allianceassure.in</strong></a>
                                        </address>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="container-fluid" style="margin-top: 10px;">
                <div class="row" style="text-align: center;">
                    <div class="col-md-6">
                        <div class="container-fluid-fluid" style="background-color: rgb(36,43,134)">
                            <strong class="text-center" style="color: bisque;"> Certificate Details</strong>
                        </div>
                        <table class="table table-condensed " style="text-align: left;">
                            <tbody style="font-size:medium">
                                <tr>
                                    <td>
                                        <strong style="font-size:medium" *ngIf="showGstAmount">Tax Invoice Number</strong>
                                        <strong style="font-size:medium" *ngIf="!showGstAmount">Certificate Number</strong>
                                    </td>
                                    <td>
                                        <strong>{{certificate.certificateNumber}}</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong *ngIf="showGstAmount">Plan Name</strong>
                                        <strong *ngIf="!showGstAmount">Product Plan</strong>
                                    </td>
                                    <td>
                                        <strong *ngIf="showGstAmount">{{certificate.planId=="Flexible" ? certificate.planTotalAmount:
                                            certificate.planId}}</strong>
                                            <strong *ngIf="!showGstAmount">{{certificate.planTotalAmount}}</strong>
                                        </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Certificate Start Date</strong>
                                    </td>
                                    <td>
                                        <strong>{{certificate.startDate| date:'dd-MM-yyyy hh:mm:ss a':'+0530'}}</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Certificate End Date</strong>
                                    </td>
                                    <td>
                                        <strong>{{certificate.endDate| date}} - MIDNIGHT</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Certificate Issue Date</strong>
                                    </td>
                                    <td>
                                        <strong>{{certificate.issueDate| date}}</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>SAC Code</strong>
                                    </td>
                                    <td>
                                        <strong>{{certificate.dealerId}}</strong>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-md-6" style="align-self: right;">
                        <div class="container-fluid-fluid" style="background-color: rgb(36,43,134);">
                            <strong class="text-center" style="color: bisque;"> Vehicle Details</strong>
                        </div>
                        <table class="table table-condensed " style="text-align: left;">
                            <tbody style="font-size:medium">
                                <tr>
                                    <td>
                                        <strong>Vehicle Registration Number</strong>
                                    </td>
                                    <td>
                                        <strong>{{certificate.registrationNumber}}</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>OEM</strong>
                                    </td>
                                    <td>
                                        <strong>{{certificate.vehicleMake}}</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Model</strong>
                                    </td>
                                    <td>
                                        <strong>{{certificate.model}}</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Variant</strong>
                                    </td>
                                    <td>
                                        <strong>{{certificate.variant}}</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Engine Number</strong>
                                    </td>
                                    <td>
                                        <strong>{{certificate.engineNumber}}</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Chassis Number</strong>
                                    </td>
                                    <td>
                                        <strong>{{certificate.chasisNumber}}</strong>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="container-fluid">
                <div class="row" style="text-align:center; background-color: rgb(36,43,134);margin-left:3px;margin-right:3px;">
                    <div class="col">
                        <strong class="text-center" style="color: bisque;"> Personal Details </strong>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col">
                        <table class="table table-condensed " width=100% 
                            style="text-align: left;  ">
                            <tbody style="font-size:medium;">
                                <tr>
                                    <td>
                                        <strong> Customer Name</strong>
                                    </td>
                                    <td>
                                        <strong> {{certificate.firstName + " " + certificate.lastName}}</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong> Mobile</strong>
                                    </td>
                                    <td>
                                        <strong> {{certificate.contactNumber}}</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong> Address</strong>
                                    </td>
                                    <td>
                                        <strong> {{certificate.address|slice:0:150}}</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong> City-State </strong>
                                    </td>
                                    <td>
                                        <strong> {{certificate.city + " - " + certificate.state}}</strong>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="container-fluid">
                <div class="row" style="text-align:center; background-color: rgb(36,43,134);margin-left:3px;margin-right:3px;">
                    <div class="col">
                        <strong class="text-center" style="color: bisque;" *ngIf="certificate.dealerId != 'DLR-10000331'"> Plan Features </strong>
                        <strong class="text-center" style="color: bisque;" *ngIf="certificate.dealerId == 'DLR-10000331'"> Roadside Assistance Plan Features </strong>
                            </div>
                </div>
            </div>
            <div class="container-fluid">
                <div class="row">
                    <div class="col">
                        <table class="table table-condensed " style="text-align: left; border-collapse: collapse;">
                            <tbody style="font-size:medium">
                                <tr>
                                    <td>
                                        <strong>S.No.</strong>
                                    </td>
                                    <td>
                                        <strong>Featured Benefits</strong>
                                    </td>
                                    <td>
                                        <strong>Description</strong>
                                    </td>
                                    <td>
                                        <strong>{{certificate.vehicleType? certificate.vehicleType: "Two Wheeler"}}</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>1</p>
                                    </td>
                                    <td>
                                        <p>Relay of urgent messages</p>
                                    </td>
                                    <td>
                                        <p>Pass on message to Rider's friends/family</p>
                                    </td>
                                    <td>
                                        <p>Yes</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>2</p>
                                    </td>
                                    <td>
                                        <p>Doctor Referral</p>
                                    </td>
                                    <td>
                                        <p>Giving the contact details of nearest doctor to Rider</p>
                                    </td>
                                    <td>
                                        <p>Yes</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>3</p>
                                    </td>
                                    <td>
                                        <p>Hotel Assistance</p>
                                    </td>
                                    <td>
                                        <p>Arrange for Hotel on Rider's / driver&rsquo;s request</p>
                                    </td>
                                    <td>
                                        <p>Yes</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>4</p>
                                    </td>
                                    <td>
                                        <p>Medical Assistance</p>
                                    </td>
                                    <td>
                                        <p>Arranging for an ambulance/ hospital for Rider</p>
                                    </td>
                                    <td>
                                        <p>Yes</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>5</p>
                                    </td>
                                    <td>
                                        <p>Programme Start Date</p>
                                    </td>
                                    <td>
                                        <p>The date of commencement of coverage under the program.</p>
                                    </td>
                                    <td>
                                        <p>As mentioned</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>6</p>
                                    </td>
                                    <td>
                                        <p>Number of Services</p>
                                    </td>
                                    <td>
                                        <p>Proposed Number of Services</p>
                                    </td>
                                    <td>
                                        <p>2</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="container-fluid">
                <div class="row">
                    <div class="col">
                        <small class="text-center" style="font-size:small"> Special Conditions (applicable to all
                            coverage): <small class="text-center" style="font-size:small" *ngIf="printCpaPolicy">Complimentary Personal Accident Insurance-Yes.</small> T&C applicable - This Certificate is valid subject to realization of the payment
                            and is effective from the Payment realization date or certificate issue date, whichever is
                            later. </small>
                        <small class="text-center" style="font-size:small"> Only assistance services, actual cost to be
                            borne by the customer. Vehicle to be used for personal purpose not for commercial.</small>
                            <small class="text-center" style="font-size:small" > Limitation as to use: The policy covers use of the vehicle for any purpose other than: a) Hire or Reward b) Carriage of goods(other than samples or personal luggage) c) Organized Racing d) Pace Making e) Speed testing f) Reliability trials g) Any purpose in connection with motor trade.The relay of urgent messages and arrangement of ambulance services are exclusively (only) covered under the RSA program</small>
                        </div>
                </div>
            </div>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12" style="align-self: center;">
                        <table class="table table-condensed">
                            <tbody style="font-size: small; text-align: center;" *ngIf="showGstAmount">
                                <tr>
                                    <td>
                                        <strong>Plan Amount (Rs)</strong>
                                    </td>
                                    <td>
                                        <strong>CGST(9%)</strong>
                                    </td>
                                    <td>
                                        <strong>SGST(9%)</strong>
                                    </td>
                                    <td>
                                        <strong>IGST(18%)</strong>
                                    </td>
                                    <td>
                                        <strong>Total Amount (Rs)</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>{{certificate.planAmount}}</strong>
                                    </td>
                                    <td>
                                        <strong>{{certificate.CgstAmount}}</strong>
                                    </td>
                                    <td>
                                        <strong>{{certificate.SgstAmount}}</strong>
                                    </td>
                                    <td>
                                        <strong>{{certificate.IgstAmount}}</strong>
                                    </td>
                                    <td>
                                        <strong>{{certificate.planTotalAmount}}</strong>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="!showGstAmount">
                                <tr>
                                    <td>
                                        <strong>Product Plan</strong>
                                    </td>
                                    <td>
                                        <strong>PLAN &nbsp; {{certificate.planTotalAmount }}</strong>
                                    </td>
                                    </tr>
                            </tbody>
    
                        </table>
                    </div>
                </div>
            </div>
            <div class="container-fluid">
                <div class="row" style="text-align:right; font-size: small;">
                    <div class="col">
                        <strong class="text-right"> For Alliance Assure Private Limited </strong><br>
                        <img src="../assets/signature.png" height="80px" width="200px"
                            style="align-self: right; margin-right: 20px;"><br>
                        <strong class="text-right" style="padding-right: 60px;"> Authorised Signatory </strong>
                    </div>
                </div>
            </div>
            <div class="container-fluid">
                <div class="row" style="text-align:center; background-color: rgb(36,43,134);margin-left:3px;margin-right:3px;">
                    <div class="col">
                        <strong class="text-center" style="color: bisque;"> Doctor On Call/Chat* </strong>
                    </div>
                </div>
            </div>
            <div class="container-fluid">
                <!-- <div class="container-fluid" style="line-height: 10px;margin-top: 5px;"> -->
                <div class="row">
                    <div class="col"
                        style="font-size: x-small;font-family: arial, helvetica, sans-serif;  font-weight: 600;">
                        <small class="text-center"
                            style="font-size: x-small;font-family: arial, helvetica, sans-serif;  font-weight: 600;">
                            • Free Doctor Chat - Opt for a voice/video call. Get Online Prescriptions from them.
                        </small>
                        <small class="text-center"
                            style="font-size: x-small;font-family: arial, helvetica, sans-serif;  font-weight: 600;">
                            • Doctor over a Phone Call - You can speak to our specialist over a regular voice call too.
                        </small>
                        <small class="text-center"
                            style="font-size: x-small;font-family: arial, helvetica, sans-serif;  font-weight: 600;">•
                            Artificial Intelligence Healthcare Assistant - Connect with our easy-to-use Artificial
                            Intelligence-powered healthcare assistant who is always available at your fingertips.
                        </small>
                        <small class="text-center"
                            style="font-size: x-small;font-family: arial, helvetica, sans-serif;  font-weight: 600;">
                            • Privacy - Share details about your health problem by privately sharing reports, photos and
                            more in our encrypted private chat. Your data is completely safe.
                        </small>
                        <small class="text-center"
                            style="font-size: x-small;font-family: arial, helvetica, sans-serif;  font-weight: 600;">
                            With Visit, you can have a chat with a Visit specialist doctor from over specialties.
                        </small><br>
                        <small class="text-center"
                            style="font-size: x-small;font-family: arial, helvetica, sans-serif;  font-weight: 600;">
                            *T&C applicable - Only one service under this certificate applicable. Services in
                            partnership with doc providers/own.
                            All Doctor on Call/Chat Services may not be available in your area. Doc chat/call will get
                            activated after 2 days of registration at 1800 212 7559
                        </small><br>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--ADD_PAGE-->

    <!-- <div class="container" style="margin-top: 990px;">
        <div class="page" >
            <div class="subpage">
                    <div class="row">
            <app-print-new-india-policy  *ngIf='certificate' [certificate]="certificate"></app-print-new-india-policy>
        </div>
    </div> 
    </div>
    </div> -->
</div>