import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ReportLayoutComponent} from './report-layout/report-layout.component'
import {CertificateReportComponent} from './certificate-report/certificate-report.component'
import {PrintCertificateComponent} from './print-certificate/print-certificate.component'
import { DealerReportComponent } from './dealer-report/dealer-report.component';
import { DealerAggregatedReportComponent } from './dealer-aggregated-report/dealer-aggregated-report.component';
import { RsaCertificateReportComponent } from './rsa-certificate-report/rsa-certificate-report.component';
import { DealerBalanceReportComponent } from './dealer-balance-report/dealer-balance-report.component';
import { PaymentPayoutReportComponent } from './payment-payout-report/payment-payout-report.component';
import { InvoiceReportComponent } from './invoice-report/invoice-report.component';
import { PayoutReportComponent } from './payout-report/payout-report.component';
import { EndorsementReportComponent } from './endorsement-report/endorsement-report.component';
import { ManagerAggregateReportComponent } from './manager-aggregate-report/manager-aggregate-report.component';
import { PackageReportComponent } from './package-report/package-report.component';
import { TotalBalanceReportComponent } from './total-balance-report/total-balance-report.component';
import { DealerInactivityReportComponent } from './dealer-inactivity-report/dealer-inactivity-report.component';
import { DealerSummaryReportComponent } from './dealer-summary-report/dealer-summary-report.component';

const routes: Routes = [
  {
      path: '', component: ReportLayoutComponent,
      children: [
          { path: 'certificateReport', component: CertificateReportComponent },
          { path: 'certificateReport/:id', component: CertificateReportComponent },
          { path: 'printCertificate',component: PrintCertificateComponent},
          { path: 'renewCertificate',component: PrintCertificateComponent},
          { path: 'dealerReport',component: DealerReportComponent},
          { path: 'rsaCertificate',component: RsaCertificateReportComponent},
          { path: 'dealerAggregateReport',component: DealerAggregatedReportComponent},
          { path: 'managerAggregateReport',component: ManagerAggregateReportComponent},
          { path: 'dealerBalanceReport',component: DealerBalanceReportComponent},
          { path: 'transactionReport', component: PaymentPayoutReportComponent}
          ,{ path: 'invoiceReport', component: InvoiceReportComponent}
          ,{ path: 'payoutReport', component: PayoutReportComponent}
          ,{ path: 'endorsementReport', component: EndorsementReportComponent}
          ,{ path: 'policyBalanceReport', component: PackageReportComponent}
          ,{ path: 'totalBalanceReport', component: TotalBalanceReportComponent}
          ,{ path: 'requestCertificate', component: PrintCertificateComponent}
          ,{ path: 'dealerInactivityReport', component: DealerInactivityReportComponent}
          ,{ path: 'dealerSummaryReport', component: DealerSummaryReportComponent}
      ]
  }
];



@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportsRoutingModule { }
