import { Component, OnInit } from '@angular/core';
import { Certificate } from '@app/_models/certificate';
import { AccountService } from '@app/_services';
import { CertificateService } from '@app/_services/certificate.service';

@Component({
  selector: 'app-iffco-tokyo-cpa-policy',
  templateUrl: './iffco-tokyo-cpa-policy.component.html',
  styleUrls: ['./iffco-tokyo-cpa-policy.component.less']
})
export class IffcoTokyoCpaPolicyComponent implements OnInit {
  cannotSeeIffcoTokioLogo = false;
  certificate = new Certificate();
  constructor(private certificateService: CertificateService, private accountService:AccountService) { }

  ngOnInit(): void {
    this.certificate = new Certificate(this.certificateService.PrintCertificate);
    this.cannotSeeIffcoTokioLogo =  (this.accountService.userValue.specialRoleAccess && this.accountService.userValue.specialRoleAccess.includes('cannotSeeIffcoTokioLogo'));
  }

}
