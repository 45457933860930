import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { User } from '@app/_models';

@Component({
  selector: 'app-dealer-dashboard',
  templateUrl: './dealer-dashboard.component.html',
  styleUrls: ['./dealer-dashboard.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterModule]
})
export class DealerDashboardComponent implements OnInit {
  @Input() user: User;
  
  accountStats = [
    { title: 'Current Balance', value: 0, icon: 'fa-wallet', color: '#4CAF50', prefix: '₹', loading: true },
    { title: 'Certificates Issued', value: 0, icon: 'fa-certificate', color: '#2196F3', loading: true }
  ];
  
  packageCounts = [
    { name: 'Package A', total: 100, used: 65, color: '#4CAF50' },
    { name: 'Package B', total: 75, used: 30, color: '#FFC107' },
    { name: 'Package C', total: 50, used: 48, color: '#F44336' }
  ];
  
  recentCertificates = [];
  
  constructor() { }

  ngOnInit(): void {
    // Simulate loading data
    setTimeout(() => {
      this.accountStats[0].value = 25000;
      this.accountStats[0].loading = false;
      
      setTimeout(() => {
        this.accountStats[1].value = 237;
        this.accountStats[1].loading = false;
      }, 300);
    }, 300);
    
    // Simulate recent certificates data
    this.recentCertificates = [
      { id: 'CRT78945', vehicle: 'Honda City', owner: 'Rahul Sharma', date: '2023-09-25', status: 'active' },
      { id: 'CRT78912', vehicle: 'Toyota Innova', owner: 'Anjali Patel', date: '2023-09-24', status: 'active' },
      { id: 'CRT78899', vehicle: 'Maruti Swift', owner: 'Vikram Singh', date: '2023-09-22', status: 'active' },
      { id: 'CRT78854', vehicle: 'Hyundai i20', owner: 'Priya Gupta', date: '2023-09-20', status: 'active' }
    ];
  }
  
  getProgressWidth(used: number, total: number): number {
    return (used / total) * 100;
  }
  
  getRemainingCount(used: number, total: number): number {
    return total - used;
  }
}
