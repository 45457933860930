import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';
import { Payments } from '@app/_models/payment';
import { AccountService, AlertService } from '@app/_services';
import { DealerService } from '@app/_services/dealer-service';
import { User } from '@app/_models';
import { first } from 'rxjs';
import { ConfigDataService } from '@app/_services/config-data.service';

@Component({
  selector: 'app-payment-add',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatProgressSpinnerModule
  ],
  templateUrl: './payment-add.component.html',
  styleUrls: ['./payment-add.component.less']
})
export class PaymentAddComponent implements OnInit {
  paymentForm!: FormGroup;
  payment!: Payments;
  dealers: string[] =[];
  user!: User;
  currentDate = new Date();
  loading = false;

  constructor(
    private fb: FormBuilder,
    private accountService: AccountService,
    private dealerService: DealerService,
    private alertService: AlertService,
    private configDataService: ConfigDataService
  ) {}

  ngOnInit(): void {
    this.user = this.accountService.userValue;
    this.paymentForm = this.fb.group({
      dealerId: [null, Validators.required],
      paymentAmount: [null, [Validators.required, Validators.min(0)]],
      comments: ['']
    });

    this.configDataService.getConfig('PaymentAddDealersByEmployee')
      .pipe(first())
      .subscribe({
        next: (dealers:string[]) => {
          this.dealers = [];
          dealers.forEach((dealer: string) => this.dealers.push(dealer));

        },
        error: error => {
          this.alertService.error(error);
        }
      });
    
  }

  onSubmit() {
    this.alertService.clear();
    if (this.paymentForm.invalid) {
      this.alertService.error('Please fill in all required fields.');
      return;
    }

    this.loading = true;
    this.payment = new Payments(this.paymentForm.value);
    this.payment.paymentDate = `${this.currentDate.getFullYear()}-${('0' + (this.currentDate.getMonth() + 1)).slice(-2)}-${('0' + this.currentDate.getDate()).slice(-2)}`;
    this.payment.userId = this.user.id;
    this.payment.userName = this.user.username;

    this.dealerService.addDealerPayment(this.payment)
      .pipe(first())
      .subscribe({
        next: () => {
          this.alertService.success("Payment added successfully.");
          this.paymentForm.reset();
          this.loading = false;
        },
        error: error => {
          this.alertService.error(error);
          this.loading = false;
        }
      });
  }
}