import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { IssueCertLayoutComponent } from './issue-cert-layout/issue-cert-layout.component';
import { AddCertificateComponent } from './add-certificate/add-certificate.component';
import { EditCertificateComponent } from './edit-certificate/edit-certificate.component';
import { BulkIndividualComponent } from './bulk-individual/bulk-individual.component';
import { RsaServiceProvidedComponent } from './rsa-service-provided/rsa-service-provided.component';

const routes: Routes = [
    {
        path: '', component: IssueCertLayoutComponent,
        children: [
            { path: '', component: AddCertificateComponent },
            { path: 'renewCertificate', component: AddCertificateComponent },
            { path: 'addCertificate', component: AddCertificateComponent },
            { path: 'editCertificate', component: EditCertificateComponent },
            { path: 'endorseRequest', component: EditCertificateComponent },
            { path: 'bulkIndividual', component: BulkIndividualComponent },
            { path: 'rsa', component: RsaServiceProvidedComponent },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class issueCertRouting { }