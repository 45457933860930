import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnalyticRoutingModule } from './analytic-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { AnalyticLayoutComponent } from './analytic-layout/analytic-layout.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HighchartsChartModule } from 'highcharts-angular';
import { DealerPayoutAnalyticsComponent } from './dealer-payout-analytics/dealer-payout-analytics.component';

@NgModule({
  // Remove DealerPayoutAnalyticsComponent from declarations as it's now standalone
  declarations: [ AnalyticLayoutComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AnalyticRoutingModule,
    NgbModule,
    HighchartsChartModule,
    // Import the standalone component
    DealerPayoutAnalyticsComponent
  ]
})
export class AnayticModule { }
