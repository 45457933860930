<div class="container">
<h1 >View Dealership Data</h1>
<div class="razorpay-embed-btn" data-url="https://pages.razorpay.com/pl_IANKvpuxtbYNEi/view" data-text="Pay Now" data-color="#528FF0" data-size="medium">
    <script>
      (function(){
        var d=document; var x=!d.getElementById('razorpay-embed-btn-js')
        if(x){ var s=d.createElement('script'); s.defer=!0;s.id='razorpay-embed-btn-js';
        s.src='https://cdn.razorpay.com/static/embed_btn/bundle.js';d.body.appendChild(s);} else{var rzp=window['__rzp__'];
        rzp && rzp.init && rzp.init()}})();
    </script>
  </div>
      
<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="form-row">
        <div class="form-group col">
            <label for="dealerId">Dealer Id</label>
            <input type="text" formControlName="dealerId" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dealerId.errors }" />
        </div>
        <div class="form-group col">
            <label for="dealerName">Dealership Name</label>
            <input type="text" formControlName="dealerName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dealerName.errors }" />
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col">
            <label for="dealerAddress">Address</label>
            <input type="text" formControlName="dealerAddress" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dealerAddress.errors }" />
        </div>
            <div class="form-group col">
                <label for="dealerState">State</label>
                <input type="text" formControlName="dealerState" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dealerState.errors }" />
            </div>
            <div class="form-group col">
                <label for="dealerCity">City</label>
                <input type="text" formControlName="dealerCity" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dealerCity.errors }" />
            </div>    
    </div>

    <div class="form-row">
        <div class="form-group col">
            <label for="dealerContactNumber">Contact Number</label>
            <input type="number" formControlName="dealerContactNumber" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dealerContactNumber.errors }" />
            <div *ngIf="submitted && f.dealerContactNumber.errors" class="invalid-feedback">
                <div *ngIf="f.dealerContactNumber.errors.required">dealerContactNumber is required</div>
            </div>
        </div>
        <div class="form-group col">
            <label for="dealerEmail">Email</label>
            <input type="text" formControlName="dealerEmail" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dealerEmail.errors }" />
            <div *ngIf="submitted && f.dealerEmail.errors" class="invalid-feedback">
                <div *ngIf="f.dealerEmail.errors.required">Email is required</div>
                <div *ngIf="f.dealerEmail.errors.email">Email id is invalid</div>
            </div>
        </div>

    </div>
    <!-- <div class="form-row">
        <div class="form-group col">
            <label for="dealerMake">Make</label>
            <select multiple="multiple" class="form-control" formControlName="dealerMake" [ngClass]="{ 'is-invalid': submitted && f.dealerMake.errors }" [compareWith]="makeCompare">
                <option *ngFor="let dealerMake of makes" [ngValue]="dealerMake">
                  {{ dealerMake.make }}
                </option>
              </select>
              <div *ngIf="submitted && f.dealerMake.errors" class="invalid-feedback">
                <div *ngIf="f.dealerMake.errors.required">Make needs to be selected</div>
            </div>
        </div>
        <div class="form-group col">
            <label for="dealerPlanType">Plan</label>
            <select  class="form-control" formControlName="dealerPlanType" [ngClass]="{ 'is-invalid': submitted && f.dealerPlanType.errors }" >
                <option *ngFor="let planType of planTypes" [ngValue]="planType.id">
                 <div> {{ planType.text }} </div>
                </option>
              </select>
              <div *ngIf="submitted && f.dealerPlanType.errors" class="invalid-feedback">
                <div *ngIf="f.dealerPlanType.errors.required">Plan type needs to be selected</div>
            </div>
        </div>
    </div> -->
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb"  >
          <li class="breadcrumb-item active" aria-current="page" >
            <i class="fa fa-money" style="margin-right: 10px; "></i>
            <p class="h5"> Financial Details</p></li>
        </ol>
      </nav>
    <div class="form-row">
        <div class="form-group col">
            <label for="dealerBalance">Dealer Balance</label>
            <input type="number" formControlName="dealerBalance" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dealerBalance.errors }" />
        </div>   
        <div class="form-group col">
        </div>   
        <div class="form-group col">
        </div>   
        <!-- <div class="form-group col">
            <label for="dealerTotalPayment">Total Business Done- Amount</label>
            <input type="number" formControlName="dealerTotalPayment" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dealerTotalPayment.errors }" disabled/>
        </div>   
        <div class="form-group col" *ngIf= "showReceivableTab">
            <label for="dealerTotalGstPayment">Total Business Done- Taxes(GST)</label>
            <input type="number" formControlName="dealerTotalGstPayment" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dealerTotalGstPayment.errors }" disabled/>
        </div>    -->
   
    </div>
    <!-- <div class="form-row">
        <div class="form-group col">
            <label for="dealerTotalRefund">Total Refund Received- Amount</label>
            <input type="number" formControlName="dealerTotalRefund" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dealerTotalRefund.errors }" disabled/>
        </div>   
        <div class="form-group col" *ngIf= "showReceivableTab">
            <label for="dealerTotalGstRefund">Total Refund Received- Taxes(GST)</label>
            <input type="number" formControlName="dealerTotalGstRefund" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dealerTotalGstRefund.errors }" disabled/>
        </div>   
        <div class="form-group col">
            <label for="dealerPendingRefund">Total Pending Receivables- Amount</label>
            <input type="number" formControlName="dealerPendingRefund" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dealerPendingRefund.errors }" disabled/>
        </div>   
        <div class="form-group col" *ngIf= "showReceivableTab">
            <label for="dealerGstPendingRefund">Total Pending Receivables- Taxes(GST)</label>
            <input type="number" formControlName="dealerGstPendingRefund" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dealerGstPendingRefund.errors }" disabled/>
        </div>   
    </div> -->
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb" >
          <li class="breadcrumb-item active" aria-current="page" >
            <i class="fa fa-money" style="margin-right: 10px; "></i>
            <p class="h5"> Receivable Account Details for Payout</p></li>
        </ol>
      </nav>
      <div class="form-row">
        <div class="form-group col">
            <label for="dealerBankAccountOwner">Account Owner Name</label>
            <input type="text" formControlName="dealerBankAccountOwner" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dealerBankAccountOwner.errors }" />
        </div>   
        <div class="form-group col">
            <label for="dealerBankAccountNumber">Account Number</label>
            <input type="text" formControlName="dealerBankAccountNumber" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dealerBankAccountNumber.errors }" />
        </div>   
        <div class="form-group col">
            <label for="dealerBankIfscCode">IFSC code</label>
            <input type="text" formControlName="dealerBankIfscCode" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dealerBankIfscCode.errors }" />
        </div> 
    </div>  
    <div class="form-row">
        <div class="form-group col">
            <label for="dealerBankOwnerPan">Receiver PAN number</label>
            <input type="text" formControlName="dealerBankOwnerPan" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dealerBankOwnerPan.errors }" />
        </div>   
        <div class="form-group col">
            <label for="dealerGstId">Receiver GST number</label>
            <input type="text" formControlName="dealerGstId" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dealerGstId.errors }" />
        </div>   
    </div>
    <div class="form-group">
        <button [disabled]="loading" class="btn btn-primary">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Save
        </button>
        <a (click)="onReset()" class="btn btn-link">Cancel</a>
    </div>


</form>
<app-user-list></app-user-list>
<!-- <app-dealer-payment-gateway *ngIf="showPaymentTab"></app-dealer-payment-gateway> -->
</div>
<!-- <app-add-payment *ngIf="!isAddMode"></app-add-payment> -->
