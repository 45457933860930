import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Alert } from '@app/_models';
import { ReportSearch } from '@app/_models/reportSearch';
import { environment } from '@environments/environment';
import { Observable, forkJoin } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { first } from 'rxjs/internal/operators/first';
import { AlertService } from './alert.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ChartsService {

  constructor(private router: Router, private alertService:AlertService,
    private http: HttpClient) { }
    savedReportSearch =  new ReportSearch();
    chartData :any[];

      setChartData(inputChartData:any[]){
        this.chartData = inputChartData;
      }

     getSummedupData(reportSearch: ReportSearch): Observable<any[]>{
      if (!this.savedReportSearch.issueDateFrom) this.savedReportSearch.issueDateFrom = " ";
      if (!this.savedReportSearch.issueDateTo) this.savedReportSearch.issueDateTo = " ";
      this.savedReportSearch = new ReportSearch(reportSearch);
      return this.http.post<any[]>(`${environment.apiUrl}/charts/getCertificateAggregatedData`, reportSearch);
    }

    // New method to get year-on-year data
    getYearOnYearData(reportSearch: ReportSearch): Observable<{current: any[], previous: any[]}> {
      // Create a copy of the search criteria for previous year
      const previousYearSearch = { ...reportSearch };
      
      // Adjust dates for previous year
      if (reportSearch.issueDateFrom && reportSearch.issueDateFrom !== "0001-01-01") {
        const fromDate = new Date(reportSearch.issueDateFrom);
        fromDate.setFullYear(fromDate.getFullYear() - 1);
        previousYearSearch.issueDateFrom = fromDate.toISOString().split('T')[0];
      }
      
      if (reportSearch.issueDateTo && reportSearch.issueDateTo !== "9999-99-99") {
        const toDate = new Date(reportSearch.issueDateTo);
        toDate.setFullYear(toDate.getFullYear() - 1);
        previousYearSearch.issueDateTo = toDate.toISOString().split('T')[0];
      }
  
      // Make parallel requests for current and previous year data
      return forkJoin({
        current: this.getSummedupData(reportSearch),
        previous: this.getSummedupData(previousYearSearch)
      });
    }
}
