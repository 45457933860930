import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ConfigMaintainLayoutComponent } from './config-maintain-layout/config-maintain-layout.component';
import { PlanMaintainComponent } from './plan-maintain/plan-maintain.component';

const routes: Routes = [
  {
      path: '', component: ConfigMaintainLayoutComponent,
      children: [
          { path: 'Plan', component: PlanMaintainComponent },
      ]
  }
];



@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ConfigMaintainRoutingModule { }
