import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { User } from '@app/_models';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterModule]
})
export class AdminDashboardComponent implements OnInit {
  @Input() user: User;
  
  statistics = [
    { title: 'Active Dealers This Month', value: 0, icon: 'fa-briefcase', color: '#4CAF50', loading: true },
    { title: 'Certificates Issued Today', value: 0, icon: 'fa-certificate', color: '#2196F3', loading: true },
    // { title: 'Revenue This Month', value: 0, icon: 'fa-money', color: '#FF9800', prefix: '₹', loading: true },
    { title: 'Pending Approvals', value: 0, icon: 'fa-clock', color: '#F44336', loading: true }
  ];
  
  quickLinks = [
    { title: 'Issue Certificate', icon: 'fa-plus-square', route: '/issueCertificate', color: '#4CAF50' },
    { title: 'Print Certificate', icon: 'fa-print', route: '/reports/printCertificate', color: '#2196F3' },
    { title: 'Dealer Management', icon: 'fa-users', route: '/users', color: '#FF9800' },
    { title: 'Payment Report', icon: 'fa-credit-card', route: '/reports/transactionReport', color: '#9C27B0' }
  ];
  
  recentActivities = [];
  
  constructor() { }

  ngOnInit(): void {
    // Simulate loading data
    setTimeout(() => {
      this.statistics[0].value = 0;
      this.statistics[0].loading = false;
      
      setTimeout(() => {
        this.statistics[1].value = 0;
        this.statistics[1].loading = false;
        
        setTimeout(() => {
          this.statistics[2].value = 0;
          this.statistics[2].loading = false;
          
          setTimeout(() => {
            this.statistics[3].value = 0;
            this.statistics[3].loading = false;
          }, 300);
        }, 300);
      }, 300);
    }, 300);
    
    // Simulate recent activities
    this.recentActivities = [
      { type: 'certificate', message: 'New certificate issued by Dealer ABC Motors', time: '10 minutes ago' },
      { type: 'payment', message: 'Payment of ₹12,500 received from XYZ Automobiles', time: '45 minutes ago' },
      { type: 'user', message: 'New dealer registered: Super Bikes Ltd.', time: '2 hours ago' },
      { type: 'endorsement', message: 'Endorsement request approved for policy #P0012345', time: '3 hours ago' },
      { type: 'system', message: 'System maintenance completed successfully', time: '1 day ago' }
    ];
  }
}
