import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-date-range-selector',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, NgbDatepickerModule],
  template: `
    <form [formGroup]="dateRangeForm">
      <div class="form-row">
        <div class="form-group col">
          <label for="dateFrom">Date From</label>
          <div class="input-group">
            <input 
              class="form-control" 
              formControlName="dateFrom" 
              ngbDatepicker 
              #d="ngbDatepicker" 
              [ngClass]="{ 'is-invalid': submitted && f.dateFrom.errors }">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary fa fa-calendar" (click)="d.toggle()" type="button"></button>
            </div>
          </div>
          <div *ngIf="submitted && f.dateFrom.errors" class="invalid-feedback">
            <div *ngIf="f.dateFrom.errors['required']">Date From is required</div>
          </div>
        </div>
        <div class="form-group col">
          <label for="dateTo">Date To</label>
          <div class="input-group">
            <input 
              class="form-control" 
              formControlName="dateTo" 
              ngbDatepicker 
              #dateTo="ngbDatepicker" 
              [ngClass]="{ 'is-invalid': submitted && f.dateTo.errors }">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary fa fa-calendar" (click)="dateTo.toggle()" type="button"></button>
            </div>
          </div>
          <div *ngIf="submitted && f.dateTo.errors" class="invalid-feedback">
            <div *ngIf="f.dateTo.errors['required']">Date To is required</div>
          </div>
        </div>
        <div class="form-group align-self-end">
          <button type="button" class="btn btn-primary" (click)="onSubmit()">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Search
          </button>
        </div>
      </div>
    </form>
  `,
  styles: [`
    .align-self-end {
      align-self: flex-end;
    }
  `]
})
export class DateRangeSelectorComponent implements OnInit {
  @Input() loading = false;
  @Input() submitted = false;
  @Output() dateRangeSelected = new EventEmitter<{dateFrom: string, dateTo: string}>();
  
  dateRangeForm: FormGroup;
  
  constructor(private fb: FormBuilder) { }
  
  ngOnInit(): void {
    this.dateRangeForm = this.fb.group({
      dateFrom: ['', Validators.required],
      dateTo: ['', Validators.required]
    });
    
    // Set initial dates (current month)
    const today = new Date();
    const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
    
    this.dateRangeForm.patchValue({
      dateFrom: {
        year: firstDay.getFullYear(),
        month: firstDay.getMonth() + 1,
        day: firstDay.getDate()
      },
      dateTo: {
        year: today.getFullYear(),
        month: today.getMonth() + 1,
        day: today.getDate()
      }
    });
  }
  
  get f() { return this.dateRangeForm.controls; }
  
  onSubmit(): void {
    this.submitted = true;
    
    if (this.dateRangeForm.invalid) {
      return;
    }
    
    const dateFrom = this.formatDate(this.f.dateFrom.value);
    const dateTo = this.formatDate(this.f.dateTo.value);
    
    this.dateRangeSelected.emit({ dateFrom, dateTo });
  }
  
  private formatDate(dateObj: any): string {
    if (!dateObj) return '';
    return `${dateObj.year}-${('0' + dateObj.month).slice(-2)}-${('0' + dateObj.day).slice(-2)}`;
  }
}
