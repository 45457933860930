    <h2>Dealer Inactivity Analysis</h2>
    <form [formGroup]="reportForm" class="flex-container" (ngSubmit)="generateReport()">
        <div class="form-row">
          <mat-form-field>
            <mat-label>Activity Duration (months)</mat-label>
            <mat-select formControlName="activityDuration">
              <mat-option *ngFor="let month of months" [value]="month">{{ month }}</mat-option>
            </mat-select>
          </mat-form-field>
      
          <mat-form-field>
            <mat-label>Inactivity Period (days)</mat-label>
            <mat-select formControlName="inactivityPeriod">
              <mat-option *ngFor="let day of days" [value]="day">{{ day }}</mat-option>
            </mat-select>
          </mat-form-field>
      
          <mat-form-field>
            <mat-label>Percentage Decrease in Business</mat-label>
            <input matInput type="number" formControlName="percentageDecrease" min="0" max="100" placeholder="100">
          </mat-form-field>
        </div>
      
        <div class="form-actions">
          <button mat-raised-button color="primary" type="submit">Generate Report</button>
        </div>
      </form>
  
    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
  
    <div class="ag-theme-alpine" style=" width: 100%;">
        <div class="grid-chart-container">
            <div class="ag-theme-alpine" style="width: 60%; ">
              <ag-grid-angular style="height:500px"
                [rowData]="dealers"
                [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef"
                [pagination]="true"
                [paginationPageSize]="100"
                (gridReady)="onGridReady($event)"
                (rowClicked)="onRowClick($event)">
              </ag-grid-angular>
            </div>
          
            <div style="width: 35%;">
                <ag-charts 
                *ngIf="chartOptions"
                [options]="chartOptions"
                style="width: 100%; height: 100%; display: block;">
              </ag-charts>
            </div>
          </div>
    </div>
    <div class="form-actions" style="margin-top: 10px;">
        <button mat-raised-button color="primary" (click)="exportToExcel()">Export To Excel</button>
      </div>  
