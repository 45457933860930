<div class="container" >
  <div class="row">
    <div class="col-md-12">
      <h2>Certificate Number: {{certificate.certificateNumber}}</h2>
      <h4 *ngIf="requestHistory?.length > 0">Comment History:</h4>
      <table class="table table-striped" *ngIf="requestHistory?.length > 0">
        <thead>
          <tr>
            <th>Date</th>
            <th>User</th>
            <th>type</th>
            <th>Comment</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let request of requestHistory ">
            <td>{{ request.requestedDate  | date: 'medium'}}</td>
            <td>{{ request.requestedBy }}</td>
            <td>{{ request.requestType }}</td>
            <td>{{ request.comment }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
