import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderConfigService } from './header-config.service';

// Import role-specific header components
import { AdminHeaderComponent } from './admin-header/admin-header.component';
import { EmployeeHeaderComponent } from './employee-header/employee-header.component';
import { DealerOwnerHeaderComponent } from './dealer-owner-header/dealer-owner-header.component';
import { RsaOperationHeaderComponent } from './rsa-operation-header/rsa-operation-header.component';
import { HeroReportHeaderComponent } from './hero-report-header/hero-report-header.component';
import { ExecutiveHeaderComponent } from './executive-header/executive-header.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [
    CommonModule, 
    RouterModule,
    AdminHeaderComponent,
    EmployeeHeaderComponent,
    DealerOwnerHeaderComponent,
    RsaOperationHeaderComponent,
    HeroReportHeaderComponent,
    ExecutiveHeaderComponent
  ]
})
export class HeaderComponent implements OnInit {
  @Input() user: any;
  @Input() dealer: any;
  @Input() showReceivableTab: boolean = false;
  
  colorConfig: any;
  headerStyle: any = {};
  userType: string = 'admin'; // default

  constructor(private headerConfigService: HeaderConfigService) { }

  ngOnInit(): void {
    this.determineUserType();
    this.colorConfig = this.headerConfigService.getColorConfig(this.userType);
    this.setHeaderStyles();
  }

  determineUserType(): void {
    if (this.user.role === 'rsaOperation') {
      this.userType = 'rsaOperation';
    } else if (this.user.specialRoleAccess && this.user.specialRoleAccess.includes('HeroReport')) {
      this.userType = 'heroReport';
    } else if (this.user.role === 'dealerOwner') {
      this.userType = 'dealerOwner';
    } else if (this.user.role === 'employee') {
      this.userType = 'employee';
    } else if (this.user.role === 'admin') {
      this.userType = 'admin';
    } else {
      // Default to executive for any other role
      this.userType = 'executive';
    }
  }

  setHeaderStyles(): void {
    // Apply dynamic styling based on user role
    document.documentElement.style.setProperty('--header-primary-color', this.colorConfig.primary);
    document.documentElement.style.setProperty('--header-accent-color', this.colorConfig.accent);
    document.documentElement.style.setProperty('--header-hover-color', this.colorConfig.hover);
    document.documentElement.style.setProperty('--header-text-color', this.colorConfig.text);
  }

  collapseNavbar(): void {
    // Close the navbar when a link is clicked (mobile view)
    const navbarToggler = document.querySelector('.navbar-toggler') as HTMLElement;
    const navbarCollapse = document.querySelector('.navbar-collapse') as HTMLElement;
    
    if (window.innerWidth < 992 && navbarCollapse.classList.contains('show')) {
      navbarToggler.click();
    }
  }

  logout(): void {
    // Emit an event to parent component to handle logout
    const event = new CustomEvent('logout');
    window.dispatchEvent(event);
  }

  getInitials(): string {
    return this.user && this.user.firstName && this.user.lastName ? 
      this.user.firstName.charAt(0) + this.user.lastName.charAt(0) : 'AA';
  }
}
