<div class="p-4">
    <div class="container">
        <h1 >Hi {{user.firstName}}!</h1>
        <p >You're logged in Alliance Assure Portal!!</p>
        <p *ngIf="user.role != 'admin' && user.role !='rsaOperation'"><a routerLink="/issueCertificate">Create Certificate</a></p>
        <div class="row" *ngIf="user.dealerId=='DLR-10000003' && user.role !='rsaOperation'">
            <div class="col">
                <a routerLink="/issueCertificate">
                <img src="../../assets/491Plan.png"  style="height: 100% ;height:auto">
            </a>
            </div>

            </div>

    </div>
</div>