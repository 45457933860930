import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '@app/_services';
import { ConfigDataService } from '@app/_services/config-data.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-policy-number-maintain',
  templateUrl: './policy-number-maintain.component.html',
  styleUrls: ['./policy-number-maintain.component.less']
})
export class PolicyNumberMaintainComponent implements OnInit {
  @Input() configInput: { key: string, heading: string, configType: string };
  configMaintainForm: UntypedFormGroup;
  configId: String;
  loading = false;
  policyMaintain = [];

  // policyMaintain = [{
  //   key: "MasterPolicyNumber",
  //   heading: "New India CPA Policy Maintain",
  //   configType: "String"
  // },
  // {
  //   key: "financeMasterPolicyNumber",
  //   heading: "New India Finance Master Policy Maintain",
  //   configType: "String"
  // },
  // {
  //   key: "financeMasterPolicyNumberIFT",
  //   heading: "Iffco Tokio Finance Master Policy Maintain",
  //   configType: "String"
  // },
  // {
  //   key: "fourWheelerMasterPolicyNumber",
  //   heading: "New India Four Wheeler Master Policy Maintain",
  //   configType: "String"
  // },
  // {
  //   key: "fourWheelerMasterFinancePolicyNumber",
  //   heading: "New India Four Wheeler Master Finance Policy Maintain",
  //   configType: "String"
  // }
  //   ,
  // {
  //   key: "iffcotokioCpaPolicy",
  //   heading: "Iffco Tokio CPA Policy",
  //   configType: "String"
  // }
  // ,
  // {
  //   key: "iffcotokioCpaCounterId",
  //   heading: "Iffco Tokio CPA Series Update",
  //   configType: "Counter"
  // }
  //   ,
  // {
  //   key: "CertificateId",
  //   heading: "New India Certificate Series Update",
  //   configType: "Counter"
  // }
  // ,
  // {
  //   key: "AplCertificateId",
  //   heading: "New India Finance Series Update",
  //   configType: "Counter"
  // }
  // ,
  // {
  //   key: "AplCertificateIdIFT",
  //   heading: "Iffco Tokio Finance Series Update",
  //   configType: "Counter"
  // }
  // ,
  // {
  //   key: "RsaEvCertificateId",
  //   heading: "RSA EV Series Update",
  //   configType: "Counter"
  // }
  //   ,
  // {
  //   key: "RsaCertificateId",
  //   heading: "RSA Series Update",
  //   configType: "Counter"
  // }
  // ,
  // {
  //   key: "RsaCarCertificateId",
  //   heading: "RSA Car Series Update",
  //   configType: "Counter"
  // }
  // ,
  // {
  //   key: "fourWheelerFinanceCounterId",
  //   heading: "New India Four Wheeler Finance Series Update",
  //   configType: "Counter"
  // }
  // ,
  // {
  //   key: "fourWheelerCounterId",
  //   heading: "New India Four Wheeler Series Update",
  //   configType: "Counter"
  // }
  // ,
  // {
  //   key: "RSAEvFeatures",
  //   heading: "RSA EV Plan Features",
  //   configType: "Array"
  // }
  //   ,
  // {
  //   key: "Model",
  //   heading: "Models Update",
  //   configType: "Array"
  // }
  // ,
  // {
  //   key: "State",
  //   heading: "States and Cities Update",
  //   configType: "Array"
  // }
  // ,
  // {
  //   key: "Plan",
  //   heading: "Plans Update",
  //   configType: "Array"
  // }
  // ,
  // {
  //   key: "PrintInstruction",
  //   heading: "Print Instructions Update",
  //   configType: "Array"
  // }
  // ,
  // {
  //   key: "Finance",
  //   heading: "Finance Company Update",
  //   configType: "Array"
  // }

  // ];

  constructor(private formBuilder: UntypedFormBuilder,
    private configDateService: ConfigDataService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    // this.configId = this.route.snapshot.params['id'];
    this.configDateService.MasterPolicyMaintain$.subscribe(x => {this.policyMaintain = [...this.policyMaintain, ...x ];
    this.configDateService.SetupMaintain$.subscribe(x => {this.policyMaintain = [...this.policyMaintain, ...x ];
    this.configDateService.SeriesMaintain$.subscribe(x => {this.policyMaintain = [...this.policyMaintain, ...x ];
    // this.policyMaintain = [...this.configDateService.setupMaintain, ...this.configDateService.masterPolicyMaintain,...this.configDateService.seriesMaintain]
    if (!this.configInput) {
      this.route.params.subscribe(a => {
        this.configId = a.id;
        if (a.id) {
          this.configInput = this.policyMaintain.find(x => x.key == this.configId);
          this.loadTab()
        }

      }
      )
    }
    else {
      this.loadTab()
    }
  })
})
})
  }
  get f() { return this.configMaintainForm.controls; }

  loadTab() {
    this.configMaintainForm = this.formBuilder.group({
      keyValue: [this.configInput.key],
      configType: [this.configInput.configType],
      ResultValue: ['']
    })
    if (this.configInput.configType.toUpperCase() == "STRING") {
      this.configDateService.getStringCacheConfig(this.configInput.key)
        .pipe(first())
        .subscribe((x) => {
          this.configMaintainForm.controls['ResultValue'].setValue(x);
        })
    }
    else   if (this.configInput.configType.toUpperCase() == "ARRAY") {
      this.configDateService.getConfig(this.configInput.key)
        .pipe(first())
        .subscribe((x) => {
          this.configMaintainForm.controls['ResultValue'].setValue(JSON.stringify(x,undefined,4));
        })}
else {
      this.configDateService.getConfigCounter(this.configInput.key)
        .pipe(first())
        .subscribe((x) => {
          this.configMaintainForm.controls['ResultValue'].setValue(x);
        });
    }


  }

  onSubmit() {
    this.loading = true;
    this.configDateService.resetStringCacheConfig(this.configMaintainForm.getRawValue())
      .pipe(first())
      .subscribe({
        next: (x) => {
          (this.configInput.configType.toUpperCase() == "ARRAY") ? this.configMaintainForm.controls['ResultValue'].setValue(JSON.stringify(x,undefined,4)): this.configMaintainForm.controls['ResultValue'].setValue(x)
          ;
          this.loading = false;
        },
        error: error => {
          this.alertService.error(error);
          this.loading = false;
        }
      })


  }
}
