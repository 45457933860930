<div class="admin-dashboard">
  <!-- Stats Section -->
  <div class="row mb-4">
    <div class="col-12">
      <h2 class="section-title">Overview</h2>
    </div>
    <div class="col-md-3 col-sm-6 mb-3" *ngFor="let stat of statistics">
      <div class="stat-card" [style.border-left-color]="stat.color">
        <div class="card-body">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <div class="stat-value" *ngIf="!stat.loading">
                <span *ngIf="stat.prefix">{{ stat.prefix }}</span>{{ stat.value | number }}
              </div>
              <div class="stat-value-loading" *ngIf="stat.loading">
                <div class="loading-placeholder"></div>
              </div>
              <div class="stat-title">{{ stat.title }}</div>
            </div>
            <div class="stat-icon" [style.background-color]="stat.color">
              <i class="fa" [ngClass]="stat.icon"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <!-- Quick Access & Recent Activities -->
  <div class="row">
    <!-- Quick Access -->
    <div class="col-lg-6 mb-4">
      <div class="card">
        <div class="card-header">
          <h5 class="mb-0">Quick Access</h5>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-6 mb-3" *ngFor="let link of quickLinks">
              <a [routerLink]="link.route" class="quick-link-card">
                <div class="icon-circle" [style.background-color]="link.color">
                  <i class="fa" [ngClass]="link.icon"></i>
                </div>
                <div class="link-title">{{ link.title }}</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <!-- Recent Activities -->
    <div class="col-lg-6 mb-4">
      <div class="card">
        <div class="card-header d-flex justify-content-between align-items-center">
          <h5 class="mb-0">Recent Activities(Dummy Data)</h5>
          <a href="#" class="small text-decoration-none">View All</a>
        </div>
        <div class="card-body p-0">
          <ul class="activity-list">
            <li *ngFor="let activity of recentActivities" class="activity-item">
              <div [ngClass]="'activity-icon ' + activity.type">
                <i class="fa" 
                   [ngClass]="{
                     'fa-certificate': activity.type === 'certificate',
                     'fa-money': activity.type === 'payment',
                     'fa-user-plus': activity.type === 'user',
                     'fa-pencil-square': activity.type === 'endorsement',
                     'fa-cogs': activity.type === 'system'
                   }"></i>
              </div>
              <div class="activity-content">
                <div class="activity-message">{{ activity.message }}</div>
                <div class="activity-time">{{ activity.time }}</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
