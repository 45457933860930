import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AgGridModule } from 'ag-grid-angular';
import { ColDef, GridApi, GridReadyEvent, RowClassParams } from 'ag-grid-community';
import { Employee, Dealer } from '../models/employee-dealer.models';
import { DetailPopupComponent } from '../detail-popup.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-dealer-management',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    AgGridModule
  ],
  templateUrl: './dealer-management.component.html',
  styleUrls: ['./dealer-management.component.less']
})
export class DealerManagementComponent implements OnInit {
  @Input() dealers: Dealer[] = [];
  @Input() employees: Employee[] = [];
  @Input() loading = false;
  
  @Output() exportData = new EventEmitter<void>();
  @Output() showDetails = new EventEmitter<Dealer>();
  @Output() assignDealer = new EventEmitter<{dealer: Dealer, employee: string}>();
  
  private gridApi!: GridApi;
  filteredDealers: Dealer[] = [];
  lastClickedRowId: string | null = null;
  preload = true;
  
  assignmentFilter: string = 'all';
  stateFilter: string = 'all';
  employeeFilter: string = 'all';
  
  // AG Grid column definitions - updated to use badges for employee count
  columnDefs: ColDef[] = [
    { 
      field: 'dealerId', 
      headerName: 'Dealer ID', 
      sortable: true, 
      filter: true,
      flex: 1
    },
    { 
      field: 'dealerName', 
      headerName: 'Dealer Name', 
      sortable: true, 
      filter: true,
      flex: 2,
      cellRenderer: (params: any) => {
        const status = this.getAssignedEmployeeCount(params.data.dealerId) > 0 ? 'active' : 'inactive';
        return `
          <div class="dealer-name-cell">
            <span class="status-indicator status-${status}"></span>
            ${params.value}
          </div>
        `;
      }
    },
    { 
      field: 'dealerCity', 
      headerName: 'City', 
      sortable: true, 
      filter: true,
      flex: 1
    },
    { 
      field: 'dealerState', 
      headerName: 'State', 
      sortable: true, 
      filter: true,
      flex: 1
    },
    { 
      headerName: 'Employees', 
      field: 'assignmentCount',
      valueGetter: (params) => this.getAssignedEmployeeCount(params.data.dealerId),
      sortable: true,
      filter: true,
      cellRenderer: (params) => {
        const count = params.value;
        let badgeClass = 'zero';
        let badgeText = 'No employees';
        
        if (count > 0) {
          badgeText = count === 1 ? '1 employee' : `${count} employees`;
          if (count <= 2) badgeClass = 'low';
          else if (count <= 5) badgeClass = 'medium';
          else badgeClass = 'high';
        }
        
        return `<div class="employee-badge ${badgeClass}" data-action="view-assignments">
                  <span class="badge-label">${badgeText}</span>
                </div>`;
      },
      flex: 1
    },
    {
      headerName: 'Edit',
      cellRenderer: (params) => {
        return `<button class="edit-icon" data-action="edit">
                  <mat-icon>edit</mat-icon>
                </button>`;
      },
      width: 80,
      suppressSizeToFit: true
    }
  ];
  
  // Grid options - enhanced
  gridOptions = {
    rowHeight: 56,
    headerHeight: 56,
    suppressRowClickSelection: true,
    rowSelection: 'single',
    animateRows: true,
    defaultColDef: {
      resizable: true,
      suppressMovable: true
    },
    getRowClass: this.getRowClass.bind(this)
  };
  
  constructor(private dialog: MatDialog) {}
  
  ngOnInit(): void {
    this.filteredDealers = [...this.dealers];
    
    // Remove preload class after initial render
    setTimeout(() => {
      this.preload = false;
    }, 100);
  }
  
  onGridReady(params: GridReadyEvent): void {
    this.gridApi = params.api;
    
    // Ensure grid columns fit the available width
    setTimeout(() => {
      params.api.sizeColumnsToFit();
    }, 100);
  }
  
  // Apply row styling dynamically
  getRowClass(params: RowClassParams): string {
    if (params.data.dealerId === this.lastClickedRowId) {
      return 'row-highlight';
    }
    return '';
  }
  
  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.gridApi?.setQuickFilter(filterValue);
  }
  
  applyFilters(): void {
    this.filteredDealers = [...this.dealers];
    
    // Apply assignment filter
    if (this.assignmentFilter !== 'all') {
      this.filteredDealers = this.filteredDealers.filter(dealer => {
        const isAssigned = this.getAssignedEmployeeCount(dealer.dealerId) > 0;
        return this.assignmentFilter === 'assigned' ? isAssigned : !isAssigned;
      });
    }
    
    // Apply state filter
    if (this.stateFilter !== 'all') {
      this.filteredDealers = this.filteredDealers.filter(dealer => 
        dealer.dealerState === this.stateFilter
      );
    }
    
    // Apply employee filter
    if (this.employeeFilter !== 'all') {
      const employee = this.employees.find(e => e.username === this.employeeFilter);
      if (employee && employee.employeeDealerList) {
        this.filteredDealers = this.filteredDealers.filter(dealer => 
          employee.employeeDealerList.includes(dealer.dealerId)
        );
      }
    }
    
    // Update grid data
    if (this.gridApi) {
      this.gridApi.setRowData(this.filteredDealers);
    }
  }
  
  onCellClicked(params: any): void {
    // Track the last clicked row for highlight animation
    this.lastClickedRowId = params.data.dealerId;
    setTimeout(() => {
      this.lastClickedRowId = null;
      this.gridApi.redrawRows();
    }, 600);
    
    const actionTarget = params.event.target.closest('[data-action]');
    if (actionTarget) {
      const action = actionTarget.getAttribute('data-action');
      
      switch(action) {
        case 'view-assignments':
          this.showEmployeeAssignments(params.data);
          break;
        case 'edit':
          this.editDealer(params.data);
          break;
      }
    }
  }
  
  onExportData(): void {
    this.exportData.emit();
  }
  
  editDealer(dealer: Dealer): void {
    // Show dealer details with option to assign employees
    const employeeNames = this.getAssignedEmployees(dealer.dealerId);
    
    this.dialog.open(DetailPopupComponent, {
      width: '600px',
      data: {
        title: `Edit ${dealer.dealerName}`,
        type: 'dealer',
        itemCount: employeeNames.length,
        items: employeeNames,
        dealer: dealer,
        employees: this.employees,
        onAssign: (employeeUsername: string) => {
          this.assignDealer.emit({dealer, employee: employeeUsername});
        }
      },
      panelClass: 'detail-dialog'
    });
  }
  
  showEmployeeAssignments(dealer: Dealer): void {
    const employeeNames = this.getAssignedEmployees(dealer.dealerId);
    
    this.dialog.open(DetailPopupComponent, {
      width: '600px',
      data: {
        title: `${dealer.dealerName} - Assigned Employees`,
        type: 'dealer',
        itemCount: employeeNames.length,
        items: employeeNames,
        dealer: dealer,
        employees: this.employees,
        onAssign: (employeeUsername: string) => {
          this.assignDealer.emit({dealer, employee: employeeUsername});
        }
      },
      panelClass: 'detail-dialog'
    });
  }
  
  getAssignedEmployees(dealerId: string): string[] {
    return this.employees
      .filter(emp => emp.employeeDealerList && emp.employeeDealerList.includes(dealerId))
      .map(emp => `${emp.firstName} ${emp.lastName}`);
  }
  
  getAssignedEmployeeCount(dealerId: string): number {
    return this.employees.filter(emp => 
      emp.employeeDealerList && emp.employeeDealerList.includes(dealerId)
    ).length;
  }
  
  getUniqueStates(): string[] {
    const states = this.dealers.map(dealer => dealer.dealerState);
    return [...new Set(states)].filter(state => !!state).sort();
  }
  
  getUnassignedDealers(): Dealer[] {
    return this.dealers.filter(dealer => 
      this.getAssignedEmployeeCount(dealer.dealerId) === 0
    );
  }
  
  // Get statistics for the summary cards
  getAssignmentStats() {
    const totalDealers = this.dealers.length;
    const unassignedCount = this.getUnassignedDealers().length;
    const assignedCount = totalDealers - unassignedCount;
    
    return {
      totalDealers,
      assignedCount,
      unassignedCount,
      assignmentPercentage: totalDealers ? Math.round((assignedCount / totalDealers) * 100) : 0
    };
  }
}
