<ul class="navbar-nav me-auto mb-2 mb-lg-0">
  <li class="nav-item">
    <a class="nav-link active" aria-current="page" routerLink="/" routerLinkActive="active" 
       [routerLinkActiveOptions]="{exact: true}" (click)="collapseNavbar()">
       <i class="fa fa-home me-1"></i>Home
    </a>
  </li>
  
  <!-- Default navigation items available to all users -->
  <li class="nav-item">
    <a class="nav-link" routerLink="/issueCertificate" routerLinkActive="active" (click)="collapseNavbar()">
      <i class="fa fa-certificate me-1"></i>Issue Certificate
    </a>
  </li>
  
  <li class="nav-item">
    <a class="nav-link" routerLink="/reports/printCertificate" 
       routerLinkActive="active" (click)="collapseNavbar()">
       <i class="fa fa-print me-1"></i>Print Certificate
    </a>
  </li>

  <li class="nav-item" *ngIf="user.specialRoleAccess && user.specialRoleAccess.includes('CanRenewAllPolicies')">
    <a class="nav-link" routerLink="/reports/renewCertificate" 
       routerLinkActive="active" (click)="collapseNavbar()">
       <i class="fa fa-refresh me-1"></i>Renew Certificate
    </a>
  </li>
  
  <!-- Special role access -->
  <li class="nav-item" *ngIf="user.specialRoleAccess && user.specialRoleAccess.includes('538539AddPayment')">
    <a class="nav-link" routerLink="/empAddPayment" routerLinkActive="active" (click)="collapseNavbar()">
      <i class="fa fa-money me-1"></i>Add Payment
    </a>
  </li>
  
  <li class="nav-item dropdown" *ngIf="user.specialRoleAccess && user.specialRoleAccess.includes('ExcelUpload')">
    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" 
       data-bs-toggle="dropdown" aria-expanded="false">
       <i class="fa fa-upload me-1"></i>Bulk Upload
    </a>
    <ul class="dropdown-menu shadow" aria-labelledby="navbarDropdown">
      <li>
        <a class="dropdown-item" routerLink="/issueCertificate/bulkIndividual" 
           routerLinkActive="active" (click)="collapseNavbar()">
           <i class="fa fa-file me-2"></i>Individual Policy
        </a>
      </li>
    </ul>
  </li>
  
  <!-- Reports dropdown - basic reports available to everyone -->
  <li class="nav-item dropdown">
    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownReports" role="button" 
       data-bs-toggle="dropdown" aria-expanded="false">
       <i class="fa fa-bar-chart me-1"></i>Reports
    </a>
    <ul class="dropdown-menu shadow reports-menu" aria-labelledby="navbarDropdownReports">
      <li>
        <a class="dropdown-item" routerLink="/reports/certificateReport" 
           routerLinkActive="active" (click)="collapseNavbar()">
           <i class="fa fa-file me-2"></i>Certificate Report
        </a>
      </li>
      <li>
        <a class="dropdown-item" routerLink="/reports/dealerBalanceReport" *ngIf="user.specialRoleAccess && user.specialRoleAccess.includes('CanViewDealerBalanceReport')" routerLinkActive="active" (click)="collapseNavbar()">
           <i class="fa fa-balance-scale me-2"></i>Dealer Balance Details
        </a>
      </li>
    </ul>
  </li>
</ul>
