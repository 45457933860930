<h1 *ngIf="!renewCertificate && !activeEndorsement && !activeCancel">Print Certificate</h1>
<h1 *ngIf="activeEndorsement">Active Endorsement Requests</h1>
<h1 *ngIf="activeCancel">Active Cancelation Requests</h1>
<h1 *ngIf="renewCertificate">Renew Certificate</h1>
<form [formGroup]="certificateReportForm" (ngSubmit)="onSubmit()">
    <div class="form-row">
         <div class="form-group col"> 
            <label for="certificateNumber">Certificate Number</label>
            <input type="text" formControlName="certificateNumber" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dealerId.errors }" />
        </div>
        <div class="form-group col">
            <label for="chasisNumber">Chasis Number</label>
            <input type="text" formControlName="chasisNumber" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.chasisNumber.errors }" />
        </div>
        <div class="form-group col" *ngIf="!renewCertificate">
            <label for="firstName">Client First Name</label>
            <input type="text" formControlName="firstName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
        </div>
        <div class="form-group col" *ngIf="!renewCertificate">
            <label for="lastName">Client Last Name</label>
            <input type="text" formControlName="lastName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
        </div>
        <div class="form-group col" *ngIf="renewCertificate">
            <label for="contactNumber">Client Contact Number</label>
            <input type="text" formControlName="contactNumber" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.contactNumber.errors }" />
        </div>
        <div class="form-group col" *ngIf="!renewCertificate">
            <label for="userName">Executive User Name</label>
            <input type="text" formControlName="userName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.userName.errors }" />
        </div>
        <div class="form-group col" *ngIf="!renewCertificate">
            <label for="issueDateFrom">Issue Date From</label>
            <div class="input-group">
                <input class="form-control" formControlName="issueDateFrom"   ngbDatepicker #d="ngbDatepicker" [ngClass]="{ 'is-invalid': submitted && f.issueDateFrom.errors }">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary fa fa-calendar" (click)="d.toggle()" type="button"></button>
                </div>
              </div>            
              <div *ngIf="submitted && f.issueDateFrom.errors" class="invalid-feedback">
                <div *ngIf="f.issueDateFrom.errors.required">Issue Date From</div>
            </div>
        </div>
        <div class="form-group col" *ngIf="!renewCertificate">
            <label for="issueDateTo">Issue Date To</label>
            <div class="input-group">
                <input class="form-control" formControlName="issueDateTo"   ngbDatepicker #issueDateTo="ngbDatepicker" [ngClass]="{ 'is-invalid': submitted && f.issueDateTo.errors }">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary fa fa-calendar" (click)="issueDateTo.toggle()" type="button"></button>
                </div>
              </div>            
              <div *ngIf="submitted && f.issueDateTo.errors" class="invalid-feedback">
                <div *ngIf="f.issueDateTo.errors.required">Issue Date From</div>
            </div>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group">
        <button [disabled]="loading" class="btn btn-primary" >
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Search
        </button><a>&nbsp;&nbsp;</a>
        <button  class="btn btn-secondary mr-1" type="reset" (click)="onReset()">
            Reset
        </button>
    </div>
    </div>
</form>
    <div class="form-row" style="width:100%; overflow: scroll;">
        <div class="form-group col" style="width:100%; overflow: scroll;">
        


<table class="table table-striped" style="width:100%; overflow: scroll;">
    <thead>
        <tr>
            <th style="width:10%;">Certificate Number</th>
            <th style="width:10%;" *ngIf="user.role == 'admin'">Dealer Name</th>
            <th style="width:10%;">Owner Name</th>
            <th style="width:10%;">Chasis Number</th>
            <th *ngIf="user.role != 'admin'" style="width:10%;">Bike Model</th>
            <th style="width:10%;">Bike Issue Date</th>
            <th *ngIf="user.role == 'admin'" style="width:10%;">Policy Type</th>
            <th style="width:10%;">Certificate Status</th>
            <th style="width:10%;">Action</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let certificate of certificates">
            <td>{{certificate.certificateNumber}}</td>
            <td *ngIf="user.role == 'admin'">{{certificate.dealerName}}</td>
            <td>{{(certificate.firstName).trim() +  " " + (certificate.lastName).trim()}}</td>
            <td>{{certificate.chasisNumber}}</td>
            <td *ngIf="user.role != 'admin'">{{certificate.model}}</td>
            <td>{{certificate.issueDateString}}</td>
            <td *ngIf="user.role == 'admin'">{{certificate.policyType}}</td>
            <td>{{certificate.certificateStatus}}</td>
            <td style="white-space: nowrap">
                <div class="dropdown" *ngIf="(['Submitted'].includes(certificate.certificateStatus))||(['Cancelation Requested', 'Endorsement Requested'].includes(certificate.certificateStatus) && user.role == 'admin')">
                    <button class="btn btn-sm btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Actions
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownActionButton">
                        <a class="dropdown-item"  (click)="printCertificate(certificate)" *ngIf="certificate.certificateStatus == 'Submitted' && !renewCertificate">
                            <i class="fas fa-print"></i> Print Certificate
                        </a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" (click)="selectCertificate(certificate,'Select')" data-bs-toggle="modal"
                        data-bs-target="#showCommentHistoryModel"
                        *ngIf="(certificate && certificate.requestHistory && certificate.requestHistory.length > 0) && (user.role == 'admin' || user.role == 'employee')">
                        <i class="fa fa-upload"></i> Comment History
                        </a>
                          <a class="dropdown-item"  (click)="renewCertificateAction(certificate)" *ngIf="certificate.certificateStatus == 'Submitted' && renewCertificate">
                            <i class="fas fa-redo"></i> Renew
                        </a>
                        <a class="dropdown-item text-danger" (click)="selectCertificate(certificate,'Cancel')" data-bs-toggle="modal"
                        data-bs-target="#myRequestModal"
                        *ngIf="['Submitted'].includes(certificate.certificateStatus)">
                        <i class="fa fa-times-circle"></i> Request Cancel
                      </a>
                      <a class="dropdown-item text-danger" (click)="selectCertificate(certificate,'Endorsement')" data-bs-toggle="modal"
                      data-bs-target="#myRequestModal"
                      *ngIf="(['Submitted'].includes(certificate.certificateStatus) && (certificate.createdDate < startDate && certificate.createdDate < dateOneMonthAgo))">
                      <i class="fa fa-times-circle"></i> Request Endorsement
                    </a>
                    <a class="dropdown-item text-info" (click)="selectCertificate(certificate,'Endorsement')" 
                      
                      *ngIf="(['Submitted'].includes(certificate.certificateStatus) && (certificate.createdDate < startDate && certificate.createdDate > dateOneMonthAgo))">
                      <i class="fa fa-pen-nib"></i> Request Endorsement
                    </a>
                    <div class="dropdown-divider" *ngIf="['Cancelation Requested', 'Endorsement Requested'].includes(certificate.certificateStatus) && user.role == 'admin'"></div>
                    <a class="dropdown-item text-danger" (click)="cancelCertificate(certificate)" *ngIf="(certificate.certificateStatus == 'Cancelation Requested' || (user.role == 'admin' || renewCertificate)) && !activeEndorsement" >
                            <i class="fas fa-times"></i> Cancel Certificate
                        </a>
                        <a class="dropdown-item text-info"  (click)="endorseCertificate(certificate)" *ngIf="((user.role == 'admin' || certificate.createdDate >= startDate ||  renewCertificate) && !activeCancel)" >
                            <i class="fas fa-check"></i> Endorse Certificate
                        </a>
                      </div>
                </div>
            </td>
            <!-- <td style="white-space: nowrap">
                <a (click)="printCertificate(certificate)" class="btn btn-sm btn-primary mr-1" *ngIf="certificate.certificateStatus == 'Submitted' && !renewCertificate">Print Certificate</a>
                    <button (click)="cancelCertificate(certificate)" class="btn btn-sm btn-danger mr-1" *ngIf="certificate.certificateStatus == 'Submitted' && (user.role == 'admin' || renewCertificate) " [disabled]="certificate.certificateStatus != 'Submitted'">
                    <span *ngIf="certificate.isDeleting" class="spinner-border spinner-border-sm"></span>
                    <span *ngIf="!certificate.isDeleting">Cancel Certificate</span>
                    </button>

                    <button (click)="endorseCertificate(certificate)" class="btn btn-sm btn-secondary mr-1" *ngIf="certificate.certificateStatus == 'Submitted' && (user.role == 'admin' || certificate.createdDate >= startDate || renewCertificate)" [disabled]="certificate.certificateStatus != 'Submitted'">
                    <span *ngIf="certificate.isDeleting" class="spinner-border spinner-border-sm"></span>
                    <span *ngIf="!certificate.isDeleting">Endorse Certificate</span>
                    </button>
                    <button (click)="renewCertificateAction(certificate)" class="btn btn-sm btn-primary mr-1" *ngIf="certificate.certificateStatus == 'Submitted' && renewCertificate">
                        <span *ngIf="certificate.isDeleting" class="spinner-border spinner-border-sm"></span>
                        <span *ngIf="!certificate.isDeleting">Renew</span>
                        </button>
                </td> -->
        </tr>
        <tr *ngIf="loading">
            <td colspan="4" class="text-center">
                <span class="spinner-border spinner-border-lg align-center"></span>
            </td>
        </tr>
    </tbody>
</table>
</div>
</div>

  <div class="modal" id="myRequestModal">
    <div class="modal-dialog">
      <div class="modal-content">
  
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Are you sure?</h4>
        </div>
  
        <!-- Modal body -->
        <div class="modal-body" *ngIf="selectedCertificate?.createdDate < dateOneMonthAgo">
          <p>For any policies issued over one month ago, please email us at support&#64;allianceassure.in with the details of your request.</p>
        </div>
        <div class="modal-body" *ngIf="selectedCertificate?.createdDate >= dateOneMonthAgo">
          <app-show-request-history></app-show-request-history>
          <form [formGroup]="cancelPackageRequestForm">
  
            <div class="form-group">
              <label for="cancelReason">Reason(Add Details for future reference):</label>
              <textarea formControlName="cancelReason" class="form-control"
                [ngClass]="{ 'is-invalid': cancelRequestF.cancelReason.errors }"></textarea>
              <div *ngIf="cancelRequestF.cancelReason?.errors" class="invalid-feedback">
                <div *ngIf="cancelRequestF.cancelReason?.errors.required">Cancel Reason is required</div>
              </div>
            </div>
            <div class="form-group">
              <label for="notificationEmail">Dealer Email Id for Notification:</label>
              <input formControlName="notificationEmail" class="form-control" type="email"
                [ngClass]="{ 'is-invalid': cancelRequestF.notificationEmail.errors }">
              <div *ngIf="cancelRequestF.notificationEmail?.errors" class="invalid-feedback">
                <div *ngIf="cancelRequestF.notificationEmail?.errors.required">Email is required</div>
              </div>
            </div>
  
          </form>
        </div>
  
        <!-- Modal footer -->
        <div class="modal-footer" *ngIf="selectedCertificate && selectedCertificate.createdDate >= dateOneMonthAgo">
          <button type="button" id="closeRequestModal" class="btn btn-primary btn-sm mr-1"
            data-bs-dismiss="modal">Back</button>
          <button (click)="requestOnCertificate()" class="btn btn-sm btn-danger mr-1"
            *ngIf="!(selectedCertificate &&  ['Cancelation Requested','Endorsement Requested'].includes(selectedCertificate.certificateStatus))">
            <span *ngIf="isDeleting" class="spinner-border spinner-border-sm"></span>
            <span *ngIf="!isDeleting">Submit</span>
          </button>
  
        </div>
        <div class="modal-footer" *ngIf="(selectedCertificate && selectedCertificate.createdDate < dateOneMonthAgo)">
          <button type="button" id="closeRequestModal" class="btn btn-primary btn-sm mr-1"
            data-bs-dismiss="modal">Back</button>  
        </div>
  
      </div>
    </div>
  </div>
  <div class="modal" id="showCommentHistoryModel">
    <div class="modal-dialog">
      <div class="modal-content">
  
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Comments</h4>
        </div>
        <div class="modal-body">
          <app-show-request-history></app-show-request-history>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary btn-sm mr-1" data-bs-dismiss="modal">Close Popup</button>
        </div>
      </div>
    </div>
  </div>
  



