<div class="printPage" id="printPage" style="font-family: Arial, Helvetica, sans-serif;">
<div class="page" >
    <div class="subpage" >
<div class="container-fluid" >
    <div class="row" style="text-align: left;">
        <div class="col-md-8"> 
            <img src="../assets/AllianceLogo.png" height="100px" style="align-self: left;">
        </div>
        <div class="col-md-4"> 
            <div class="container-fluid">
                <div class="row">
                    <small class="text-right">{{currentDate}} </small>
                </div>        
            </div>
        </div>
    </div>
</div>
<div class="container-fluid" >
    <div class="row" style="text-align: left;background: rgb(36,43,134)">
        <div class="col"> 
            <h4 class="text-center" style="color: bisque;">  Alliance Assure - Certificate of Assistance </h4>
        </div>
    </div>
</div>
<div class="container-fluid" >
    <div class="row" style="text-align: center;">
        <div class="col"> 
            <strong class="text-center" >  CERTIFICATE NUMBER - {{certificate.certificateNumber}} </strong>
        </div>
    </div>
</div>
<div class="container-fluid" style="margin-top: 10px;" >
    <div class="row" style="text-align: center;">
        <div class="col"> 
            <table class="table table-condensed" style="text-align: left; border: 1px solid black;">
                <tbody style="font-size:medium;">
                    <tr>
                    <td>
                        <address>
                            <strong>Registered Office: Alliance Assure Private Limited</strong><br>
                            D 61, Sector 43 ,Golf Course Road, Gurugram ( Haryana ) -122009<br>
                            PH: 1800 212 7559, CIN: U74999HR2021PTC092448
                        </address>
                </td>
                <td >
                    <address>
                        <strong>For Assistance, Please contact us at: </strong><br>
                        <!-- Toll Free Number:<br> -->
                        Email ID: <a href="mailto::%20support@allianceassure.in"><strong>: support&#64;allianceassure.in</strong></a>
                    </address>
                </td>
                </tr>
            </tbody>
        </table>
       </div>
    </div>
</div>
<div class="container-fluid" style="margin-top: 10px;" >
    <div class="row" style="text-align: center;">
        <div class="col-md-6">
            <div class="container-fluid-fluid" style="background-color: rgb(36,43,134);" >
                <strong class="text-center" style="color: bisque;"> Certificate Details</strong>
            </div> 
            <table class="table table-condensed " style="text-align: left;">
                <tbody style="font-size:medium">
                    <tr >
                        <td>
                            <strong style="font-size:medium">Certificate Number</strong>
                        </td>
                        <td>
                            <strong>{{certificate.certificateNumber}}</strong>
                        </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Plan Name</strong>
                            </td>
                            <td>
                                <strong>{{certificate.planId=="Flexible" ? certificate.planTotalAmount: certificate.planId}}</strong>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Certificate Start Date</strong>
                            </td>
                            <td>
                                <strong>{{certificate.startDate| date:'dd-MM-yyyy hh:mm:ss a':'+0530'}}</strong>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Certificate End Date</strong>
                            </td>
                            <td>
                                <strong>{{certificate.endDate| date}} - MIDNIGHT</strong>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>Certificate Issue Date</strong>
                            </td>
                            <td>
                                <strong>{{certificate.issueDate| date}}</strong>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>SAC Code</strong>
                            </td>
                            <td>
                                <strong>{{certificate.dealerId}}</strong>
                            </td>
                        </tr>
                   </tbody>
             </table>
            </div>
       <div class="col-md-6" style="align-self: right;"> 
        <div class="container-fluid-fluid" style="background-color: rgb(36,43,134);" >
            <strong class="text-center" style="color: bisque;"> Vehicle Details</strong>
        </div> 
        <table class="table table-condensed " style="text-align: left;">
            <tbody style="font-size:medium">
            <tr>
                    <td>
                        <strong>Vehicle Registration Number</strong>
                    </td>
                    <td>
                        <strong>{{certificate.registrationNumber}}</strong>
                    </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>OEM</strong>
                        </td>
                        <td>
                            <strong>{{certificate.vehicleMake}}</strong>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Model</strong>
                        </td>
                        <td>
                            <strong>{{certificate.model}}</strong>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Variant</strong>
                        </td>
                        <td>
                            <strong>{{certificate.variant}}</strong>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Engine Number</strong>
                        </td>
                        <td>
                            <strong>{{certificate.engineNumber}}</strong>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Chassis Number</strong>
                        </td>
                        <td>
                            <strong>{{certificate.chasisNumber}}</strong>
                        </td>
                    </tr>
               </tbody>
         </table>
        </div>
    </div>
</div>
<div class="container-fluid" >
    <div class="row" style="text-align:center; background-color: rgb(36,43,134);">
        <div class="col"> 
            <strong class="text-center" style="color: bisque;">  Personal Details </strong>
        </div>
    </div>
</div>
<div class="container" >
    <div class="row" >
        <div class="col">
            <table class="table table-condensed " width=100% height=300px style="text-align: left; margin-top: 10px; ">
                <tbody style="font-size:medium;">
                    <tr>
                        <td >
                            <strong> First Name</strong>
                        </td>
                        <td >
                            <strong> {{certificate.firstName}}</strong>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong> Last Name</strong>
                        </td>
                        <td>
                            <strong> {{certificate.lastName}}</strong>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong> Mobile</strong>
                        </td>
                        <td>
                            <strong> {{certificate.contactNumber}}</strong>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong> Address</strong>
                        </td>
                        <td>
                            <strong> {{certificate.address|slice:0:150}}</strong>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong> City</strong>
                        </td>
                        <td>
                            <strong> {{certificate.city}}</strong>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong> State</strong>
                        </td>
                        <td>
                            <strong> {{certificate.state}}</strong>
                        </td>
                    </tr>
                    </tbody>
                    </table>
            </div>
    </div>
</div>
<div class="container-fluid" >
    <div class="row" style="text-align:center; background-color: rgb(36,43,134);">
        <div class="col"> 
            <strong class="text-center" style="color: bisque;"> Plan Features </strong>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col">
            <table class="table table-condensed " style="text-align: left; border-collapse: collapse;">
                <tbody style="font-size:medium">
                    <tr>
                        <td>
                            <strong>S.No.</strong>
                        </td>
                        <td >
                            <strong>Featured Benefits</strong>
                        </td>
                        <td >
                            <strong>Description</strong>
                        </td>
                        <td >
                            <strong>{{certificate.vehicleType? certificate.vehicleType: "Two Wheeler"}}</strong>
                        </td>
                    </tr>
                    <tr >
                        <td >
                            <p>1</p>
                        </td>
                        <td >
                            <p>Relay of urgent messages</p>
                        </td>
                        <td >
                            <p>Pass on message to Rider's friends/family</p>
                        </td>
                        <td >
                            <p>Yes</p>
                        </td>
                    </tr>
                    <tr>
                        <td >
                            <p>2</p>
                        </td>
                        <td >
                            <p>Doctor Referral</p>
                        </td>
                        <td >
                            <p>Giving the contact details of nearest doctor to Rider</p>
                        </td>
                        <td >
                            <p>Yes</p>
                        </td>
                    </tr>
                    <tr>
                        <td >
                            <p>3</p>
                        </td>
                        <td >
                            <p>Hotel Assistance</p>
                        </td>
                        <td >
                            <p>Arrange for Hotel on Rider's / driver&rsquo;s request</p>
                        </td>
                        <td >
                            <p>Yes</p>
                        </td>
                    </tr>
                    <tr>
                        <td >
                            <p>4</p>
                        </td>
                        <td >
                            <p>Medical Assistance</p>
                        </td>
                        <td >
                            <p>Arranging for an ambulance/ hospital for Rider</p>
                        </td>
                        <td >
                            <p>Yes</p>
                        </td>
                    </tr>
                    <tr>
                        <td >
                            <p>5</p>
                        </td>
                        <td >
                            <p>Programme Start Date</p>
                        </td>
                        <td >
                            <p>The date of commencement of coverage under the program.</p>
                        </td>
                        <td >
                            <p>As mentioned</p>
                        </td>
                    </tr>
                    <tr>
                        <td >
                            <p>6</p>
                        </td>
                        <td >
                            <p>Number of Services</p>
                        </td>
                        <td >
                            <p>Proposed Number of Services</p>
                        </td>
                        <td >
                            <p>2</p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<div class="container-fluid" >
    <div class="row" >
        <div class="col"> 
            <small class="text-center" style="font-size:small" > Special Conditions (applicable to all coverage): T&C applicable -  This Certificate is valid subject to realization of the payment and is effective from the Payment realization date or certificate issue date, whichever is later.For EV RSA services are limited as per T&C </small>
        </div>
    </div>
</div>
<div class="container-fluid" >
    <div class="row" >
        <div class="col-md-3"></div>
        <div class="col-md-6" style="align-self: center;"> 
            <table class="table table-bordered">
                <tbody>
                    <tr>
                        <td>
                            <strong>Product Plan</strong>
                        </td>
                        <td>
                            <strong>PLAN AMOUNT (Rs.) &nbsp; {{certificate.planTotalAmount}}</strong>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
</div>
</div>
<!--ADD_PAGE-->

<!-- <div class="container" style="margin-top: 990px;">
    <div class="page" >
        <div class="subpage">
                <div class="row">
        <app-print-new-india-policy  *ngIf='certificate' [certificate]="certificate"></app-print-new-india-policy>
    </div>
</div> 
</div>
</div> -->
</div>