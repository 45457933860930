      <div>
          <div class="form-row">
            <div class="form-group">
              <button (click)="certificateExportExcel()" [disabled]="loading" class="btn btn-primary">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                Export to excel
              </button><a>&nbsp;&nbsp;</a>
            </div>
          </div>
        <ag-grid-angular #aggrid 
        style="width: 100%; height: 600px;"
        [gridOptions] = "gridOptionsCertificate"
        class="ag-theme-alpine">
    </ag-grid-angular>
      </div>
    
  
  
