import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, UntypedFormBuilder } from '@angular/forms';
import { AlertService, AccountService } from '@app/_services';
import { SearchService } from '@app/_services/search.service';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, GridApi, GridReadyEvent, ValueFormatterParams } from 'ag-grid-community';
// Angular Chart Component
import { AgCharts } from 'ag-charts-angular';
// Chart Options Type Interface
import { AgChartOptions } from 'ag-charts-community';
import { first } from 'rxjs';
import { DealerService } from '@app/_services/dealer-service';
import { Dealer } from '@app/_models/dealer';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-dealer-inactivity-report',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule, 
    AgGridAngular,
    AgCharts,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatProgressBarModule,
    MatTableModule,
    MatIconModule,
    MatTooltipModule,
    // Removed BreakpointObserver as it is not a valid import for standalone components
  ],
  templateUrl: './dealer-inactivity-report.component.html',
  styleUrl: './dealer-inactivity-report.component.less'
})
export class DealerInactivityReportComponent implements OnInit {
  private gridApi!: GridApi;
  reportForm: FormGroup;
  months = [1, 2, 3, 4, 5, 6, 8, 12];
  days = [3, 4, 5, 7, 10, 15, 20, 30];
  dealers = [{
    "dealerId": ""
  }];
  dealerData: Dealer[] = [];
  loading = false;
  selectedDealerId: string | null = null;
  chartOptions: AgChartOptions | null = null;
  isMobile = false;
  screenWidth = 0;

  // AG Grid settings
  columnDefs:ColDef[] = [
    {
      headerName: 'Dealer ID',
      field: 'dealerId',
      sortable: true,
      filter: true,
      cellRenderer: (params) => `<a href="javascript:void(0)" class="dealer-id-link">${params.value}</a>`,
      minWidth: 120
    },
    { 
      headerName: 'Dealer Name', 
      field: 'dealerName', 
      sortable: true, 
      filter: true,
      minWidth: 150 
    },
    { 
      headerName: 'City', 
      field: 'dealerCity', 
      sortable: true, 
      filter: true,
      minWidth: 100 
    },
    { 
      headerName: 'State', 
      field: 'dealerState', 
      sortable: true, 
      filter: true,
      minWidth: 80,
      maxWidth: 100 
    },
    { 
      headerName: 'Group Name', 
      field: 'dealerGroupName', 
      sortable: true, 
      filter: true,
      minWidth: 140 
    },
    { 
      headerName: 'Dealer Type', 
      field: 'dealerPlanType', 
      sortable: true, 
      filter: true,
      minWidth: 120 
    },
    { 
      headerName: 'Last Transaction Date', 
      field: 'lastTransactionDate', 
      sortable: true, 
      filter: 'agDateColumnFilter',
      valueFormatter: this.formatDate,
      minWidth: 130
    },
    { 
      headerName: 'Average Transaction', 
      field: 'dailyAverageTransactions', 
      sortable: true, 
      filter: true,
      valueFormatter: this.formatDecimal,
      minWidth: 100
    },
    { 
      headerName: 'Expected Average', 
      field: 'expectedTransactionsAverage', 
      sortable: true, 
      filter: true,
      valueFormatter: this.formatDecimal,
      minWidth: 100
    },
    { 
      headerName: 'Active Period Average', 
      field: 'activePeriodDailyAverageTransactions',
      sortable: true, 
      filter: true,
      valueFormatter: this.formatDecimal,
      minWidth: 120
    }
  ];
  
  defaultColDef = {
    resizable: true, 
    sortable: true,
    filter: true,
    flex: 1,
    minWidth: 100,
    autoHeight: true
  };

  constructor(
    private formBuilder: UntypedFormBuilder,
    private searchService: SearchService,
    private dealerService: DealerService, 
    private alertService: AlertService,
    private accountService: AccountService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.reportForm = this.formBuilder.group({
      activityDuration: [1],
      inactivityPeriod: [3],
      percentageDecrease: [100]
    });
    
    // Detect screen size changes
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
    ]).subscribe(result => {
      this.isMobile = result.matches;
      this.updateColumnDefs();
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = window.innerWidth;
    this.updateColumnDefs();
  }
  
  ngOnInit(): void {
    // Get initial screen width
    this.screenWidth = window.innerWidth;
    this.isMobile = this.screenWidth < 768;
    
    this.dealerService.getAllDealer().pipe(first()).subscribe({
      next: (data) => {
        this.dealerData = data;
      },
      error: (error) => {
        this.alertService.error('Error fetching dealers:', error);
      }
    });
  }

  // Format date for grid display
  formatDate(params: ValueFormatterParams) {
    if (!params.value) return '';
    const date = new Date(params.value);
    return date.toLocaleDateString();
  }

  // Format decimal values for grid display
  formatDecimal(params: ValueFormatterParams) {
    if (params.value === null || params.value === undefined) return '';
    return Number(params.value).toFixed(2);
  }

  generateReport(): void {
    const { activityDuration, inactivityPeriod, percentageDecrease } = this.reportForm.value;
    this.loading = true;
    // Reset chart when generating new report
    this.selectedDealerId = null;
    this.chartOptions = null;

    this.searchService.getInactiveDealers(activityDuration, inactivityPeriod, percentageDecrease)
    .pipe(first()).subscribe({
      next: (data) => {
        this.dealers = data;
        this.loading = false;
        this.dealers = this.dealers.map(dealer => {
          const dealerData = this.dealerData.find(d => d.dealerId === dealer.dealerId);
          return {
            ...dealer,
            dealerState: dealerData?.dealerState,
            dealerCity: dealerData?.dealerCity,
            dealerGroupName: dealerData?.dealerGroupName,
            dealerPlanType: dealerData?.dealerPlanType
          };
        });
        
        // Show success message if dealers found
        if (this.dealers.length > 1 || (this.dealers.length === 1 && this.dealers[0].dealerId !== '')) {
          this.alertService.success(`Found ${this.dealers.length} dealers matching criteria`);
        } else {
          this.alertService.info('No dealers found matching the criteria');
        }
      },
      error: (error) => {
        this.alertService.error('Error fetching report:', error);
        this.loading = false;
      }
    });
  }

  onRowClick(event: any): void {
    const dealerId = event.data.dealerId;
    this.selectedDealerId = dealerId;
    this.loadDealerActivityChart(dealerId);
  }

  loadDealerActivityChart(dealerId: string): void {
    this.loading = true;
    const { activityDuration, inactivityPeriod } = this.reportForm.value;
    
    this.searchService.getDealerDailyActivity(dealerId, activityDuration ? activityDuration : 6).subscribe({
      next: (activityData) => {
        // add missing dates for last 30 days if entry is not present 
        activityData = activityData.map(data => ({ date: data._id, amount: data.amount }));
        const lastNDays = this.generateLastNDays(inactivityPeriod ? inactivityPeriod : 30, activityData);
        const activityDataMap = new Map(activityData.map(data => [data.date, data.amount]));
        const filledActivityData = lastNDays.map(date => {
          return {
            date: date,
            amount: activityDataMap.get(date) || 0
          };
        });

        this.chartOptions = {
          title: {
            text: `Daily Transaction Activity`,
            fontSize: 16
          },
          subtitle: {
            text: `Dealer ${dealerId} - Last ${inactivityPeriod || 30} days`
          },
          data: filledActivityData,
          series: [
            {
              type: 'line',
              xKey: 'date',
              yKey: 'amount',
              xName: 'Date',
              yName: 'Transactions',
              marker: { 
                enabled: true,
                size: 6,
                fill: '#FF4081'
              },
              tooltip: {
                enabled: true
              },
              stroke: '#3F51B5',
              strokeWidth: 3
            }
          ],
          legend: {
            enabled: false
          },
          background: {
            fill: 'white'
          },
          padding: {
            top: 20,
            right: this.isMobile ? 10 : 20,
            bottom: this.isMobile ? 30 : 40,
            left: this.isMobile ? 40 : 50
          },
          // Set the container element directly in the template or dynamically via JavaScript
                    container: document.getElementById('chart-container'),
          axes: [
            {
              type: 'category',
              position: 'bottom',
              title: {
                text: 'Date',
                enabled: !this.isMobile // Hide title on mobile
              },
              label: {
                rotation: this.isMobile ? 90 : 45, // More rotation on mobile
                formatter: this.isMobile ? 
                  (params) => params.value.split('-')[2] : // Only show day on mobile
                  (params) => params.value
              }
            },
            {
              type: 'number',
              position: 'left',
              title: {
                text: 'Transactions',
                enabled: !this.isMobile // Hide title on mobile
              }
            }
          ]
        };
        this.loading = false;
      },
      error: (error) => {
        this.alertService.error('Error loading activity data');
        this.loading = false;
      }
    });
  }

  exportToExcel(): void {
    if (this.gridApi) {
      const params = {
        fileName: `Dealer_Inactivity_Report_${new Date().toISOString().slice(0,10)}.csv`
      };
      this.gridApi.exportDataAsCsv(params);
      this.alertService.success('Report exported successfully');
    }
  }
  
  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    
    // Update column defs based on current screen size
    this.updateColumnDefs();
    
    // Size columns appropriately
    setTimeout(() => {
      this.fitColumns();
    }, 100);
    
    // Add resize listener for responsive grid
    window.addEventListener('resize', () => {
      setTimeout(() => {
        this.fitColumns();
      }, 100);
    });
  }
  
  generateLastNDays(n: number, activityData:{date,amount}[]): string[] {
    const dates = [];
    const today = new Date();

    for (let i = 0; i < n; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() - i);
      dates.push(date.toISOString().split('T')[0]);
    }
    
    const filterDates = activityData
      .filter(a => a.date < dates[dates.length - 1])
      .map(a => a.date)
      .reverse();
      
    filterDates.forEach(data => {
      dates.push(data);
    });
    
    return dates.reverse();
  }

  // Method to update column definitions based on screen size
  updateColumnDefs() {
    if (this.isMobile) {
      // Simplified column set for mobile
      this.columnDefs = [
        {
          headerName: 'ID',
          field: 'dealerId',
          sortable: true,
          filter: true,
          cellRenderer: (params) => `<a href="javascript:void(0)" class="dealer-id-link">${params.value}</a>`,
          minWidth: 80
        },
        { 
          headerName: 'Name', 
          field: 'dealerName', 
          sortable: true,
          filter: true, 
          minWidth: 120
        },
        { 
          headerName: 'City', 
          field: 'dealerCity', 
          sortable: true, 
          filter: true,
          minWidth: 80
        },
        { 
          headerName: 'Last Trans', 
          field: 'lastTransactionDate', 
          sortable: true,
          valueFormatter: this.formatDate,
          minWidth: 90
        }
      ];
    } else {
      // Full column set for larger screens
      this.columnDefs = [
        {
          headerName: 'Dealer ID',
          field: 'dealerId',
          sortable: true,
          filter: true,
          cellRenderer: (params) => `<a href="javascript:void(0)" class="dealer-id-link">${params.value}</a>`,
          minWidth: 120
        },
        { 
          headerName: 'Dealer Name', 
          field: 'dealerName', 
          sortable: true, 
          filter: true,
          minWidth: 150 
        },
        { 
          headerName: 'City', 
          field: 'dealerCity', 
          sortable: true, 
          filter: true,
          minWidth: 100 
        },
        { 
          headerName: 'State', 
          field: 'dealerState', 
          sortable: true, 
          filter: true,
          minWidth: 80,
          maxWidth: 100 
        },
        { 
          headerName: 'Group Name', 
          field: 'dealerGroupName', 
          sortable: true, 
          filter: true,
          minWidth: 140 
        },
        { 
          headerName: 'Dealer Type', 
          field: 'dealerPlanType', 
          sortable: true, 
          filter: true,
          minWidth: 120 
        },
        { 
          headerName: 'Last Transaction', 
          field: 'lastTransactionDate', 
          sortable: true, 
          filter: 'agDateColumnFilter',
          valueFormatter: this.formatDate,
          minWidth: 130
        },
        { 
          headerName: 'Avg Trans', 
          field: 'dailyAverageTransactions', 
          sortable: true, 
          filter: true,
          valueFormatter: this.formatDecimal,
          minWidth: 100
        },
        { 
          headerName: 'Expected Avg', 
          field: 'expectedTransactionsAverage', 
          sortable: true, 
          filter: true,
          valueFormatter: this.formatDecimal,
          minWidth: 100
        },
        { 
          headerName: 'Active Period Avg', 
          field: 'activePeriodDailyAverageTransactions',
          sortable: true, 
          filter: true,
          valueFormatter: this.formatDecimal,
          minWidth: 120
        }
      ];
    }
    
    // If grid is already initialized, update the columns
    if (this.gridApi) {
      this.gridApi.setColumnDefs(this.columnDefs);
      setTimeout(() => {
        this.fitColumns();
      }, 100);
    }
  }
  
  // Enhanced method for fitting columns
  fitColumns() {
    if (this.gridApi) {
      if (this.isMobile) {
        this.gridApi.sizeColumnsToFit();
      } else {
        // For desktop, auto-size all columns first, then size to fit
        const allColumnIds = this.columnDefs.map(column => column.field);
        this.gridApi.autoSizeColumns(allColumnIds);
        this.gridApi.sizeColumnsToFit();
      }
    }
  }
}