import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { PlanDetails } from '@app/_models/planDetails';
import { ConfigDataService } from '@app/_services/config-data.service';
import { PlanMaintainService } from '../plan-maintain.service';
import { first } from 'rxjs';
import { AlertService } from '@app/_services';
import { PrintInstruction } from '@app/_models/printInstruction';

@Component({
  selector: 'app-plan-maintain',
  templateUrl: './plan-maintain.component.html',
  styleUrls: ['./plan-maintain.component.less']
})
export class PlanMaintainComponent implements OnInit {
  plans:PlanDetails[] = [];
  
  selectedPlan: PlanDetails | null = null;
  planForm: UntypedFormGroup;
  printInstructions: PrintInstruction[] = [];
  updatePlan = {      keyValue: "Plan",
    configType: "Array",
    ResultValue: []}
  loading: boolean;
  isEdit: boolean;
  submitted: boolean = true
  applicableOnlyOnPolicyTypes = ["Old","New","Both"];
  booleanArray = ["Yes","No"];
  policyMaintain = [];
  seriesMaintain = [];
  gstPercentage: number;

  constructor(private configService:ConfigDataService, private alertService:AlertService,private formBuilder: UntypedFormBuilder,) { 
    this.planForm = this.formBuilder.group({
      planId: ['', Validators.required],
      planAmount: ['', Validators.required],
      planGstAmount: ['', Validators.required],
      planNetAmount: ['', Validators.required],
      planTotalAmount: ['', Validators.required],
      planMaximumAmount: ['', Validators.required],
      policyType: [''],
      gstApplicable: ['', Validators.required],
      gstShow: ['', Validators.required],
      planMinimumAmount: [0],
      rsaNumberOfServices: [0],
      printCpaPolicy: [''],
      planCPACoverAmount: [''],
      printRsaNumber: [''],
      counterId: [''],
      masterPolicyNumber: [''],
      certificatePrefix: [''],
      numberOfYears: [1],
      applicableOnlyOnPolicyType: ["Both"]
    });
  }

  ngOnInit(): void {
    this.gstPercentage = this.configService.getGST();
    this.configService.SeriesMaintain$.subscribe(seriesMaintain => this.seriesMaintain = seriesMaintain);
    this.configService.MasterPolicyMaintain$.subscribe(policyMaintain => this.policyMaintain = policyMaintain);
    this.configService.getPrintInstructionList().subscribe(printInstructions => this.printInstructions = printInstructions);
    this.fetchPlans();
    this.planForm.get('planTotalAmount').valueChanges.subscribe((value) => {   
      if (value){  
      this.planForm.controls['planGstAmount'].setValue(parseFloat((value - parseFloat((value / (1 + this.gstPercentage)).toFixed(2))).toFixed(2)));
      this.planForm.controls['planAmount'].setValue(parseFloat((value / (1 + this.gstPercentage)).toFixed(2)));
      this.planForm.controls['planMaximumAmount'].setValue(parseFloat((value / (1 + this.gstPercentage)).toFixed(2)));
      this.planForm.controls['planMinimumAmount'].setValue(parseFloat((value / (1 + this.gstPercentage)).toFixed(2)));
    }
    else{
      this.planForm.controls['planGstAmount'].setValue(0);
      this.planForm.controls['planAmount'].setValue(0);
      this.planForm.controls['planMaximumAmount'].setValue(0);
      this.planForm.controls['planMinimumAmount'].setValue(0);
    }

  });
    }

  fetchPlans(): void {
    this.configService.getPlanDetails().subscribe(plans => this.plans = plans);
  }

  onSubmit(): void {
    const planData = this.planForm.getRawValue();
      const index = this.plans.findIndex(p => p.planId === planData.planId);
      if (index > -1) {
        this.plans[index] = planData;
      }
     else {
      this.plans.push(new PlanDetails(planData));
    }
    this.updatePlan.ResultValue = this.plans;
    this.configService.resetStringCacheConfig(this.updatePlan).pipe(first()).subscribe({next: (plans) =>{
      console.log(plans)
      this.resetForm();
      this.loading = false;
    },
    error: error => {
      this.alertService.error(error);
      this.resetForm();
      this.loading = false;
    }
  })

  }

  selectPlan(plan: PlanDetails): void {
    this.selectedPlan = {...plan};
  }
  onEdit(plan: PlanDetails): void {
    this.isEdit = true;
    this.planForm.patchValue(plan);
  }
  onDelete(plan: PlanDetails): void {
    const index = this.plans.findIndex(p => p.planId === plan.planId);
    if (index > -1) {
      this.plans.splice(index, 1);
    }
    this.updatePlan.ResultValue = this.plans;
    this.configService.resetStringCacheConfig(this.updatePlan).pipe(first()).subscribe({next: (plans) =>{
      console.log(plans)
      this.resetForm();
      this.loading = false;
    },
    error: error => {
      this.alertService.error(error);
      this.resetForm();
      this.loading = false;
    }
  })
  }
  addNew(): void {
    this.resetForm();
  }
  get f() { return this.planForm.controls; }
  resetForm(): void {
    this.planForm.reset({
      planMinimumAmount: 0,
      rsaNumberOfServices: 0,
      numberOfYears: 1,
      applicableOnlyOnPolicyType: "Both"
    });
    this.isEdit = false;
  }
}
