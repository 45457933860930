export class DealerModelPrice {
    make:string;
    model: string;
    heroVariant:string;
    orientalVariantCode:string;
    iDVPercentage:number;
    totalAmount: number;
    insuranceAmount: number;
    allianceAmount: number;
    exShowroomAmount: number;
    aadharUploadMandatory: string;
    invoiceUploadMandatory: string;
    panUploadMandatory: string;
    
    public constructor(init?: Partial<DealerModelPrice>) {
        Object.assign(this, init);
    }
}