<ul class="navbar-nav me-auto mb-2 mb-lg-0">
  <li class="nav-item">
    <a class="nav-link active" aria-current="page" routerLink="/" routerLinkActive="active" 
       [routerLinkActiveOptions]="{exact: true}" (click)="collapseNavbar()">
       <i class="fa fa-home me-1"></i>Home
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLink="/issueCertificate/rsa" 
       routerLinkActive="active" (click)="collapseNavbar()">
       <i class="fa fa-id-card me-1"></i>RSA
    </a>
  </li>
  <li class="nav-item" *ngIf="user.specialRoleAccess && user.specialRoleAccess.includes('CanRenewAllPolicies')">
    <a class="nav-link" routerLink="/reports/renewCertificate" 
       routerLinkActive="active" (click)="collapseNavbar()">
       <i class="fa fa-refresh me-1"></i>Renew Certificate
    </a>
  </li>
</ul>
