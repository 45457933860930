import { CommonModule, DatePipe } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Certificate } from '@app/_models/certificate';
import { PrintInstruction } from '@app/_models/printInstruction';
import { User } from '@app/_models/user';
import { AlertService, AccountService } from '@app/_services';
import { CertificateService } from '@app/_services/certificate.service';
import { ConfigDataService } from '@app/_services/config-data.service';
import { DealerService } from '@app/_services/dealer-service';
import { QRCodeModule } from 'angularx-qrcode';
import jsPDF from 'jspdf';
@Component({
  selector: 'app-print',
  standalone: true,
  imports: [
    MatProgressSpinnerModule,
    MatButtonModule,
    CommonModule,
    QRCodeModule,
  ],
  templateUrl: './print.component.html',
  styleUrl: './print.component.less',
})
export class PrintComponent implements OnInit, AfterViewInit {
  isLoading = true;
  pdfSrc: SafeResourceUrl;
  qrCodeDownloadLink: SafeUrl = '';
  printCpaInsurancePolicy = true;
  certificate: Certificate;
  pdf: jsPDF;
  n: string[];
  xPosition: number = 0;
  yPosition: number = 0;
  marginTop: number = 10.89;
  marginStart: number = 10.14;
  marginEnd: number = 10.14;
  PAGE_WIDTH = 575;
  canSeePolicyCompanyLogo = false;
  spinnerColor = 'primary';
    selectedPrintInstruction = new PrintInstruction({
      "policyType": "default",
      "printPage": false,
      "printPage2": false,
      "printPage3": false,
      "printPageFinance": false,
      "policyCompany":"THE NEW INDIA ASSURANCE CO. LTD",
      "printPageCarCPA": false,
      "printOnlyFirstPage": false
    });
    showGstAmount: boolean;
    printRsaNumber: boolean;
    printCpaPolicy: boolean;
    pdfDataUri: any;
    isInitCall: boolean;
    user: User;
    constructor( private certificateService: CertificateService, private configDataService: ConfigDataService,
      private datepipe:DatePipe, private dealerService: DealerService, private alertService: AlertService,
       private accountService: AccountService,private sanitizer: DomSanitizer, private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.spinnerSetInterval()
    this.certificate = new Certificate(this.certificateService.PrintCertificate)
    this.canSeePolicyCompanyLogo =  (this.accountService.userValue.specialRoleAccess && this.accountService.userValue.specialRoleAccess.includes('canSeePolicyCompanyLogo'));
    this.configDataService.getPrintInstructionList().subscribe((data) => {
      this.selectedPrintInstruction = data.find(x => x.policyType == this.certificate.policyType);
      this.showGstAmount = this.certificate.gstApplicable ? this.certificate.gstApplicable =="N" ? false : true : true; 
      this.showGstAmount = this.showGstAmount == false? false : this.certificate.gstShow ? this.certificate.gstShow =="N" ? false : true : true; 
      this.printRsaNumber = this.certificate.printRsaNumber?this.certificate.printRsaNumber == "Yes"?true:false:true;  
      this.printCpaPolicy = this.certificate.printCpaPolicy?this.certificate.printCpaPolicy == "Yes"?true:false:true;
      this.user = this.accountService.userValue;  
      this.isLoading = true;
      this.pdf = new jsPDF('portrait',"pt","a4",true);
    if (this.certificate.onePager){
      this.onePagerPrint()
    }
    else{
      this.twoPagerPrint()
    }
    if(this.certificate.showSummaryPage){
      this.summaryPage()
    }
    this.loadPdfToMyframe();
    });
  }
  ngAfterViewInit(): void {

  }
  onePagerPrint(){
    switch (true)
    {
      case this.certificate.policyType.includes("HOS"):
        this.cpaHosDocCertificate();
        this.paCoverSection();
        break;
      case this.certificate.policyType.startsWith("CPA + RSA + DOC") :
        this.cpaRsaDocCertificateOnePager();
        break;
      case this.certificate.policyType.startsWith("CPA + RSA") :
          this.onlyRsaOnePager();
          this.paCoverSection();
      break;
      case this.certificate.policyType.startsWith("RSA (CAR)") :
        this.cpaRsaDocCertificateOnePager();
        break;
      case this.certificate.policyType.toUpperCase().startsWith("RSA EV") :
        this.rsaDocCertificateOnePager();
        break;
      case this.certificate.policyType.includes("RSA"):
        this.onlyRsaOnePager();
        break;
      case this.certificate.policyType.includes("CPA + DOC") || (this.certificate.gstShow && this.certificate.gstShow == "N" && this.certificate.policyType.includes("PA")):
        this.cpaDocCertificateOnePager();
        break;
      default:
        this.cpaCertificateOnePager()
        break;
    }
  }
  cpaDocCertificateOnePager(){
    this.xPosition = this.marginStart;
    this.yPosition = this.marginTop + 10;
    this.pdf.addImage('assets/AllianceLogo.png','PNG',this.xPosition,this.yPosition,100,50,'FAST');
    this.yPosition += 50 + 10;
    this.processHeader("Tax Invoice cum Certificate Number: " + this.certificate.certificateNumber,24)
    this.yPosition += 2;
    this.pdf.setFillColor('#FFFFFF');
    this.pdf.rect(this.xPosition,this.yPosition,380,42,'DF');
    this.pdf.rect(this.xPosition + 380,this.yPosition,195,42,'DF');
    this.pdf.setTextColor('#000000').setFontSize(8).setFont('helvetica','bold');
    this.pdf.text("Corporate Address: Alliance Assure Private Limited",this.xPosition + 4,this.yPosition + 10,{align:'left'});
    this.pdf.text("For Assistance, Please contact us at:",this.xPosition + 4 + 380,this.yPosition + 10,{align:'left'});
    this.pdf.setTextColor('#000000').setFontSize(8).setFont('helvetica','normal');
    this.pdf.text("#29,HSVP Shopping Complex, Sector 39,",this.xPosition + 4,this.yPosition + 20,{align:'left'});
    this.pdf.text("Email ID: support@allianceassure.in",this.xPosition + 4 + 380,this.yPosition + 20,{align:'left'});
    this.pdf.text("Gurugram(Haryana)-122003,",this.xPosition + 4,this.yPosition + 30,{align:'left'});
    this.pdf.text("PH: 1800 212 7559, CIN: U74999HR2021PTC092448, GSTIN:06AAUCA6872D1ZC",this.xPosition + 4,this.yPosition + 40,{align:'left'});
    this.yPosition += 42;
    let formattedEndDate = this.datepipe.transform(new Date(this.certificate.endDate),'dd-MM-yyyy') + " MIDNIGHT" ;
    let formattedDate = this.datepipe.transform(new Date(this.certificate.issueDate),'dd-MM-yyyy hh:mm:ss a') ;
    this.firstTableRow("Effective Period: " + formattedDate + " - " + formattedEndDate,"IMD Code: " + this.certificate.dealerId);
    this.firstTableRow("Name of Certificate Holder: " + this.certificate.firstName + " " + (this.certificate.lastName?this.certificate.lastName:""),"Dealer: " + this.certificate.dealerId);
    if (this.certificate.ageOrDob && this.certificate.ageOrDob == 'age')
      {
        this.firstTableRow("Age: " + this.certificate.age,"Gender: " + this.certificate.gender)
      }
    else
    {
      this.firstTableRow("DOB: " + this.certificate.dob,"Gender: " + this.certificate.gender);
    }
    this.firstTableRow("Contact Number: " + this.certificate.contactNumber,"Email: " + this.certificate.email);
    this.pdf.setFillColor('#FFFFFF');
    this.pdf.rect(this.xPosition,this.yPosition,380,22,'DF');
    this.pdf.rect(this.xPosition + 380,this.yPosition,195,22,'DF');
    this.pdf.setTextColor('#000000').setFontSize(8).setFont('helvetica','bold');
    this.pdf.text("City/District: " + this.certificate.city,this.xPosition + 4 + 380,this.yPosition + 10,{align:'left'});
    this.certificate.address = this.certificate.address.slice(0,250);
    this.pdf.text(this.pdf.splitTextToSize("Address: " + this.certificate.address,360),this.xPosition + 4,this.yPosition + 10,{align:'left'});
    this.yPosition += 22
    this.firstTableRow("State: " + this.certificate.state,"Vehicle Type: " + this.certificate.vehicleType);
    this.firstTableRow(("Model/Varient: " + this.certificate.model +'/' +this.certificate.variant).slice(0,85),"OEM: " + this.certificate.vehicleMake);
    this.firstTableRow("Registration Number: " + this.certificate.registrationNumber,"Manufacturing Year: " + this.certificate.vehicleYear);
    this.firstTableRow("Engine Number: " + this.certificate.engineNumber,"Chasis Number: " + this.certificate.chasisNumber);
    this.yPosition += 10
    this.processHeader("Plan Features",21)
    this.pdf.setFillColor('#D1D1D1');
    this.pdf.rect(this.xPosition,this.yPosition,30,12,'DF');
    this.pdf.rect(this.xPosition +30,this.yPosition,130,12,'DF');
    this.pdf.rect(this.xPosition + 160,this.yPosition,340,12,'DF');
    this.pdf.rect(this.xPosition + 500,this.yPosition,75,12,'DF');
    this.pdf.setTextColor('#000000').setFontSize(10).setFont('helvetica','bold');
    this.pdf.text("S.No",this.xPosition + 4,this.yPosition + 10,{align:'left'});
    this.pdf.text("Benefits",this.xPosition + 34 + 65,this.yPosition + 10,{align:'center'});
    this.pdf.text("Description",this.xPosition + 164 + 170,this.yPosition + 10,{align:'center'});
    this.pdf.text("Value",this.xPosition + 500 + 40,this.yPosition + 10,{align:'center'});
    this.yPosition += 12;
    this.pdf.setTextColor('#000000').setFontSize(8).setFont('helvetica','normal');
    this.secondTableRow("1","Relay of urgent messages","Pass on message to Rider's friends, family","Yes");
    this.secondTableRow("2","Medical Assistance","Arranging for an ambulance/ hospital for Rider","Yes");
    this.secondTableRow("3","Hospital Appointment","Arranging appointment in the nearest available hospital","Yes");
    this.secondTableRow("4","Diagnostic Test Assistance","Arranging appointment in the nearest available diagnostic center","Yes");
    this.secondTableRow("5","Medicine delivery assistance","Arranging medicines from nearest available medical center","Yes");
    this.secondTableRow("6","Police assistance","Arranging contact details of nearest police station","Yes");
    this.secondTableRow("7","24*7 response center","24 by 7 response center available for client needs","Yes");
    this.secondTableRow("8","Taxi Assistance ","Arrange for taxi on Rider's / driver's request irrespective of breakdown location","Yes");
    this.secondTableRow("9","Hotel Assistance","Arrange for Hotel on Rider's / driver's request","Yes");
    this.secondTableRow("10","Complimentary insurance","Includes Complimentary personal accident insurance","Yes");
    this.secondTableRow("11","Number of Services","Proposed Number of Services ","3");
    this.yPosition += 5;
    this.pdf.text("Special Conditions (applicable to all coverages): (a) All additional expenses regarding any other service which does not form a part of the standard services ",this.xPosition ,this.yPosition + 10,{align:'left'});
    this.yPosition += 10;
    this.pdf.text("provided would be on chargeable basis to the insured.(b) This Certificate is valid subject to realisation of the payment and is effective from the Payment",this.xPosition ,this.yPosition + 10,{align:'left'});
    this.yPosition += 10;
    this.pdf.text(" realisation date or certificate issue date, whichever is later.",this.xPosition ,this.yPosition + 10,{align:'left'});
    this.yPosition += 22;
    if (this.showGstAmount)
      {
      this.amountTable();}
    else {this.processHeader("Plan Details",15);
    this.halfTableRow("Product Plan","PLAN " + this.certificate.planTotalAmount)}
    this.pdf.setTextColor('#000000').setFontSize(10).setFont('helvetica','bold');
    this.pdf.text("For Alliance Assure Pvt Ltd",this.xPosition + 570 ,this.yPosition + 8,{align:'right'});
    this.yPosition += 12
    this.pdf.addImage('assets/signature.png','PNG',this.xPosition + 470,this.yPosition,100,50);
    this.yPosition += 52;
    this.doctorOnCall();

    this.processHeader("Personal Accidental Cover Details",21)
    if (this.certificate.policyType && this.certificate.policyType.includes("Ifco"))
      {
        this.halfTableRow("Master Policy No: " + this.certificate.policyNumber + "-IFT","Master Policy Holder: Alliance Assure Private Limited" )
      }
    else
    {
      this.halfTableRow("Master Policy No: " + this.certificate.policyNumber,"Master Policy Holder: Alliance Assure Private Limited" )
    }
    this.halfTableRow("Name of Certificate Holder: " + this.certificate.firstName + " " + (this.certificate.lastName?this.certificate.lastName:""),"Period of Insurance:" + formattedDate + " - " + formattedEndDate)
    this.halfTableRow("Nominee Name: " + this.certificate.nomineeName,"Nominee Relationship: " + this.certificate.nomineeRelationship );
    this.halfTableRow("Insurer: " + this.selectedPrintInstruction.policyCompany,"Nominee Age/Gender: " + this.certificate.nomineeAge + "/" + this.certificate.nomineeGender);
    this.pdf.text("Special Conditions",this.xPosition ,this.yPosition + 10,{align:'left'});
    this.pdf.setTextColor('#000000').setFontSize(6).setFont('helvetica','bold');
    this.yPosition += 8;
    this.pdf.setTextColor('#000000').setFontSize(6).setFont('helvetica','normal');
    let message = ((this.certificate.planCPACoverAmount == 500000)?"1)Per individual SI":"1)Per individual SI is fixed Rs. ") + (this.inWords(this.certificate.planCPACoverAmount ? this.certificate.planCPACoverAmount: 1500000)).trim() + ((this.certificate.planCPACoverAmount == 500000)?" sum insured is fixed. ":".")  +" 2) Age Band - " + this.selectedPrintInstruction.minAgeAllowed + " to " + this.selectedPrintInstruction.maxAgeAllowed +" yrs. 3) Accidental Death(AD)- Covers Death due to Accident only. 4) We shall pay compensation for death, in direct connection with the vehicle cover for above Roadside Assistance Certificate and of which he / she is registered owner or whilst driving such registered vehicle or whilst travelling in it as a co-driver, caused by violent accidental external and visible means which independent of any other cause shall within six calendar months of such injury result in Death 100% CSI. 5) No compensation shall be payable in respect of death or bodily injury directly or indirectly wholly or in part arising or resulting from or traceable to -(a) Intentional self injury suicide or attempted suicide physical defect or infirmity or (b) An accident happening whilst such person is under the influence of intoxicating liquor or drugs (c) Death due to non adherence of Traffic rules. B) Such compensation shall be payable directly to his/her legal representatives. 6) This cover is subject to -(a) The Insured is the registered owner of the vehicle and has direct connection with his/her death. (b) The Insured holds a valid and effective driving licence, in accordance with the provisions of Section 3 of Motor Vehicle Act, 1988, at the time of the accident. 7) Any form of nuclear, chemical and biological Terrorism is excluded. 8) Warranted that claim should be intimated to an insurer within 30 days of date of accident/incident.If a claim is intimated after the defined timeline then it will be repudiated. 9) Scope of Cover - 24 Hrs, Within India only."
    this.printDisclaimerMessage(message);


    
  }

  cpaCertificateOnePager(){
    this.xPosition = this.marginStart;
    this.yPosition = this.marginTop + 10;
    this.pdf.addImage('assets/AllianceLogo.png','PNG',this.xPosition,this.yPosition,100,50,'FAST');
    this.yPosition += 50 + 10;
    this.processHeader("Tax Invoice cum Certificate Number: " + this.certificate.certificateNumber,24)
    this.yPosition += 2;
    this.pdf.setFillColor('#FFFFFF');
    this.pdf.rect(this.xPosition,this.yPosition,380,42,'DF');
    this.pdf.rect(this.xPosition + 380,this.yPosition,195,42,'DF');
    this.pdf.setTextColor('#000000').setFontSize(8).setFont('helvetica','bold');
    this.pdf.text("Corporate Address: Alliance Assure Private Limited",this.xPosition + 4,this.yPosition + 10,{align:'left'});
    this.pdf.text("For Assistance, Please contact us at:",this.xPosition + 4 + 380,this.yPosition + 10,{align:'left'});
    this.pdf.setTextColor('#000000').setFontSize(8).setFont('helvetica','normal');
    this.pdf.text("#29,HSVP Shopping Complex, Sector 39,",this.xPosition + 4,this.yPosition + 20,{align:'left'});
    this.pdf.text("Email ID: support@allianceassure.in",this.xPosition + 4 + 380,this.yPosition + 20,{align:'left'});
    this.pdf.text("Gurugram(Haryana)-122003,",this.xPosition + 4,this.yPosition + 30,{align:'left'});
    this.pdf.text("PH: 1800 212 7559, CIN: U74999HR2021PTC092448, GSTIN:06AAUCA6872D1ZC",this.xPosition + 4,this.yPosition + 40,{align:'left'});
    this.yPosition += 42;
    let formattedEndDate = this.datepipe.transform(new Date(this.certificate.endDate),'dd-MM-yyyy') + " MIDNIGHT" ;
    let formattedDate = this.datepipe.transform(new Date(this.certificate.issueDate),'dd-MM-yyyy hh:mm:ss a') ;
    this.firstTableRow("Effective Period: " + formattedDate + " - " + formattedEndDate,"IMD Code: " + this.certificate.dealerId);
    this.firstTableRow("Name of Certificate Holder: " + this.certificate.firstName + " " + (this.certificate.lastName?this.certificate.lastName:""),"Dealer: " + this.certificate.dealerId);
    if (this.certificate.ageOrDob && this.certificate.ageOrDob == 'age')
      {
        this.firstTableRow("Age: " + this.certificate.age,"Gender: " + this.certificate.gender)
      }
    else
    {
      this.firstTableRow("DOB: " + this.certificate.dob,"Gender: " + this.certificate.gender);
    }
    this.firstTableRow("Contact Number: " + this.certificate.contactNumber,"Email: " + this.certificate.email);
    this.pdf.setFillColor('#FFFFFF');
    this.pdf.rect(this.xPosition,this.yPosition,380,22,'DF');
    this.pdf.rect(this.xPosition + 380,this.yPosition,195,22,'DF');
    this.pdf.setTextColor('#000000').setFontSize(8).setFont('helvetica','bold');
    this.pdf.text("City/District: " + this.certificate.city,this.xPosition + 4 + 380,this.yPosition + 10,{align:'left'});
    this.certificate.address = this.certificate.address.slice(0,250);
    this.pdf.text(this.pdf.splitTextToSize("Address: " + this.certificate.address,360),this.xPosition + 4,this.yPosition + 10,{align:'left'});
    this.yPosition += 22
    this.firstTableRow("State: " + this.certificate.state,"Vehicle Type: " + this.certificate.vehicleType);
    this.firstTableRow(("Model/Varient: " + this.certificate.model +'/' +this.certificate.variant).slice(0,85),"OEM: " + this.certificate.vehicleMake);
    this.firstTableRow("Registration Number: " + this.certificate.registrationNumber,"Manufacturing Year: " + this.certificate.vehicleYear);
    this.firstTableRow("Engine Number: " + this.certificate.engineNumber,"Chasis Number: " + this.certificate.chasisNumber);
    this.yPosition += 10
    this.processHeader("Plan Features",21)
    this.pdf.setFillColor('#D1D1D1');
    this.pdf.rect(this.xPosition,this.yPosition,30,12,'DF');
    this.pdf.rect(this.xPosition +30,this.yPosition,130,12,'DF');
    this.pdf.rect(this.xPosition + 160,this.yPosition,340,12,'DF');
    this.pdf.rect(this.xPosition + 500,this.yPosition,75,12,'DF');
    this.pdf.setTextColor('#000000').setFontSize(10).setFont('helvetica','bold');
    this.pdf.text("S.No",this.xPosition + 4,this.yPosition + 10,{align:'left'});
    this.pdf.text("Benefits",this.xPosition + 34 + 65,this.yPosition + 10,{align:'center'});
    this.pdf.text("Description",this.xPosition + 164 + 170,this.yPosition + 10,{align:'center'});
    this.pdf.text("Value",this.xPosition + 500 + 40,this.yPosition + 10,{align:'center'});
    this.yPosition += 12;
    this.pdf.setTextColor('#000000').setFontSize(8).setFont('helvetica','normal');
    this.secondTableRow("1","Relay of urgent messages","Pass on message to Rider's friends, family","Yes");
    this.secondTableRow("2","Medical Assistance","Arranging for an ambulance/ hospital for Rider","Yes");
    this.secondTableRow("3","Hospital Appointment","Arranging appointment in the nearest available hospital","Yes");
    this.secondTableRow("4","Diagnostic Test Assistance","Arranging appointment in the nearest available diagnostic center","Yes");
    this.secondTableRow("5","Medicine delivery assistance","Arranging medicines from nearest available medical center","Yes");
    this.secondTableRow("6","Police assistance","Arranging contact details of nearest police station","Yes");
    this.secondTableRow("7","24*7 response center","24 by 7 response center available for client needs","Yes");
    this.secondTableRow("8","Taxi Assistance ","Arrange for taxi on Rider's / driver's request irrespective of breakdown location","Yes");
    this.secondTableRow("9","Hotel Assistance","Arrange for Hotel on Rider's / driver's request","Yes");
    this.secondTableRow("10","Complimentary insurance","Includes Complimentary personal accident insurance","Yes");
    this.secondTableRow("11","Number of Services","Proposed Number of Services ","3");
    this.yPosition += 5;
    this.pdf.text("Special Conditions (applicable to all coverages): (a) All additional expenses regarding any other service which does not form a part of the standard services ",this.xPosition ,this.yPosition + 10,{align:'left'});
    this.yPosition += 10;
    this.pdf.text("provided would be on chargeable basis to the insured.(b) This Certificate is valid subject to realisation of the payment and is effective from the Payment",this.xPosition ,this.yPosition + 10,{align:'left'});
    this.yPosition += 10;
    this.pdf.text(" realisation date or certificate issue date, whichever is later.",this.xPosition ,this.yPosition + 10,{align:'left'});
    this.yPosition += 22;
    this.processHeader("Payment Details",21)
    this.halfTableRow("Plan Amount",this.certificate.planAmount.toString());
    if (this.showGstAmount){
    this.halfTableRow("SGST(9%)",this.certificate.SgstAmount.toString());
    this.halfTableRow("CGST(9%)",this.certificate.CgstAmount.toString());
    this.halfTableRow("IGST(18%)",this.certificate.IgstAmount.toString());
    this.halfTableRow("Total",this.certificate.planTotalAmount.toString());
    }
    this.yPosition += 10
    this.pdf.setTextColor('#000000').setFontSize(10).setFont('helvetica','bold');
    this.pdf.text("For Alliance Assure Pvt Ltd",this.xPosition + 570 ,this.yPosition + 8,{align:'right'});
    this.yPosition += 12
    this.pdf.addImage('assets/signature.png','PNG',this.xPosition + 470,this.yPosition,100,50);
    this.yPosition += 52;
    this.yPosition += 10
    this.paCoverSection()
    
  }
  paCoverSection(){
    let formattedEndDate = this.datepipe.transform(new Date(this.certificate.endDate),'dd-MM-yyyy') + " MIDNIGHT" ;
    let formattedDate = this.datepipe.transform(new Date(this.certificate.issueDate),'dd-MM-yyyy hh:mm:ss a') ;
    this.processHeader("Personal Accidental Cover Details",21)
    if (this.certificate.policyType && this.certificate.policyType.includes("Ifco"))
      {
        this.halfTableRow("Master Policy No: " + this.certificate.policyNumber + "-IFT","Master Policy Holder: Alliance Assure Private Limited" )
      }
    else
    {
      this.halfTableRow("Master Policy No: " + this.certificate.policyNumber,"Master Policy Holder: Alliance Assure Private Limited" )
    }
    this.halfTableRow("Name of Certificate Holder: " + this.certificate.firstName + " " + (this.certificate.lastName?this.certificate.lastName:""),"Period of Insurance:" + formattedDate + " - " + formattedEndDate)
    this.halfTableRow("Nominee Name: " + this.certificate.nomineeName,"Nominee Relationship: " + this.certificate.nomineeRelationship );
    this.halfTableRow("Insurer: " + this.selectedPrintInstruction.policyCompany,"Nominee Age/Gender: " + this.certificate.nomineeAge + "/" + this.certificate.nomineeGender);
    this.pdf.text("Special Conditions",this.xPosition ,this.yPosition + 10,{align:'left'});
    this.pdf.setTextColor('#000000').setFontSize(6).setFont('helvetica','bold');
    this.yPosition += 8;
    this.pdf.setTextColor('#000000').setFontSize(6).setFont('helvetica','normal');
    let message = ((this.certificate.planCPACoverAmount == 500000)?"1)Per individual SI":"1)Per individual SI is fixed Rs. ") + (this.inWords(this.certificate.planCPACoverAmount ? this.certificate.planCPACoverAmount: 1500000)).trim() + ((this.certificate.planCPACoverAmount == 500000)?" sum insured is fixed. ":".")  +" 2) Age Band - " + this.selectedPrintInstruction.minAgeAllowed + " to " + this.selectedPrintInstruction.maxAgeAllowed + " yrs. 3) Accidental Death(AD)- Covers Death due to Accident only. 4) We shall pay compensation for death, in direct connection with the vehicle cover for above Roadside Assistance Certificate and of which he / she is registered owner or whilst driving such registered vehicle or whilst travelling in it as a co-driver, caused by violent accidental external and visible means which independent of any other cause shall within six calendar months of such injury result in Death 100% CSI. 5) No compensation shall be payable in respect of death or bodily injury directly or indirectly wholly or in part arising or resulting from or traceable to -(a) Intentional self injury suicide or attempted suicide physical defect or infirmity or (b) An accident happening whilst such person is under the influence of intoxicating liquor or drugs (c) Death due to non adherence of Traffic rules. B) Such compensation shall be payable directly to his/her legal representatives. 6) This cover is subject to -(a) The Insured is the registered owner of the vehicle and has direct connection with his/her death. (b) The Insured holds a valid and effective driving licence, in accordance with the provisions of Section 3 of Motor Vehicle Act, 1988, at the time of the accident. 7) Any form of nuclear, chemical and biological Terrorism is excluded. 8) Warranted that claim should be intimated to an insurer within 30 days of date of accident/incident.If a claim is intimated after the defined timeline then it will be repudiated. 9) Scope of Cover - 24 Hrs, Within India only."
    this.printDisclaimerMessage(message);


  }

  cpaRsaDocCertificateOnePager(){
    this.rsaDocCertificateOnePager();
    let formattedEndDate = this.datepipe.transform(new Date(this.certificate.endDate),'dd-MM-yyyy') + " MIDNIGHT" ;
    let formattedDate = this.datepipe.transform(new Date(this.certificate.issueDate),'dd-MM-yyyy hh:mm:ss a') ;
    this.processHeader("Personal Accidental Cover Details",15)
    if (this.certificate.policyType && this.certificate.policyType.includes("Ifco"))
      {
        this.halfTableRow("Master Policy No: " + this.certificate.policyNumber + "-IFT","Master Policy Holder: Alliance Assure Private Limited" )
      }
    else
    {
      this.halfTableRow("Master Policy No: " + this.certificate.policyNumber,"Master Policy Holder: Alliance Assure Private Limited" )
    }
    this.halfTableRow("Name of Certificate Holder: " + this.certificate.firstName + " " + (this.certificate.lastName?this.certificate.lastName:""),"Period of Insurance:" + formattedDate + " - " + formattedEndDate)
    this.halfTableRow("Nominee Name: " + this.certificate.nomineeName,"Nominee Relationship: " + this.certificate.nomineeRelationship );
    this.halfTableRow("Insurer: " + this.selectedPrintInstruction.policyCompany,"Nominee Age/Gender: " + this.certificate.nomineeAge + "/" + this.certificate.nomineeGender);
    this.pdf.text("Special Conditions",this.xPosition ,this.yPosition + 10,{align:'left'});
    this.pdf.setTextColor('#000000').setFontSize(6).setFont('helvetica','bold');
    this.yPosition += 8;
    this.pdf.setTextColor('#000000').setFontSize(6).setFont('helvetica','normal');

    let message = ((this.certificate.planCPACoverAmount == 500000)?"1)Per individual SI":"1)Per individual SI is fixed Rs. ") + (this.inWords(this.certificate.planCPACoverAmount ? this.certificate.planCPACoverAmount: 1500000)).trim() + ((this.certificate.planCPACoverAmount == 500000)?" sum insured is fixed. ":".")  +" 2) Age Band - " + this.selectedPrintInstruction.minAgeAllowed + " to " + this.selectedPrintInstruction.maxAgeAllowed + " yrs. 3) Accidental Death(AD)- Covers Death due to Accident only. 4) We shall pay compensation for death, in direct connection with the vehicle cover for above Roadside Assistance Certificate and of which he / she is registered owner or whilst driving such registered vehicle or whilst travelling in it as a co-driver, caused by violent accidental external and visible means which independent of any other cause shall within six calendar months of such injury result in Death 100% CSI. 5) No compensation shall be payable in respect of death or bodily injury directly or indirectly wholly or in part arising or resulting from or traceable to -(a) Intentional self injury suicide or attempted suicide physical defect or infirmity or (b) An accident happening whilst such person is under the influence of intoxicating liquor or drugs (c) Death due to non adherence of Traffic rules. B) Such compensation shall be payable directly to his/her legal representatives. 6) This cover is subject to -(a) The Insured is the registered owner of the vehicle and has direct connection with his/her death. (b) The Insured holds a valid and effective driving licence, in accordance with the provisions of Section 3 of Motor Vehicle Act, 1988, at the time of the accident. 7) Any form of nuclear, chemical and biological Terrorism is excluded. 8) Warranted that claim should be intimated to an insurer within 30 days of date of accident/incident.If a claim is intimated after the defined timeline then it will be repudiated. 9) Scope of Cover - 24 Hrs, Within India only."
    this.printDisclaimerMessage(message);
  }
  onlyRsaOnePager(){
    this.xPosition += this.marginStart;
    this.yPosition += this.marginTop;
    this.pdf.addImage('assets/AllianceLogo.png','PNG',this.xPosition,this.yPosition,100,50,'FAST');
    this.yPosition += 50 + 5;
    this.processHeader("Tax Invoice cum Certificate Number: " + this.certificate.certificateNumber,24)
    this.yPosition += 2;
    this.pdf.setFillColor('#FFFFFF');
    this.pdf.rect(this.xPosition,this.yPosition,380,42,'DF');
    this.pdf.rect(this.xPosition + 380,this.yPosition,195,42,'DF');
    this.pdf.setTextColor('#000000').setFontSize(8).setFont('helvetica','bold');
    this.pdf.text("Corporate Address: Alliance Assure Private Limited",this.xPosition + 4,this.yPosition + 10,{align:'left'});
    this.pdf.text("For Assistance, Please contact us at:",this.xPosition + 4 + 380,this.yPosition + 10,{align:'left'});
    this.pdf.setTextColor('#000000').setFontSize(8).setFont('helvetica','normal');
    this.pdf.text("#29,HSVP Shopping Complex, Sector 39,",this.xPosition + 4,this.yPosition + 20,{align:'left'});
    this.pdf.text("Email ID: support@allianceassure.in",this.xPosition + 4 + 380,this.yPosition + 20,{align:'left'});
    this.pdf.text("Gurugram(Haryana)-122003,",this.xPosition + 4,this.yPosition + 30,{align:'left'});
    this.pdf.text("PH: 1800 212 7559, CIN: U74999HR2021PTC092448, GSTIN:06AAUCA6872D1ZC",this.xPosition + 4,this.yPosition + 40,{align:'left'});
    this.yPosition += 42;
    let formattedEndDate = this.datepipe.transform(new Date(this.certificate.endDate),'dd-MM-yyyy') + " MIDNIGHT" ;
    let formattedDate = this.datepipe.transform(new Date(this.certificate.issueDate),'dd-MM-yyyy hh:mm:ss a') ;
    this.firstTableRow("Effective Period: " + formattedDate + " - " + formattedEndDate,"IMD Code: " + this.certificate.dealerId);
    this.firstTableRow("Name of Certificate Holder: " + this.certificate.firstName + " " + (this.certificate.lastName?this.certificate.lastName:""),"Dealer: " + this.certificate.dealerId);
    if (this.certificate.ageOrDob && this.certificate.ageOrDob == 'age')
      {
        this.firstTableRow("Age: " + this.certificate.age,"Gender: " + this.certificate.gender)
      }
    else
    {
      this.firstTableRow("DOB: " + this.certificate.dob,"Gender: " + this.certificate.gender);
    }
    this.firstTableRow("Contact Number: " + this.certificate.contactNumber,"Email: " + this.certificate.email);
    this.pdf.setFillColor('#FFFFFF');
    this.pdf.rect(this.xPosition,this.yPosition,380,22,'DF');
    this.pdf.rect(this.xPosition + 380,this.yPosition,195,22,'DF');
    this.pdf.setTextColor('#000000').setFontSize(8).setFont('helvetica','bold');
    this.pdf.text("City/District: " + this.certificate.city,this.xPosition + 4 + 380,this.yPosition + 10,{align:'left'});
    this.certificate.address = this.certificate.address.slice(0,250);
    this.pdf.text(this.pdf.splitTextToSize("Address: " + this.certificate.address,360),this.xPosition + 4,this.yPosition + 10,{align:'left'});

    this.yPosition += 22
    this.firstTableRow("State: " + this.certificate.state,"Vehicle Type: " + this.certificate.vehicleType);
    this.firstTableRow(("Model/Varient: " + this.certificate.model +'/' +this.certificate.variant).slice(0,85),"OEM: " + this.certificate.vehicleMake);
    this.firstTableRow("Registration Number: " + this.certificate.registrationNumber,"Manufacturing Year: " + this.certificate.vehicleYear);
    this.firstTableRow("Engine Number: " + this.certificate.engineNumber,"Chasis Number: " + this.certificate.chasisNumber);
    this.yPosition += 10
    this.processHeader("Coverages of Roadside Assistance - Toll Free Number - 1800 212 7559",15)
    this.pdf.setFillColor('#D1D1D1');
    this.pdf.rect(this.xPosition,this.yPosition,30,12,'DF');
    this.pdf.rect(this.xPosition +30,this.yPosition,130,12,'DF');
    this.pdf.rect(this.xPosition + 160,this.yPosition,340,12,'DF');
    this.pdf.rect(this.xPosition + 500,this.yPosition,75,12,'DF');
    this.pdf.setTextColor('#000000').setFontSize(10).setFont('helvetica','bold');
    this.pdf.text("S.No",this.xPosition + 4,this.yPosition + 10,{align:'left'});
    this.pdf.text("Benefits",this.xPosition + 34 + 65,this.yPosition + 10,{align:'center'});
    this.pdf.text("Description",this.xPosition + 164 + 170,this.yPosition + 10,{align:'center'});
    this.pdf.text("Value",this.xPosition + 500 + 40,this.yPosition + 10,{align:'center'});
    this.yPosition += 12;
    this.pdf.setTextColor('#000000').setFontSize(8).setFont('helvetica','normal');
    this.secondTableRow("1","Relay of urgent messages","Pass on message to Rider's friends, family","Yes");
    this.secondTableRow("2","Doctor Referral","Giving the contact details of nearest doctor to Rider","Yes");
    this.secondTableRow("3","Vehicle Breakdown-Phone Support ","Guiding the Rider on phone about vehicle related problems","Yes");
    this.secondTableRow("4","On Site Minor Repair","Arranging for a mechanic to do minor repairs on the spot","Yes");
    this.secondTableRow("5","Replacement of Keys","Arrange for pick-up and delivery of duplicate keys from Rider residence","Yes");
    this.secondTableRow("6","Lost Keys","Arrange for a locksmith or a technician to open the lock","Yes");
    this.secondTableRow("7","Fuel Delivery","Arrange for fuel delivery in case vehicle is out of fuel (Fuel cost on actual basis)","Yes");
    this.secondTableRow("8","Wrong Fueling","Arrange for tank cleaning or towing in case of wrong fueling","Yes");
    this.pdf.setFillColor('#FFFFFF');
    this.pdf.rect(this.xPosition,this.yPosition,30,52,'DF');
    this.pdf.rect(this.xPosition +30,this.yPosition,130,52,'DF');
    this.pdf.rect(this.xPosition + 160,this.yPosition,340,52,'DF');
    this.pdf.rect(this.xPosition + 500,this.yPosition,75,52,'DF');
    this.pdf.text("9",this.xPosition + 4,this.yPosition + 10,{align:'left'});
    this.pdf.text("Flat tyre Support",this.xPosition + 34 ,this.yPosition + 10,{align:'left'});
    let mesage = "Arrange for technician to change the tyre or get it repaired, Material/spare parts if required to repair the Vehicle (including repair of flat spare stepney tyre) will be borne by the Insured. Incase the spare tyre is not available in the covered Vehicle, the flat tyre will be taken to thenearest flat tyre repair shop for repairs and re-attached to the Vehicle.All incidental charges for the same shall be borne by the Insured."
    this.pdf.text(mesage.slice(0,98),this.xPosition + 164 ,this.yPosition + 10,{align:'left'});
    this.pdf.text(mesage.slice(98,195),this.xPosition + 164 ,this.yPosition + 20,{align:'left'});
    this.pdf.text(mesage.slice(195,290),this.xPosition + 164 ,this.yPosition + 30,{align:'left'});
    this.pdf.text(mesage.slice(290,390),this.xPosition + 164 ,this.yPosition + 40,{align:'left'});
    this.pdf.text(mesage.slice(390,432),this.xPosition + 164 ,this.yPosition + 50,{align:'left'});
    this.pdf.text("Yes",this.xPosition + 495 + 40 ,this.yPosition + 10,{align:'center'});
    this.yPosition += 52
    this.secondTableRow("10","Battery Jump-Start","A technician to be arranged for battery jumpstart","Yes");
    this.secondTableRow("11","Mechanical & elec. breakdown","Arrange for towing in case of any mechanical breakdowns","Yes");
    this.secondTableRow("12","Towing for accidental cases","In case of accident arrange for towing to send it to repair garage","Yes");
    this.secondTableRow("13","Taxi Assistance ","Arrange for taxi on Rider's / driver's request irrespective of breakdown location","Yes");
    this.secondTableRow("14","Hotel Assistance","Arrange for Hotel on Rider's / driver's request","Yes");
    this.secondTableRow("15","Medical Assistance","Arranging for an ambulance/ hospital for Rider","Yes");
    this.secondTableRow("16","Vehicle Custody Services","Take custody of vehicle in case Rider cannot attend the vehicle ","Yes");
    this.pdf.setFillColor('#FFFFFF');
    this.pdf.rect(this.xPosition,this.yPosition,30,22,'DF');
    this.pdf.rect(this.xPosition +30,this.yPosition,130,22,'DF');
    this.pdf.rect(this.xPosition + 160,this.yPosition,340,22,'DF');
    this.pdf.rect(this.xPosition + 500,this.yPosition,75,22,'DF');
    this.pdf.text("17",this.xPosition + 4,this.yPosition + 10,{align:'left'});
    this.pdf.text("Programme Start Date",this.xPosition + 34 ,this.yPosition + 10,{align:'left'});
    this.pdf.text("The date of commencement of coverage under the program.",this.xPosition + 164 ,this.yPosition + 10,{align:'left'});
    this.pdf.text("The program start date will be after 7 days from the program purchase date.",this.xPosition + 164 ,this.yPosition + 20,{align:'left'});
    this.pdf.text("After 7 Days",this.xPosition + 495 + 40 ,this.yPosition + 10,{align:'center'});
    this.yPosition += 22;
    this.secondTableRow("18","Number of Services","Proposed Number of Services ","4");
    this.yPosition += 5;
    this.pdf.text("Special Conditions (applicable to all coverages): (a) All additional expenses regarding replacement of a part, additional Fuel and any other service which does not ",this.xPosition ,this.yPosition + 10,{align:'left'});
    this.yPosition += 10;
    this.pdf.text("form a part of the standard services provided would be on chargeable basis to the insured.(b) This Certificate is valid subject to realisation of the payment and is",this.xPosition ,this.yPosition + 10,{align:'left'});
    this.yPosition += 10;
    this.pdf.text("effective from the Payment realisation date or certificate issue date, whichever is later.",this.xPosition ,this.yPosition + 10,{align:'left'});
    this.yPosition += 12;
    if (this.showGstAmount)
    {
    this.amountTable();}
  else {this.processHeader("Plan Details",15);
  this.halfTableRow("Product Plan","PLAN " + this.certificate.planTotalAmount)}

    this.pdf.setTextColor('#000000').setFontSize(6).setFont('helvetica','bold');
    this.pdf.text("For Alliance Assure Pvt Ltd",this.xPosition + 570 ,this.yPosition + 8,{align:'right'});
    this.yPosition += 10
    this.pdf.addImage('assets/signature.png','PNG',this.xPosition + 520,this.yPosition,50,25);
    this.yPosition += 27;
  }

  rsaDocCertificateOnePager(){
    this.onlyRsaOnePager()
    this.processHeader("Doctor On Call/Chat",15)
    this.pdf.setTextColor('#000000').setFontSize(6).setFont('helvetica','normal');
    this.pdf.text("Free Doctor Chat - Opt for a voice/video call. Get Online Prescriptions from them. Doctor over a Phone Call - You can speak to our specialist over a regular voice call too.Artificial Intelligence Healthcare Assistant - ",this.xPosition ,this.yPosition + 8,{align:'left'});
    this.yPosition += 8
    this.pdf.text("Connect with our easy to use our Artificial Intelligence-powered healthcare assistant who is always available at your fingertips.*T&C applicable - Only one service under this certificate applicable. All Doctor on Call/Chat ",this.xPosition ,this.yPosition + 8,{align:'left'});
    this.yPosition += 8
    this.pdf.text("Services may not be available in your area. Doctor chat/call will get activated after 2 days of registration at 1800 212 7559",this.xPosition ,this.yPosition + 8,{align:'left'});
    this.yPosition += 10
    }
    cpaHosDocCertificate(){
    this.xPosition = this.marginStart;
    this.yPosition = this.marginTop + 10;
    this.pdf.addImage('assets/AllianceLogo.png','PNG',this.xPosition,this.yPosition,100,50,'FAST');
    this.yPosition += 50 + 10;
    this.processHeader("Tax Invoice cum Certificate Number: " + this.certificate.certificateNumber,24)
    this.yPosition += 2;
    this.pdf.setFillColor('#FFFFFF');
    this.pdf.rect(this.xPosition,this.yPosition,380,42,'DF');
    this.pdf.rect(this.xPosition + 380,this.yPosition,195,42,'DF');
    this.pdf.setTextColor('#000000').setFontSize(8).setFont('helvetica','bold');
    this.pdf.text("Corporate Address: Alliance Assure Private Limited",this.xPosition + 4,this.yPosition + 10,{align:'left'});
    this.pdf.text("For Assistance, Please contact us at:",this.xPosition + 4 + 380,this.yPosition + 10,{align:'left'});
    this.pdf.setTextColor('#000000').setFontSize(8).setFont('helvetica','normal');
    this.pdf.text("#29,HSVP Shopping Complex, Sector 39,",this.xPosition + 4,this.yPosition + 20,{align:'left'});
    this.pdf.text("Email ID: support@allianceassure.in",this.xPosition + 4 + 380,this.yPosition + 20,{align:'left'});
    this.pdf.text("Gurugram(Haryana)-122003,",this.xPosition + 4,this.yPosition + 30,{align:'left'});
    this.pdf.text("PH: 1800 212 7559, CIN: U74999HR2021PTC092448, GSTIN:06AAUCA6872D1ZC",this.xPosition + 4,this.yPosition + 40,{align:'left'});
    this.yPosition += 42;
    let formattedEndDate = this.datepipe.transform(new Date(this.certificate.endDate),'dd-MM-yyyy') + " MIDNIGHT" ;
    let formattedDate = this.datepipe.transform(new Date(this.certificate.issueDate),'dd-MM-yyyy hh:mm:ss a') ;
    this.firstTableRow("Effective Period: " + formattedDate + " - " + formattedEndDate,"IMD Code: " + this.certificate.dealerId);
    this.firstTableRow("Name of Certificate Holder: " + this.certificate.firstName + " " + (this.certificate.lastName?this.certificate.lastName:""),"Dealer: " + this.certificate.dealerId);
    if (this.certificate.ageOrDob && this.certificate.ageOrDob == 'age')
      {
        this.firstTableRow("Age: " + this.certificate.age,"Gender: " + this.certificate.gender)
      }
    else
    {
      this.firstTableRow("DOB: " + this.certificate.dob,"Gender: " + this.certificate.gender);
    }
    this.firstTableRow("Contact Number: " + this.certificate.contactNumber,"Email: " + this.certificate.email);
    this.pdf.setFillColor('#FFFFFF');
    this.pdf.rect(this.xPosition,this.yPosition,380,22,'DF');
    this.pdf.rect(this.xPosition + 380,this.yPosition,195,22,'DF');
    this.pdf.setTextColor('#000000').setFontSize(8).setFont('helvetica','bold');
    this.pdf.text("City/District: " + this.certificate.city,this.xPosition + 4 + 380,this.yPosition + 10,{align:'left'});
    this.certificate.address = this.certificate.address.slice(0,250);
    this.pdf.text(this.pdf.splitTextToSize("Address: " + this.certificate.address,360),this.xPosition + 4,this.yPosition + 10,{align:'left'});
    this.yPosition += 22
    this.firstTableRow("State: " + this.certificate.state,"Vehicle Type: " + this.certificate.vehicleType);
    this.firstTableRow(("Model/Varient: " + this.certificate.model +'/' +this.certificate.variant).slice(0,85),"OEM: " + this.certificate.vehicleMake);
    this.firstTableRow("Registration Number: " + this.certificate.registrationNumber,"Manufacturing Year: " + this.certificate.vehicleYear);
    this.firstTableRow("Engine Number: " + this.certificate.engineNumber,"Chasis Number: " + this.certificate.chasisNumber);
    this.yPosition += 10
    this.processHeader("Plan Features",21)
    this.pdf.setFillColor('#D1D1D1');
    this.pdf.rect(this.xPosition,this.yPosition,30,12,'DF');
    this.pdf.rect(this.xPosition +30,this.yPosition,130,12,'DF');
    this.pdf.rect(this.xPosition + 160,this.yPosition,340,12,'DF');
    this.pdf.rect(this.xPosition + 500,this.yPosition,75,12,'DF');
    this.pdf.setTextColor('#000000').setFontSize(10).setFont('helvetica','bold');
    this.pdf.text("S.No",this.xPosition + 4,this.yPosition + 10,{align:'left'});
    this.pdf.text("Benefits",this.xPosition + 34 + 65,this.yPosition + 10,{align:'center'});
    this.pdf.text("Description",this.xPosition + 164 + 170,this.yPosition + 10,{align:'center'});
    this.pdf.text("Value",this.xPosition + 500 + 40,this.yPosition + 10,{align:'center'});
    this.yPosition += 12;
    this.pdf.setTextColor('#000000').setFontSize(8).setFont('helvetica','normal');
    this.secondTableRow("1","Relay of urgent messages","Pass on message to Rider's friends, family","Yes");
    this.secondTableRow("2","Medical Assistance","Arranging for an ambulance/ hospital for Rider","Yes");
    this.secondTableRow("3","Hospital Appointment","Arranging appointment in the nearest available hospital","Yes");
    this.secondTableRow("4","Diagnostic Test Assistance","Arranging appointment in the nearest available diagnostic center","Yes");
    this.secondTableRow("5","Medicine delivery assistance","Arranging medicines from nearest available medical center","Yes");
    this.secondTableRow("6","Police assistance","Arranging contact details of nearest police station","Yes");
    this.secondTableRow("7","24*7 response center","24 by 7 response center available for client needs","Yes");
    this.secondTableRow("8","Taxi Assistance ","Arrange for taxi on Rider's / driver's request irrespective of breakdown location","Yes");
    this.secondTableRow("9","Hotel Assistance","Arrange for Hotel on Rider's / driver's request","Yes");
    this.secondTableRow("10","Complimentary insurance","Includes Complimentary personal accident insurance","Yes");
    this.secondTableRow("11","Number of Services","Proposed Number of Services ","3");
    this.yPosition += 5;
    this.pdf.text("Special Conditions (applicable to all coverages): (a) All additional expenses regarding any other service which does not form a part of the standard services ",this.xPosition ,this.yPosition + 10,{align:'left'});
    this.yPosition += 10;
    this.pdf.text("provided would be on chargeable basis to the insured.(b) This Certificate is valid subject to realisation of the payment and is effective from the Payment",this.xPosition ,this.yPosition + 10,{align:'left'});
    this.yPosition += 10;
    this.pdf.text(" realisation date or certificate issue date, whichever is later.",this.xPosition ,this.yPosition + 10,{align:'left'});
    this.yPosition += 22;
    if (this.showGstAmount)
      {
      this.amountTable();}
    else {this.processHeader("Plan Details",15);
    this.halfTableRow("Product Plan","PLAN " + this.certificate.planTotalAmount)}
    this.pdf.setTextColor('#000000').setFontSize(10).setFont('helvetica','bold');
    this.pdf.text("For Alliance Assure Pvt Ltd",this.xPosition + 570 ,this.yPosition + 8,{align:'right'});
    this.yPosition += 12
    this.pdf.addImage('assets/signature.png','PNG',this.xPosition + 470,this.yPosition,100,50);
    this.yPosition += 52;

    this.hospitalCash();
    this.doctorOnCall();

    
  }
  hospitalCash(){
    this.processHeader("Accidental Hospital Daily Cash",15);
    this.pdf.setTextColor('#000000').setFontSize(6).setFont('helvetica','normal');
    this.yPosition += 8
    var lines = this.pdf.splitTextToSize("ADHC Benefits:Fixed amount per day of hospitalisation in direct connection with above mentioned vehicle of which he/she is registered owner and whilst driving or whilst travelling in it as a co-driver, caused by violent accidental external and visible means upto a maximum number of 10 days in a policy year. Multiple claims during the policy year upto a maximum of 10 days. Entry Age:Minimum 18 Years to 65 years.To avail 'Accidental Hospital Daily Cash' benefit minimum 24 hours hospitalisation is mandatory",this.PAGE_WIDTH);
    this.pdf.text(lines,this.xPosition,this.yPosition,{align:'left'});
    this.yPosition += (lines.length - 1) * 8;
    this.halfTableRow('Coverage Amount - Rs. 1000 per day',' Maximum Number of days - 10')
    this.pdf.setTextColor('#000000').setFontSize(6).setFont('helvetica','normal');
    this.pdf.text("For AHDC: Please reach out: Alliance Assure Pvt Ltd, Website: www.allianceassure.com, Email: help@allianceassure.in",this.xPosition ,this.yPosition + 8,{align:'left'});
    this.yPosition += 10;
  }

  twoPagerPrint(){
    switch (true)
    {
      case this.certificate.policyType.includes("HOS"):
        this.cpaHosDocCertificate();
        break;
      case this.certificate.policyType.startsWith("CPA + RSA + DOC") :
        this.cpaRsaDocPagePrint();
        break;
      case this.certificate.policyType.startsWith("CPA + RSA") :
          this.cpaRsaPagePrint();
      break;
      case this.certificate.policyType.startsWith("RSA (CAR)") :
        this.cpaRsaDocPagePrint();
        break;
      case this.certificate.policyType.toUpperCase().startsWith("RSA EV") :
        this.rsaDocCertificateOnePager();
        break;
      case this.certificate.policyType.includes("RSA"):
        this.cpaRsaPagePrint();
        break;
      case this.certificate.policyType.includes("CPA + DOC") || (this.certificate.gstShow && this.certificate.gstShow == "N" && this.certificate.policyType.includes("PA")):
        this.cpaDocPagePrint();
        break;
      default:
        this.cpaPagePrint()
        break;
    }

  
    this.printIfcoTokioCPAPage();
  }
  cpaPagePrint(){
    this.cpaHeader();
    this.yPosition += 10;
    this.processHeader("Certificate Details",18);
    this.firstTableCPARow("Tax Invoice Number",this.certificate.certificateNumber)
    this.firstTableCPARow("Plan Name",(this.certificate.planId=="Flexible" ? this.certificate.planTotalAmount: this.certificate.planId).toString())
    console.log(this.certificate.endDate)
    let formattedEndDate = this.datepipe.transform(new Date(this.certificate.endDate),'MMM dd, YYYY') + " - MIDNIGHT" ;
    let formattedDate = this.datepipe.transform(new Date(this.certificate.issueDate),'dd-MM-yyyy hh:mm:ss a') ;

    this.firstTableCPARow("Certificate Start Date",formattedDate);
    this.firstTableCPARow("Certificate End Date",formattedEndDate);
    this.firstTableCPARow("Certificate Issue Date",this.datepipe.transform(new Date(this.certificate.issueDate),'MMM dd, YYYY'));
    this.firstTableCPARow("SAC Code",this.certificate.dealerId);
    this.yPosition += 10;
    this.processHeader("Vehicle Details",18);

    this.firstTableCPARow("Vehicle Registration Number",this.certificate.registrationNumber);
    this.firstTableCPARow("OEM",this.certificate.vehicleMake);
    this.firstTableCPARow("Model",this.certificate.model);
    this.firstTableCPARow("Variant",this.certificate.variant);
    this.firstTableCPARow("Engine Number",this.certificate.engineNumber);
    this.firstTableCPARow("Chassis Number",this.certificate.chasisNumber);
    this.yPosition += 10;
    this.processHeader("Personal Details",18);
    this.firstTableCPARow("Customer Name",this.certificate.firstName + " " + (this.certificate.lastName?this.certificate.lastName:""));
    this.firstTableCPARow("Mobile",this.certificate.contactNumber);
    this.firstTableCPARow("Address",this.certificate.address.slice(0,77));
    this.firstTableCPARow("City-State",this.certificate.city + " - " + this.certificate.state);
    this.yPosition += 10;
    this.processHeader("Plan Features",18);
    this.pdf.setFillColor('#D1D1D1');
    this.pdf.rect(this.xPosition,this.yPosition,30,12,'DF');
    this.pdf.rect(this.xPosition +30,this.yPosition,130,12,'DF');
    this.pdf.rect(this.xPosition + 160,this.yPosition,340,12,'DF');
    this.pdf.rect(this.xPosition + 500,this.yPosition,75,12,'DF');
    this.pdf.setTextColor('#000000').setFontSize(10).setFont('helvetica','bold');
    this.pdf.text("S.No",this.xPosition + 4,this.yPosition + 10,{align:'left'});
    this.pdf.text("Benefits",this.xPosition + 34 + 65,this.yPosition + 10,{align:'center'});
    this.pdf.text("Description",this.xPosition + 164 + 170,this.yPosition + 10,{align:'center'});
    this.pdf.text("Value",this.xPosition + 500 + 40,this.yPosition + 10,{align:'center'});
    this.yPosition += 12;
    this.pdf.setTextColor('#000000').setFontSize(8).setFont('helvetica','normal');
    this.secondTableRow("1","Relay of urgent messages","Pass on message to Rider's friends/family","Yes");
    this.secondTableRow("2","Doctor Referral","Giving the contact details of nearest doctor to Rider","Yes");
    this.secondTableRow("3","Hotel Assistance","Arrange for Hotel on Rider's / driver’s request","Yes");
    this.secondTableRow("4","Medical Assistance","Arranging for an ambulance/ hospital for Rider","Yes");
    this.secondTableRow("5","Programme Start Date","The date of commencement of coverage under the program.","As Mentioned");
    this.secondTableRow("6","Number of Services","Proposed Number of Services","2");
    this.yPosition += 15;
    var lines = this.pdf.splitTextToSize("Special Conditions (applicable to all coverage): Complimentary Personal Accident Insurance-Yes. T&C applicable - This Certificate is valid subject to realization of the payment and is effective from the Payment realization date or certificate issue date, whichever is later. For EV RSA services are limited as per T&C. Only assistance services, actual cost to be borne by the customer. Vehicle to be used for personal purpose not for commercial. Limitation as to use: The policy covers use of the vehicle for any purpose other than: a) Hire or Reward b) Carriage of goods(other than samples or personal luggage) c) Organized Racing d) Pace Making e) Speed testing f) Reliability trials g) Any purpose in connection with motor trade.The relay of urgent messages and arrangement of ambulance services are exclusively (only) covered under the RSA program",this.PAGE_WIDTH);
    this.pdf.text(lines,this.xPosition,this.yPosition,{align:'left'});
    this.yPosition += lines.length * 10;
    this.yPosition += 10;
    this.amountTable();
    this.yPosition += 7
    this.pdf.setTextColor('#000000').setFontSize(8).setFont('helvetica','bold');
    this.pdf.text("For Alliance Assure Pvt Ltd",this.xPosition + 570 ,this.yPosition + 8,{align:'right'});
    this.yPosition += 12
    this.pdf.addImage('assets/signature.png','PNG',this.xPosition + 470,this.yPosition,100,50);
    this.yPosition += 52;
    this.pdf.text("Authorised Signatory",this.xPosition + 570 ,this.yPosition + 8,{align:'right'});
    this.yPosition += 17;

  }
  cpaDocPagePrint(){
    this.cpaPagePrint();
    this.doctorOnCall();
  }
  rsaEvPagePrint(){
    this.cpaHeader()
    let formattedEndDate = this.datepipe.transform(new Date(this.certificate.endDate),'dd-MM-yyyy') + " MIDNIGHT" ;
    let formattedDate = this.datepipe.transform(new Date(this.certificate.issueDate),'dd-MM-yyyy hh:mm:ss a') ;
    this.firstTableRow("Tax Invoice Number:  " + this.certificate.certificateNumber,"Plan Type: " + this.certificate.planTotalAmount);
    this.firstTableRow("Effective Period: " + formattedDate + " - " + formattedEndDate,"IMD Code: " + this.certificate.dealerId);
    this.firstTableRow("Name of Certificate Holder: " + this.certificate.firstName + " " + (this.certificate.lastName?this.certificate.lastName:""),"Dealer: " + this.certificate.dealerId);
    if (this.certificate.ageOrDob && this.certificate.ageOrDob == 'age')
      {
        this.firstTableRow("Age: " + this.certificate.age,"Gender: " + this.certificate.gender)
      }
    else
    {
      this.firstTableRow("DOB: " + this.certificate.dob,"Gender: " + this.certificate.gender);
    }
    this.firstTableRow("Contact Number: " + this.certificate.contactNumber,"Email: " + this.certificate.email);
    this.pdf.setFillColor('#FFFFFF');
    this.pdf.rect(this.xPosition,this.yPosition,380,22,'DF');
    this.pdf.rect(this.xPosition + 380,this.yPosition,195,22,'DF');
    this.pdf.setTextColor('#000000').setFontSize(8).setFont('helvetica','bold');
    this.pdf.text("City/District: " + this.certificate.city,this.xPosition + 4 + 380,this.yPosition + 10,{align:'left'});
    this.certificate.address = this.certificate.address.slice(0,150);
    this.pdf.text(this.pdf.splitTextToSize("Address: " + this.certificate.address,360),this.xPosition + 4,this.yPosition + 10,{align:'left'});
    this.yPosition += 22
    this.firstTableRow("State: " + this.certificate.state,"Vehicle Type: " + this.certificate.vehicleType);
    this.firstTableRow(("Model/Varient: " + this.certificate.model +'/' +this.certificate.variant).slice(0,85),"OEM: " + this.certificate.vehicleMake);
    this.firstTableRow("Registration Number: " + this.certificate.registrationNumber,"Manufacturing Year: " + this.certificate.vehicleYear);
    this.firstTableRow("Engine Number: " + this.certificate.engineNumber,"Chasis Number: " + this.certificate.chasisNumber);
    this.yPosition += 2
    this.pdf.setFillColor('#242B86');
    this.pdf.rect(this.xPosition,this.yPosition,this.PAGE_WIDTH,18,'DF');
    this.yPosition += 18 * 2/3;
    this.pdf.setTextColor('#D5CEA3').setFontSize(18/2).setFont('helvetica','bold');
    this.pdf.text("Plan Features",this.xPosition + 5,this.yPosition,{align:'left'});
    this.yPosition +=18/3;
    this.xPosition = this.marginStart;


  }
  cpaRsaPagePrint(){
    this.cpaHeader();
    this.rsaFormat();
    this.amountTable();
    this.pdf.setTextColor('#000000').setFontSize(8).setFont('helvetica','bold');
    this.pdf.text("For Alliance Assure Pvt Ltd",this.xPosition + 570 ,this.yPosition + 8,{align:'right'});
    this.yPosition += 12
    this.pdf.addImage('assets/signature.png','PNG',this.xPosition + 470,this.yPosition,100,50);
    this.yPosition += 52;
    this.pdf.text("Authorised Signatory",this.xPosition + 570 ,this.yPosition + 8,{align:'right'});
    }

  cpaRsaDocPagePrint(){
      this.cpaHeader();
      this.rsaFormat();
  this.amountTable();
  this.pdf.setTextColor('#000000').setFontSize(6).setFont('helvetica','bold');
  this.pdf.text("For Alliance Assure Pvt Ltd",this.xPosition + 570 ,this.yPosition + 8,{align:'right'});
  this.yPosition += 10
  this.pdf.addImage('assets/signature.png','PNG',this.xPosition + 520,this.yPosition,50,25);
  this.yPosition += 27;
  this.doctorOnCall()
  }
  rsaFormat(){
    let formattedEndDate = this.datepipe.transform(new Date(this.certificate.endDate),'dd-MM-yyyy') + " MIDNIGHT" ;
    let formattedDate = this.datepipe.transform(new Date(this.certificate.issueDate),'dd-MM-yyyy hh:mm:ss a') ;
    this.firstTableRow("Tax Invoice Number:  " + this.certificate.certificateNumber,"Plan Type: " + this.certificate.planTotalAmount);
    this.firstTableRow("Effective Period: " + formattedDate + " - " + formattedEndDate,"IMD Code: " + this.certificate.dealerId);
    this.firstTableRow("Name of Certificate Holder: " + this.certificate.firstName + " " + (this.certificate.lastName?this.certificate.lastName:""),"Dealer: " + this.certificate.dealerId);
    if (this.certificate.ageOrDob && this.certificate.ageOrDob == 'age')
      {
        this.firstTableRow("Age: " + this.certificate.age,"Gender: " + this.certificate.gender)
      }
    else
    {
      this.firstTableRow("DOB: " + this.certificate.dob,"Gender: " + this.certificate.gender);
    }
    this.firstTableRow("Contact Number: " + this.certificate.contactNumber,"Email: " + this.certificate.email);
    this.pdf.setFillColor('#FFFFFF');
    this.pdf.rect(this.xPosition,this.yPosition,380,22,'DF');
    this.pdf.rect(this.xPosition + 380,this.yPosition,195,22,'DF');
    this.pdf.setTextColor('#000000').setFontSize(8).setFont('helvetica','bold');
    this.pdf.text("City/District: " + this.certificate.city,this.xPosition + 4 + 380,this.yPosition + 10,{align:'left'});
    this.certificate.address = this.certificate.address.slice(0,150);
    this.pdf.text(this.pdf.splitTextToSize("Address: " + this.certificate.address,360),this.xPosition + 4,this.yPosition + 10,{align:'left'});
    this.yPosition += 22
    this.firstTableRow("State: " + this.certificate.state,"Vehicle Type: " + this.certificate.vehicleType);
    this.firstTableRow(("Model/Varient: " + this.certificate.model +'/' +this.certificate.variant).slice(0,85),"OEM: " + this.certificate.vehicleMake);
    this.firstTableRow("Registration Number: " + this.certificate.registrationNumber,"Manufacturing Year: " + this.certificate.vehicleYear);
    this.firstTableRow("Engine Number: " + this.certificate.engineNumber,"Chasis Number: " + this.certificate.chasisNumber);
    this.yPosition += 2
    this.pdf.setFillColor('#242B86');
    this.pdf.rect(this.xPosition,this.yPosition,this.PAGE_WIDTH,18,'DF');
    this.yPosition += 18 * 2/3;
    this.pdf.setTextColor('#D5CEA3').setFontSize(18/2).setFont('helvetica','bold');
    this.pdf.text("Plan Features",this.xPosition + 5,this.yPosition,{align:'left'});
    this.yPosition +=18/3;
    this.xPosition = this.marginStart;
    this.yPosition += 2
    this.processSubHeader("Breakdown support",15);
    this.yPosition += 9
    this.pdf.setTextColor('#000000').setFontSize(6).setFont('helvetica','normal');
    var lines:string[] = this.pdf.splitTextToSize("In the event Covered Vehicle breaks down due to a minor mechanical / electrical fault / accident and immediate repair on the spot is deemed possible within the scope of services, assist Customer by arranging for a vehicle technician to reach the breakdown location and will bear labour cost and conveyance costs. Cost of Material & Spare Parts if required to repair the vehicle on the spot and any other incidental conveyance to obtain such material & spare parts will be borne by the Customer",this.PAGE_WIDTH);
    this.pdf.text(lines,this.xPosition,this.yPosition,{align:'left'});
    this.yPosition += lines.length * 6;
    this.processSubHeader("Alternate Battery or Jump Start",15);
    this.yPosition += 9
    this.pdf.setTextColor('#000000').setFontSize(6).setFont('helvetica','normal');
    lines = this.pdf.splitTextToSize("In the event Covered Vehicle is immobilized, while on trip, due to rundown battery, assist the Customer by organizing for a Vehicle technician to jump start the Vehicle with appropriate means and will bear labour and conveyance costs. If the run down battery has to be replaced with a new battery, the cost of such battery replacement and any costs to obtain the battery will be borne by the Customer.",this.PAGE_WIDTH);
    this.pdf.text(lines,this.xPosition,this.yPosition,{align:'left'});
    this.yPosition += lines.length * 6;
    this.processSubHeader("Arrangement of fuel",15);
    this.yPosition += 9
    this.pdf.setTextColor('#000000').setFontSize(6).setFont('helvetica','normal');
    lines = this.pdf.splitTextToSize("In the event Covered Vehicle runs out of fuel and hence is immobilized while on a trip, assist Customer by organizing for a Vehicle technician to supply emergency fuel (up to 2 litres on a chargeable basis) at the location of breakdown and will bear labour and conveyance costs. The cost of the fuel will be borne by the customer.",this.PAGE_WIDTH);
    this.pdf.text(lines,this.xPosition,this.yPosition,{align:'left'});
    this.yPosition += lines.length * 6;
    this.processSubHeader("Tyre Change",15);
    this.yPosition += 9
    this.pdf.setTextColor('#000000').setFontSize(6).setFont('helvetica','normal');
    lines = this.pdf.splitTextToSize("In the event Covered Vehicle is immobilized due to a flat tyre, assist the Customer by organizing for a vehicle technician to replace the flat tyre with the spare stepney tyre of the Vehicle at the location of breakdown and will bear labour cost and round-trip conveyance costs of the provider. Material/spare parts if required to repair the Vehicle (including repair of flat spare stepney tyre) will be borne by the Customer. In case the spare tyre is not available in the covered Vehicle, the flat tyre will be taken to the nearest flat tyre repair shop for repairs & re-attached to the Vehicle. All incidental charges for the same shall be borne by the Customer",this.PAGE_WIDTH);
    this.pdf.text(lines,this.xPosition,this.yPosition,{align:'left'});
    this.yPosition += lines.length * 6;
    this.processSubHeader("Arrangement of spare keys",15);
    this.yPosition += 9
    this.pdf.setTextColor('#000000').setFontSize(6).setFont('helvetica','normal');
    lines = this.pdf.splitTextToSize("If the keys of the covered vehicle are locked inside the vehicle, broken, lost, or misplaced, (upon the request of the customer) will arrange for the forwarding of another set from his/her place of residence or office by courier / in person by hand-delivery to the location of the vehicle after receiving the requisite authorizations from the Customer with regards to the person designated to hand over the same. The Customer may be requested to submit an identity proof at the time of delivery of the keys",this.PAGE_WIDTH);
    this.pdf.text(lines,this.xPosition,this.yPosition,{align:'left'});
    this.yPosition += lines.length * 6;
    this.processSubHeader("Taxi Arrangements",15);
    this.yPosition += 9
    this.pdf.setTextColor('#000000').setFontSize(6).setFont('helvetica','normal');
    lines = this.pdf.splitTextToSize("In the event where the Customer’s vehicle is immobilized due to a breakdown and is towed to the authorized service centre, will assist the Customer by arranging for alternate mode of transport (Taxi) to continue the journey to the destination or return to Customer’s home up to the Covered Distance from the location of the breakdown. Taxi cost will be borne by the customer.",this.PAGE_WIDTH);
    this.pdf.text(lines,this.xPosition,this.yPosition,{align:'left'});
    this.yPosition += lines.length * 6;
    this.processSubHeader("Message relay to relatives/colleagues/emergency numbers",15);
    this.yPosition += 9
    this.pdf.setTextColor('#000000').setFontSize(6).setFont('helvetica','normal');
    lines = this.pdf.splitTextToSize("To take charge of relaying urgent messages relating to the breakdown to the authorized workshop and/or service contacts. When requested, will relay urgent messages on behalf of the Customers to a designated person of their choice.",this.PAGE_WIDTH);
    this.pdf.text(lines,this.xPosition,this.yPosition,{align:'left'});
    this.yPosition += lines.length * 6;
    this.processSubHeader("Hotel Arrangements",15);
    this.yPosition += 9
    this.pdf.setTextColor('#000000').setFontSize(6).setFont('helvetica','normal');
    lines = this.pdf.splitTextToSize("In the event where the Customer’s vehicle is immobilized due to a breakdown and is towed to the authorized service centre, assist the Customer by arranging for a stay. Hotel cost will be borne by the customer.",this.PAGE_WIDTH);
    this.pdf.text(lines,this.xPosition,this.yPosition,{align:'left'});
    this.yPosition += lines.length * 6;
    this.processSubHeader(this.certificate.policyType.includes('TowingNonAccident')?"Towing of Vehicle":"Towing of Vehicle on accident",15);
    this.yPosition += 9
    this.pdf.setTextColor('#000000').setFontSize(6).setFont('helvetica','normal');
    if (this.certificate.policyType.includes('TowingNonAccident')){
      lines = this.pdf.splitTextToSize("In the event Covered Vehicle suffers an accident or breakdown due to mechanical or electrical fault which cannot be repaired on the spot, Alliance Assure will assist in making arrangement for the Vehicle to be towed to the nearest Authorised Service Center, using tow trucks in the cities & corresponding covered area where available. Toll charges if any to be paid by the customer.(Free Towing To and From upto 40kms)",this.PAGE_WIDTH);
    }
    else
    {
    lines = this.pdf.splitTextToSize("In the event Covered Vehicle suffers an accident which cannot be repaired on the spot, Assist in making arrangement for the Vehicle to be towed to the nearest Authorised Service Center, using tow trucks in the cities & corresponding covered area where available. Only in accident cases.(Free Towing To and From upto 40kms)",this.PAGE_WIDTH);
    }
    this.pdf.text(lines,this.xPosition,this.yPosition,{align:'left'});
    this.yPosition += lines.length * 6;
    this.processSubHeader("Ambulance Assistance Service",15);
    this.yPosition += 9
    this.pdf.setTextColor('#000000').setFontSize(6).setFont('helvetica','normal');
    lines = this.pdf.splitTextToSize("PAN India Ambulance service availability with 24x7 dedicated helpline from the incident location till the nearest medical facility. Ambulance cost will be borne by the customer.",this.PAGE_WIDTH);
    this.pdf.text(lines,this.xPosition,this.yPosition,{align:'left'});
    this.yPosition += lines.length * 6;
    lines = this.pdf.splitTextToSize("Roadside assistance services will get activated after 7 days from issuing date. Special Conditions(applicable to all coverage):Vehicle to be used for personal purpose not for commercial." + (this.printCpaPolicy?"Complimentary Personal Accident Insurance-Yes.":"") + "T&C applicable -  This Certificate is valid subject to realization of the payment and is effective from the Payment realization date or certificate issue date, whichever is later.For EV RSA services are limited as per T&C",this.PAGE_WIDTH);
    this.pdf.text(lines,this.xPosition,this.yPosition,{align:'left'});
    this.yPosition += lines.length * 6;
    lines = this.pdf.splitTextToSize("Limitation as to use: The policy covers use of the vehicle for any purpose other than: a) Hire or Reward b) Carriage of goods(other than samples or personal luggage) c) Organized Racing d) Pace Making e) Speed testing f) Reliability trials g) Any purpose in connection with motor trade.",this.PAGE_WIDTH);
    this.pdf.text(lines,this.xPosition,this.yPosition,{align:'left'});
    this.yPosition += lines.length * 6;
    if (this.certificate.rsaNumberOfServices && this.certificate.rsaNumberOfServices > 0){
    lines = this.pdf.splitTextToSize("Total number of " + this.certificate.rsaNumberOfServices + " services covered under this period of 12 months.",this.PAGE_WIDTH);
    this.pdf.text(lines,this.xPosition,this.yPosition,{align:'left'});
    this.yPosition += lines.length * 6;
  }

  }

  cpaHeader(){
    this.xPosition = this.marginStart;
    this.yPosition = this.marginTop;
    this.pdf.addImage('assets/AllianceLogo.png','PNG',this.xPosition,this.yPosition,100,50,'FAST');
    this.pdf.setTextColor('#000000').setFontSize(12).setFont('helvetica','bold');
    this.yPosition += 50 + 5;
    this.processHeader("Alliance Assure - Certificate of Assistance",24)
    this.pdf.setTextColor('#000000').setFontSize(12).setFont('helvetica','bold');
    this.pdf.text("CERTIFICATE NUMBER - " + this.certificate.certificateNumber,this.PAGE_WIDTH/2  + 5,this.yPosition + 12,{align:'center'});
    this.yPosition += 20;
    this.pdf.setFillColor('#FFFFFF');
    this.pdf.setTextColor('#000000').setFontSize(8).setFont('helvetica','bold');
    this.pdf.rect(this.xPosition,this.yPosition,380,42,'DF');
    this.pdf.rect(this.xPosition + 380,this.yPosition,195,42,'DF');
    this.pdf.setTextColor('#000000').setFontSize(8).setFont('helvetica','bold');
    this.pdf.text("Corporate Address: Alliance Assure Private Limited",this.xPosition + 4,this.yPosition + 10,{align:'left'});
    this.pdf.text("For Assistance, Please contact us at:",this.xPosition + 4 + 380,this.yPosition + 10,{align:'left'});
    this.pdf.setTextColor('#000000').setFontSize(8).setFont('helvetica','normal');
    this.pdf.text("#29,HSVP Shopping Complex, Sector 39,",this.xPosition + 4,this.yPosition + 20,{align:'left'});
    this.pdf.text("Email ID: support@allianceassure.in",this.xPosition + 4 + 380,this.yPosition + 20,{align:'left'});
    this.pdf.text("Gurugram(Haryana)-122003,",this.xPosition + 4,this.yPosition + 30,{align:'left'});
    this.pdf.text("PH: 1800 212 7559, CIN: U74999HR2021PTC092448, GSTIN:06AAUCA6872D1ZC",this.xPosition + 4,this.yPosition + 40,{align:'left'});
    this.yPosition += 42;
  }

  printIfcoTokioCPAPage(){
    this.pdf.addPage();
    this.xPosition = this.marginStart;
    this.yPosition = this.marginTop;
    console.log("Printing Ifco Tokio CPAPage");
    if (!this.canSeePolicyCompanyLogo)
    {
      console.log(this.certificate.certificateNumber);
    }
    else
    {
    console.log(this.selectedPrintInstruction.policyCompanyImage)
      this.pdf.addImage(this.selectedPrintInstruction.policyCompanyImage, 'PNG', this.xPosition, this.yPosition, 150, 50, this.selectedPrintInstruction.policyCompany, 'FAST');

    }
    this.pdf.setTextColor('#000000').setFontSize(12).setFont('helvetica','bold');
    this.yPosition += 50 + 5;
    this.processHeader(this.selectedPrintInstruction.policyCompany,24)
    this.pdf.setTextColor('#000000').setFontSize(12).setFont('helvetica','bold');
    this.pdf.text("CERTIFICATE NUMBER - " + this.certificate.certificateNumber,this.PAGE_WIDTH/2,this.yPosition + 12,{align:'center'});
    this.yPosition += 20;
    this.pdf.setFillColor('#FFFFFF');
    this.pdf.setTextColor('#000000').setFontSize(8).setFont('helvetica','bold');
    this.firstTableRow("Master Policy No: " + this.certificate.policyNumber ,"Policy Holder: Alliance Assure Private Limited" );
    let formattedEndDate = this.datepipe.transform(new Date(this.certificate.endDate),'dd-MM-yyyy') + " MIDNIGHT" ;
    let formattedDate = this.datepipe.transform(new Date(this.certificate.issueDate),'dd-MM-yyyy hh:mm:ss a') ;

    this.firstTableRow("Effective Period: " + formattedDate + " - " + formattedEndDate,"Vehicle Type: " + (this.certificate.vehicleType?this.certificate.vehicleType: "Two Wheeler") );
    this.firstTableRow("Name of Certificate Holder: " + this.certificate.firstName + " " + (this.certificate.lastName?this.certificate.lastName:""),"Gender: " + this.certificate.gender);
    this.firstTableRow("Mobile:" + this.certificate.contactNumber,"EmailId: " + this.certificate.email);
    this.pdf.setFillColor('#FFFFFF');
    this.pdf.rect(this.xPosition,this.yPosition,380,22,'DF');
    this.pdf.rect(this.xPosition + 380,this.yPosition,195,22,'DF');
    this.pdf.setTextColor('#000000').setFontSize(8).setFont('helvetica','bold');
    this.pdf.text("City/District: " + this.certificate.city,this.xPosition + 4 + 380,this.yPosition + 10,{align:'left'});
    this.certificate.address = this.certificate.address.slice(0,300);
    this.pdf.text(this.pdf.splitTextToSize("Address: " + this.certificate.address,360),this.xPosition + 4,this.yPosition + 10,{align:'left'});
    this.yPosition += 22;
    this.firstTableRow("State:" + this.certificate.state, (this.certificate.ageOrDob && this.certificate.ageOrDob == 'age')? "Age: " + this.certificate.age:"DOB: " + this.certificate.dob)
    this.firstTableRow("Vehicle Manufacturer: " + this.certificate.vehicleMake, ("Model/Varient: " + this.certificate.model +'/' +this.certificate.variant).slice(0,43));
    this.firstTableRow("Engine Number: " + this.certificate.engineNumber,"Chasis Number: " + this.certificate.chasisNumber);
    this.firstTableRow("Nominee Name: " + this.certificate.nomineeName, "Nominee Relationship:" + this.certificate.nomineeRelationship);
    this.firstTableRow("Nominee Gender: " + this.certificate.nomineeGender, "Nominee Age:" + this.certificate.nomineeAge);
    this.yPosition += 10;
    this.pdf.setTextColor('#000000').setFontSize(8).setFont('helvetica','normal');
    this.pdf.text("Rs." + (this.certificate.planCPACoverAmount?this.certificate.planCPACoverAmount: 1500000).toLocaleString('en-IN') + "/- (Accidental Death). Cover is valid only while the insured is driving the vehicle covered under the Road Side Assistance plan mentioned above or",this.xPosition,this.yPosition,{align:'left'});
    this.yPosition += 10
    this.pdf.text("accompanied the vehicle covered under the Road Side Assistance plan mentioned above as a passenger.",this.xPosition,this.yPosition,{align:'left'});
    this.yPosition += 8
    this.processSubHeader("Restriction of Coverage",18);
    this.yPosition += 10
    this.pdf.setTextColor('#000000').setFontSize(8).setFont('helvetica','normal');
    this.pdf.text("Accidental Death: It is strictly restricted to Death due to accident, while the owner of vehicle driving the vehicle or accompanied the vehicle as a passenger and ",this.xPosition,this.yPosition,{align:'left'});
    this.yPosition += 10
    this.pdf.text("vehicle is registered with Alliance Assure. Coverage is applicable.",this.xPosition,this.yPosition,{align:'left'});
    this.yPosition += 10
    var lines:string[] = this.pdf.splitTextToSize("Coverage is applicable to persons with age " + this.selectedPrintInstruction.minAgeAllowed + " to " + this.selectedPrintInstruction.maxAgeAllowed + " years. Warranted that claim should be intimated to an insurer within 30 days of date of accident / incident. If a claim is intimated after the defined timeline then it will be repudiated",this.PAGE_WIDTH );
    this.pdf.text(lines,this.xPosition,this.yPosition,{align:'left'});
    this.yPosition += lines.length * 10;
    this.yPosition += 8
    this.processSubHeader("Coverage in Brief",18);
    this.yPosition += 10
    this.pdf.setTextColor('#000000').setFontSize(8).setFont('helvetica','normal');
    this.pdf.text("If the Insured person meets with an accident and sustains any bodily injury during the policy period which directly and independently of all other causes result in ",this.xPosition,this.yPosition,{align:'left'});
    this.yPosition += 10
    this.pdf.text("death / permanent total disablement within 12 months from the date of accident resulting solely and directly from accident then the company shall pay to the insured ",this.xPosition,this.yPosition,{align:'left'});
    this.yPosition += 10
    this.pdf.text("the sum set in the schedule to the insureds persons nominee,beneficiary or legal representative.",this.xPosition,this.yPosition,{align:'left'});
    this.yPosition += 10
    this.pdf.text("Accidental Death: 100% of cumulative Sum Insured (CSI).",this.xPosition,this.yPosition,{align:'left'});
    this.yPosition += 10
    this.pdf.text("Please refer to policy for detail information on Policy coverage",this.xPosition,this.yPosition,{align:'left'});
    this.yPosition += 8
    this.processSubHeader("Exclusions in Brief",18);
    this.yPosition += 10
    this.pdf.setTextColor('#000000').setFontSize(8).setFont('helvetica','normal');
    lines = this.pdf.splitTextToSize("• War, invasion, act of foreign enemy, hostilities (whether war be declared or not) civil war, rebellion, revolution, insurrection, mutiny military or usurped power, confiscation, seizure, capture, assault, restraint, nationalization, civil commotion or loot or pillage in connection Herewith. • Ionizing radiation or contamination by radioactivity from any nuclear fuel or from any nuclear waste from the combustion of nuclear fuel. For the purpose of this exclusion, combustion shall include any self-sustaining process of nuclear fission. • The radioactive, toxic, explosive or the hazardous properties of any nuclear assembly or nuclear component or nuclear weapons material. • Whilst engaging in Aviation or Ballooning whilst mounting into, dismounting from or traveling in any balloon or aircraft • Participation in any kind of motor speed contest (including trial, training and qualifying heats)  Accident resulting from violation of laws of land • This Insurance does not cover any loss, damage, cost or expense directly or indirectly arising out of -Biological or chemical contamination, Missiles, bombs, grenades, explosives • Services on duty with any Armed forces • Intentional self-injury, suicide, or attempted suicide • venereal diseases, aids or insanity • Whilst under the influence of Alcohol or insanity • Whilst under the influence of Alcohol or intoxicating liquor or drugs. • Medical or surgical treatment • Whilst committing any breach of law with criminal intent. • Child birth, pregnancy or other physical cause peculiar to the female sex • Terrorism / Terrorist Incident of whatsoever nature directly or indirectly caused by, resulting from or in connection with any act of terrorism regardless of any other cause or event contributing concurrently or in any other sequence to the loss. Please refer to policy for detail information on exclusions and other terms and conditions.",this.PAGE_WIDTH );
    this.pdf.text(lines,this.xPosition,this.yPosition,{align:'left'})
    this.yPosition += 108
    this.processSubHeader("Claims Process / Documentation",18);
    this.yPosition += 10
    this.pdf.setTextColor('#000000').setFontSize(8).setFont('helvetica','normal');
    this.pdf.text("Upon happening of any accident and/or injury which may give rise to a claim under this policy:",this.xPosition,this.yPosition,{align:'left'});
    this.yPosition += 10
    this.pdf.text(this.pdf.splitTextToSize("• You shall give the notice to our call centre immediately and also intimate in writing to info@allianceassure.in. In case of Death, written notice also of Death must, unless reasonable cause is shown, be given before internment/ cremation and in any case, within one calendar month after the Death. In the event of loss of sight or amputation of limbs, written notice thereof must also be given within one calendar month after such loss of sight or amputation.",this.PAGE_WIDTH),this.xPosition,this.yPosition,{align:'left'});
    this.yPosition += 28
    lines = this.pdf.splitTextToSize("• All certificates, information and evidence from a Medical Practitioner or otherwise required by us shall be provided by you.",this.PAGE_WIDTH);
    this.pdf.text(lines,this.xPosition,this.yPosition,{align:'left'});
    this.yPosition += lines.length * 10;
    lines = this.pdf.splitTextToSize("• On receipt of intimation from you regarding a claim under the policy, we are entitled to carry out examination and ascertain details and in the event of Death get the post-mortem examination done in respect of deceased person.",this.PAGE_WIDTH);
    this.pdf.text(lines,this.xPosition,this.yPosition,{align:'left'});
    this.yPosition += lines.length * 10;
    lines = this.pdf.splitTextToSize("Following documents shall be required in the event of a claim for Death:",this.PAGE_WIDTH);
    this.pdf.text(lines,this.xPosition,this.yPosition,{align:'left'});
    this.yPosition += lines.length * 10;
    lines = this.pdf.splitTextToSize("• Policy Copy • Duly filled up claims form • Original Death Certificate or Death Certificate-Notarized/Attested by a gazette officer, if applicable • Original F.I.R or F.I.R-Notarized/Attested by a Gazetted officer • Insured valid and effective driving license, in accordance with provisions of Section 3 of Motor Vehicle Act 1988 • Police Final chargesheet/Court Final order - Notarized/attested by a Gazetted Officer-if applicable • Spot Panchnama and Police Inquest report - Notarized/Attested by a gazette officer, if applicable • Post Mortem Report-Notarized/Attested by a Gazetted officer, if concluded • Viscera Analysis Report/Chemical analysis report/Forensic Science Lab report notarized/Attested by gazette officer • Other Document as per Case details • Complete medical records including Death Summary; if hospitalized, Website Links/Newspaper cuttings, Other references. Such compensation shall be payable directly to his/her legal representatives.",this.PAGE_WIDTH);
    this.pdf.text(lines,this.xPosition,this.yPosition,{align:'left'});
    this.yPosition += lines.length * 10;
    lines = this.pdf.splitTextToSize("• If claim amount is more than 1lakh, AML Documents - Pan Card Copy, Residence Proof,2 Passport size colour photos of claimant",this.PAGE_WIDTH);
    this.pdf.text(lines,this.xPosition,this.yPosition,{align:'left'});
    this.yPosition += lines.length * 10;
    lines = this.pdf.splitTextToSize("• Cancel Cheque with NEFT Mandate form - duly filled in by the claimant and bank",this.PAGE_WIDTH);
    this.pdf.text(lines,this.xPosition,this.yPosition,{align:'left'});
    this.yPosition += lines.length * 10;
    lines = this.pdf.splitTextToSize("• Any other document as required by the Company to investigate the Claim or Our obligation to make payment for.",this.PAGE_WIDTH);
    this.pdf.text(lines,this.xPosition,this.yPosition,{align:'left'});
    this.yPosition += lines.length * 10;
    lines = this.pdf.splitTextToSize("1. In respect of fatal claims, the payment is to be made to the assignee named under the policy. If there is no assignee, the payment is made to the legal representative as identified by Will / Probate / Letter of Administration/Succession Certificate.",this.PAGE_WIDTH);
    this.pdf.text(lines,this.xPosition,this.yPosition,{align:'left'});
    this.yPosition += lines.length * 10;
    lines = this.pdf.splitTextToSize("2. Where the above documents are not available, the following procedure may be followed: -",this.PAGE_WIDTH);
    this.pdf.text(lines,this.xPosition,this.yPosition,{align:'left'});
    this.yPosition += lines.length * 10;
    lines = this.pdf.splitTextToSize("• An affidavit from the Claimant(s) that he/she(they) is (are) the legal heir(s) of the deceased",this.PAGE_WIDTH);
    this.pdf.text(lines,this.xPosition,this.yPosition,{align:'left'});
    this.yPosition += lines.length * 10;
    lines = this.pdf.splitTextToSize("• An affidavit from other near family members and relatives of the deceased that they have no objection if the claim amount is paid to the claimant(s)",this.PAGE_WIDTH);
    this.pdf.text(lines,this.xPosition,this.yPosition,{align:'left'});
    this.yPosition += lines.length * 10;
    this.processSubHeader("For Claims, Please Connect: Email: info@allianceassure.in",18);

  }
    
  pdfPrint() {
    this.pdf.save(this.certificate.certificateNumber + ".pdf")
  }
  spinnerSetInterval()
  {
    setInterval(() => {
      if (this.spinnerColor === 'primary') {
        this.spinnerColor = 'accent';
      } else if (this.spinnerColor === 'accent') {
        this.spinnerColor = 'warn';
      } else {
        this.spinnerColor = 'primary';
      }
    }, 2000);
  }
  processHeader(headerText:string,width:number){
    this.pdf.setFillColor('#242B86');
    this.pdf.rect(this.xPosition,this.yPosition,this.PAGE_WIDTH,width,'DF');
    this.xPosition += this.PAGE_WIDTH/2;
    this.yPosition += width * 2/3;
    this.pdf.setTextColor('#D5CEA3').setFontSize(width/2).setFont('helvetica','bold');
    this.pdf.text(headerText,this.xPosition,this.yPosition,{align:'center'});
    this.yPosition +=width/3;
    this.xPosition = this.marginStart;

  }
  processSubHeader(headerText:string,width:number){
    this.pdf.setFillColor('#FFFFFF');
    this.pdf.rect(this.xPosition,this.yPosition,this.PAGE_WIDTH,width,'DF');
    this.yPosition += width * 2/3;
    this.pdf.setTextColor('#000000').setFontSize(width/2).setFont('helvetica','bold');
    this.pdf.text(headerText,this.xPosition + 5,this.yPosition,{align:'left'});
    this.yPosition +=width/3;
    this.xPosition = this.marginStart;

  }
  firstTableCPARow(column1Text:string,column2Text:string)
  {
    this.pdf.setFillColor('#FFFFFF');
    this.pdf.rect(this.xPosition,this.yPosition,195,12,'DF');
    this.pdf.rect(this.xPosition + 195,this.yPosition,380,12,'DF');
    this.pdf.setTextColor('#000000').setFontSize(8).setFont('helvetica','bold');
    this.pdf.text(column1Text,this.xPosition + 4,this.yPosition + 10,{align:'left'});
    this.pdf.text(column2Text,this.xPosition + 4 + 195,this.yPosition + 10,{align:'left'});
    this.yPosition += 12
  }

  firstTableRow(column1Text:string,column2Text:string)
  {
    this.pdf.setFillColor('#FFFFFF');
    this.pdf.rect(this.xPosition,this.yPosition,380,12,'DF');
    this.pdf.rect(this.xPosition + 380,this.yPosition,195,12,'DF');
    this.pdf.setTextColor('#000000').setFontSize(8).setFont('helvetica','bold');
    this.pdf.text(column1Text,this.xPosition + 4,this.yPosition + 10,{align:'left'});
    this.pdf.text(column2Text,this.xPosition + 4 + 380,this.yPosition + 10,{align:'left'});
    this.yPosition += 12
  }
  
  summaryPage(){
    this.pdf.addPage()
    this.xPosition = this.marginStart;
    this.yPosition = this.marginTop;
      this.pdf.setTextColor('#000000').setFontSize(12).setFont('helvetica','bold');
      // this.pdf.addImage('assets/AllianceLogo.png','PNG',this.xPosition,this.yPosition,120,60,'FAST');
      this.pdf.setFillColor('#242B86');
      this.yPosition += 80;
      this.pdf.rect(this.xPosition,this.yPosition,this.PAGE_WIDTH,40,'F');
      this.pdf.setTextColor('#D5CEA3').setFontSize(20).setFont('helvetica','bold');
      this.pdf.text("Business Summary Sheet",180,this.yPosition + 30);
      this.yPosition += 50;
      this.pdf.setFillColor('#D1D1D1');
      this.pdf.rect(this.xPosition,this.yPosition,279,30,'DF');
      this.pdf.rect(280,this.yPosition,100,30,'DF');
      this.pdf.rect(380,this.yPosition,100,30,'DF');
      this.pdf.rect(480,this.yPosition,100,30,'DF');
      this.pdf.setTextColor('#000000').setFontSize(12).setFont('helvetica','bold');
      this.pdf.text("Particulars",this.xPosition + 140 ,this.yPosition + 18,{align:'center'});
      this.pdf.text("Net Amount",this.xPosition + 320,this.yPosition + 18,{align:'center'});
      this.pdf.text("GST",this.xPosition + 420,this.yPosition + 18,{align:'center'});
      this.pdf.text("Total",this.xPosition + 520,this.yPosition + 18,{align:'center'});
      this.yPosition += 30;
      this.pdf.setFillColor('#FFFFFF');
      this.pdf.rect(this.xPosition,this.yPosition,279,30,'DF');
      this.pdf.rect(280,this.yPosition,100,30,'DF');
      this.pdf.rect(380,this.yPosition,100,30,'DF');
      this.pdf.rect(480,this.yPosition,100,30,'DF');
      this.summaryTableRow("Summary INS",parseFloat(this.certificate.insPolicyAmount.toFixed(2)), parseFloat(this.certificate.insPolicyGStAmount.toFixed(2)),parseFloat(this.certificate.insPolicyTotalAmount.toFixed(2)));
      this.summaryTableRow("Summary AAPL",parseFloat(this.certificate.planAmount.toFixed(2)), parseFloat(this.certificate.planGSTAmount.toFixed(2)),parseFloat(this.certificate.planTotalAmount.toFixed(2)));
      this.summaryTableRow("Total",parseFloat((this.certificate.planAmount + this.certificate.insPolicyAmount).toFixed(2)), parseFloat((this.certificate.planGSTAmount + this.certificate.insPolicyGStAmount).toFixed(2)),parseFloat((this.certificate.planTotalAmount + this.certificate.insPolicyTotalAmount).toFixed(2)));

  }
  summaryTableRow(column1Txt: string,column2Text:number,column3Text:number,column4Text:number ){
    this.pdf.setFillColor('#FFFFFF');
    this.pdf.rect(this.xPosition,this.yPosition,272,30,'DF');
    this.pdf.rect(280,this.yPosition,100,30,'DF');
    this.pdf.rect(380,this.yPosition,100,30,'DF');
    this.pdf.rect(480,this.yPosition,100,30,'DF');
    this.pdf.setTextColor('#000000').setFontSize(10).setFont('helvetica','bold');
    this.pdf.text(column1Txt,this.xPosition + 4,this.yPosition + 18,{align:'left'});
    this.pdf.text(column2Text.toString(),376,this.yPosition + 18,{align:'right'});
    this.pdf.text(column3Text.toString(),476,this.yPosition + 18,{align:'right'});
    this.pdf.text(column4Text.toString(),576,this.yPosition + 18,{align:'right'});
    this.yPosition += 30;
  }
  loadPdfToMyframe(){
    console.log(this.isLoading)
    setTimeout(() => {
    this.pdfDataUri = this.pdf.output('datauri');
    this.pdfSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.pdfDataUri);
    this.isLoading = false;
    this.cd.detectChanges();
    // document.getElementById('myFrame').setAttribute('src',this.pdfSrc.toString());    
    });
  }

  inWords (num) {
    num = Math.round (num);
   var a = ['','1 ','2 ','3 ','4 ', '5 ','6 ','7 ','8 ','9 ','10 ','11 ','12 ','13 ','14 ','15 ','16 ','17 ','18 ','19 '];
   var b = ['', '', '20','30','40','50', '60','70','80','90'];
     if ((num = num.toString()).length > 9) return 'overflow';
      this.n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
     if (!this.n) return; var str = '';
     str += (Number(this.n[1]) != 0) ? (a[Number(this.n[1])] || b[this.n[1][0]] + ' ' + a[this.n[1][1]]) + 'crore ' : '';
     str += (Number(this.n[2]) != 0) ? (a[Number(this.n[2])] || b[this.n[2][0]] + ' ' + a[this.n[2][1]]) + 'lakh ' : '';
     str += (Number(this.n[3]) != 0) ? (a[Number(this.n[3])] || b[this.n[3][0]] + ' ' + a[this.n[3][1]]) + 'thousand ' : '';
     str += (Number(this.n[4]) != 0) ? (a[Number(this.n[4])] || b[this.n[4][0]] + ' ' + a[this.n[4][1]]) + 'hundred ' : '';
     str += (Number(this.n[5]) != 0) ? ((str != '') ? 'and ' : '') + (a[Number(this.n[5])] || b[this.n[5][0]] + ' ' + a[this.n[5][1]]) + 'only ' : '';
     return str;
 }
 secondTableRow(column1Text:string,column2Text:string,column3Text:string,column4Text:string){
  this.pdf.setFillColor('#FFFFFF');
  this.pdf.rect(this.xPosition,this.yPosition,30,12,'DF');
  this.pdf.rect(this.xPosition +30,this.yPosition,130,12,'DF');
  this.pdf.rect(this.xPosition + 160,this.yPosition,340,12,'DF');
  this.pdf.rect(this.xPosition + 500,this.yPosition,75,12,'DF');
  this.pdf.text(column1Text,this.xPosition + 4,this.yPosition + 10,{align:'left'});
  this.pdf.text(column2Text,this.xPosition + 34 ,this.yPosition + 10,{align:'left'});
  this.pdf.text(column3Text,this.xPosition + 164 ,this.yPosition + 10,{align:'left'});
  this.pdf.text(column4Text,this.xPosition + 495 + 40 ,this.yPosition + 10,{align:'center'});
  this.yPosition += 12

}
halfTableRow(column1Text:string,column2Text:string)
{
  this.pdf.setFillColor('#FFFFFF');
  this.pdf.rect(this.xPosition,this.yPosition,290,12,'DF');
  this.pdf.rect(this.xPosition + 290,this.yPosition,285,12,'DF');
  this.pdf.setTextColor('#000000').setFontSize(8).setFont('helvetica','bold');
  this.pdf.text(column1Text,this.xPosition + 4,this.yPosition + 10,{align:'left'});
  this.pdf.text(column2Text,this.xPosition + 4 + 290,this.yPosition + 10,{align:'left'});
  this.yPosition += 12
}

amountTable(){
  if (!this.showGstAmount)
     {
      this.processHeader("Plan Details",15);
      this.halfTableRow("Product Plan","PLAN " + this.certificate.planTotalAmount)
      return;
    }
  this.pdf.setFillColor('#D1D1D1');
  this.pdf.rect(this.xPosition,this.yPosition,116,12,'DF');
  this.pdf.rect(this.xPosition +116,this.yPosition,116,12,'DF');
  this.pdf.rect(this.xPosition + 232,this.yPosition,116,12,'DF');
  this.pdf.rect(this.xPosition + 348,this.yPosition,116,12,'DF');
  this.pdf.rect(this.xPosition + 464,this.yPosition,110,12,'DF');
  this.pdf.setTextColor('#000000').setFontSize(10).setFont('helvetica','bold');
  this.pdf.text("Plan Amount",this.xPosition + 58,this.yPosition + 10,{align:'center'});
  this.pdf.text("SGST(9%)",this.xPosition + 58 + 116 ,this.yPosition + 10,{align:'center'});
  this.pdf.text("CGST(9%)",this.xPosition + 58 + 232 ,this.yPosition + 10,{align:'center'});
  this.pdf.text("IGST(18%)",this.xPosition + 58 + 348 ,this.yPosition + 10,{align:'center'});
  this.pdf.text("Total",this.xPosition + 58 + 464 ,this.yPosition + 10,{align:'center'});
  this.yPosition += 12
  this.pdf.setFillColor('#FFFFFF');
  this.pdf.rect(this.xPosition,this.yPosition,116,12,'DF');
  this.pdf.rect(this.xPosition +116,this.yPosition,116,12,'DF');
  this.pdf.rect(this.xPosition + 232,this.yPosition,116,12,'DF');
  this.pdf.rect(this.xPosition + 348,this.yPosition,116,12,'DF');
  this.pdf.rect(this.xPosition + 464,this.yPosition,110,12,'DF');
  this.pdf.setTextColor('#000000').setFontSize(10).setFont('helvetica','bold');
  this.pdf.text(this.certificate.planAmount.toFixed(2).toString(),this.xPosition + 58,this.yPosition + 10,{align:'center'});
  this.pdf.text(this.certificate.SgstAmount.toFixed(2).toString(),this.xPosition + 58 + 116 ,this.yPosition + 10,{align:'center'});
  this.pdf.text(this.certificate.CgstAmount.toFixed(2).toString(),this.xPosition + 58 + 233 ,this.yPosition + 10,{align:'center'});
  this.pdf.text(this.certificate.IgstAmount.toFixed(2).toString(),this.xPosition + 58 + 348 ,this.yPosition + 10,{align:'center'});
  this.pdf.text(this.certificate.planTotalAmount.toFixed(2).toString(),this.xPosition + 58 + 464 ,this.yPosition + 10,{align:'center'});
  this.yPosition += 13

}
doctorOnCall(){
  this.processHeader("Doctor On Call/Chat",15)
  this.yPosition += 9
  this.pdf.setTextColor('#000000').setFontSize(6).setFont('helvetica','normal');
var lines = this.pdf.splitTextToSize("• Free Doctor Chat - Opt for a voice/video call. Get Online Prescriptions from them. • Doctor over a Phone Call - You can speak to our specialist over a regular voice call too. • Artificial Intelligence Healthcare Assistant - Connect with our easy-to-use Artificial Intelligence-powered healthcare assistant who is always available at your fingertips. • Privacy - Share details about your health problem by privately sharing reports, photos and more in our encrypted private chat. Your data is completely safe.With Visit, you can have a chat with a Visit specialist doctor from over specialties.*T&C applicable - Only one service under this certificate applicable. Services in partnership with doc providers/own.All Doctor on Call/Chat Services may not be available in your area. Doc chat/call will get activated after 2 days of registration at 1800 212 7559",this.PAGE_WIDTH);
  this.pdf.text(lines,this.xPosition,this.yPosition,{align:'left'});
  this.yPosition += lines.length * 6;

}
printDisclaimerMessage(message:string){
  let lines = this.pdf.splitTextToSize(message,this.PAGE_WIDTH);
  this.pdf.text(lines, this.xPosition, this.yPosition + 8, { align: 'left' })

}
}
