import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { User } from '@app/_models';

@Component({
  selector: 'app-employee-dashboard',
  templateUrl: './employee-dashboard.component.html',
  styleUrls: ['./employee-dashboard.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterModule]
})
export class EmployeeDashboardComponent implements OnInit {
  @Input() user: User;
  
  statistics = [
    { title: 'Assigned Dealers', value: 0, icon: 'fa-briefcase', color: '#3F51B5', loading: true },
    { title: 'Certificates This Month', value: 0, icon: 'fa-certificate', color: '#009688', loading: true },
    // { title: 'Requests Pending', value: 0, icon: 'fa-clock-o', color: '#FF5722', loading: true },
    { title: 'Active Packages', value: 0, icon: 'fa-cubes', color: '#607D8B', loading: true }
  ];
  
  quickLinks = [
    { title: 'Issue Certificate', icon: 'fa-plus-square', route: '/issueCertificate', color: '#009688' },
    { title: 'Print Certificate', icon: 'fa-print', route: '/reports/printCertificate', color: '#3F51B5' },
    // { title: 'Endorsement Requests', icon: 'fa-file-text-o', route: '/reports/activeEndorsement', color: '#FF9800' },
    { title: 'Dealer Statistics', icon: 'fa-bar-chart', route: '/reports/dealerSummaryReport', color: '#E91E63' }
  ];
  
  assignedDealers = [];
  pendingRequests = [];
  
  constructor() { }

  ngOnInit(): void {
    // Simulate loading data
    setTimeout(() => {
      this.statistics[0].value = 18;
      this.statistics[0].loading = false;
      
      setTimeout(() => {
        this.statistics[1].value = 523;
        this.statistics[1].loading = false;
        
        setTimeout(() => {
          this.statistics[2].value = 7;
          this.statistics[2].loading = false;
          
          setTimeout(() => {
            this.statistics[3].value = 5;
            this.statistics[3].loading = false;
          }, 300);
        }, 300);
      }, 300);
    }, 300);
    
    // Simulate assigned dealers data
    this.assignedDealers = [
      { id: 'D001', name: 'ABC Motors', location: 'Mumbai', certificates: 156, status: 'active' },
      { id: 'D012', name: 'XYZ Automobiles', location: 'Delhi', certificates: 89, status: 'active' },
      { id: 'D023', name: 'Super Bikes Ltd.', location: 'Bangalore', certificates: 67, status: 'active' },
      { id: 'D034', name: 'Luxury Cars', location: 'Chennai', certificates: 45, status: 'inactive' }
    ];
    
    // Simulate pending requests data
    this.pendingRequests = [
      { id: 'REQ4578', type: 'endorsement', dealer: 'ABC Motors', date: '2023-09-26', urgency: 'high' },
      { id: 'REQ4563', type: 'cancel', dealer: 'Super Bikes Ltd.', date: '2023-09-25', urgency: 'medium' },
      { id: 'REQ4559', type: 'endorsement', dealer: 'XYZ Automobiles', date: '2023-09-25', urgency: 'low' }
    ];
  }
  
  getRequestTypeIcon(type: string): string {
    return type === 'endorsement' ? 'fa-file-text-o' : 'fa-ban';
  }
  
  getRequestTypeClass(type: string): string {
    return type === 'endorsement' ? 'endorsement' : 'cancel';
  }
  
  getUrgencyClass(urgency: string): string {
    switch(urgency) {
      case 'high': return 'badge bg-danger';
      case 'medium': return 'badge bg-warning';
      default: return 'badge bg-info';
    }
  }
}
