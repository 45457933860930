import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CpaCertificatev2Component } from './cpa-certificatev2/cpa-certificatev2.component';
import { CpaDocPolicyComponent } from './cpa-doc-policy/cpa-doc-policy.component';
import { FinanceComponent } from './finance/finance.component';
import { NewRsaCertificateComponent } from './new-rsa-certificate/new-rsa-certificate.component';

import { PdfCertificateCreateComponent } from './pdf-certificate-create/pdf-certificate-create.component';
import { PrintLayoutComponent } from './print-layout/print-layout.component';
import { RsaCertificateComponent } from './rsa-certificate/rsa-certificate.component';
import { RsaDocCertificateComponent } from './rsa-doc-certificate/rsa-doc-certificate.component';
import { RsaEvComponent } from './rsa-ev/rsa-ev.component';
import { RsaPvCertificateComponent } from './rsa-pv-certificate/rsa-pv-certificate.component';

const routes: Routes = [
    {
        path: '', component: PrintLayoutComponent,
        children: [
            { path: '', component: PdfCertificateCreateComponent },
            { path: 'PA/:id', component: PdfCertificateCreateComponent },
            { path: 'RSAEv', component: RsaEvComponent },
            { path: 'RSA', component: RsaCertificateComponent },
            { path: 'PaNew', component: CpaCertificatev2Component },
            { path: 'RSANew', component: NewRsaCertificateComponent },
            { path: 'RSAPv', component: RsaPvCertificateComponent},
            { path: 'CpaDoc', component: CpaDocPolicyComponent },
            { path: 'RSANewDoc', component: RsaDocCertificateComponent },
            {path:'Finance',component:FinanceComponent }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class printRouting { }