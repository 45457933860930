<div class="dealer-dashboard">
  <!-- Account Stats -->
  <!-- <div class="row mb-4">
    <div class="col-12">
      <h2 class="section-title">Account Overview</h2>
    </div>
    <div class="col-md-6 mb-3" *ngFor="let stat of accountStats">
      <div class="stat-card" [style.border-left-color]="stat.color">
        <div class="card-body">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <div class="stat-value" *ngIf="!stat.loading">
                <span *ngIf="stat.prefix">{{ stat.prefix }}</span>{{ stat.value | number }}
              </div>
              <div class="stat-value-loading" *ngIf="stat.loading">
                <div class="loading-placeholder"></div>
              </div>
              <div class="stat-title">{{ stat.title }}</div>
            </div>
            <div class="stat-icon" [style.background-color]="stat.color">
              <i class="fa" [ngClass]="stat.icon"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  
  <!-- Quick Actions -->
  <div class="row mb-4">
    <div class="col-12">
      <h2 class="section-title">Quick Actions</h2>
    </div>
    <div class="col-md-3 col-sm-6 mb-3">
      <a routerLink="/issueCertificate" class="action-card">
        <div class="action-icon" style="background-color: #4CAF50;">
          <i class="fa fa-plus-circle"></i>
        </div>
        <div class="action-title">Issue New Certificate</div>
      </a>
    </div>
    <div class="col-md-3 col-sm-6 mb-3">
      <a routerLink="/reports/printCertificate" class="action-card">
        <div class="action-icon" style="background-color: #2196F3;">
          <i class="fa fa-print"></i>
        </div>
        <div class="action-title">Print Certificate</div>
      </a>
    </div>
    <!-- <div class="col-md-3 col-sm-6 mb-3">
      <a routerLink="/account/recharge" class="action-card">
        <div class="action-icon" style="background-color: #FF9800;">
          <i class="fa fa-credit-card"></i>
        </div>
        <div class="action-title">Add Balance</div>
      </a>
    </div> -->
    <div class="col-md-3 col-sm-6 mb-3">
      <a routerLink="/reports/certificateReport" class="action-card">
        <div class="action-icon" style="background-color: #673AB7;">
          <i class="fa fa-list"></i>
        </div>
        <div class="action-title">View Reports</div>
      </a>
    </div>
  </div>
  
  <!-- Package Status & Recent Certificates -->
  <!-- <div class="row">
    <div class="col-md-5 mb-4">
      <div class="card h-100">
        <div class="card-header">
          <h5 class="mb-0">Package Status</h5>
        </div>
        <div class="card-body">
          <div class="package-item" *ngFor="let pkg of packageCounts">
            <div class="d-flex justify-content-between align-items-center mb-1">
              <span class="package-name">{{ pkg.name }}</span>
              <span class="package-count">{{ pkg.used }}/{{ pkg.total }}</span>
            </div>
            <div class="progress" style="height: 8px;">
              <div class="progress-bar" 
                   [style.width.%]="getProgressWidth(pkg.used, pkg.total)" 
                   [style.background-color]="pkg.color"></div>
            </div>
            <div class="package-remaining mt-1">
              <small>{{ getRemainingCount(pkg.used, pkg.total) }} certificates remaining</small>
            </div>
          </div>
          
          <div class="mt-4 text-center">
            <a routerLink="/packages" class="btn btn-outline-primary btn-sm">View All Packages</a>
          </div>
        </div>
      </div>
    </div>
    
    <div class="col-md-7 mb-4">
      <div class="card h-100">
        <div class="card-header d-flex justify-content-between align-items-center">
          <h5 class="mb-0">Recent Certificates</h5>
          <a routerLink="/reports/certificateReport" class="small text-decoration-none">View All</a>
        </div>
        <div class="table-responsive">
          <table class="table certificate-table">
            <thead>
              <tr>
                <th>Certificate ID</th>
                <th>Vehicle</th>
                <th>Owner</th>
                <th>Issue Date</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let cert of recentCertificates">
                <td><span class="certificate-id">{{ cert.id }}</span></td>
                <td>{{ cert.vehicle }}</td>
                <td>{{ cert.owner }}</td>
                <td>{{ cert.date | date:'dd-MM-yyyy' }}</td>
                <td>
                  <div class="btn-group btn-group-sm">
                    <button type="button" class="btn btn-outline-primary"><i class="fa fa-print"></i></button>
                    <button type="button" class="btn btn-outline-info"><i class="fa fa-eye"></i></button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>  -->
</div>
