import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HeaderConfigService {
  
  // Color configurations by role
  private colorConfig = {
    admin: {
      primary: '#2d4da1', // Lighter Chathams Blue
      accent: '#e35619',  // Hot Cinnamon
      hover: '#5069a2',   // Kashmir Blue
      text: '#ffffff'     // White
    },
    dealerOwner: {
      primary: '#7289b8', // Lighter Kashmir Blue
      accent: '#e35619',  // Hot Cinnamon
      hover: '#1c3c84',   // Chathams Blue
      text: '#ffffff'     // White
    },
    rsaOperation: {
      primary: '#6b9467', // Lighter Hippie Green
      accent: '#e35619',  // Hot Cinnamon
      hover: '#5069a2',   // Kashmir Blue
      text: '#ffffff'     // White
    },
    heroReport: {
      primary: '#f47741', // Lighter Hot Cinnamon
      accent: '#1c3c84',  // Chathams Blue
      hover: '#5069a2',   // Kashmir Blue
      text: '#ffffff'     // White
    },
    employee: {
      primary: '#3f5da8', // Medium Chathams Blue
      accent: '#e35619',  // Hot Cinnamon
      hover: '#5069a2',   // Kashmir Blue
      text: '#ffffff'     // White
    },
    executive: {
      primary: '#4d67ab', // Light Chathams Blue
      accent: '#e35619',  // Hot Cinnamon
      hover: '#5069a2',   // Kashmir Blue
      text: '#ffffff'     // White
    }
  };

  getColorConfig(role: string): any {
    // Default to admin if role not found
    if (!this.colorConfig[role]) {
      return this.colorConfig['executive'];
    }
    return this.colorConfig[role];
  }

  // Nav item configurations by role - can be expanded
  getNavItems(role: string): any[] {
    // Define common and role-specific navigation items here
    // This is a placeholder that can be expanded
    return [];
  }
}
