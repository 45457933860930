import { Component, Input, OnInit } from '@angular/core';
import { Certificate } from '@app/_models/certificate';
import { AccountService } from '@app/_services';
import { CertificateService } from '@app/_services/certificate.service';

@Component({
  selector: 'app-print-new-india-car-policy',
  templateUrl: './print-new-india-car-policy.component.html',
  styleUrls: ['./print-new-india-car-policy.component.less']
})
export class PrintNewIndiaCarPolicyComponent implements OnInit {
  //@Input() certificate: any;
  canSeeNewIndiaLogo = false;

  certificate = new Certificate();
  constructor(private certificateService: CertificateService, private accountService: AccountService) { }

  ngOnInit(): void {
    console.log(this.certificate);
    this.certificate = new Certificate(this.certificateService.PrintCertificate);
    this.canSeeNewIndiaLogo =  (this.accountService.userValue.specialRoleAccess && this.accountService.userValue.specialRoleAccess.includes('canSeeNewIndiaLogo'));

  }

}
