import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { User } from '@app/_models';
import { Dealer } from '@app/_models/dealer';
import { AccountService, AlertService } from '@app/_services';
import { DealerService } from '@app/_services/dealer-service';
import { NgbDateStruct, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AgGridAngular } from 'ag-grid-angular';
import { GridOptions, GridApi, ColumnApi } from 'ag-grid-community';
import { first } from 'rxjs/internal/operators/first';
import { ReportSearch } from '../../_models/reportSearch';
import { SearchService } from '../../_services/search.service';
import { PinCodeInfo } from '@app/_models/pincodeInfo';
import { ConfigDataService } from '@app/_services/config-data.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  imports: [
    CommonModule,
    AgGridAngular,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatDividerModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatSelectModule
  ],
  standalone: true,
  selector: 'app-certificate-report',
  templateUrl: './certificate-report.component.html',
  styleUrls: ['./certificate-report.component.less']
})
export class CertificateReportComponent implements OnInit {
  @ViewChild('aggrid') aggrid: AgGridAngular;
  
  // Enhanced UI properties
  showFilters = false;
  showDateFilters = true;
  darkMode = false;
  hasResults = false;
  resultCount = 0;
  lastUpdated = new Date();
  compactMode = false;
  quickFiltersVisible = false;
  keyboardShortcutsEnabled = true;
  presetFilters = [
    { name: 'Issue Date Today', filter: () => this.applyActiveFilter(1) },
    { name: 'Created Today', filter: () => this.setDateRange('today') },
    { name: 'Created This Month', filter: () => this.setDateRange('month') },
    { name: 'Start Date Today', filter: () => this.applyActiveFilter(0) },
  ];
  
  // Existing properties
  public gridApi: GridApi;
  private columnApi: ColumnApi;
  certificateReportForm: UntypedFormGroup;
  pincodeInfo: PinCodeInfo[];
  id: string;
  submitted = false;
  issueDateFrom: NgbDateStruct;
  issueDateTo: NgbDateStruct;
  gridOptions: GridOptions;
  loading = false;
  gridReady = true;
  reportSearch: ReportSearch;
  issueDateFromSearch: string;
  issueDateToSearch: string;
  reportCertificateData: any[];
  rowData = [];
  user: User;
  dealer: Dealer;
  startOrIssueDateOptions = [{id: 'startdate', name: 'Start Date'}, {id: 'issuedate', name: 'Issue Date'}];

  constructor(  
    private route: ActivatedRoute,      
    private formBuilder: UntypedFormBuilder,
    private searchService: SearchService, 
    private alertService: AlertService,
    private accountService: AccountService,
    private configDataService: ConfigDataService,
    private dealerService: DealerService,
    private snackBar: MatSnackBar
  ) { }

  // Handle keyboard shortcuts
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (!this.keyboardShortcutsEnabled) return;
    
    // Ctrl + Enter to search
    if (event.ctrlKey && event.key === 'Enter') {
      this.onSubmit();
      event.preventDefault();
    }
    
    // Ctrl + R to reset
    if (event.ctrlKey && event.key === 'r') {
      this.onReset();
      event.preventDefault();
    }
    
    // Ctrl + E to export to CSV
    if (event.ctrlKey && event.key === 'e') {
      if (this.hasResults) this.exportToCsv();
      event.preventDefault();
    }
    
    // Show keyboard shortcuts help with F1
    if (event.key === 'F1') {
      this.showKeyboardShortcutsHelp();
      event.preventDefault();
    }
  }

  ngOnInit() {
    this.user = this.accountService.userValue;
    this.id = this.route.snapshot.params['id'];
    this.certificateReportForm = this.formBuilder.group({
      certificateNumber: [''],
      firstName: [''],
      lastName: [''],
      userName: [''],
      issueDateFrom: [''],
      issueDateTo: [''],
      startDateOrIssueDate: [this.startOrIssueDateOptions[1].id],
      dealerId: [''],
      dealerGroupName: [''],
    });
      
    this.configDataService.PincodeInfo$.subscribe(x => {
      this.pincodeInfo = x;
    });
    
    this.initialProcess();

    // Apply default date filter (This Month)
    setTimeout(() => {
      this.setDateRange('month');
      this.showWelcomeMessage();
    }, 0);
  }

  get f() { return this.certificateReportForm.controls; }
  
  initialProcess() {
    // ...existing code...
    this.gridOptions = <GridOptions>{};
    this.gridOptions.defaultColDef = {
      resizable: true
    };

    if(this.id == "NewIndiaPolicy") {
      this.gridOptions.columnDefs = [
        {headerName: "Certificate Id", field: "certificateNumber",sortable: true, filter:true }
        ,{headerName: "VehicleMake", field: "vehicleMake",sortable: true, filter:true }
        ,{headerName: "Model", field: "model",sortable: true, filter:true }
        ,{headerName: "Variant", field: "variant",sortable: true, filter:true }
        ,{headerName: "Vehicle Year", field: "vehicleYear",sortable: true, filter:true }
        ,{headerName: "Registration Number", field: "registrationNumber",sortable: true, filter:true }
        ,{headerName: "Chasis Number", field: "chasisNumber",sortable: true, filter:true }
        ,{headerName: "Engine Number", field: "engineNumber",sortable: true, filter:true }
        ,{headerName: "First Name", field: "firstName",sortable: true, filter:true }
        ,{headerName: "Last Name", field: "lastName",sortable: true, filter:true }
        ,{headerName: "Customer DOB", field: "dob",sortable: true, filter:true }
        ,{headerName: "Customer Age", field: "age",sortable: true, filter:true }
        ,{headerName: "Nominee Name", field: "nomineeName",sortable: true, filter:true }
        ,{headerName: "Nominee Relationship", field: "nomineeRelationship",sortable: true, filter:true }        
        ,{headerName: "Nominee Age", field: "nomineeAge",sortable: true, filter:true }        
        ,{headerName: "Issue Date", field: "issueDateString",sortable: true, filter:true }
        ,{headerName: "End Date", field: "endDate", sortable: true, filter:true }
        ,{headerName: "Certificate Status", field: "certificateStatus",sortable: true, filter:true }
        ,{headerName: "Created Date", field: "createdDateString",sortable: true, filter:true }
        ,{headerName: "Policy Type", field: "policyType",sortable: true, filter:true }
        ,{headerName: "Master Policy Number", field: "policyNumber",sortable: true, filter:true }
        ,{headerName: "Policy Cover Amount", field: "planCPACoverAmount",sortable: true, filter:true }
        ,{headerName:"Address", field: "address",sortable: true, filter:true }
        ,{headerName: "City", field: "city",sortable: true, filter:true }
        ,{headerName: "State", field: "state",sortable: true, filter:true }
        ,{headerName: "Gender", field:"gender",sortable: true, filter:true }
        ,{headerName: "Contact Number", field: "contactNumber",sortable: true, filter:true }
        ,      {
          headerName: "PinCode", valueGetter: (params) => {
            const stateInfo = this.pincodeInfo.find(a => a.state.toLowerCase() === params.data.state.toLowerCase());
            if (stateInfo) {
              const cityInfo = stateInfo.cityInfo.find(x => x.cityName.toLowerCase() === params.data.city.toLowerCase());
              return cityInfo ? cityInfo.pinCode : "N/A";
            }
            return "N/A";
          }, sortable: true, filter: true
        }
      ]
  } else {

    if ((this.user.role == "executive") || (this.user.role =="manager"))
    {
      if (this.user.specialRoleAccess && this.user.specialRoleAccess.includes('Show Total Package Balance'))
      {
      this.gridOptions.columnDefs = [
        {headerName: "Certificate Id", field: "certificateNumber",sortable: true, filter:true }
        // ,{headerName: "Policy Number", field: "policyNumber",sortable: true, filter:true }
        ,{headerName: "First Name", field: "firstName",sortable: true, filter:true }
        ,{headerName: "Last Name", field: "lastName",sortable: true, filter:true }
        ,{headerName: "Registration Number", field: "registrationNumber",sortable: true, filter:true }
        ,{headerName: "Chasis Number", field: "chasisNumber",sortable: true, filter:true }
        ,{headerName: "Engine Number", field: "engineNumber",sortable: true, filter:true }
        ,{headerName: "Model", field: "model",sortable: true, filter:true }
        ,{headerName: "Variant", field: "variant",sortable: true, filter:true }
        ,{headerName: "Plan", field: "planId",sortable: true, filter:true }
        ,{headerName: "Amount",valueGetter: (params)=>{return (params.data.planTotalAmount + (params.data.insPolicyTotalAmount ? params.data.insPolicyTotalAmount : params.data.insPolicyTotalAmount))},sortable: true, filter:true }
        ,{headerName: "Customer City", field: "city",sortable: true, filter:true }
        ,{headerName: "Customer State", field: "state",sortable: true, filter:true }
        ,{headerName: "Contact Number", field: "contactNumber",sortable: true, filter:true }
        ,{headerName: "Nominee Name", field: "nomineeName",sortable: true, filter:true }
        ,{headerName: "Nominee Relationship", field: "nomineeRelationship",sortable: true, filter:true }        
        ,{headerName: "Executive Username", field: "userName",sortable: true, filter:true }
        ,{headerName: "Certificate Status", field: "certificateStatus",sortable: true, filter:true }
        ,{headerName: "Issue Date", field: "issueDateString",sortable: true, filter:true }
        ,{headerName: "Created Date and Time", field: "createdDateString",sortable: true, filter:true }
      ]
    }
    else
    {
      this.gridOptions.columnDefs = [
        {headerName: "Certificate Id", field: "certificateNumber",sortable: true, filter:true }
        // ,{headerName: "Policy Number", field: "policyNumber",sortable: true, filter:true }
        ,{headerName: "First Name", field: "firstName",sortable: true, filter:true }
        ,{headerName: "Last Name", field: "lastName",sortable: true, filter:true }
        ,{headerName: "Registration Number", field: "registrationNumber",sortable: true, filter:true }
        ,{headerName: "Chasis Number", field: "chasisNumber",sortable: true, filter:true }
        ,{headerName: "Engine Number", field: "engineNumber",sortable: true, filter:true }
        ,{headerName: "Model", field: "model",sortable: true, filter:true }
        ,{headerName: "Variant", field: "variant",sortable: true, filter:true }
        ,{headerName: "Plan", field: "planId",sortable: true, filter:true }
        ,{headerName: "Total Plan", field: "planTotalAmount",sortable: true, filter:true }
        ,{headerName: "Customer City", field: "city",sortable: true, filter:true }
        ,{headerName: "Customer State", field: "state",sortable: true, filter:true }
        ,{headerName: "Contact Number", field: "contactNumber",sortable: true, filter:true }
        ,{headerName: "Nominee Name", field: "nomineeName",sortable: true, filter:true }
        ,{headerName: "Nominee Relationship", field: "nomineeRelationship",sortable: true, filter:true }        
        ,{headerName: "Executive Username", field: "userName",sortable: true, filter:true }
        ,{headerName: "Certificate Status", field: "certificateStatus",sortable: true, filter:true }
        ,{headerName: "Issue Date", field: "issueDateString",sortable: true, filter:true }
        ,{headerName: "Created Date and Time", field: "createdDateString",sortable: true, filter:true }
      ]

    }
    }
    else {
      if (this.user.role == "dealerOwner")
      {
        this.gridOptions.columnDefs = [
          {headerName: "Certificate Id", field: "certificateNumber",sortable: true, filter:true }
          ,{headerName: "Executive Name", field: "userName",sortable: true, filter:true }
          ,{headerName: "Certificate Status", field: "certificateStatus",sortable: true, filter:true }
          ,{headerName: "First Name", field: "firstName",sortable: true, filter:true }
          ,{headerName: "Last Name", field: "lastName",sortable: true, filter:true }
          ,{headerName: "Contact Number", field: "contactNumber",sortable: true, filter:true }
          ,{headerName: "Customer DOB", field: "dob",sortable: true, filter:true }
          ,{headerName: "Customer Age", field: "age",sortable: true, filter:true }
          ,{headerName: "Customer City", field: "city",sortable: true, filter:true }
          ,{headerName: "Nominee Name", field: "nomineeName",sortable: true, filter:true }
          ,{headerName: "Nominee Relationship", field: "nomineeRelationship",sortable: true, filter:true }        
          ,{headerName: "Nominee Age", field: "nomineeAge",sortable: true, filter:true }        
          // ,{headerName: "Policy Number", field: "policyNumber",sortable: true, filter:true }
          ,{headerName: "VehicleMake", field: "vehicleMake",sortable: true, filter:true }
          ,{headerName: "Model", field: "model",sortable: true, filter:true }
          ,{headerName: "Variant", field: "variant",sortable: true, filter:true }
          ,{headerName: "Vehicle Year", field: "vehicleYear",sortable: true, filter:true }
          ,{headerName: "Registration Number", field: "registrationNumber",sortable: true, filter:true }
          ,{headerName: "Chasis Number", field: "chasisNumber",sortable: true, filter:true }
          ,{headerName: "Engine Number", field: "engineNumber",sortable: true, filter:true }
          ,{headerName: "Plan", field: "planId",sortable: true, filter:true }
          ,{headerName: "Dealer Plan Type", field: "dealerPlanType",sortable: true, filter:true }
          ,{headerName: "Total Paid Amount", field: "planTotalAmount",sortable: true, filter:true }
          ,{headerName: "Plan Amount", field: "planAmount",sortable: true, filter:true }
          ,{headerName: "plan GST Amount", field: "planGSTAmount",sortable: true, filter:true }
          ,{headerName: "Eligible Amount", valueGetter: (params)=>{return (params.data.planAmount - params.data.NetPlanAmount).toFixed(2)}, sortable: true, filter:true }
          ,{headerName: "Eligible GST Amount", valueGetter: (params)=>{return (params.data.planGSTAmount - params.data.NetPlanGSTAmount).toFixed(2)}, sortable: true, filter:true }
          ,{headerName: "Total Eligible Amount", valueGetter: (params)=>{return (params.data.planAmount - params.data.NetPlanAmount + params.data.planGSTAmount - params.data.NetPlanGSTAmount).toFixed(2)}, sortable: true, filter:true }
          ,{headerName: "Customer State", field: "state",sortable: true, filter:true }
          ,{headerName: "Issue Date", field: "issueDateString",sortable: true, filter:true }
          ,{headerName: "Dealer Id", field: "dealerId",sortable: true, filter:true }
          ,{headerName: "Dealer Name", field: "dealerName",sortable: true, filter:true }
          ,{headerName: "Created Timestamp", field: "createdDateString",sortable: true, filter:true }
          ,{headerName: "Email", field: "email",sortable: true, filter:true }
        ];
    
        this.dealerOwnerGridColumn();
      }
    else
    {
      if (this.user.role == "employee")
      this.gridOptions.columnDefs = [
        {headerName: "Certificate Id", field: "certificateNumber",sortable: true, filter:true }
        // ,{headerName: "Policy Number", field: "policyNumber",sortable: true, filter:true }
        ,{headerName: "Dealer Id", field: "dealerId",sortable: true, filter:true }
        ,{headerName: "Dealer Name", field: "dealerName",sortable: true, filter:true }
        // ,{headerName: "Dealer Balance", field: "dealerBalance",sortable: true, filter:true }
        ,{headerName: "First Name", field: "firstName",sortable: true, filter:true }
        ,{headerName: "Last Name", field: "lastName",sortable: true, filter:true }
        ,{headerName: "Registration Number", field: "registrationNumber",sortable: true, filter:true }
        ,{headerName: "Chasis Number", field: "chasisNumber",sortable: true, filter:true }
        ,{headerName: "Engine Number", field: "engineNumber",sortable: true, filter:true }
        ,{headerName: "Model", field: "model",sortable: true, filter:true }
        ,{headerName: "Variant", field: "variant",sortable: true, filter:true }
        ,{headerName: "Plan", field: "planId",sortable: true, filter:true }
        ,{headerName: "Total Paid Amount", field: "planTotalAmount",sortable: true, filter:true }
        ,{headerName: "Customer City", field: "city",sortable: true, filter:true }
        ,{headerName: "Customer State", field: "state",sortable: true, filter:true }
        ,{headerName: "Contact Number", field: "contactNumber",sortable: true, filter:true }
        ,{headerName: "Nominee Name", field: "nomineeName",sortable: true, filter:true }
        ,{headerName: "Nominee Relationship", field: "nomineeRelationship",sortable: true, filter:true }        
        ,{headerName: "Executive Username", field: "userName",sortable: true, filter:true }
        ,{headerName: "Certificate Status", field: "certificateStatus",sortable: true, filter:true }
        ,{headerName: "Issue Date", field: "issueDateString",sortable: true, filter:true }
        ,{headerName: "Created Date and Time", field: "createdDateString",sortable: true, filter:true }
      ]
      else
      if (this.user.specialRoleAccess.includes("ViewNetAmountGST"))
      {
        this.gridOptions.columnDefs = [
          {headerName: "Certificate Id", field: "certificateNumber",sortable: true, filter:true }
          ,{headerName: "Dealer Id", field: "dealerId",sortable: true, filter:true }
          ,{headerName: "Dealer Name", field: "dealerName",sortable: true, filter:true }
          // ,{headerName: "Dealer Balance", field: "dealerBalance",sortable: true, filter:true }
          ,{headerName: "First Name", field: "firstName",sortable: true, filter:true }
          ,{headerName: "Last Name", field: "lastName",sortable: true, filter:true }
          ,{headerName: "Contact Number", field: "contactNumber",sortable: true, filter:true }
          ,{headerName: "Customer DOB", field: "dob",sortable: true, filter:true }
          ,{headerName: "Customer Age", field: "age",sortable: true, filter:true }
          ,{headerName: "Customer City", field: "city",sortable: true, filter:true }
          ,{headerName: "Nominee Name", field: "nomineeName",sortable: true, filter:true }
          ,{headerName: "Nominee Relationship", field: "nomineeRelationship",sortable: true, filter:true }        
          ,{headerName: "Nominee Age", field: "nomineeAge",sortable: true, filter:true }        
          ,{headerName: "VehicleMake", field: "vehicleMake",sortable: true, filter:true }
          ,{headerName: "Model", field: "model",sortable: true, filter:true }
          ,{headerName: "Variant", field: "variant",sortable: true, filter:true }
          ,{headerName: "Vehicle Year", field: "vehicleYear",sortable: true, filter:true }
          ,{headerName: "Registration Number", field: "registrationNumber",sortable: true, filter:true }
          ,{headerName: "Chasis Number", field: "chasisNumber",sortable: true, filter:true }
          ,{headerName: "Engine Number", field: "engineNumber",sortable: true, filter:true }
          ,{headerName: "Plan", field: "planId",sortable: true, filter:true }
          ,{headerName: "Policy Type", field: "policyType",sortable: true, filter:true }
          ,{headerName: "Dealer Plan Type", field: "dealerPlanType",sortable: true, filter:true }
          ,{headerName: "Total Paid Amount", field: "planTotalAmount",sortable: true, filter:true }
          ,{headerName: "Plan Amount", field: "planAmount",sortable: true, filter:true }
          ,{headerName: "plan GST Amount", field: "planGSTAmount",sortable: true, filter:true }
            ,{headerName: "Customer State", field: "state",sortable: true, filter:true }
          ,{headerName: "Issue Date", field: "issueDateString",sortable: true, filter:true }
          ,{headerName: "Executive Name", field: "userName",sortable: true, filter:true }
          ,{headerName: "Certificate Status", field: "certificateStatus",sortable: true, filter:true }
          ,{headerName: "Created Date", field: "createdDateString",sortable: true, filter:true }
          ,{headerName: "Email", field: "email",sortable: true, filter:true }
          ,{headerName: "Invoice Type", field: "invoiceType",sortable: true, filter:true }
        ];
  
      }
      else
      {
      this.gridOptions.columnDefs = [
        {headerName: "Certificate Id", field: "certificateNumber",sortable: true, filter:true }
        ,{headerName: "Dealer Id", field: "dealerId",sortable: true, filter:true }
        ,{headerName: "Dealer Name", field: "dealerName",sortable: true, filter:true }
        // ,{headerName: "Dealer Balance", field: "dealerBalance",sortable: true, filter:true }
        ,{headerName: "First Name", field: "firstName",sortable: true, filter:true }
        ,{headerName: "Last Name", field: "lastName",sortable: true, filter:true }
        ,{headerName: "Contact Number", field: "contactNumber",sortable: true, filter:true }
        ,{headerName: "Customer DOB", field: "dob",sortable: true, filter:true }
        ,{headerName: "Customer Age", field: "age",sortable: true, filter:true }
        ,{headerName: "Customer City", field: "city",sortable: true, filter:true }
        ,{headerName: "Nominee Name", field: "nomineeName",sortable: true, filter:true }
        ,{headerName: "Nominee Relationship", field: "nomineeRelationship",sortable: true, filter:true }        
        ,{headerName: "Nominee Age", field: "nomineeAge",sortable: true, filter:true }        
        // ,{headerName: "Policy Number", field: "policyNumber",sortable: true, filter:true }
        ,{headerName: "VehicleMake", field: "vehicleMake",sortable: true, filter:true }
        ,{headerName: "Model", field: "model",sortable: true, filter:true }
        ,{headerName: "Variant", field: "variant",sortable: true, filter:true }
        ,{headerName: "Vehicle Year", field: "vehicleYear",sortable: true, filter:true }
        ,{headerName: "Registration Number", field: "registrationNumber",sortable: true, filter:true }
        ,{headerName: "Chasis Number", field: "chasisNumber",sortable: true, filter:true }
        ,{headerName: "Engine Number", field: "engineNumber",sortable: true, filter:true }
        ,{headerName: "Plan", field: "planId",sortable: true, filter:true }
        ,{headerName: "Policy Type", field: "policyType",sortable: true, filter:true }
        ,{headerName: "Dealer Plan Type", field: "dealerPlanType",sortable: true, filter:true }
        ,{headerName: "Total Paid Amount", field: "planTotalAmount",sortable: true, filter:true }
        ,{headerName: "Plan Amount", field: "planAmount",sortable: true, filter:true }
        ,{headerName: "plan GST Amount", field: "planGSTAmount",sortable: true, filter:true }
        ,{headerName: "Eligible Amount", valueGetter: (params)=>{return (params.data.planAmount - params.data.NetPlanAmount).toFixed(2)}, sortable: true, filter:true }
        ,{headerName: "Eligible GST Amount", valueGetter: (params)=>{return (params.data.planGSTAmount - params.data.NetPlanGSTAmount).toFixed(2)}, sortable: true, filter:true }
        ,{headerName: "Total Eligible Amount", valueGetter: (params)=>{return (params.data.planAmount - params.data.NetPlanAmount + params.data.planGSTAmount - params.data.NetPlanGSTAmount).toFixed(2)}, sortable: true, filter:true }
        ,{headerName: "Product Cost", field: "NetPlanAmount",sortable: true, filter:true }
        ,{headerName: "Product Net GST amount", field: "NetPlanGSTAmount",sortable: true, filter:true }
        ,{headerName: "Product Total Amount", field: "NetPlanTotalAmount",sortable: true, filter:true }
        ,{headerName: "State GST", field: "SgstAmount",sortable: true, filter:true }
        ,{headerName: "Center GST", field: "CgstAmount",sortable: true, filter:true }
        ,{headerName: "Integrated GST", field: "IgstAmount",sortable: true, filter:true }
        ,{headerName: "Customer State", field: "state",sortable: true, filter:true }
        ,{headerName: "Issue Date", field: "issueDateString",sortable: true, filter:true }
        ,{headerName: "Executive Name", field: "userName",sortable: true, filter:true }
        ,{headerName: "Certificate Status", field: "certificateStatus",sortable: true, filter:true }
        ,{headerName: "Created Date", field: "createdDateString",sortable: true, filter:true }
        ,{headerName: "Email", field: "email",sortable: true, filter:true }
        ,{headerName: "Invoice Type", field: "invoiceType",sortable: true, filter:true }
        ,{headerName:"Address", field: "address",sortable: true, filter:true }
        ,{headerName: "Gender", field:"gender",sortable: true, filter:true }
      ];
    }
    }
    }
  }
    // this.gridOptions.enableCharts = true;
    // this.gridOptions.enableRangeSelection = true;
    this.gridOptions.rowData = [];
    this.gridReady = true;
    
    this.certificateReportForm.get('issueDateFrom').valueChanges
      .subscribe(value => {
        if (value != undefined) {
          this.issueDateFromSearch = value.year + '-' + ('0' + value.month).slice(-2) + '-' + ('0' + value.day).slice(-2);
        }
      });

    this.certificateReportForm.get('issueDateTo').valueChanges
      .subscribe(value => {
        if (value != undefined) {
          this.issueDateToSearch = value.year + '-' + ('0' + value.month).slice(-2) + '-' + ('0' + value.day).slice(-2);
        }
      });
  }

  dealerOwnerGridColumn() {
    this.dealerService.getByDealerId(this.user.dealerId).subscribe((y) => {
      this.dealer = y;
      if (this.dealer.dealerInvoiceShow && this.dealer.dealerInvoiceShow == "No") {
        this.aggrid.api.setColumnDefs([
          // ...existing column definitions...
          {headerName: "Certificate Id", field: "certificateNumber", sortable: true, filter: true},
          // ...more columns...
        ]);
      }
    });
  }

  onSubmit() {
    this.submitted = true;
    this.loading = true;
    this.alertService.clear();
    
    // Show loading overlay on grid
    if (this.gridApi) {
      this.gridApi.showLoadingOverlay();
    }

    if (this.user.id == undefined) {
      this.alertService.error("User Credential Missing, Please login again and try");
      return;
    }

    this.reportSearch = new ReportSearch();
    this.reportSearch.userId = this.user.id;
    
    if (this.issueDateFromSearch == undefined) {
      this.reportSearch.issueDateFrom = "0001-01-01";
    } else {
      this.reportSearch.issueDateFrom = this.issueDateFromSearch;
    }

    if (this.issueDateToSearch == undefined) {
      this.reportSearch.issueDateTo = "9999-99-99";
    } else {
      this.reportSearch.issueDateTo = this.issueDateToSearch;
    }
    this.reportSearch.certificateNumber = this.certificateReportForm.get('certificateNumber').value;
    this.reportSearch.firstName = this.certificateReportForm.get('firstName').value;
    this.reportSearch.lastName = this.certificateReportForm.get('lastName').value;
    this.reportSearch.userName = this.certificateReportForm.get('userName').value;
    this.reportSearch.dealerId = this.certificateReportForm.get('dealerId').value;
    this.reportSearch.dealerGroupName = this.certificateReportForm.get('dealerGroupName').value;
    this.reportSearch.startDateOrIssueDate = this.certificateReportForm.get('startDateOrIssueDate').value;  
    
    const updateSuccessHandler = (x) => {
      if (!x || x.length === 0) {
        this.alertService.warn("No data found matching your criteria.");
        this.rowData = [];
        this.hasResults = false;
        if (this.gridApi) {
          this.gridApi.showNoRowsOverlay();
        }
        this.loading = false;
        return;
      }
      
      this.reportCertificateData = x.map(a => {
        a.createdDateString = new Date(a.createdDate).toString();
        if (this.id == "NewIndiaPolicy") {
          a.endDate = new Date(a.endDate).getFullYear() + "-" + 
            ('0' + (new Date(a.endDate).getMonth() + 1)).slice(-2) + "-" + 
            ('0' + (new Date(a.endDate).getDate())).slice(-2) + " MIDNIGHT";
        }
        return a;
      });

      if (this.id == "NewIndiaPolicy") {
        this.reportCertificateData = this.reportCertificateData.filter(x => x.policyNumber != "NA");
      }
      
      // Update grid data and UI state
      this.rowData = this.reportCertificateData;
      this.hasResults = this.reportCertificateData.length > 0;
      this.resultCount = this.reportCertificateData.length;
      this.lastUpdated = new Date();
      
      // Ensure grid has data properly set
      if (this.gridApi) {
        this.gridApi.setRowData(this.reportCertificateData);
        
        // Hide loading overlay
        if (this.reportCertificateData.length > 0) {
          this.gridApi.hideOverlay();
        } else {
          this.gridApi.showNoRowsOverlay();
        }
      } else if (this.aggrid && this.aggrid.api) {
        this.aggrid.api.setRowData(this.reportCertificateData);
      }
      
      this.gridReady = true;
      this.loading = false;
    };

    if ((this.user.role == "admin") || (this.user.role == "dealerOwner")) {
      this.searchService.getAdminCertificateReport(this.reportSearch)
        .pipe(first())
        .subscribe({
          next: updateSuccessHandler,
          error: error => {
            this.alertService.error(error);
            this.rowData = [];
            this.hasResults = false;
            this.loading = false;
          }
        });  
    } else {
      this.searchService.getCertificateReport(this.reportSearch)
        .pipe(first())
        .subscribe({
          next: updateSuccessHandler,
          error: error => {
            this.alertService.error(error);
            this.loading = false;
            this.rowData = [];
            this.hasResults = false;
          }
        });
    }
  }

  onReset() {
    // Reset the form
    this.certificateReportForm.reset();
    
    // Set default values
    this.certificateReportForm.patchValue({
      startDateOrIssueDate: this.startOrIssueDateOptions[1].id
    });
    
    // Reset UI state
    this.hasResults = false;
    this.resultCount = 0;
    
    // Reset grid
    if (this.gridApi) {
      this.gridApi.setRowData([]);
    }
    
    this.snackBar.open('Form has been reset', 'Dismiss', {
      duration: 3000,
      horizontalPosition: 'end',
      panelClass: ['snackbar-success']
    });
  }

  // New methods for enhanced UI
  toggleFilterPanel() {
    this.showFilters = !this.showFilters;
    localStorage.setItem('certificateReport_showFilters', String(this.showFilters));
  }
  
  toggleDateFilters() {
    this.showDateFilters = !this.showDateFilters;
    localStorage.setItem('certificateReport_showDateFilters', String(this.showDateFilters));
  }
  
  toggleDarkMode() {
    this.darkMode = !this.darkMode;
    document.body.classList.toggle('dark-theme', this.darkMode);
    localStorage.setItem('certificateReport_darkMode', String(this.darkMode));
  }
  
  toggleCompactMode() {
    this.compactMode = !this.compactMode;
    localStorage.setItem('certificateReport_compactMode', String(this.compactMode));
  }
  
  toggleKeyboardShortcuts() {
    this.keyboardShortcutsEnabled = !this.keyboardShortcutsEnabled;
    localStorage.setItem('certificateReport_keyboardShortcuts', String(this.keyboardShortcutsEnabled));
    this.snackBar.open(`Keyboard shortcuts ${this.keyboardShortcutsEnabled ? 'enabled' : 'disabled'}`, 'OK', {
      duration: 2000
    });
  }
  
  showWelcomeMessage() {
    if (!localStorage.getItem('certificateReport_welcomeSeen')) {
      this.snackBar.open('Welcome to the enhanced Certificate Report! Press F1 for keyboard shortcuts.', 'Got it', {
        duration: 5000
      });
      localStorage.setItem('certificateReport_welcomeSeen', 'true');
    }
  }
  
  showKeyboardShortcutsHelp() {
    this.alertService.info(`
      <h4>Keyboard Shortcuts</h4>
      <ul>
        <li><strong>Ctrl + Enter:</strong> Run search</li>
        <li><strong>Ctrl + R:</strong> Reset form</li>
        <li><strong>Ctrl + E:</strong> Export to CSV</li>
        <li><strong>F1:</strong> Show this help</li>
      </ul>
    `);
  }
  
  setDateRange(range: string) {
    const today = new Date();
    const startDate: NgbDateStruct = {
      year: today.getFullYear(),
      month: today.getMonth() + 1,
      day: today.getDate()
    };
    const endDate: NgbDateStruct = {
      year: today.getFullYear(),
      month: today.getMonth() + 1,
      day: today.getDate()
    };
    
    switch (range) {
      case 'today':
        // startDate and endDate are already set to today
        break;
      case 'week':
        // Set start date to beginning of week (Sunday)
        const dayOfWeek = today.getDay();
        const diff = today.getDate() - dayOfWeek;
        const weekStartDate = new Date(today);
        weekStartDate.setDate(diff);
        startDate.year = weekStartDate.getFullYear();
        startDate.month = weekStartDate.getMonth() + 1;
        startDate.day = weekStartDate.getDate();
        break;
      case 'month':
        // Set start date to beginning of month
        startDate.day = 1;
        break;
      case 'year':
        // Set start date to beginning of year
        startDate.month = 1;
        startDate.day = 1;
        break;
      default:
        return;
    }
    
    this.certificateReportForm.patchValue({
      issueDateFrom: startDate,
      issueDateTo: endDate
    });
    
    // Update the search strings
    this.issueDateFromSearch = `${startDate.year}-${('0' + startDate.month).slice(-2)}-${('0' + startDate.day).slice(-2)}`;
    this.issueDateToSearch = `${endDate.year}-${('0' + endDate.month).slice(-2)}-${('0' + endDate.day).slice(-2)}`;
    
    // Show notification
    const rangeText = range.charAt(0).toUpperCase() + range.slice(1);
    this.snackBar.open(`Date range set to: ${rangeText}`, '', {
      duration: 2000
    });
  }
  
  applyActiveFilter(id:number) {
    // Apply an active certificates filter
    this.certificateReportForm.patchValue({
      startDateOrIssueDate: this.startOrIssueDateOptions[id].id
    });
    this.setDateRange('today');
    this.onSubmit();
  }
  

  exportToCsv() {
    if (!this.hasResults) {
      this.snackBar.open('No results to export', 'OK', { duration: 3000 });
      return;
    }
    
    const params = {
      columnGroups: true,
      fileName: `certificate_report_${new Date().toISOString().split('T')[0]}`,
      columnSeparator: ','
    };
    this.aggrid.api.exportDataAsCsv(params);
    
    this.snackBar.open('Report exported to CSV', 'Dismiss', {
      duration: 3000,
      panelClass: ['snackbar-success']
    });
  }
  
  saveReport() {
    const reportConfig = {
      name: `Report ${new Date().toISOString()}`,
      filters: this.certificateReportForm.value,
      dateCreated: new Date(),
      user: this.user.id
    };
    
    localStorage.setItem(`savedReport_${Date.now()}`, JSON.stringify(reportConfig));
    
    this.snackBar.open('Report configuration saved', 'View Saved Reports', {
      duration: 5000
    }).onAction().subscribe(() => {
      // Could navigate to a saved reports page
      this.alertService.info('Saved reports can be accessed from the main menu.');
    });
  }
  
  onGridReady(params) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    
    // Ensure grid is properly set up
    this.gridApi.setDomLayout('autoHeight');
    
    // Auto-size columns
    this.columnApi.autoSizeAllColumns();
    
    // Show loading overlay until data is ready
    this.gridApi.showLoadingOverlay();
    
    // If we already have data, set it
    if (this.reportCertificateData && this.reportCertificateData.length > 0) {
      this.gridApi.setRowData(this.reportCertificateData);
      this.gridApi.hideOverlay();
    }
  }
}
