<h1>Dealership</h1>
<a routerLink="addDealer" class="btn btn-sm btn-primary mb-2">Add Dealership</a><a>&nbsp;&nbsp;</a>
<a routerLink="add" class="btn btn-sm btn-success mb-2">Add User</a> 
<form [formGroup]="form">
    <div class="form-row">
        <div class="form-group col">
            <label for="dealerId">Dealer Id</label>
            <input type="text" formControlName="dealerId" class="form-control" />
        </div>
        <div class="form-group col">
            <label for="dealerName">Dealer Name</label>
            <input type="text" formControlName="dealerName" class="form-control" />
        </div>
        <div class="form-group col">
            <label for="dealerGroupName">Group Name</label>
            <input type="text" formControlName="dealerGroupName" class="form-control" />
        </div>
        <div class="form-group col">
            <label for="dealerCity"> Dealer City</label>
            <input type="text" formControlName="dealerCity" class="form-control" />
        </div>
        <div class="form-group col">
            <label for="dealerState"> Dealer State</label>
            <input type="text" formControlName="dealerState" class="form-control" />
        </div>
        <div class="form-group col">
            <label for="dealerType"> Dealer Type</label>
            <select class="form-select" formControlName="dealerType" >
                <option *ngFor="let role of dealerTypes" [ngValue]="role">
                  {{ role }}
                </option>
              </select>
        </div>
    </div>

    <div class="form-row">
        <div class="form-group col" style="overflow:scroll">
<table class="table table-striped" >
    <thead>
        <tr>
            <th style="width: 10%">Dealer Id</th>
            <th style="width: 10%">Dealer Name</th>
            <th style="width: 10%">Group Name</th>
            <th style="width: 20%">Dealer City</th>
            <th style="width: 20%">Dealer State</th>
            <th style="width: 20%">Contact</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let dealer of dealers">
            <td>{{dealer.dealerId}}</td>
            <td>{{dealer.dealerName}}</td>
            <td>{{dealer.dealerGroupName}}</td>
            <td>{{dealer.dealerCity}}</td>
            <td>{{dealer.dealerState}}</td>
            <td>{{dealer.dealerContactNumber}}</td>
            <td style="white-space: nowrap">
                <a routerLink="editDealer/{{dealer.dealerId}}" class="btn btn-sm btn-primary mr-1">Edit</a>
            </td>
            <td style="white-space: nowrap">
                <a routerLink="add/{{dealer.dealerId}}" class="btn btn-sm btn-success mr-1">Add User</a>
            </td>
        </tr>
        <tr *ngIf="!dealers">
            <td colspan="4" class="text-center">
                <span class="spinner-border spinner-border-lg align-center"></span>
            </td>
        </tr>
    </tbody>
</table>
</div>
</div>
</form>

