<div class="certificate-report-container" [ngClass]="{'dark-mode': darkMode, 'compact-mode': compactMode}">
    <div class="card main-card">
        <div class="card-header d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
                <h1 class="mb-0 mr-3">Certificate Report</h1>
                <span *ngIf="hasResults" class="badge badge-info results-badge animate-pulse">
                    {{resultCount}} records
                </span>
            </div>
            <div class="controls">
                <button class="btn btn-sm" [ngClass]="showFilters ? 'btn-primary' : 'btn-outline-secondary'" (click)="toggleFilterPanel()" matTooltip="Toggle advanced filters">
                    <mat-icon>{{showFilters ? 'keyboard_arrow_up' : 'filter_list'}}</mat-icon>
                </button>
                <button class="btn btn-sm" [ngClass]="showDateFilters ? 'btn-primary' : 'btn-outline-secondary'" (click)="toggleDateFilters()" matTooltip="Toggle date filters">
                    <mat-icon>{{showDateFilters ? 'date_range' : 'calendar_today'}}</mat-icon>
                </button>
                <button class="btn btn-sm btn-outline-secondary" (click)="toggleCompactMode()" matTooltip="Toggle compact view">
                    <mat-icon>{{compactMode ? 'fullscreen_exit' : 'fullscreen'}}</mat-icon>
                </button>
                <button class="btn btn-sm btn-outline-secondary" (click)="toggleDarkMode()" matTooltip="Toggle dark mode">
                    <mat-icon>{{darkMode ? 'wb_sunny' : 'dark_mode'}}</mat-icon>
                </button>
                <button class="btn btn-sm btn-outline-secondary" (click)="toggleKeyboardShortcuts()" matTooltip="Toggle keyboard shortcuts">
                    <mat-icon [ngClass]="keyboardShortcutsEnabled ? '' : 'text-muted'">keyboard</mat-icon>
                </button>
            </div>
        </div>

        <div class="card-body">
            <form [formGroup]="certificateReportForm" (ngSubmit)="onSubmit()">
                <!-- Quick Preset Filters -->
                <div class="preset-filters mb-3">
                    <div class="d-flex justify-content-between align-items-center">
                        <h6 class="mb-2">Quick Filters:</h6>
                        <div class="btn-group btn-group-sm">
                            <button type="button" *ngFor="let preset of presetFilters" 
                                    class="btn btn-outline-primary"
                                    (click)="preset.filter()">
                                {{preset.name}}
                            </button>
                        </div>
                    </div>
                </div>

                <!-- Date Filters Panel (Always visible by default) -->
                <div class="date-filter-panel" [ngClass]="{'collapsed': !showDateFilters}">
                    <div class="row">
                        <div class="col-lg-3 col-md-4 col-sm-6 mb-3">
                            <label for="startDateOrIssueDate">Date Type</label>
                            <select formControlName="startDateOrIssueDate" class="form-control" 
                                [ngClass]="{ 'is-invalid': submitted && f.startDateOrIssueDate.errors }">
                                <option *ngFor="let dateType of startOrIssueDateOptions" [value]="dateType.id">{{dateType.name}}</option>
                            </select>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 mb-3">
                            <label for="issueDateFrom">From Date</label>
                            <div class="input-group datepicker-container">
                                <input class="form-control" formControlName="issueDateFrom" ngbDatepicker #d="ngbDatepicker" 
                                    [ngClass]="{ 'is-invalid': submitted && f.issueDateFrom.errors }"
                                    placeholder="yyyy-mm-dd">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
                                        <mat-icon>calendar_today</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-4 col-sm-6 mb-3">
                            <label for="issueDateTo">To Date</label>
                            <div class="input-group datepicker-container">
                                <input class="form-control" formControlName="issueDateTo" ngbDatepicker #issueDateTo="ngbDatepicker" 
                                    [ngClass]="{ 'is-invalid': submitted && f.issueDateTo.errors }">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary" (click)="issueDateTo.toggle()" type="button">
                                        <mat-icon>calendar_today</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-12 col-sm-12 mb-3">
                            <label>Quick Date Ranges</label>
                            <div class="btn-group d-flex">
                                <button type="button" class="btn btn-sm btn-outline-secondary" (click)="setDateRange('today')">Today</button>
                                <button type="button" class="btn btn-sm btn-outline-secondary" (click)="setDateRange('week')">This Week</button>
                                <button type="button" class="btn btn-sm btn-outline-secondary" (click)="setDateRange('month')">This Month</button>
                                <button type="button" class="btn btn-sm btn-outline-secondary" (click)="setDateRange('year')">This Year</button>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Other Filters Panel (Hidden by default) -->
                <div class="filter-panel" [ngClass]="{'collapsed': !showFilters}">
                    <div class="row">
                        <div class="col-md-4 col-sm-6 mb-3">
                            <label for="certificateNumber">Certificate Number</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><mat-icon>badge</mat-icon></span>
                                </div>
                                <input type="text" formControlName="certificateNumber" class="form-control" 
                                    placeholder="Enter certificate number" />
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 mb-3">
                            <label for="firstName">Client First Name</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><mat-icon>person</mat-icon></span>
                                </div>
                                <input type="text" formControlName="firstName" class="form-control" 
                                    placeholder="Enter first name" />
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 mb-3">
                            <label for="lastName">Client Last Name</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><mat-icon>person</mat-icon></span>
                                </div>
                                <input type="text" formControlName="lastName" class="form-control" 
                                    placeholder="Enter last name" />
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 mb-3">
                            <label for="userName">Executive User Name</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><mat-icon>account_circle</mat-icon></span>
                                </div>
                                <input type="text" formControlName="userName" class="form-control" 
                                    placeholder="Enter username" />
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 mb-3" *ngIf='user.role == "admin"'>
                            <label for="dealerId">Dealer Id</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><mat-icon>business</mat-icon></span>
                                </div>
                                <input type="text" formControlName="dealerId" class="form-control" 
                                    placeholder="Enter dealer ID" />
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 mb-3" *ngIf='user.role == "admin" || user.role == "employee"'>
                            <label for="dealerGroupName">Dealer Group Name</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><mat-icon>domain</mat-icon></span>
                                </div>
                                <input type="text" formControlName="dealerGroupName" class="form-control" 
                                    placeholder="Enter dealer group name" />
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Action Buttons -->
                <div class="action-bar">
                    <div class="primary-actions">
                        <button [disabled]="loading" class="btn btn-primary" type="submit" matTooltip="Ctrl+Enter">
                            <mat-icon class="mr-1">search</mat-icon>
                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                            Search
                        </button>
                        <button class="btn btn-secondary" type="reset" (click)="onReset()" matTooltip="Ctrl+R">
                            <mat-icon class="mr-1">refresh</mat-icon> Reset
                        </button>
                    </div>
                    
                    <div class="secondary-actions">
                        <button mat-raised-button 
                            color="accent" 
                            [disabled]="!hasResults || loading"
                            (click)="exportToCsv()"
                            matTooltip="Export to CSV (Ctrl+E)">
                            <mat-icon class="mr-1">file_download</mat-icon> Export CSV
                        </button>
                        
                        <button mat-stroked-button
                            color="primary"
                            (click)="saveReport()" 
                            [disabled]="!hasResults"
                            matTooltip="Save current report configuration">
                            <mat-icon class="mr-1">bookmark_border</mat-icon> Save Report
                        </button>
                    </div>
                </div>

                <!-- Results Section -->
                <div class="results-section" *ngIf="hasResults">
                    <div class="results-header">
                        <div class="results-info">
                            <div class="result-count">
                                <span class="count">{{resultCount}}</span>
                                <span class="label">Records found</span>
                            </div>
                            <div class="last-updated">
                                <span class="label">Last updated:</span>
                                <span class="time">{{lastUpdated | date:'medium'}}</span>
                            </div>
                        </div>
                        
                        <div class="grid-actions">
                            <button class="btn btn-sm btn-outline-secondary" (click)="gridApi.sizeColumnsToFit()">
                                <mat-icon>swap_horiz</mat-icon> Fit Columns
                            </button>
                            <button class="btn btn-sm btn-outline-secondary" (click)="gridApi.setDomLayout(compactMode ? 'normal' : 'autoHeight')">
                                <mat-icon>{{compactMode ? 'swap_vert' : 'open_with'}}</mat-icon> {{ compactMode ? 'Expand' : 'Compact' }}
                            </button>
                        </div>
                    </div>
                    
                    <!-- Grid View -->
                    <div class="grid-container">
                        <ag-grid-angular #aggrid
                            class="ag-theme-alpine reports-grid"
                            [ngClass]="{'dark-theme': darkMode}"
                            [gridOptions]="gridOptions"
                            [rowData]="rowData"
                            [pagination]="true"
                            [paginationPageSize]="20"
                            (gridReady)="onGridReady($event)">
                        </ag-grid-angular>
                    </div>
                </div>
                
                <!-- Empty State -->
                <div class="empty-state" *ngIf="!hasResults && !loading">
                    <div class="empty-state-content">
                        <mat-icon style="font-size: 48px; height: 48px; width: 48px; margin-bottom: 16px">search</mat-icon>
                        <h3>No Records Found</h3>
                        <p>Use the filters above to search for certificates</p>
                        <button class="btn btn-primary" type="submit">
                            <mat-icon class="mr-1">search</mat-icon> Search
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    
    <!-- Keyboard Shortcuts Hint -->
    <div class="keyboard-shortcut-hint" *ngIf="keyboardShortcutsEnabled">
        <span><mat-icon inline>keyboard</mat-icon> Press F1 for keyboard shortcuts</span>
    </div>
    
    <!-- Loading overlay -->
    <div *ngIf="loading" class="loading-overlay">
        <div class="spinner-container">
            <mat-progress-spinner mode="indeterminate" diameter="50"></mat-progress-spinner>
            <p class="mt-2">Loading data...</p>
        </div>
    </div>
</div>