import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderBase } from '../base/header-base.interface';

@Component({
  selector: 'app-dealer-owner-header',
  templateUrl: './dealer-owner-header.component.html',
  standalone: true,
  imports: [CommonModule, RouterModule]
})
export class DealerOwnerHeaderComponent implements HeaderBase {
  @Input() user: any;
  @Input() dealer: any;
  @Input() showReceivableTab: boolean = false;

  collapseNavbar(): void {
    const navbarToggler = document.querySelector('.navbar-toggler') as HTMLElement;
    const navbarCollapse = document.querySelector('.navbar-collapse') as HTMLElement;
    
    if (window.innerWidth < 992 && navbarCollapse.classList.contains('show')) {
      navbarToggler.click();
    }
  }

  logout(): void {
    const event = new CustomEvent('logout');
    window.dispatchEvent(event);
  }

  getInitials(): string {
    return this.user && this.user.firstName && this.user.lastName ? 
      this.user.firstName.charAt(0) + this.user.lastName.charAt(0) : 'AA';
  }
}
