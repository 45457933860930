<ul class="navbar-nav me-auto mb-2 mb-lg-0">
  <!-- Home -->
  <li class="nav-item">
    <a class="nav-link active" aria-current="page" routerLink="/" routerLinkActive="active" 
       [routerLinkActiveOptions]="{exact: true}" (click)="collapseNavbar()">
       <i class="fa fa-dashboard me-1"></i>Dashboard
    </a>
  </li>
  
  <!-- Dealer Management -->
  <li class="nav-item">
    <a class="nav-link" routerLink="/users" routerLinkActive="active" (click)="collapseNavbar()">
      <i class="fa fa-briefcase me-1"></i>Dealer Management
    </a>
  </li>

  <!-- Certificate Management Dropdown -->
  <li class="nav-item dropdown">
    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownCertManagement" role="button" 
       data-bs-toggle="dropdown" aria-expanded="false">
       <i class="fa fa-certificate me-1"></i>Certificate Management
    </a>
    <ul class="dropdown-menu shadow" aria-labelledby="navbarDropdownCertManagement">
      <li>
        <a class="dropdown-item" routerLink="/issueCertificate" 
           routerLinkActive="active" (click)="collapseNavbar()">
           <i class="fa fa-plus-square me-2"></i>Issue Certificate
        </a>
      </li>
      <li *ngIf="user.specialRoleAccess && user.specialRoleAccess.includes('ExcelUpload')">
        <a class="dropdown-item" routerLink="/issueCertificate/bulkIndividual" 
           routerLinkActive="active" (click)="collapseNavbar()">
           <i class="fa fa-upload me-2"></i>Bulk Upload
        </a>
      </li>
      <li>
        <a class="dropdown-item" routerLink="/reports/printCertificate" 
           routerLinkActive="active" (click)="collapseNavbar()">
           <i class="fa fa-print me-2"></i>Print Certificate
        </a>
      </li>
      <li *ngIf="user.specialRoleAccess && user.specialRoleAccess.includes('CanRenewAllPolicies')">
        <a class="dropdown-item" routerLink="/reports/renewCertificate" 
           routerLinkActive="active" (click)="collapseNavbar()">
           <i class="fa fa-refresh me-2"></i>Renew Certificate
        </a>
      </li>
    </ul>
  </li>
  
  <!-- NEW: Active Requests as a separate dropdown -->
  <li class="nav-item dropdown">
    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownActiveRequests" role="button" 
       data-bs-toggle="dropdown" aria-expanded="false">
       <i class="fa fa-tasks me-1"></i>Active Requests
    </a>
    <ul class="dropdown-menu shadow" aria-labelledby="navbarDropdownActiveRequests">
      <li>
        <a class="dropdown-item" routerLink="/reports/activeEndorsement" 
           routerLinkActive="active" (click)="collapseNavbar()">
           <i class="fa fa-file-text-o me-2"></i>Active Endorsement Request
        </a>
      </li>
      <li>
        <a class="dropdown-item" routerLink="/reports/activeCancel" 
           routerLinkActive="active" (click)="collapseNavbar()">
           <i class="fa fa-ban me-2"></i>Active Cancel Request
        </a>
      </li>
    </ul>
  </li>
  
  <!-- Admin Control Panel Dropdown (now without Active Requests) -->
  <li class="nav-item dropdown">
    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownAdminActions" role="button" 
       data-bs-toggle="dropdown" aria-expanded="false">
       <i class="fa fa-gavel me-1"></i>Admin Control Panel
    </a>
    <ul class="dropdown-menu shadow config-mega-menu" aria-labelledby="navbarDropdownAdminActions">
      <!-- Configuration Updates Section -->
      <div class="config-scroll-container">
        <!-- CPA & Master Policy Updates -->
        <div class="row mb-2">
          <div class="col-12">
            <li class="dropdown-header border-bottom pb-2">CPA & Master Policy Updates</li>
            <div class="row">
              <div class="col-md-6">
                <li *ngFor="let polMaintain of masterPolicyMaintain; let i = index">
                  <a *ngIf="i < masterPolicyMaintain.length/2" class="dropdown-item" [routerLink]="polMaintain.url" 
                    routerLinkActive="active" (click)="collapseNavbar()">
                    <i class="fa fa-wrench me-2"></i>{{polMaintain.heading}}
                  </a>
                </li>
              </div>
              <div class="col-md-6">
                <li *ngFor="let polMaintain of masterPolicyMaintain; let i = index">
                  <a *ngIf="i >= masterPolicyMaintain.length/2" class="dropdown-item" [routerLink]="polMaintain.url" 
                    routerLinkActive="active" (click)="collapseNavbar()">
                    <i class="fa fa-wrench me-2"></i>{{polMaintain.heading}}
                  </a>
                </li>
              </div>
            </div>
          </div>
        </div>

        <!-- Series Updates -->
        <div class="row mb-2">
          <div class="col-12">
            <li class="dropdown-header border-bottom pb-2">Series Updates</li>
            <div class="row">
              <div class="col-md-6">
                <li *ngFor="let serMaintain of seriesMaintain; let i = index">
                  <a *ngIf="i < seriesMaintain.length/2" class="dropdown-item" [routerLink]="serMaintain.url" 
                    routerLinkActive="active" (click)="collapseNavbar()">
                    <i class="fa fa-cog me-2"></i>{{serMaintain.heading}}
                  </a>
                </li>
              </div>
              <div class="col-md-6">
                <li *ngFor="let serMaintain of seriesMaintain; let i = index">
                  <a *ngIf="i >= seriesMaintain.length/2" class="dropdown-item" [routerLink]="serMaintain.url" 
                    routerLinkActive="active" (click)="collapseNavbar()">
                    <i class="fa fa-cog me-2"></i>{{serMaintain.heading}}
                  </a>
                </li>
              </div>
            </div>
          </div>
        </div>

        <!-- System Setup -->
        <div class="row mb-2">
          <div class="col-12">
            <li class="dropdown-header border-bottom pb-2">System Setup</li>
            <div class="row">
              <div class="col-md-6">
                <li *ngFor="let setMaintain of setupMaintain; let i = index">
                  <a *ngIf="i < setupMaintain.length/2" class="dropdown-item" [routerLink]="setMaintain.url" 
                    routerLinkActive="active" (click)="collapseNavbar()">
                    <i class="fa fa-sliders me-2"></i>{{setMaintain.heading}}
                  </a>
                </li>
              </div>
              <div class="col-md-6">
                <li *ngFor="let setMaintain of setupMaintain; let i = index">
                  <a *ngIf="i >= setupMaintain.length/2" class="dropdown-item" [routerLink]="setMaintain.url" 
                    routerLinkActive="active" (click)="collapseNavbar()">
                    <i class="fa fa-sliders me-2"></i>{{setMaintain.heading}}
                  </a>
                </li>
              </div>
            </div>
          </div>
        </div>

        <!-- User Management -->
        <div class="row">
          <div class="col-12">
            <li class="dropdown-header border-bottom pb-2">User Management</li>
            <div class="row">
              <div class="col-md-12">
                <li>
                  <a class="dropdown-item" routerLink="/empDealerAssign" routerLinkActive="active" (click)="collapseNavbar()">
                    <i class="fa fa-user-plus me-2"></i>Employee Dealer Assignment
                  </a>
                </li>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ul>
  </li>

  <!-- Reports & Analytics Dropdown -->
  <li class="nav-item dropdown">
    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownReportsAnalytics" role="button" 
       data-bs-toggle="dropdown" aria-expanded="false">
       <i class="fa fa-chart-pie me-1"></i>Reports & Analytics
    </a>
    <ul class="dropdown-menu shadow mega-menu" aria-labelledby="navbarDropdownReportsAnalytics">
      <div class="row">
        <div class="col-md-6">
          <li class="dropdown-header">Standard Reports</li>
          <li>
            <a class="dropdown-item" routerLink="/reports/certificateReport" 
               routerLinkActive="active" (click)="collapseNavbar()">
               <i class="fa fa-file me-2"></i>Certificate Report
            </a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="/reports/dealerReport" 
               routerLinkActive="active" (click)="collapseNavbar()">
               <i class="fa fa-users me-2"></i>Dealer Report
            </a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="/reports/dealerAggregateReport" 
               routerLinkActive="active" (click)="collapseNavbar()">
               <i class="fa fa-bar-chart me-2"></i>Dealer Aggregate Report
            </a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="/reports/dealerInactivityReport" 
               routerLinkActive="active" (click)="collapseNavbar()">
               <i class="fa fa-clock-o me-2"></i>Dealer Inactivity Report
            </a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="/reports/dealerSummaryReport" 
               routerLinkActive="active" (click)="collapseNavbar()">
               <i class="fa fa-table me-2"></i>Dealer Summary Report
            </a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="/reports/heroDealerSummaryReport" 
               routerLinkActive="active" (click)="collapseNavbar()">
               <i class="fa fa-star me-2"></i>Hero Dealer Summary Report
            </a>
          </li>
        </div>
        <div class="col-md-6">
          <li class="dropdown-header">Financial & Special Reports</li>
          <li>
            <a class="dropdown-item" routerLink="/reports/rsaCertificate" 
               routerLinkActive="active" (click)="collapseNavbar()">
               <i class="fa fa-id-card me-2"></i>Rsa Certificate
            </a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="/reports/endorsementReport" 
               routerLinkActive="active" (click)="collapseNavbar()">
               <i class="fa fa-pencil-square-o me-2"></i>Endorsement Report
            </a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="/reports/dealerBalanceReport" 
               routerLinkActive="active" (click)="collapseNavbar()">
               <i class="fa fa-balance-scale me-2"></i>Dealer Balance Details
            </a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="/reports/transactionReport" 
               routerLinkActive="active" (click)="collapseNavbar()">
               <i class="fa fa-money me-2"></i>Payment Report
            </a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="/reports/invoiceReport" 
               routerLinkActive="active" (click)="collapseNavbar()">
               <i class="fa fa-file-text-o me-2"></i>Invoice Report
            </a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="/reports/payoutReport" 
               routerLinkActive="active" (click)="collapseNavbar()">
               <i class="fa fa-credit-card me-2"></i>Payout Report
            </a>
          </li>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <li class="dropdown-header">Special Reports</li>
          <li>
            <a class="dropdown-item" routerLink="/reports/certificateReport/NewIndiaPolicy" 
               routerLinkActive="active" (click)="collapseNavbar()">
               <i class="fa fa-file-o me-2"></i>New India Report
            </a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="/reports/policyBalanceReport" 
               routerLinkActive="active" (click)="collapseNavbar()">
               <i class="fa fa-list me-2"></i>Policy Balance Details
            </a>
          </li>
          <li>
            <a class="dropdown-item" routerLink="/reports/totalBalanceReport" 
               routerLinkActive="active" (click)="collapseNavbar()">
               <i class="fa fa-briefcase me-2"></i>Package Balance Details
            </a>
          </li>
        </div>
        <div class="col-md-6">
          <li class="dropdown-header">Analytics</li>
          <li>
            <a class="dropdown-item" routerLink="/analytics/receivable" 
               routerLinkActive="active" (click)="collapseNavbar()">
               <i class="fa fa-line-chart me-2"></i>Receivable Analytics
            </a>
          </li>
        </div>
      </div>
    </ul>
  </li>
</ul>
