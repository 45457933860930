import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatChipsModule } from '@angular/material/chips';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { EditEmployeeDealersDialog } from './edit-employee-dealers.dialog';
import { AccountService, AlertService } from '@app/_services';
import { DealerService } from '@app/_services/dealer-service';
import { first } from 'rxjs';
import * as XLSX from 'xlsx';
// Import AG Grid modules
import { AgGridModule } from 'ag-grid-angular';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import { DetailPopupComponent } from './detail-popup.component';
import { Employee, Dealer } from './models/employee-dealer.models';
import { EmployeeManagementComponent } from './employee-management/employee-management.component';
import { DealerManagementComponent } from './dealer-management/dealer-management.component';

@Component({
  selector: 'app-employee-dealer-assignment',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    MatChipsModule,
    MatTabsModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    MatProgressSpinnerModule,
    EditEmployeeDealersDialog,
    AgGridModule,
    EmployeeManagementComponent,
    DealerManagementComponent
  ],
  templateUrl: 'employee-dealer-assignment.component.html',
  styleUrl:'employee-dealer-assignment.component.less',
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', opacity: 0 })),
      state('expanded', style({ height: '*', opacity: 1 })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class EmployeeDealerAssignmentComponent implements OnInit {
  employees: Employee[] = [];
  dealers: Dealer[] = [];
  loading = false;
  
  // Quick assign functionality
  quickAssignEmployee: string = '';
  
  // Grid API reference for employee grid
  private employeeGridApi!: GridApi;
  
  constructor(
    private dialog: MatDialog,
    private accountService: AccountService,
    private dealerService: DealerService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.loading = true;
    
    // Load employees
    this.accountService.getAllEmployee()
      .pipe(first())
      .subscribe({
        next: (employees: Employee[]) => {
          this.employees = employees;
          this.loadDealers();
        },
        error: error => {
          this.alertService.error(error);
          this.loading = false;
        }
      });
  }
  
  loadDealers() {
    // Load dealers
    this.dealerService.getAll()
      .pipe(first())
      .subscribe({
        next: (dealers: any[]) => {
          this.dealers = dealers;
          this.loading = false;
        },
        error: error => {
          this.alertService.error(error);
          this.loading = false;
        }
      });
  }
  
  // Tab change handler
  onTabChange(event: any) {
    // Reset any component state if needed when changing tabs
  }
  
  // UTILITY FUNCTIONS
  getDealerName(dealerId: string): string {
    const dealer = this.dealers.find(d => d.dealerId === dealerId);
    return dealer ? dealer.dealerName : 'Unknown Dealer';
  }
  
  getAssignedEmployees(dealerId: string): string[] {
    return this.employees
      .filter(emp => emp.employeeDealerList && emp.employeeDealerList.includes(dealerId))
      .map(emp => `${emp.firstName} ${emp.lastName}`);
  }
  
  getAssignedEmployeeCount(dealerId: string): number {
    return this.employees.filter(emp => 
      emp.employeeDealerList && emp.employeeDealerList.includes(dealerId)
    ).length;
  }
  
  getUnassignedDealers(): Dealer[] {
    return this.dealers.filter(dealer => 
      this.getAssignedEmployeeCount(dealer.dealerId) === 0
    );
  }
  
  // ANALYTICS FUNCTIONS
  getAverageAssignmentsPerEmployee(): number {
    const totalAssignments = this.employees.reduce(
      (sum, emp) => sum + (emp.employeeDealerList ? emp.employeeDealerList.length : 0), 0
    );
    return this.employees.length ? totalAssignments / this.employees.length : 0;
  }
  
  getMostAssignedEmployee(): string {
    // ...existing code...
    return '';
  }
  
  getLeastAssignedEmployee(): string {
    // ...existing code...
    return '';
  }
  
  getTopAssignedEmployees(count: number = 5): any[] {
    return this.employees
      .map(emp => ({
        name: `${emp.firstName} ${emp.lastName}`,
        count: emp.employeeDealerList ? emp.employeeDealerList.length : 0
      }))
      .sort((a, b) => b.count - a.count)
      .slice(0, count);
  }
  
  getStateDistribution(): any[] {
    const stateCounts: { [state: string]: number } = {};
    let maxCount = 0;
    
    // Count dealers per state
    this.dealers.forEach(dealer => {
      if (!dealer.dealerState) return;
      
      if (!stateCounts[dealer.dealerState]) {
        stateCounts[dealer.dealerState] = 0;
      }
      stateCounts[dealer.dealerState]++;
      
      if (stateCounts[dealer.dealerState] > maxCount) {
        maxCount = stateCounts[dealer.dealerState];
      }
    });
    
    // Convert to array with percentages
    return Object.entries(stateCounts)
      .map(([state, count]) => ({
        state,
        count,
        percentage: (count / maxCount) * 100
      }))
      .sort((a, b) => b.count - a.count);
  }
  
  getChartColor(index: number): string {
    const colors = [
      '#3f51b5', '#f44336', '#4caf50', '#ff9800', '#9c27b0',
      '#2196f3', '#ff5722', '#009688', '#673ab7', '#e91e63'
    ];
    return colors[index % colors.length];
  }
  
  getBarHeight(count: number): number {
    const maxCount = Math.max(...this.getTopAssignedEmployees().map(emp => emp.count));
    return maxCount > 0 ? (count / maxCount) * 200 : 0;
  }
  
  // EVENT HANDLERS - Employee Management
  onEditEmployee(employee: Employee): void {
    this.openEditDialog(employee);
  }
  
  onShowEmployeeDetails(employee: Employee): void {
    this.showEmployeeDetailsPopup(employee);
  }
  
  onExportEmployeeData(): void {
    this.exportEmployeeData();
  }
  
  // EVENT HANDLERS - Dealer Management
  onDealerDetails(dealer: Dealer): void {
    this.showDealerDetailsPopup(dealer);
  }
  
  onAssignDealer(data: {dealer: Dealer, employee: string}): void {
    this.quickAssignEmployee = data.employee;
    this.quickAssignDealerToEmployee(data.dealer.dealerId);
  }
  
  onExportDealerData(): void {
    this.exportDealerData();
  }
  
  // ACTION HANDLERS
  openEditDialog(employee: Employee): void {
    const dialogRef = this.dialog.open(EditEmployeeDealersDialog, {
      width: '600px',
      data: {
        employee: { ...employee },
        dealers: this.dealers
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.dealerIds) {
        this.loading = true;
        var request = {"username":employee.username,"employeeDealerList": result.dealerIds};
        
        // Update the employee's dealer assignments
        this.accountService.updateEmployeeDealerList(request)
          .pipe(first())
          .subscribe({
            next: () => {
              // Update local data
              const index = this.employees.findIndex(e => e.username === employee.username);
              if (index !== -1) {
                this.employees[index].employeeDealerList = result.dealerIds;
              }
              this.alertService.success('Employee dealer assignments updated successfully');
              this.loading = false;
            },
            error: error => {
              this.alertService.error(error);
              this.loading = false;
            }
          });
      }
    });
  }
  
  quickAssignDealerToEmployee(dealerId: string): void {
    if (!this.quickAssignEmployee) {
      this.alertService.error('Please select an employee first');
      return;
    }
    
    this.loading = true;
    
    // Find the employee
    const employee = this.employees.find(e => e.username === this.quickAssignEmployee);
    
    if (!employee) {
      this.alertService.error('Selected employee not found');
      this.loading = false;
      return;
    }
    
    // Create a new array with the existing assignments plus the new one
    const updatedDealerIds = employee.employeeDealerList ? 
      [...employee.employeeDealerList] : [];
    
    // Only add if not already assigned
    if (!updatedDealerIds.includes(dealerId)) {
      updatedDealerIds.push(dealerId);
      
      this.accountService.updateEmployeeDealerList({"username":employee.username, "employeeDealerList":updatedDealerIds})
        .pipe(first())
        .subscribe({
          next: () => {
            // Update local data
            const index = this.employees.findIndex(e => e.username === employee.username);
            if (index !== -1) {
              this.employees[index].employeeDealerList = updatedDealerIds;
            }
            
            this.alertService.success(`Dealer assigned to ${employee.firstName} ${employee.lastName}`);
            this.loading = false;
            this.quickAssignEmployee = '';
          },
          error: error => {
            this.alertService.error(error);
            this.loading = false;
          }
        });
    } else {
      this.alertService.info('This dealer is already assigned to the selected employee');
      this.loading = false;
    }
  }
  
  // EXPORT FUNCTIONS
  exportEmployeeData(): void {
    this.loading = true;
    
    try {
      const exportData = this.employees.map(employee => {
        const dealerNames = employee.employeeDealerList ? 
          employee.employeeDealerList.map(id => this.getDealerName(id)).join(', ') : '';
        
        return {
          'Username': employee.username,
          'First Name': employee.firstName,
          'Last Name': employee.lastName,
          'Role': employee.role,
          'Assigned Dealers Count': employee.employeeDealerList ? employee.employeeDealerList.length : 0,
          'Assigned Dealers': dealerNames
        };
      });
      
      this.exportToExcel(exportData, 'Employee_Dealer_Assignments');
    } catch(error) {
      this.alertService.error('Error exporting employee data');
      console.error(error);
    }
    
    this.loading = false;
  }
  
  exportDealerData(): void {
    this.loading = true;
    
    try {
      const exportData = this.dealers.map(dealer => {
        const employeeNames = this.getAssignedEmployees(dealer.dealerId).join(', ');
        
        return {
          'Dealer ID': dealer.dealerId,
          'Dealer Name': dealer.dealerName,
          'Address': dealer.dealerAddress,
          'City': dealer.dealerCity,
          'State': dealer.dealerState,
          'Group': dealer.dealerGroupName,
          'Type': dealer.dealerType,
          'Assigned Employees Count': this.getAssignedEmployeeCount(dealer.dealerId),
          'Assigned Employees': employeeNames
        };
      });
      
      this.exportToExcel(exportData, 'Dealer_Employee_Assignments');
    } catch(error) {
      this.alertService.error('Error exporting dealer data');
      console.error(error);
    }
    
    this.loading = false;
  }
  
  private exportToExcel(data: any[], fileName: string): void {
    // ...existing code...
  }
  
  private getColumnWidths(data: any[]): number[] {
    // ...existing code...
    return [];
  }
  
  private saveExcelFile(buffer: any, fileName: string): void {
    // ...existing code...
  }
  
  // Employee grid ready handler
  onEmployeeGridReady(params: GridReadyEvent) {
    this.employeeGridApi = params.api;
    setTimeout(() => {
      params.api.sizeColumnsToFit();
    }, 100);
  }
  
  // Show employee details in a popup
  showEmployeeDetailsPopup(employee: Employee) {
    const dealerNames = employee.employeeDealerList ? 
      employee.employeeDealerList.map(id => this.getDealerName(id)) : [];
    
    this.dialog.open(DetailPopupComponent, {
      width: '600px',
      data: {
        title: `${employee.firstName} ${employee.lastName} - Assigned Dealers`,
        type: 'employee',
        itemCount: dealerNames.length,
        items: dealerNames,
        employee: employee,
        dealers: this.dealers,
      },
      panelClass: 'detail-dialog'
    });
  }
  
  // Show dealer details in a popup
  showDealerDetailsPopup(dealer: Dealer) {
    const employeeNames = this.getAssignedEmployees(dealer.dealerId);
    
    this.dialog.open(DetailPopupComponent, {
      width: '600px',
      data: {
        title: `${dealer.dealerName} - Details`,
        type: 'dealer',
        itemCount: employeeNames.length,
        items: employeeNames,
        dealer: dealer,
        employees: this.employees,
        onAssign: (employeeUsername: string) => {
          this.quickAssignEmployee = employeeUsername;
          this.quickAssignDealerToEmployee(dealer.dealerId);
        }
      },
      panelClass: 'detail-dialog'
    });
  }
}