import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ReportSearch } from '@app/_models/reportSearch';
import { User } from '@app/_models/user';
import { AccountService } from '@app/_services/account.service';
import { AlertService } from '@app/_services/alert.service';
import { ChartsService } from '@app/_services/charts.service';
import { NgbDateStruct, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import * as Highcharts from 'highcharts';
import { first } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { HighchartsChartModule } from 'highcharts-angular';
import { SearchService } from '@app/_services/search.service';
import { DateRangeSelectorComponent } from '../date-range-selector/date-range-selector.component';
import { YearOnYearChartComponent } from '../chart-components/year-on-year-chart.component';
import { SalesInsightsComponent } from '../insights-component/sales-insights.component';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-dealer-payout-analytics',
  templateUrl: './dealer-payout-analytics.component.html',
  styleUrls: ['./dealer-payout-analytics.component.less'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgbDatepickerModule,
    HighchartsChartModule,
    DateRangeSelectorComponent,
    YearOnYearChartComponent,
    SalesInsightsComponent
  ]
})
export class DealerPayoutAnalyticsComponent implements OnInit {
  highcharts: typeof Highcharts = Highcharts;
  submitted=false;
  issueDateFrom: NgbDateStruct;
  issueDateTo: NgbDateStruct;
  loading= false;
  reportCreated = false;
  reportSearch: ReportSearch;
  issueDateFromSearch : string;
  issueDateToSearch : string;
  user: User;
  certificateReportForm: FormGroup;
  filterForm: FormGroup;
  chartData: any[];
  populateChartData: any[];
  seriesData: any[];
  dateMap : Map<string,number>;
  initialDateMap = new Map<any,any>();
  searchRegex : RegExp;
  barChartOptions: Highcharts.Options = {
    chart: {
      type: 'column',
      height: 700
    },
    title: {
      text: 'Total Receivables by Policy Type'
    },

  };
  chartOptions: Highcharts.Options = {
    chart: {
      type: 'line',
      height: 700
    },
    title: {
      text: 'Total Receivable by Dealer'
    },

  //   // plotOptions: {
  //   //   series: {
  //   //       pointStart: Date.UTC(2010, 0, 1),
  //   //       pointInterval: 24 * 3600 * 1000 // one day
  //   //   }
  // },
    series: []
    //   {
    //     name: 'Normal',
    //     turboThreshold: 500000,
    //     data: [[new Date('2018-01-25 18:38:31').getTime(), 2]]
    //   },
    //   {
    //     name: 'Abnormal',
    //     turboThreshold: 500000,
    //     data: [[new Date('2018-02-05 18:38:31').getTime(), 7]]
    //   }
    // ]
  }
  
  // Year-on-year data
  currentYearAggregateData: any[] = [];
  previousYearAggregateData: any[] = [];
  
  currentYearSummaryData: any[] = [];
  previousYearSummaryData: any[] = [];
  
  // Charts data
  policyTypesData: { current: any[], previous: any[] } = { current: [], previous: [] };
  vehicleMakesData: { current: any[], previous: any[] } = { current: [], previous: [] };
  monthlyTrendData: { current: any[], previous: any[] } = { current: [], previous: [] };
  
  uniqueVehicleMakes: string[] = [];
  
  constructor(        
    private formBuilder: FormBuilder,
    private chartsService: ChartsService,
    private alertService: AlertService,
    private accountService: AccountService,
    private searchService: SearchService
    ) { }

  ngOnInit()  {
    this.dateMap = new Map();
    this.user = this.accountService.userValue;
    this.certificateReportForm = this.formBuilder.group({
      issueDateFrom: [''],
      issueDateTo: [''],
      dealerId: [''],
      dealerName: [''],
          });
    
    this.certificateReportForm.get('dealerName').valueChanges
    .subscribe(value => {
      if ((value != undefined) && (this.chartData)) {
        this.searchRegex = new RegExp(value,"i");
        this.populateChartData =  this.chartData.filter(x => x.dealerName.match(this.searchRegex) )
        
        this.plotLineArray(this.populateChartData);
        this.plotBarArray(this.populateChartData);
        this.plotTotalLineArray(this.populateChartData);

      }
    });

    this.certificateReportForm.get('dealerId').valueChanges
    .subscribe(value => {
      if ((value != undefined) && (this.chartData)) {
        this.searchRegex = new RegExp(value,"i");
        this.populateChartData =  this.chartData.filter(x => x.dealerId.match(this.searchRegex) )
        
        this.plotLineArray(this.populateChartData);
        this.plotBarArray(this.populateChartData);
        this.plotTotalLineArray(this.populateChartData);

      }
    });

    this.certificateReportForm.get('issueDateFrom').valueChanges
    .subscribe(value => {
      if (value != undefined) {
        this.issueDateFromSearch =  value.year + '-' + ('0' + value.month).slice(-2) + '-' + ('0' + value.day).slice(-2);
      }
    });

    this.certificateReportForm.get('issueDateTo').valueChanges
    .subscribe(value => {
      if (value != undefined) {
        this.issueDateToSearch =  value.year + '-' + ('0' + value.month).slice(-2) + '-' + ('0' + value.day).slice(-2);
      }
      console.log("issueDateToSearch: " + this.issueDateToSearch)
    });

    this.filterForm = this.formBuilder.group({
      dealerId: [''],
      dealerName: [''],
      policyType: [''],
      vehicleMake: ['']
    });
    
    // Apply filters when form values change
    this.filterForm.valueChanges.subscribe(() => {
      this.applyFilters();
    });

  }
      // convenience getter for easy access to form fields
      get f() { return this.certificateReportForm.controls; }

  onSubmit(){
    this.submitted = true;
    this.loading = true;
    this.alertService.clear();

    if (this.user.role != "admin" && this.user.role != "dealerOwner")
    {
      this.alertService.error("Unauthorized Access");
      return
    }

    this.reportSearch = new ReportSearch();
    this.reportSearch.userId = this.user.id;
    
    if (this.issueDateFromSearch == undefined)
    {
      this.reportSearch.issueDateFrom = "0001-01-01";
    }
    else
    this.reportSearch.issueDateFrom = this.issueDateFromSearch;

    if (this.issueDateToSearch == undefined)
    {
      this.reportSearch.issueDateTo = "9999-99-99";
    }
    else
    this.reportSearch.issueDateTo = this.issueDateToSearch;


    this.chartsService.getSummedupData(this.reportSearch)
    .pipe(first())
    .subscribe({
      next: (x) => {
        this.chartsService.setChartData(x);
        this.chartData = x;
        this.plotLineArray(x);
        this.plotBarArray(x);
        this.plotTotalLineArray(x);
        // console.log(JSON.stringify(this.chartData));
        this.loading = false;
        this.reportCreated = true;
    
          },
      error: error => {
        this.alertService.error(error)
        this.loading = false;
      }
    })      

  }
  plotLineArray(chartData:any[]){
    var DealerName = "";
    this.seriesData = [];


    chartData.sort((a,b) => {if (a.dealerName + a.createdDateString > b.dealerName + b.createdDateString) return 1;
    else return -1;}).map(elementData=>{
      if (elementData.dealerName != DealerName){
        if (DealerName != "")
        {
          var count = 0;
          this.seriesData.push(
            {
              name: DealerName,
              data: Array.from(Object.entries(this.dateMap).map(([key,value]) => (value)))
              .map((x) => count = count + x),
            }
          );
        }
        for(var d = new Date(this.reportSearch.issueDateFrom); d <= new Date(this.reportSearch.issueDateTo); d.setDate(d.getDate() + 1))
        { 
          var charDate = [
            d.getFullYear(),
            ('0' + (d.getMonth() + 1)).slice(-2),
            ('0' + d.getDate()).slice(-2)
          ].join('-');
          this.dateMap[charDate] = 0;
         };
        DealerName = elementData.dealerName;
        this.dateMap[elementData.createdDateString] = elementData.totalPlanAmount;
      }
      else {
        this.dateMap[elementData.createdDateString] = elementData.totalPlanAmount;
      }

    });
    var count = 0;
    this.seriesData.push(
      {
        name: DealerName,
        data: Array.from(Object.entries(this.dateMap).map(([key,value]) => (value)))
         .map((x) => count = count + x),
      });

    // console.log("SeriesData" + JSON.stringify(this.seriesData));
    this.chartOptions.xAxis = {
      categories: Array.from(Object.entries(this.dateMap).map(([key,value]) => (key))).sort((a, b) => a > b?1:-1) 
    };
    if (this.user.role == "admin")
    this.chartOptions.title.text = "Total Receivables By Dealer";
    else
    this.chartOptions.title.text = "Total Receivables By Executive";

    this.chartOptions.series = this.seriesData;
    // this.chartOptions.plotOptions.series.pointStart = Date.UTC(parseInt(this.reportSearch.issueDateFrom.slice(1,4)),parseInt(this.reportSearch.issueDateFrom.slice(6,7)),parseInt(this.reportSearch.issueDateFrom.slice(9,10)));
    // console.log(JSON.stringify(this.chartOptions));
    // if (this.user.role =="admin")
    Highcharts.chart('lineChart',this.chartOptions);
    // this.highcharts.chart(this.chartOptions);
  }

  plotBarArray(chartData:any[]){
    var DealerName = "";
    this.seriesData = [];
    var cpaDateMap = new Map();
    var rsaDateMap = new Map();
    var docDateMap = new Map();
    for(var d = new Date(this.reportSearch.issueDateFrom); d <= new Date(this.reportSearch.issueDateTo); d.setDate(d.getDate() + 1))
    { 
      var charDate = [
        d.getFullYear(),
        ('0' + (d.getMonth() + 1)).slice(-2),
        ('0' + d.getDate()).slice(-2)
      ].join('-');
      cpaDateMap[charDate] = 0;
      rsaDateMap[charDate] = 0;
      docDateMap[charDate] = 0;
     };


    chartData.map(elementData=>{
      cpaDateMap[elementData.createdDateString] = cpaDateMap[elementData.createdDateString] + elementData.totalPlanCpaSoldAmount - elementData.netCpaPlanSoldAmount;
      rsaDateMap[elementData.createdDateString] = rsaDateMap[elementData.createdDateString] + elementData.totalPlanRsaSoldAmount - elementData.netRsaPlanSoldAmount;
      docDateMap[elementData.createdDateString] = docDateMap[elementData.createdDateString] + elementData.totalPlanDocSoldAmount - elementData.netDocPlanSoldAmount;
 });
    this.seriesData.push(
      {
        name: "HA",
        data: Array.from(Object.entries(cpaDateMap).map(([key,value]) => (value))),
      }
      ,
      {
        name: "RSA",
        data: Array.from(Object.entries(rsaDateMap).map(([key,value]) => (value))),
      }
      ,
      {
        name: "Flexible",
        data: Array.from(Object.entries(docDateMap).map(([key,value]) => (value))),
      }
      );

    // console.log("SeriesData" + JSON.stringify(this.seriesData));
    this.barChartOptions.xAxis = {
      categories: Array.from(Object.entries(cpaDateMap).map(([key,value]) => (key))) 
    };
    this.barChartOptions.yAxis = {
      min:0,
      title: {
        text: 'Total Recivables'
      },
      stackLabels: {
        enabled: true
      }
    };
    this.barChartOptions.plotOptions = {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled:true
        }
      }
    };
    this.barChartOptions.series = this.seriesData;
    this.barChartOptions.chart.type = "column"; 
    Highcharts.chart('barChart',this.barChartOptions);
  }

  plotTotalLineArray(chartData:any[]){
    var DealerName = "";
    this.seriesData = [];
    var totalDateMap = new Map();
    for(var d = new Date(this.reportSearch.issueDateFrom); d <= new Date(this.reportSearch.issueDateTo); d.setDate(d.getDate() + 1))
    { 
      var charDate = [
        d.getFullYear(),
        ('0' + (d.getMonth() + 1)).slice(-2),
        ('0' + d.getDate()).slice(-2)
      ].join('-');
      totalDateMap[charDate] = 0;
     };


    chartData.map(elementData=>{
      totalDateMap[elementData.createdDateString] = totalDateMap[elementData.createdDateString] + elementData.totalPlanCpaSoldAmount - elementData.netCpaPlanSoldAmount + elementData.totalPlanRsaSoldAmount - elementData.netRsaPlanSoldAmount + elementData.totalPlanDocSoldAmount - elementData.netDocPlanSoldAmount
 });
  var count = 0;
    this.seriesData.push(
      {
        name: "Total Receivables",
        data: Array.from(Object.entries(totalDateMap).map(([key,value]) => (value)))
         .map((x) => count = count + x),
      });

    // console.log("SeriesData" + JSON.stringify(this.seriesData));
    this.chartOptions.xAxis = {
      categories: Array.from(Object.entries(totalDateMap).map(([key,value]) => (key))).sort((a, b) => a > b?1:-1) 
    };
    this.chartOptions.title.text = "Total Receivables";
    this.chartOptions.series = this.seriesData;
    // this.chartOptions.plotOptions.series.pointStart = Date.UTC(parseInt(this.reportSearch.issueDateFrom.slice(1,4)),parseInt(this.reportSearch.issueDateFrom.slice(6,7)),parseInt(this.reportSearch.issueDateFrom.slice(9,10)));
    // console.log(JSON.stringify(this.chartOptions));
    Highcharts.chart('totalLineChart',this.chartOptions);

  }

  onDateRangeSelected(dateRange: { dateFrom: string, dateTo: string }): void {
    this.loading = true;
    this.alertService.clear();
    
    if (this.user.role !== "admin" && this.user.role !== "dealerOwner") {
      this.alertService.error("Unauthorized Access");
      this.loading = false;
      return;
    }
    
    const reportSearch = new ReportSearch();
    reportSearch.userId = this.user.id;
    reportSearch.issueDateFrom = dateRange.dateFrom;
    reportSearch.issueDateTo = dateRange.dateTo;
    
    // Fetch both aggregate and summary data in parallel
    forkJoin({
      aggregate: this.chartsService.getYearOnYearData(reportSearch),
      summary: this.searchService.getYearOnYearSummaryData(reportSearch)
    }).subscribe({
      next: (results) => {
        // Store data
        this.currentYearAggregateData = results.aggregate.current;
        this.previousYearAggregateData = results.aggregate.previous;
        
        this.currentYearSummaryData = results.summary.current;
        this.previousYearSummaryData = results.summary.previous;
        
        // Extract unique vehicle makes for filter
        this.extractUniqueVehicleMakes();
        
        // First mark loading as complete so DOM elements are rendered
        this.loading = false;
        
        // Then process data with a small delay to ensure DOM is ready
        setTimeout(() => {
          // Process data for specialized charts
          this.processPolicyTypeData();
          this.processVehicleMakeData();
          this.processMonthlyTrendData();
          this.processMonthlyTrendDataByDealer(); // Add this line
          this.renderPolicyDistributionChart();
        }, 100);
      },
      error: (error) => {
        this.alertService.error(error);
        this.loading = false;
      }
    });
  }
  
  private extractUniqueVehicleMakes(): void {
    // Extract unique vehicle makes from summary data for the filter dropdown
    const makes = new Set<string>();
    
    this.currentYearSummaryData.forEach(item => {
      if (item.vehicleMake) {
        makes.add(item.vehicleMake);
      }
    });
    
    this.previousYearSummaryData.forEach(item => {
      if (item.vehicleMake) {
        makes.add(item.vehicleMake);
      }
    });
    
    this.uniqueVehicleMakes = Array.from(makes).sort();
  }
  
  private processPolicyTypeData(): void {
    // Process data for policy type comparison - using numberOfPolicySold instead of amount
    const currentPolicyTypes = this.groupByAndSum(
      this.currentYearSummaryData, 
      'policyType', 
      'numberOfPolicySold'  // Changed from 'totalPlanSoldAmount'
    );
    
    const previousPolicyTypes = this.groupByAndSum(
      this.previousYearSummaryData, 
      'policyType', 
      'numberOfPolicySold'  // Changed from 'totalPlanSoldAmount'
    );
    
    this.policyTypesData = {
      current: this.convertToChartData(currentPolicyTypes, 'policyType', 'count'),  // Changed from 'amount'
      previous: this.convertToChartData(previousPolicyTypes, 'policyType', 'count')  // Changed from 'amount'
    };
  }
  
  private processVehicleMakeData(): void {
    // Process data for vehicle make comparison - using numberOfPolicySold instead of amount
    const currentVehicleMakes = this.groupByAndSum(
      this.currentYearSummaryData, 
      'vehicleMake', 
      'numberOfPolicySold'  // Changed from 'totalPlanSoldAmount'
    );
    
    const previousVehicleMakes = this.groupByAndSum(
      this.previousYearSummaryData, 
      'vehicleMake', 
      'numberOfPolicySold'  // Changed from 'totalPlanSoldAmount'
    );
    
    // Sort and take top 10
    const topMakes = this.getTopKeys(currentVehicleMakes, 10);
    
    this.vehicleMakesData = {
      current: this.convertToChartData(currentVehicleMakes, 'vehicleMake', 'count'),  // Changed from 'amount'
      previous: this.convertToChartData(previousVehicleMakes, 'vehicleMake', 'count', topMakes)  // Changed from 'amount'
    };
  }
  
  private processMonthlyTrendData(): void {
    // Process monthly trend data - using numberOfPolicySold instead of amount
    const currentMonthly = this.aggregateByMonth(this.currentYearAggregateData, 'createdDateString', 'numberOfPolicySold');  // Changed from 'totalPlanSoldAmount'
    const previousMonthly = this.aggregateByMonth(this.previousYearAggregateData, 'createdDateString', 'numberOfPolicySold');  // Changed from 'totalPlanSoldAmount'
    
    // Format data for the chart
    this.monthlyTrendData = {
      current: this.convertToMonthlyChartData(currentMonthly),
      previous: this.convertToMonthlyChartData(previousMonthly)
    };
    
    // Add debug logging to verify data
    console.log('Monthly Trend Data:', {
      current: this.monthlyTrendData.current,
      previous: this.monthlyTrendData.previous
    });
  }
  
  private renderPolicyDistributionChart(): void {
    // Wait for the DOM to be ready
    setTimeout(() => {
      // Check if the element exists in the DOM
      if (!document.getElementById('policyDistributionChart')) {
        console.warn('Element #policyDistributionChart not found in DOM');
        return;
      }
  
      // Calculate total policies by type for current year
      const policyCounts = this.groupByAndSum(
        this.currentYearSummaryData,
        'policyType',
        'numberOfPolicySold'
      );
      
      // Prepare data for pie chart
      const chartData = Object.entries(policyCounts).map(([key, value]) => ({
        name: key,
        y: value,
      }));
      
      // Create pie chart
      Highcharts.chart('policyDistributionChart', {
        chart: {
          type: 'pie'
        },
        title: {
          text: 'Policy Distribution by Type'
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.percentage:.1f}%'
            },
            showInLegend: true
          }
        },
        series: [{
          name: 'Policies',
          colorByPoint: true,
          data: chartData,
          type: 'pie'
        }]
      } as any);
    }, 100); // Small delay to ensure DOM is ready
  }
  
  private groupByAndSum(data: any[], groupField: string, sumField: string): Record<string, number> {
    return data.reduce((acc, item) => {
      const key = item[groupField] || 'Unknown';
      if (!acc[key]) {
        acc[key] = 0;
      }
      acc[key] += (item[sumField] || 0);
      return acc;
    }, {});
  }
  
  private aggregateByMonth(data: any[], dateField: string, valueField: string): Record<string, number> {
    const monthlyData: Record<string, number> = {};
    
    // Initialize all months with zero
    for (let i = 0; i < 12; i++) {
      const monthKey = (i + 1).toString().padStart(2, '0');
      monthlyData[monthKey] = 0;
    }
    
    // Aggregate data by month
    data.forEach(item => {
      if (item[dateField]) {
        const dateParts = item[dateField].split('-');
        if (dateParts.length >= 2) {
          const month = dateParts[1]; // Extract month (MM format)
          monthlyData[month] += (item[valueField] || 0);
        }
      }
    });
    
    return monthlyData;
  }
  // Changed from 'amount' to 'count' to match the yAxisField in the template
  private convertToMonthlyChartData(monthlyData: Record<string, number>): any[] {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    
    return Object.entries(monthlyData).map(([key, value], index) => ({
      month: months[parseInt(key) - 1],
      count: value, // Changed from 'amount' to 'count' to match yAxisField in the template
      sortOrder: parseInt(key) // Used for sorting
    })).sort((a, b) => a.sortOrder - b.sortOrder);
  }

  // Add a new method to process monthly trend data with dealer breakdown
  private processMonthlyTrendDataByDealer(): void {
    // Process data to show monthly trend by dealer
    const currentYearByDealer = this.aggregateByMonthAndDealer(this.currentYearAggregateData, 'createdDateString', 'numberOfPolicySold', 'dealerName');
    const previousYearByDealer = this.aggregateByMonthAndDealer(this.previousYearAggregateData, 'createdDateString', 'numberOfPolicySold', 'dealerName');
    
    // Format data for the chart - take top 5 dealers for readability
    const topDealers = this.getTopDealers(5);
    
    // Create series for each dealer
    const currentSeries = topDealers.map(dealer => ({
      name: `${dealer} (Current)`,
      data: this.getDealerMonthlyData(currentYearByDealer[dealer] || {})
    }));
    
    const previousSeries = topDealers.map(dealer => ({
      name: `${dealer} (Previous)`,
      dashStyle: 'Dash',
      data: this.getDealerMonthlyData(previousYearByDealer[dealer] || {})
    }));
    
    // Set directly to the chart
    setTimeout(() => {
      if (!document.getElementById('dealerComparisonChart')) {
        console.warn('Element #dealerComparisonChart not found in DOM');
        return;
      }
      
      Highcharts.chart('dealerComparisonChart', {
        chart: { type: 'line' },
        title: { text: 'Monthly Policy Sales by Dealer' },
        xAxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        },
        yAxis: {
          title: { text: 'Number of Policies' }
        },
        tooltip: {
          shared: true,
          valueDecimals: 0
        },
        series: [...currentSeries, ...previousSeries]
      } as any);
    }, 100);
  }
  
  private aggregateByMonthAndDealer(
    data: any[], 
    dateField: string, 
    valueField: string, 
    dealerField: string
  ): Record<string, Record<string, number>> {
    // Group data by dealer and then by month
    const result: Record<string, Record<string, number>> = {};
    
    data.forEach(item => {
      if (!item[dateField] || !item[dealerField]) return;
      
      const dateParts = item[dateField].split('-');
      if (dateParts.length < 2) return;
      
      const month = dateParts[1]; // Extract month (MM format)
      const dealer = item[dealerField];
      
      if (!result[dealer]) {
        result[dealer] = {};
        // Initialize all months with zero
        for (let i = 1; i <= 12; i++) {
          result[dealer][i.toString().padStart(2, '0')] = 0;
        }
      }
      
      // Add value for the specific month
      result[dealer][month] = (result[dealer][month] || 0) + (item[valueField] || 0);
    });
    
    return result;
  }
  
  private getDealerMonthlyData(dealerData: Record<string, number>): number[] {
    // Convert dealer monthly data to an array for chart series
    const monthlyData = [];
    
    for (let i = 1; i <= 12; i++) {
      const month = i.toString().padStart(2, '0');
      monthlyData.push(dealerData[month] || 0);
    }
    
    return monthlyData;
  }
  
  private getTopDealers(count: number): string[] {
    // Get top dealers by policy count
    const dealerCounts: Record<string, number> = {};
    
    this.currentYearAggregateData.forEach(item => {
      if (!item.dealerName) return;
      dealerCounts[item.dealerName] = (dealerCounts[item.dealerName] || 0) + (item.numberOfPolicySold || 0);
    });
    
    return Object.entries(dealerCounts)
      .sort((a, b) => b[1] - a[1])
      .slice(0, count)
      .map(([dealer]) => dealer);
  }
  
  private getTopKeys(data: Record<string, number>, count: number): string[] {
    return Object.entries(data)
      .sort((a, b) => b[1] - a[1])
      .slice(0, count)
      .map(([key]) => key);
  }

  private convertToChartData(data: Record<string, number>, groupField: string, valueField: string, topKeys?: string[]): any[] {
    const chartData = Object.entries(data).map(([key, value]) => ({
      [groupField]: key,
      [valueField]: value
    }));
    
    if (topKeys) {
      return chartData.filter(item => topKeys.includes(String(item[groupField])));
    }
    
    return chartData;
  }

  private applyFilters(): void {
    const filters = this.filterForm.value;
    
    // Apply filters to both years' data
    const filterCurrentYearData = () => {
      let filtered = [...this.currentYearSummaryData];
      
      if (filters.dealerId) {
        const regex = new RegExp(filters.dealerId, 'i');
        filtered = filtered.filter(item => item.dealerId && regex.test(item.dealerId));
      }
      
      if (filters.dealerName) {
        const regex = new RegExp(filters.dealerName, 'i');
        filtered = filtered.filter(item => item.dealerName && regex.test(item.dealerName));
      }
      
      if (filters.policyType) {
        filtered = filtered.filter(item => item.policyType === filters.policyType);
      }
      
      if (filters.vehicleMake) {
        filtered = filtered.filter(item => item.vehicleMake === filters.vehicleMake);
      }
      
      return filtered;
    };
    
    const filterPreviousYearData = () => {
      let filtered = [...this.previousYearSummaryData];
      
      if (filters.dealerId) {
        const regex = new RegExp(filters.dealerId, 'i');
        filtered = filtered.filter(item => item.dealerId && regex.test(item.dealerId));
      }
      
      if (filters.dealerName) {
        const regex = new RegExp(filters.dealerName, 'i');
        filtered = filtered.filter(item => item.dealerName && regex.test(item.dealerName));
      }
      
      if (filters.policyType) {
        filtered = filtered.filter(item => item.policyType === filters.policyType);
      }
      
      if (filters.vehicleMake) {
        filtered = filtered.filter(item => item.vehicleMake === filters.vehicleMake);
      }
      
      return filtered;
    };
    
    // Apply filters and update charts with filtered data
    const filteredCurrentYearData = filterCurrentYearData();
    const filteredPreviousYearData = filterPreviousYearData();
    
    // Recalculate and update charts with filtered data
    this.policyTypesData = {
      current: this.convertToChartData(
        this.groupByAndSum(filteredCurrentYearData, 'policyType', 'numberOfPolicySold'),  // Changed from 'totalPlanSoldAmount'
        'policyType',
        'count'  // Changed from 'amount'
      ),
      previous: this.convertToChartData(
        this.groupByAndSum(filteredPreviousYearData, 'policyType', 'numberOfPolicySold'),  // Changed from 'totalPlanSoldAmount'
        'policyType',
        'count'  // Changed from 'amount'
      )
    };
    
    // Update vehicle makes chart
    const currentVehicleMakes = this.groupByAndSum(
      filteredCurrentYearData, 
      'vehicleMake', 
      'numberOfPolicySold'  // Changed from 'totalPlanSoldAmount'
    );
    
    const previousVehicleMakes = this.groupByAndSum(
      filteredPreviousYearData, 
      'vehicleMake', 
      'numberOfPolicySold'  // Changed from 'totalPlanSoldAmount'
    );
    
    // Sort and take top 10
    const topMakes = this.getTopKeys(currentVehicleMakes, 10);
    
    this.vehicleMakesData = {
      current: this.convertToChartData(currentVehicleMakes, 'vehicleMake', 'count', topMakes),  // Changed from 'amount'
      previous: this.convertToChartData(previousVehicleMakes, 'vehicleMake', 'count', topMakes)  // Changed from 'amount'
    };
    
    // Update monthly trend
    const currentMonthlyData = this.aggregateByMonth(
      this.currentYearAggregateData.filter(item => {
        // Apply dealer filters to aggregate data
        if (filters.dealerId && (!item.dealerId || !item.dealerId.match(new RegExp(filters.dealerId, 'i')))) {
          return false;
        }
        
        if (filters.dealerName && (!item.dealerName || !item.dealerName.match(new RegExp(filters.dealerName, 'i')))) {
          return false;
        }
        
        return true;
      }),
      'createdDateString',
      'numberOfPolicySold'  // Changed from 'totalPlanSoldAmount'
    );
    
    const previousMonthlyData = this.aggregateByMonth(
      this.previousYearAggregateData.filter(item => {
        // Apply dealer filters to aggregate data
        if (filters.dealerId && (!item.dealerId || !item.dealerId.match(new RegExp(filters.dealerId, 'i')))) {
          return false;
        }
        
        if (filters.dealerName && (!item.dealerName || !item.dealerName.match(new RegExp(filters.dealerName, 'i')))) {
          return false;
        }
        
        return true;
      }),
      'createdDateString',
      'numberOfPolicySold'  // Changed from 'totalPlanSoldAmount'
    );
    
    this.monthlyTrendData = {
      current: this.convertToMonthlyChartData(currentMonthlyData),
      previous: this.convertToMonthlyChartData(previousMonthlyData)
    };
    
    // Re-render policy distribution chart with delay
    setTimeout(() => {
      this.renderPolicyDistributionChart();
    }, 100);
  }
}
