import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Dealer} from '@app/_models/dealer';
import { Payments } from '@app/_models/payment';
import { Payouts } from '@app/_models/payout';
import { DealerSummary } from '@app/_models/dealerSummary';

@Injectable({
  providedIn: 'root'
})
export class DealerService {

  constructor( private router: Router,
    private http: HttpClient
) { }
public PrintDealer: Dealer;
public PrintDealerSummary: DealerSummary[];

getInvoiceId() {
  return this.http.get<Number>(`${environment.apiUrl}/counter/InvoiceId`);
}
getDealerId() {
  return this.http.get<Number>(`${environment.apiUrl}/counter/DealerId`);
}

addDealer(dealer: Dealer){
  console.log("dealer: " + dealer);
  return this.http.post(`${environment.apiUrl}/dealer/addDealer`, dealer);
}
getAll(){
  return this.http.get<Dealer[]>(`${environment.apiUrl}/dealer/`);
}
getAllDealer(){
  return this.http.get<Dealer[]>(`${environment.apiUrl}/dealer/getAllDealer/`);

}
getById(id: string) {
  return this.http.get<Dealer>(`${environment.apiUrl}/dealer/${id}`);
}
getByDealerId(dealerId:string) {
  return this.http.get<Dealer>(`${environment.apiUrl}/dealer/dealerId/${dealerId}`);

}
update(id, params) {
  return this.http.put(`${environment.apiUrl}/dealer/${id}`, params)
}

updateByDealer(id, params) {
  return this.http.put(`${environment.apiUrl}/dealer/updateByDealer/${id}`, params)
}

dealerPlanUpdate(id, params) {
  return this.http.put(`${environment.apiUrl}/dealer/dealerPlanUpdate/${id}`, params)
}
dealerInvoiceUpdate(id, params) {
  return this.http.put(`${environment.apiUrl}/dealer/dealerInvoiceUpdate/${id}`, params)
}

addDealerPayment(payment: Payments){
  console.log("dealerPayment: " + payment);
  return this.http.post(`${environment.apiUrl}/dealer/addDealerPayment`, payment);
}
addDealerPolicyPayment(payment: Payments){
  console.log("dealerPayment: " + payment);
  return this.http.post(`${environment.apiUrl}/dealer/addDealerPolicyPayment`, payment);
}

getDealerPolicyPayment(getPaymentList: {id:string,recordCount:number}){
  return this.http.post<Payments[]>(`${environment.apiUrl}/dealer/getDealerPolicyPayment`, getPaymentList);
}
getDealerPayment(getPaymentList: {id:string,recordCount:number}){
  return this.http.post<Payments[]>(`${environment.apiUrl}/dealer/getDealerPayment`, getPaymentList);
}
addDealerPayout(payout: Payouts){
  console.log("dealerpayout: " + payout);
  return this.http.post(`${environment.apiUrl}/dealer/addDealerPayout`, payout);
}

getDealerPayout(getPayoutList: {id:string,recordCount:number}){
  return this.http.post<Payouts[]>(`${environment.apiUrl}/dealer/getDealerPayout`, getPayoutList);
}

getDealerTotalCertSold(getTotalCertSold: {dealerId:string,invoiceYear:number,invoiceMonth:number}){
  console.log('total cert called' );
  return this.http.post<DealerSummary[]>(`${environment.apiUrl}/dealer/getDealerTotalCertSold`, getTotalCertSold);

}

}
