<div class="container">
    <form [formGroup]="certificateReportForm" (ngSubmit)="onSubmit()">
    <div class="form-row">
        <div class="form-group col">
            <label for="issueDateFrom">Issue Date From</label>
            <div class="input-group">
                <input class="form-control" formControlName="issueDateFrom"   ngbDatepicker #d="ngbDatepicker" [ngClass]="{ 'is-invalid': submitted && f.issueDateFrom.errors }">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary fa fa-calendar" (click)="d.toggle()" type="button"></button>
                </div>
              </div>            
              <div *ngIf="submitted && f.issueDateFrom.errors" class="invalid-feedback">
                <div *ngIf="f.issueDateFrom.errors.required">Issue Date From</div>
            </div>
        </div>
        <div class="form-group col">
            <label for="issueDateTo">Issue Date To</label>
            <div class="input-group">
                <input class="form-control" formControlName="issueDateTo"   ngbDatepicker #issueDateTo="ngbDatepicker" [ngClass]="{ 'is-invalid': submitted && f.issueDateTo.errors }">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary fa fa-calendar" (click)="issueDateTo.toggle()" type="button"></button>
                </div>
              </div>            
              <div *ngIf="submitted && f.issueDateTo.errors" class="invalid-feedback">
                <div *ngIf="f.issueDateTo.errors.required">Issue Date To</div>
            </div>
        </div>
    </div>
    <div class="form-row">
        <div class="form-group">
        <button [disabled]="loading" class="btn btn-primary" >
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Search
        </button><a>&nbsp;&nbsp;</a>
    </div>
    </div>
        <div class="form-row" *ngIf="reportCreated"  >
    
          <div class="form-group col" >
            <label for="dealerName">Name</label>
            <input type="text" formControlName="dealerName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dealerName.errors }" />
            <div *ngIf="submitted && f.dealerName.errors" class="invalid-feedback">
                <div *ngIf="f.dealerName.errors.required">Dealership Id is required</div>
            </div>
        </div>
        <div class="form-group col" *ngIf='user.role == "admin"'>
          <label for="dealerId">Dealer Id</label>
          <input type="text" formControlName="dealerId" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dealerId.errors }" />
          <div *ngIf="submitted && f.dealerId.errors" class="invalid-feedback">
              <div *ngIf="f.dealerId.errors.required">Dealership Id is required</div>
          </div>
      </div>
  </div>


</form>
</div>
<div id="barChart"></div>
<div id="totalLineChart"></div>
<div id="lineChart" ></div>
