<div class="container">
    <h1>RSA Operation</h1>
    <form [formGroup]="certificateReportForm" (ngSubmit)="onSubmit()">
        <div class="form-row">
            <div class="form-group col">
                <label for="certificateNumber">Certificate Number</label>
                <input type="text" formControlName="certificateNumber" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.certificateNumber.errors }" />
            </div>
            <div class="form-group col">
                <label for="chasisNumber">Chasis Number</label>
                <input type="text" formControlName="chasisNumber" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.chasisNumber.errors }" />
            </div>
            <div class="form-group col">
                <label for="contactNumber">Client Contact Number</label>
                <input type="text" formControlName="contactNumber" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.contactNumber.errors }" />
            </div>
        </div>
        <div class="form-row">
            <div class="form-group">
                <button [disabled]="loading" class="btn btn-primary">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Search
                </button><a>&nbsp;&nbsp;</a>
            </div>
        </div>
    </form>
    <nav aria-label="Page navigation example" *ngIf="totalCertificate > 1">
        <ul class="pagination justify-content-end">
            <li class="page-item disabled">
                <a class="page-link" (click)="previousAction()" tabindex="-1"
                    aria-disabled="currentCertificateIndex <= 0">Previous</a>
            </li>
            <div *ngFor="let certificate of certificates; let i=index">
                <li class="page-item"><a class="page-link" (click)="pageSelected(i)">{{i}}</a></li>
            </div>
            <li class="page-item">
                <a class="page-link" (click)="nextAction()"
                    aria-disabled="currentCertificateIndex >= totalCertificate - 1">Next</a>
            </li>
        </ul>
    </nav>
</div>
<div class="container" *ngIf="totalCertificate >= 1">
    <div class="row">
        <div class="col">
            <div class="card text-center">
                <div class="card-header" style="background-color: rgb(134, 224, 212)">
                    <h3>
                        Certificate Number:
                        <small class="text-muted">{{selectedCertificate.certificateNumber}}</small>
                    </h3>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
            <div class="col-lg-4 align-items-stretch" style="min-width: 18rem;">
            <div class="card">
                <div class="card-header">
                    <h4>Vehicle & Dealer Details</h4>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col">
                            <h6>Vehicle Make</h6>
                        </div>
                        <div class="col">
                            <h6>{{selectedCertificate.vehicleMake}}</h6>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <h6>Model/Variant</h6>
                        </div>
                        <div class="col">
                            <h6>{{selectedCertificate.model + "/" + selectedCertificate.variant}}</h6>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <h6>Chasis Number</h6>
                        </div>
                        <div class="col">
                            <h6>{{selectedCertificate.chasisNumber}}</h6>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <h6>Engine Number</h6>
                        </div>
                        <div class="col">
                            <h6>{{selectedCertificate.engineNumber}}</h6>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <h6>Dealer Name</h6>
                        </div>
                        <div class="col">
                            <h6>{{selectedCertificate.dealerName}}</h6>
                        </div>
                    </div>

                </div>

            </div>
        </div>
        <div class="col-lg-4 align-items-stretch" style="min-width: 18rem;">
            <div class="card" >
                <div class="card-header">
                    <h4>Owner & Nominee Details</h4>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col">
                            <h6>Owner Name</h6>
                        </div>
                        <div class="col">
                            <h6>{{selectedCertificate.firstName + " " + selectedCertificate.lastName}}</h6>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <h6>Contact Number</h6>
                        </div>
                        <div class="col">
                            <h6>{{selectedCertificate.contactNumber}}</h6>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <h6>Date of Birth/Age</h6>
                        </div>
                        <div class="col">
                            <h6>{{selectedCertificate.dob + "/" + (selectedCertificate.age?selectedCertificate.age:"")}}
                            </h6>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <h6>Nominee Name</h6>
                        </div>
                        <div class="col">
                            <h6>{{selectedCertificate.nomineeName}}</h6>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <h6>City(State)</h6>
                        </div>
                        <div class="col">
                            <h6>{{ selectedCertificate.city + "(" + selectedCertificate.state + ")"}}</h6>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="col-lg-4 align-items-stretch" style="min-width: 18rem;">
            <div class="card">
                <div class="card-header">
                    <h4>Date & Plan</h4>
                </div>
                <div class="card-body" [ngStyle]='{"background-color": isCertificateExpired?"red":"white"}'>
                    <div class="row">
                        <div class="col">
                            <h6>Start Date</h6>
                        </div>
                        <div class="col">
                            <h6>{{selectedCertificate.startDate}}</h6>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <h6>End Date</h6>
                        </div>
                        <div class="col">
                            <h6>{{selectedCertificate.endDate}}</h6>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <h6>Certificate Type</h6>
                        </div>
                        <div class="col">
                            <h6>{{selectedCertificate.policyType}}</h6>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <h6>Certificate Status</h6>
                        </div>
                        <div class="col">
                            <h6>{{selectedCertificate.certificateStatus}}</h6>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <h6>Plan Id</h6>
                        </div>
                        <div class="col">
                            <h6>{{selectedCertificate.planId}}</h6>
                        </div>
                    </div>

                </div>

            </div>
        </div>
</div>
</div>

<div class="container" *ngIf="totalCertificate >= 1 && selectedCertificate.rsaProvidedInfo && selectedCertificate.rsaProvidedInfo.length > 0" style="margin-top: 10px;">
    <div class="row">
        <div class="col">
            <div class="card text-center">
                <div class="card-header" style="background-color: rgb(134, 224, 212)">
                    <h3>
                        RSA Provided History
                    </h3>
                </div>
            </div>
        </div>
    </div>

    <div class="accordion" id="accordionExample">
        <div class="accordion-item" *ngFor="let rsaProvide of selectedCertificate.rsaProvidedInfo; let j=index">
            <h2 class="accordion-header" id="headingOne_{{j}}">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" attr.data-bs-target="#collapseOne_{{j}}" aria-expanded="true" attr.aria-controls="collapseOne_{{j}}">
                  {{"RSA Provided #" + rsaProvide.rsaProvideInstanceNumber}}     
                </button>
              </h2>
              <div id="collapseOne_{{j}}" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    <p>
                            <strong>Date & Time:</strong>{{rsaProvide.rsaProvidedDate}}<br>
                            <strong>City:</strong>{{rsaProvide.city}}<br>
                            <strong>Caller Name:</strong>{{rsaProvide.callerName}}<br>
                            <strong>Callback Number:</strong>{{rsaProvide.callbackNumber}}<br>
                            <strong>Problem:</strong>{{rsaProvide.problemStatement}}<br>
                            <strong>Resolution:</strong>{{rsaProvide.resolutionComment}}<br>
                    </p>
                    <button [disabled]="loading" class="btn btn-primary" (click)="editRsaProvide(rsaProvide)" *ngIf="!isCertificateExpired">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        Edit
                    </button>
                </div>
              </div>          

        </div>

    </div>
</div>

<div class="container" *ngIf="totalCertificate >= 1" style="margin-top: 10px;">
    <div class="row">
        <div class="form-group">
            <!-- <button [disabled]="loading" class="btn btn-primary" (click)="printCertificate(selectedCertificate)">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                Print Certificate
            </button><a>&nbsp;&nbsp;</a> -->
            <button [disabled]="loading" class="btn btn-secondary" (click)="enterRSAClick()" *ngIf="!isCertificateExpired">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                Add RSA Info
            </button><a>&nbsp;&nbsp;</a>
        </div>
    </div>
</div>

<div class="container" style="margin-top: 10px;" *ngIf="showRsaForm">
    <h1>RSA Operation</h1>
    <form [formGroup]="rsaProvidedForm" (ngSubmit)="onRsaSubmit()">
        <div class="row">
            <div class="form-group col">
                <label for="callerName">Caller Name</label>
                <input type="text" formControlName="callerName" class="form-control" [ngClass]="{ 'is-invalid': submitted && rsaF.callerName.errors }" />
                <div *ngIf="submitted && rsaF.callerName.errors" class="invalid-feedback">
                    <div *ngIf="rsaF.callerName.errors.required">Caller name is required</div>
                </div>
            </div>
            <div class="form-group col">
                <label for="callbackNumber">Call Back Number</label>
                <input type="number" formControlName="callbackNumber" class="form-control" [ngClass]="{ 'is-invalid': submitted && rsaF.callbackNumber.errors }" />
                <div *ngIf="submitted && rsaF.callbackNumber.errors" class="invalid-feedback">
                    <div *ngIf="rsaF.callbackNumber.errors.required">Callback Number is required</div>
                </div>
            </div>
            <div class="form-group col">
                <label for="city">City Where Problem was Faced</label>
                <input type="text" formControlName="city" class="form-control" [ngClass]="{ 'is-invalid': submitted && rsaF.city.errors }" />
                <div *ngIf="submitted && rsaF.city.errors" class="invalid-feedback">
                    <div *ngIf="rsaF.city.errors.required">City is required</div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="form-group col">
                <label for="problemStatement">Problem Statement</label>
                <textarea formControlName="problemStatement" class="form-control"  rows=5 [ngClass]="{ 'is-invalid': submitted && rsaF.problemStatement.errors }"></textarea>
                <div *ngIf="submitted && rsaF.problemStatement.errors" class="invalid-feedback">
                    <div *ngIf="rsaF.problemStatement.errors.required">Problem Statement is required</div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="form-group col">
                <label for="resolutionComment">Resolution</label>
                <textarea formControlName="resolutionComment" class="form-control"  rows=5 [ngClass]="{ 'is-invalid': submitted && rsaF.resolutionComment.errors }"></textarea>
                <div *ngIf="submitted && rsaF.resolutionComment.errors" class="invalid-feedback">
                    <div *ngIf="rsaF.resolutionComment.errors.required">Resolution is required</div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="form-group">
                <button [disabled]="loading" class="btn btn-primary">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Submit
                </button><a>&nbsp;&nbsp;</a>
            </div>
        </div>

    </form>
</div>
