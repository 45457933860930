<nav aria-label="breadcrumb" (click)="ShowInvoiceDataToggle()" >
  <ol class="breadcrumb" >
    <li class="breadcrumb-item active" aria-current="page" >
      <i class="fa fa-plus" style="margin-right: 10px; " *ngIf="!isInvoiceDataShow"></i>
      <i class="fa fa-minus" style="margin-right: 10px; " *ngIf="isInvoiceDataShow"></i>
      <p class="h5"> Invoice</p>
    </li>
  </ol>
</nav>
<form [formGroup]="payoutDetailForm" >
  <div class="container-fluid" *ngIf="isInvoiceDataShow">
    <div class="form-row">
      <div class="form-group col">
        <label for="invoiceYear">Invoice Year</label>
        <select class="form-select" formControlName="invoiceYear"
          [ngClass]="{ 'is-invalid': submitted && f.invoiceYear.errors }">
          <option *ngFor="let selectedYear of newYears" [ngValue]="selectedYear">
            {{ selectedYear }}
          </option>
        </select>
        <div *ngIf="submitted && f.invoiceYear.errors" class="invalid-feedback">
          <div *ngIf="f.invoiceYear.errors.required">Invoice Year needs to be selected</div>
        </div>
      </div>
      <div class="form-group col">
        <label for="invoiceMonth">Invoice Month</label>
        <select class="form-select" formControlName="invoiceMonth"
          [ngClass]="{ 'is-invalid': submitted && f.invoiceMonth.errors }">
          <option *ngFor="let selectedMonth of months" [ngValue]="selectedMonth.value">
            {{ selectedMonth.name }}
          </option>
        </select>
        <div *ngIf="submitted && f.invoiceYear.errors" class="invalid-feedback">
          <div *ngIf="f.invoiceYear.errors.required">Invoice Year needs to be selected</div>
        </div>
      </div>
      <div class="form-group col">
        <label style="padding-bottom: 18px;" for="invoiceMonth"></label><br>
        <button class="btn btn-primary" (click)="onSubmit()">
          Get Invoice
        </button>
      </div>

    </div>
    <div class="container" style="width:80%; " *ngIf="selectedInvoice && selectedInvoice.invoiceStatus">

      <nav aria-label="breadcrumb" (click)="ShowInvoiceDetailDataToggle()" style="margin-left: 0px;padding-left: 0px;" >
        <ol class="breadcrumb" style="background-color: rgb(146, 98, 142);">
          <li class="breadcrumb-item active" aria-current="page" >
            <p class="h5 text-white"> Invoice Details</p>
          </li>
        </ol>
      </nav>
      <div class="form-row">
        <div class="form-group col">
          <label for="invoiceNumber" class="form-label">Invoice Number</label>
          <input class="form-control" type="text" formControlName="invoiceNumber">
        </div>
        <div class="form-group col">
          <label for="test">Invoice Status</label><br>
          <label for="test2"> {{selectedInvoice.invoiceStatus}}</label>
        </div>
        <div class="form-group col">
          <label for="test">Invoice Generated Date</label><br>
          <label for="test2"> {{selectedInvoice.invoiceGeneratedDate? selectedInvoice.invoiceGeneratedDate :
            "NA"}}</label>
        </div>
        <div class="form-group col">
          <label for="test">Invoice Paid Date</label><br>
          <label for="test2"> {{selectedInvoice.invoicePaidDate?selectedInvoice.invoicePaidDate: "NA"}}</label>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-2">
          </div>
          <div class="form-group col-md-8">

          <table class="table table-striped table-condensed" style="min-width: 100%; ">
            <thead style="min-width:100%">
              <tr>
                <th>Amount Type</th>
                <th>Payables</th>
                <th>GST </th>
              </tr>
            </thead>
            <tbody style="min-width:100%">
              <tr>
                <td>Total Eligible Amount</td>
                <td>{{selectedInvoice.dealerTotalPayment}}</td>
                <td>{{selectedInvoice.dealerTotalGstPayment}}</td>
              </tr>
              <tr>
                <td>Paid Amount</td>
                <td>{{selectedInvoice.dealerTotalRefund}}</td>
                <td>{{selectedInvoice.dealerTotalGstRefund}}</td>

              </tr>
              <tr>
                <td>Pending Amount</td>
                <td>{{selectedInvoice.dealerPendingRefund}}</td>
                <td>{{selectedInvoice.dealerGstPendingRefund}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
        <div class="form-group ">
            <button (click)="getCertificateReport(selectedInvoice)" class="btn btn-info mr-1"
              style="white-space: normal;">Certificate Report</button> <a>&nbsp;&nbsp;</a><button (click)="generateInvoice(selectedInvoice, 'Normal')"
              *ngIf="user.role == 'admin' || (selectedInvoice.invoiceStatus == 'NotSubmitted' && dealer.invoiceType !='Marketing' )"
              class="btn btn-primary mr-1" style="white-space: normal;">Generate Invoice</button><a>&nbsp;&nbsp;</a><button (click)="generateInvoice(selectedInvoice, 'B2B')" class="btn btn-warning "
              *ngIf="user.role == 'admin' || user.role == 'employee'" style="white-space: normal;">B2B
              Invoice</button><a>&nbsp;&nbsp;</a><button (click)="generateInvoice(selectedInvoice, 'Marketing')" class="btn btn-secondary "
              *ngIf="user.role == 'admin' || user.role == 'employee' || (selectedInvoice.invoiceStatus == 'NotSubmitted' && dealer.invoiceType=='Marketing')" style="white-space: normal;">Marketing
              Invoice</button>

        </div>
    </div>
  </div>
</form>

<div *ngIf="user.role == 'admin'">
  <nav aria-label="breadcrumb" (click)="ShowInvoiceHistoryDataToggle()" >
    <ol class="breadcrumb" >
      <li class="breadcrumb-item active" aria-current="page" >
        <i class="fa fa-plus" style="margin-right: 10px; " *ngIf="!isInvoiceHistoryShow"></i>
        <i class="fa fa-minus" style="margin-right: 10px; " *ngIf="isInvoiceHistoryShow"></i>
        <p class="h5"> Invoice History</p>
      </li>
    </ol>
  </nav>
  <div class="container-fluid" *ngIf="isInvoiceHistoryShow">
    <div class="row">
      <table class="table table-striped" style = "display: block; overflow-x: auto; ">
        <thead >
            <tr>
                <th >Year-Month</th>
                <th >Invoice Number</th>
                <th >Eligible Amount</th>
                <th >Eligible GST Amount</th>
                <th >Paid Amount</th>
                <th >Paid GST Amount</th>
                <th >Pending Eligible Amount</th>
                <th >Pending GST Amount</th>
                <th >Invoice Status</th>
                <th >Invoice Generated Date</th>
                <th >Invoice Paid Date</th>
            </tr>
        </thead>
        <tbody > 
            <tr *ngFor="let dealerInvoice of invoices">
                <td>{{dealerInvoice.invoiceYear + "-" + dealerInvoice.invoiceMonth}}</td>
                <!-- <td><input type="text" id='{{dealerInvoice.invoiceYear + "-" + dealerInvoice.invoiceMonth}}' value='{{(dealerInvoice.invoiceNumber) ? dealerInvoice.invoiceNumber : ""}}' (change)='this.setInvoiceNumber(dealerInvoice,$event.target.value)'></td> -->
                <td><input type="text" id='{{dealerInvoice.invoiceYear + "-" + dealerInvoice.invoiceMonth}}' value='{{(dealerInvoice.invoiceNumber) ? dealerInvoice.invoiceNumber : ""}}' (change)='dealerInvoice.invoiceNumber = $event.target.value'></td>
                <td>{{dealerInvoice.dealerTotalPayment}}</td>
                <td>{{dealerInvoice.dealerTotalGstPayment}}</td>
                <td>{{dealerInvoice.dealerTotalRefund}}</td>
                <td>{{dealerInvoice.dealerTotalGstRefund}}</td>
                <td>{{dealerInvoice.dealerPendingRefund}}</td>
                <td>{{dealerInvoice.dealerGstPendingRefund}}</td>
                <td>{{dealerInvoice.invoiceStatus}}</td>
                <td>{{dealerInvoice.invoiceGeneratedDate}}</td>
                <td>{{dealerInvoice.invoicePaidDate}}</td>
            </tr>
            <tr *ngIf="!invoices">
                <td colspan="4" class="text-center">
                    <span class="spinner-border spinner-border-lg align-center"></span>
                </td>
            </tr>
        </tbody>
    </table>
    </div>
  </div>
  
</div>

<nav aria-label="breadcrumb" (click)="payoutShowToggle()">
  <ol class="breadcrumb" >
    <li class="breadcrumb-item active" aria-current="page" >
      <i class="fa fa-plus" style="margin-right: 10px; " *ngIf="!isPayoutShow"></i>
      <i class="fa fa-minus" style="margin-right: 10px; " *ngIf="isPayoutShow"></i>
      <p class="h5"> Receivable Paid History</p>
    </li>
  </ol>
</nav>
<div *ngIf="isPayoutShow">
  <div>
    <form [formGroup]="payoutDetailForm" (ngSubmit)="getPayoutDetail()">
      <div class="form-row">
        <div class="form-group col">
          <label for="certificateNumber">Number of Records</label>
          <select class="form-select" formControlName="recordCount"
            [ngClass]="{ 'is-invalid': submitted && pmntDtlForm.recordCount.errors }">
            <option *ngFor="let recordCount of numberofRecords" [ngValue]="recordCount">
              {{ recordCount }}
            </option>
          </select>
          <div *ngIf="submitted && pmntDtlForm.recordCount.errors" class="invalid-feedback">
            <div *ngIf="pmntDtlForm.recordCount.errors.required">Record Count is required</div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group">
          <button [disabled]="loading" class="btn btn-primary">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Get Details
          </button><a>&nbsp;&nbsp;</a>
        </div>
      </div>
    </form>
    <ag-grid-angular #aggrid style="width: 100%; height: 600px;" [gridOptions]="gridOptions" class="ag-theme-alpine">
    </ag-grid-angular>
  </div>

</div>
<div *ngIf="isCertificateReportShow">
  <nav aria-label="breadcrumb" (click)="isCertificateReportShow = !isCertificateReportShow">
    <ol class="breadcrumb" >
      <li class="breadcrumb-item active" aria-current="page" >
        <i class="fa fa-close" style="margin-right: 10px; "></i>
        <p class="h5"> Certificate List </p>
      </li>
    </ol>
  </nav>

  <app-payout-certificate-report [invoice]=invoice></app-payout-certificate-report>
</div>