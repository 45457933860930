import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, UntypedFormBuilder } from '@angular/forms';
import { AlertService, AccountService } from '@app/_services';
import { SearchService } from '@app/_services/search.service';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableModule } from '@angular/material/table';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
// Angular Chart Component
import { AgCharts } from 'ag-charts-angular';
// Chart Options Type Interface
import { AgChartOptions } from 'ag-charts-community';
import { first } from 'rxjs';
import { DealerService } from '@app/_services/dealer-service';
import { Dealer } from '@app/_models/dealer';

@Component({
  selector: 'app-dealer-inactivity-report',
  standalone: true,
  imports: [CommonModule,ReactiveFormsModule, AgGridAngular,AgCharts,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatProgressBarModule,
    MatTableModule
  ],
  templateUrl: './dealer-inactivity-report.component.html',
  styleUrl: './dealer-inactivity-report.component.less'
})
export class DealerInactivityReportComponent implements OnInit {
  private gridApi!: GridApi;
  reportForm: FormGroup;
  months = [1, 2, 3, 4, 5, 6,8,12];
  days = [3, 4, 5,7,10,15,20,30];
  dealers = [{
    "dealerId": ""
  }];
  dealerData: Dealer[] = [];
  loading = false;
  selectedDealerId: string | null = null;
  chartOptions: AgChartOptions | null = null;

  // AG Grid settings
  columnDefs:ColDef[] = [
    {
      headerName: 'Dealer ID',
      field: 'dealerId',
      sortable: true,
      filter: true,
      cellRenderer: (params) => `<a href="javascript:void(0)" class="dealer-id-link">${params.value}</a>`
    },
    { headerName: 'Dealer Name', field: 'dealerName', sortable: true, filter: true },
    { headerName: 'City', field: 'dealerCity', sortable: true, filter: true },
    { headerName: 'State', field: 'dealerState', sortable: true, filter: true },
    { headerName: 'Group Name', field: 'dealerGroupName', sortable: true, filter: true },
    { headerName: 'Dealer Type', field: 'dealerPlanType', sortable: true, filter: true },
    { headerName: 'Last Transaction Date', field: 'lastTransactionDate', sortable: true, filter: 'agDateColumnFilter' },
    { headerName: 'Average Transaction', field: 'dailyAverageTransactions', sortable: true, filter: true },
    { headerName: 'Expected Average in active period', field: 'expectedTransactionsAverage', sortable: true, filter: true },
    { headerName: 'Active Period Average Transaction', field: 'activePeriodDailyAverageTransactions', sortable: true, filter: true }
  ];
  
  defaultColDef = {
    resizable: true, 
    sortable: true,
    filter: true
  };


  constructor(
    private formBuilder: UntypedFormBuilder,
    private searchService: SearchService,
    private dealerService: DealerService, 
    private alertService: AlertService,
    private accountService: AccountService,
) {
    this.reportForm = this.formBuilder.group({
      activityDuration: [1],
      inactivityPeriod: [3],
      percentageDecrease: [100]
    });
  }

  ngOnInit(): void {
    this.dealerService.getAllDealer().pipe(first()).subscribe({
      next: (data) => {
        this.dealerData = data;
      },
      error: (error) => {
        this.alertService.error('Error fetching dealers:', error);
      }
    });
  }

  generateReport(): void {
    const { activityDuration, inactivityPeriod, percentageDecrease } = this.reportForm.value;
    this.loading = true;

    this.searchService.getInactiveDealers(activityDuration, inactivityPeriod, percentageDecrease)
    .pipe(first()).subscribe({
      next: (data) => {
        this.dealers = data;
        this.loading = false;
        this.dealers = this.dealers.map(dealer => {
          const dealerData = this.dealerData.find(d => d.dealerId === dealer.dealerId);
          return {
            ...dealer,
            dealerState: dealerData?.dealerState,
            dealerCity: dealerData?.dealerCity,
            dealerGroupName: dealerData?.dealerGroupName,
            dealerPlanType: dealerData?.dealerPlanType
          };
        });
        // this.aggrid.api.setRowData(this.dealers);
      },
      error: (error) => {
        this.alertService.error('Error fetching report:', error);
        this.loading = false;
      }
    }
    );
  }

  onRowClick(event: any): void {
    const dealerId = event.data.dealerId;
    this.selectedDealerId = dealerId;
    this.loadDealerActivityChart(dealerId);
  }

  loadDealerActivityChart(dealerId: string): void {
    const { activityDuration, inactivityPeriod, percentageDecrease } = this.reportForm.value;
    this.searchService.getDealerDailyActivity(dealerId, activityDuration?activityDuration:6).subscribe((activityData) => {
      // add missing dates for last 30 days if entry is not present 
      activityData = activityData.map(data => ({ date: data._id, amount: data.amount }))
      const lastNDays = this.generateLastNDays(inactivityPeriod?inactivityPeriod:30, activityData );
      const activityDataMap = new Map(activityData.map(data => [data.date, data.amount]));
      const filledActivityData = lastNDays.map(date => {
        return {
          date: date,
          amount: activityDataMap.get(date) || 0
        };
      });

      activityData.map(data => ({ date: data._id, amount: data.amount }))
      this.chartOptions = {
        title: {
          text: `Daily Activity for Dealer ${dealerId}`
        },
        data: filledActivityData,
        series: [
          {
            type: 'line',
            xKey: 'date',
            yKey: 'amount',
            xName: 'Date',
            yName: 'Number of Transaction',
            marker: { enabled: true },
            // tooltip: {
            //   renderer: ({ xValue, yValue }) => ({
            //     content: `Date: ${xValue.toDateString()}<br>Amount: $${yValue}`
            //   })
            // }
          }
        ],
      };
    });
  }
  exportToExcel(): void {
    this.gridApi.exportDataAsCsv();
  }
  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
  }
  generateLastNDays(n: number, activityData:{date,amount}[]): string[] {
    const dates = [];
    const today = new Date();

    for (let i = 0; i < n; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() - i);
      dates.push(date.toISOString().split('T')[0]);
    }
    console.log(dates);
    console.log(dates[dates.length - 1]);
    const filterDates = activityData.filter(a => a.date< dates[dates.length - 1] ).map(a => a.date).reverse();
    filterDates.forEach(data => {
      dates.push(data);
    });
    return dates.reverse();
  }
}