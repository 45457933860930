import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { MakeModel } from '@app/_models/makeModel';
import { StateCity } from '@app/_models/stateCity';
import { PlanDetails } from '@app/_models/planDetails';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { map, shareReplay, take } from 'rxjs/operators';
import { PrintInstruction } from '@app/_models/printInstruction';
import { DealerModelConfig } from '@app/_models/dealerModelConfig';
import { PinCodeInfo } from '@app/_models/pincodeInfo';

@Injectable({
  providedIn: 'root'
})
export class ConfigDataService {

  FinanceCompanyList$ = this.getFinanceCompanyList()
  .pipe(
    shareReplay(1)
  );

  PincodeInfo$ = this.getPincodeInfo()
  .pipe(
    shareReplay(1)
  );
   MakeModel$ = this.getMakeModel()
   .pipe(
     shareReplay(1)
   );

   StateCity$ = this.getStateCity()
   .pipe(
     shareReplay(1)
   );
   PlanDetails$ = this.getPlanDetails()
   .pipe(
     shareReplay(1)
   );

   PrintInstruction$ = this.getPrintInstructionList()
   .pipe(
     shareReplay(1)
   );
   RSAEvFeatures$ = this.getRSAEvFeatures()
   .pipe(
     shareReplay(1)
   );
  // MakeModel$ = this.MakeModels$.subscribe();

  constructor(private http: HttpClient) { }

  getInvoiceId() {
    return this.http.get<Number>(`${environment.apiUrl}/counter/InvoiceId`);
  }

  getTodaysDate(): Observable<Date>{
    return this.http.get<Date>(`${environment.apiUrl}/counter/Date`)
  }

  getGST(): number{
    return .18;
  }
  getMakeModel(): Observable<MakeModel[]>{
     return this.http.get<MakeModel[]>(`${environment.apiUrl}/counter/config/Model`)
  }

  getStateCity(): Observable<StateCity[]>{
    return this.http.get<StateCity[]>(`${environment.apiUrl}/counter/config/State`);
  }
  getPincodeInfo(): Observable<PinCodeInfo[]>{
    return this.http.get<PinCodeInfo[]>(`${environment.apiUrl}/counter/config/PincodeInfo`);
  }

  getPlanDetails(): Observable<PlanDetails[]>{
    return this.http.get<PlanDetails[]>(`${environment.apiUrl}/counter/config/Plan`);
  }

  getFinanceCompanyList(): Observable<string[]>{
    return this.http.get<string[]>(`${environment.apiUrl}/counter/config/Finance`);
  }

  getPrintInstructionList(): Observable<PrintInstruction[]>{
    return this.http.get<PrintInstruction[]>(`${environment.apiUrl}/counter/config/PrintInstruction`);
  }
  getRSAEvFeatures(): Observable<any[]>{
    return this.http.get<any[]>(`${environment.apiUrl}/counter/config/RSAEvFeatures`);
  }
  getConfig(keyValue: string) {
    return this.http.get(`${environment.apiUrl}/counter/config/${keyValue}`);
  }

  getConfigCounter(configId:any): Observable<any>{
    return this.http.get(`${environment.apiUrl}/counter/getConfigCounter/${configId}`);
  }

  getStringCacheConfig(keyValue: string): Observable<string>{
    return this.http.get<string>(`${environment.apiUrl}/counter/getStringCacheConfig/${keyValue}`);
  }
  resetStringCacheConfig(inputData: any): Observable<string>{
    if (inputData.configType.toUpperCase() == "COUNTER")
    return this.http.post<string>(`${environment.apiUrl}/counter/setConfigCounter/`,inputData);
    else
    {
    if (inputData.configType.toUpperCase() == "ARRAY")
    {
      if(inputData.keyValue != "Plan")
      inputData.ResultValue = JSON.parse(inputData.ResultValue);
    }
    return this.http.post<string>(`${environment.apiUrl}/counter/resetStringCacheConfig/`,inputData);
  }
  }

  getRoles(): Observable<string[]>{
    return of ( ["admin","executive","dealerOwner","manager","employee","rsaOperation"
  ])
  }
  getSpecialRoles(): Observable<string[]>{
    return of ( ["CanViewDealerBalanceReport","ViewNetAmountGST","CannotViewDealerBalance","CanViewOldCertificate","ExcelUpload","CanRenewAllPolicies","canSeeAge","canSeeNewIndiaLogo","HeroReport",
    "cannotSeeIffcoTokioLogo","CanSeePolicyBalance","Can View Policy Balance Details Report","Can View Package Balance Detail Report","Show Total Package Balance","CanSeeOnlyNewPolicies"
    ,"canSeePolicyCompanyLogo"
    ,"newPolicyPrint"
    ,"538539AddPayment"
  ])
  }
  getDealerModelConfig(dealerId: string): Observable<DealerModelConfig[]>{
    return this.http.get<DealerModelConfig[]>(`${environment.apiUrl}/counter/dealerConfig/DealerModelConfig/${dealerId}`);
  }
  getMasterPolicyMaintain(): Observable<any[]>{
    return this.http.get<any[]>(`${environment.apiUrl}/counter/config/MasterPolicyMaintain`);
  }
  getSeriesMaintain(): Observable<any[]>{
    return this.http.get<any[]>(`${environment.apiUrl}/counter/config/SeriesMaintain`);
  }
  getSetupMaintain(): Observable<any[]>{
    return this.http.get<any[]>(`${environment.apiUrl}/counter/config/SetupMaintain`);
  }
  SeriesMaintain$ = this.getSeriesMaintain()
  .pipe(
    shareReplay(1)
  );
  SetupMaintain$ = this.getSetupMaintain()
  .pipe(
    shareReplay(1)
  );
  MasterPolicyMaintain$ = this.getMasterPolicyMaintain()
  .pipe(
    shareReplay(1)
  );

}
