<ul class="navbar-nav me-auto mb-2 mb-lg-0">
  <!-- Home -->
  <li class="nav-item">
    <a class="nav-link active" aria-current="page" routerLink="/" routerLinkActive="active" 
       [routerLinkActiveOptions]="{exact: true}" (click)="collapseNavbar()">
       <i class="fa fa-dashboard me-1"></i>Dashboard
    </a>
  </li>

  <!-- Dealership Management -->
  <li class="nav-item dropdown">
    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownDealerManage" role="button" 
       data-bs-toggle="dropdown" aria-expanded="false">
       <i class="fa fa-briefcase me-1"></i>My Dealership
    </a>
    <ul class="dropdown-menu shadow" aria-labelledby="navbarDropdownDealerManage">
      <li>
        <a class="dropdown-item" routerLink="/users/viewDealer" routerLinkActive="active" (click)="collapseNavbar()">
          <i class="fa fa-user-circle me-2"></i>View Dealership
        </a>
      </li>
      <li *ngIf="showReceivableTab">
        <a class="dropdown-item" routerLink="/users/dealerPayout/" routerLinkActive="active" (click)="collapseNavbar()">
          <i class="fa fa-credit-card me-2"></i>Receivables
        </a>
      </li>
    </ul>
  </li>
  
  <!-- Certificate Operations -->
  <li class="nav-item dropdown">
    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownCertOps" role="button" 
       data-bs-toggle="dropdown" aria-expanded="false">
       <i class="fa fa-certificate me-1"></i>Certificate Operations
    </a>
    <ul class="dropdown-menu shadow" aria-labelledby="navbarDropdownCertOps">
      <li>
        <a class="dropdown-item" routerLink="/issueCertificate" routerLinkActive="active" (click)="collapseNavbar()">
          <i class="fa fa-plus-circle me-2"></i>Issue Certificate
        </a>
      </li>
      <li>
        <a class="dropdown-item" routerLink="/reports/printCertificate" 
           routerLinkActive="active" (click)="collapseNavbar()">
           <i class="fa fa-print me-2"></i>Print Certificate
        </a>
      </li>
    </ul>
  </li>
  
  <!-- Reports & Analytics -->
  <li class="nav-item dropdown">
    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownReports" role="button" 
       data-bs-toggle="dropdown" aria-expanded="false">
       <i class="fa fa-chart-bar me-1"></i>Reports
    </a>
    <ul class="dropdown-menu shadow reports-menu" aria-labelledby="navbarDropdownReports">
      <li>
        <a class="dropdown-item" routerLink="/reports/certificateReport" 
           routerLinkActive="active" (click)="collapseNavbar()">
           <i class="fa fa-file me-2"></i>Certificate Report
        </a>
      </li>
      <li>
        <a class="dropdown-item" routerLink="/reports/managerAggregateReport" 
           routerLinkActive="active" (click)="collapseNavbar()">
           <i class="fa fa-line-chart me-2"></i>Aggregate Report
        </a>
      </li>
      <li *ngIf="user.specialRoleAccess && user.specialRoleAccess.includes('Can View Policy Balance Details Report') && dealer.dealerCreatePackage">
        <a class="dropdown-item" routerLink="/reports/policyBalanceReport" 
           routerLinkActive="active" (click)="collapseNavbar()">
           <i class="fa fa-list me-2"></i>Policy Balance Details
        </a>
      </li>
      <li *ngIf="user.specialRoleAccess && user.specialRoleAccess.includes('Can View Package Balance Detail Report') && dealer.dealerCreatePackage">
        <a class="dropdown-item" routerLink="/reports/totalBalanceReport" 
           routerLinkActive="active" (click)="collapseNavbar()">
           <i class="fa fa-briefcase me-2"></i>Package Balance Details
        </a>
      </li>
      <li>
        <a class="dropdown-item" routerLink="/reports/dealerBalanceReport" *ngIf="user.specialRoleAccess && user.specialRoleAccess.includes('CanViewDealerBalanceReport')" 
           routerLinkActive="active" (click)="collapseNavbar()">
           <i class="fa fa-balance-scale me-2"></i>Dealer Balance Details
        </a>
      </li>

    </ul>
  </li>
</ul>
