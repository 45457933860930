<div class="assignment-container">
  <!-- Dashboard Summary Cards -->
  <div class="dashboard-summary">
    <mat-card>
      <mat-card-content>
        <div class="summary-number">{{employees.length}}</div>
        <div class="summary-label">Total Employees</div>
      </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-content>
        <div class="summary-number">{{dealers.length}}</div>
        <div class="summary-label">Total Dealers</div>
      </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-content>
        <div class="summary-number">{{getUnassignedDealers().length}}</div>
        <div class="summary-label">Unassigned Dealers</div>
      </mat-card-content>
    </mat-card>
  </div>

  <!-- Tab Navigation -->
  <mat-tab-group animationDuration="300ms" (selectedTabChange)="onTabChange($event)">
    <!-- Employee Management Tab -->
    <mat-tab label="Employee Management">
      <div class="tab-content">
        <!-- Loading Indicator -->
        <div *ngIf="loading" class="spinner-container">
          <mat-progress-spinner mode="indeterminate" diameter="40"></mat-progress-spinner>
        </div>
        
        <!-- Employee Management Component -->
        <app-employee-management 
          *ngIf="!loading"
          [employees]="employees"
          [dealers]="dealers"
          [loading]="loading"
          (editEmployee)="onEditEmployee($event)"
          (showDetails)="onShowEmployeeDetails($event)"
          (exportData)="onExportEmployeeData()">
        </app-employee-management>
      </div>
    </mat-tab>

    <!-- Dealer Management Tab -->
    <mat-tab label="Dealer Management">
      <div class="tab-content">
        <!-- Loading Indicator -->
        <div *ngIf="loading" class="spinner-container">
          <mat-progress-spinner mode="indeterminate" diameter="40"></mat-progress-spinner>
        </div>
        
        <!-- Dealer Management Component -->
        <app-dealer-management 
          *ngIf="!loading"
          [dealers]="dealers"
          [employees]="employees"
          [loading]="loading"
          (showDetails)="onDealerDetails($event)"
          (assignDealer)="onAssignDealer($event)"
          (exportData)="onExportDealerData()">
        </app-dealer-management>
      </div>
    </mat-tab>
    
    <!-- Analytics Tab -->
    <mat-tab label="Assignment Analytics">
      <div class="tab-content">
        <div class="analytics-container">
          <div class="chart-container">
            <h3>Dealer Assignment Distribution</h3>
            <p>Visual representation of how dealers are distributed among employees</p>
            <div class="chart-area">
              <!-- Distribution visualization -->
              <div class="distribution-chart">
                <div *ngFor="let emp of getTopAssignedEmployees(); let i = index" 
                     class="distribution-bar" 
                     [style.height.px]="getBarHeight(emp.count)"
                     [style.background-color]="getChartColor(i)">
                  <div class="bar-value">{{emp.count}}</div>
                </div>
              </div>
              <div class="chart-labels">
                <div *ngFor="let emp of getTopAssignedEmployees()" class="chart-label">
                  {{emp.name | slice:0:10}}{{emp.name.length > 10 ? '...' : ''}}
                </div>
              </div>
            </div>
            <div class="chart-legend">
              <div *ngFor="let emp of getTopAssignedEmployees(); let i = index" class="legend-item">
                <div class="legend-color" [style.background-color]="getChartColor(i)"></div>
                <div class="legend-label">{{emp.name}} ({{emp.count}})</div>
              </div>
            </div>
          </div>
          <div class="analytics-stats">
            <mat-card>
              <mat-card-header>
                <mat-card-title>Assignment Statistics</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <div class="stat-row">
                  <div class="stat-label">Average Dealers per Employee:</div>
                  <div class="stat-value">{{getAverageAssignmentsPerEmployee() | number:'1.1-1'}}</div>
                </div>
                <div class="stat-row">
                  <div class="stat-label">Most Assigned Employee:</div>
                  <div class="stat-value">{{getMostAssignedEmployee()}}</div>
                </div>
                <div class="stat-row">
                  <div class="stat-label">Least Assigned Employee:</div>
                  <div class="stat-value">{{getLeastAssignedEmployee()}}</div>
                </div>
                <div class="stat-row">
                  <div class="stat-label">Unassigned Dealers:</div>
                  <div class="stat-value">{{getUnassignedDealers().length}}</div>
                </div>
              </mat-card-content>
            </mat-card>
            
            <mat-card>
              <mat-card-header>
                <mat-card-title>State Distribution</mat-card-title>
              </mat-card-header>
              <mat-card-content>
                <div class="state-distribution">
                  <div *ngFor="let state of getStateDistribution()" class="state-item">
                    <div class="state-name">{{state.state}}</div>
                    <div class="state-bar">
                      <div class="state-progress" [style.width.%]="state.percentage"></div>
                    </div>
                    <div class="state-count">{{state.count}}</div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>