import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';

import { AccountService } from './_services';
import { User } from './_models';
import { Dealer } from './_models/dealer';
import { DealerService } from './_services/dealer-service';
import { ConfigDataService } from './_services/config-data.service';

@Component({ selector: 'app', templateUrl: 'app.component.html',styleUrls: ['app.component.less'] })
export class AppComponent {

    user: User;
    dealer:Dealer;
    showReceivableTab = false;
    // showTermsCondition = false;
    // showPrivatePolicy = false;
    showCancellationPolicy = false;
    collapseNavbarFlag = false;
    masterPolicyMaintain = [];
    seriesMaintain = [];
    setupMaintain = [];
    // showContactUs = false;

    constructor(private accountService: AccountService,private dealerService: DealerService,private configService: ConfigDataService) {
        this.accountService.getLoggedInUserChange.subscribe((x) => { this.ngOnInit()});
    }

    ngOnInit(){
        this.configService.SeriesMaintain$.subscribe((x) => this.seriesMaintain = x);
        this.configService.MasterPolicyMaintain$.subscribe((x) => this.masterPolicyMaintain = x);
        this.configService.SetupMaintain$.subscribe((x) => this.setupMaintain = x);
        this.user = this.accountService.userValue;  
        if (this.user)  {
        console.log('role: ' + this.user.role)
            if (this.user.role != "admin" && this.user.role != "employee"){
            this.dealerService.getByDealerId(this.user.dealerId).subscribe((y) => 
            {
                this.dealer = y;
                this.dealer.dealerCreatePackage = this.dealer.dealerCreatePackage?this.dealer.dealerCreatePackage:false; 
                console.log(this.dealer.dealerInvoiceShow);
            this.showReceivableTab = this.dealer.dealerInvoiceShow ? this.dealer.dealerInvoiceShow == "No"? false: true : true})
        }
        else
        {
         this.showReceivableTab = false;
         this.dealer = new Dealer();
         this.dealer.dealerCreatePackage = true;
    };}
    }
    // termsConditionToggle(){
    //     this.showTermsCondition = !this.showTermsCondition;
    //     this.showPrivatePolicy = false;
    //     this.showCancellationPolicy = false;
    //     this.showContactUs = false;
    // }
    // privatePolicyToggle(){
    //     this.showTermsCondition = false;
    //     this.showPrivatePolicy = !this.showPrivatePolicy;
    //     this.showCancellationPolicy = false;
    //     this.showContactUs = false;
    // }
    cancellationPolicyToggle(){
        // this.showTermsCondition = false;
        // this.showPrivatePolicy = false;
        this.showCancellationPolicy = !this.showCancellationPolicy;
        // this.showContactUs = false;
    }
    contactUsToggle(){
        // this.showTermsCondition = false;
        // this.showPrivatePolicy = false;
        this.showCancellationPolicy = false;
        // this.showContactUs = !this.showContactUs;

    }
    logout() {
        this.accountService.logout();
    }

collapseNavbar(){
    this.collapseNavbarFlag = !this.collapseNavbarFlag;
}

}
