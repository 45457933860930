import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NumberOfPoliciesComponent } from './number-of-policies/number-of-policies.component';
import { AnalyticRoutingModule } from './analytic-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { AnalyticLayoutComponent } from './analytic-layout/analytic-layout.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HighchartsChartModule } from 'highcharts-angular';
import { DealerPayoutAnalyticsComponent } from './dealer-payout-analytics/dealer-payout-analytics.component';



@NgModule({
  declarations: [NumberOfPoliciesComponent, AnalyticLayoutComponent, DealerPayoutAnalyticsComponent],
  imports: [
    CommonModule
    ,ReactiveFormsModule
    ,AnalyticRoutingModule
    ,NgbModule
    ,HighchartsChartModule
    
  ]
})
export class AnayticModule { }
