import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { User } from '@app/_models';
import { Dealer } from '@app/_models/dealer';
import { PrintInstruction } from '@app/_models/printInstruction';
import { ReportSearch } from '@app/_models/reportSearch';
import { AccountService } from '@app/_services';
import { AlertService } from '@app/_services/alert.service';
import { ConfigDataService } from '@app/_services/config-data.service';
import { DealerService } from '@app/_services/dealer-service';
import { SearchService } from '@app/_services/search.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';
import { AgGridAngular } from 'ag-grid-angular';
import { GridOptions } from 'ag-grid-community';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-manager-aggregate-report',
  templateUrl: './manager-aggregate-report.component.html',
  styleUrls: ['./manager-aggregate-report.component.less']
})
export class ManagerAggregateReportComponent implements OnInit {
  @ViewChild('aggrid') aggrid: AgGridAngular;
  private gridApi;
  certificateReportForm: UntypedFormGroup;
  submitted = false;
  issueDateFrom: NgbDateStruct;
  issueDateTo: NgbDateStruct;
  gridOptions: GridOptions;
  loading = false;
  gridReady = true;
  reportSearch: ReportSearch;
  selectedPrintInstruction:String[];
  issueDateFromSearch: string;
  issueDateToSearch: string;
  reportCertificateData: any[];
  currentDate = new Date();
  stateRegexp : RegExp;
  idRegexp: RegExp;
  user: User;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private searchService: SearchService,
    private alertService: AlertService,
    private accountService: AccountService,
    private dealerService: DealerService,
    private configDataService: ConfigDataService

  ) { }

  ngOnInit(): void {
    this.user = this.accountService.userValue;
    this.gridOptions = <GridOptions>{};
    this.certificateReportForm = this.formBuilder.group({
      issueDateFrom: [''],
      issueDateTo: [''],
      aggregationType: [''],
      // dealerGroupName: [''],
      // dealerState: [''],
      // dealerId: [''],
      // cpaPolicyCompany:['']
    });
    this.configDataService.PrintInstruction$.subscribe((x) => {
      this.selectedPrintInstruction = x.filter(a => a.printOnlyFirstPage == true).map(x => x.policyType);
    });

    this.gridOptions.columnDefs = [
      { headerName: "Employee User Name", field: "userName", sortable: true, filter: true }
      , { headerName: "First Name", field: "firstName", sortable: true, filter: true }
      , { headerName: "Last Name", field: "lastName", sortable: true, filter: true }
      , { headerName: "Issue Date", field: "issueDate", sortable: true, filter: true }
      , { headerName: "Total", field: "totalPolicyNumber", sortable: true, filter: true }
    ]
    this.gridOptions.rowData = [];
    this.gridReady = true;

    this.certificateReportForm.get('issueDateFrom').valueChanges
      .subscribe(value => {
        if (value != undefined) {
          this.issueDateFromSearch = value.year + '-' + ('0' + value.month).slice(-2) + '-' + ('0' + value.day).slice(-2);
        }
      });

    this.certificateReportForm.get('issueDateTo').valueChanges
      .subscribe(value => {
        if (value != undefined) {
          this.issueDateToSearch = value.year + '-' + ('0' + value.month).slice(-2) + '-' + ('0' + value.day).slice(-2);
        }
        //console.log("issueDateToSearch: " + this.issueDateToSearch)
      });


  }
  // convenience getter for easy access to form fields
  get f() { return this.certificateReportForm.controls; }

  onSubmit() {
    this.submitted = true;
    this.loading = true;
    this.alertService.clear();
    if (this.user.role != "manager" && this.user.role != "dealerOwner") {
      this.alertService.error("Unauthorized access");
      this.submitted = false;
      this.loading = false;
      return;
    }
    this.reportSearch = new ReportSearch();
    if (this.issueDateFromSearch == undefined) {
      this.reportSearch.issueDateFrom = "0001-01-01";
    }
    else
      this.reportSearch.issueDateFrom = this.issueDateFromSearch;

    if (this.issueDateToSearch == undefined) {
      this.reportSearch.issueDateTo = "9999-99-99";
    }
    else
      this.reportSearch.issueDateTo = this.issueDateToSearch;
    if (this.certificateReportForm.get("aggregationType").value.length > 0)
    this.reportSearch.aggregationType = this.certificateReportForm.get("aggregationType").value;
    else this.reportSearch.aggregationType = "";

    this.reportSearch.aggregationType = this.reportSearch.aggregationType.includes("IssueDate") ? "IssueDate" : this.reportSearch.aggregationType;

    this.searchService.getManagerAggregation(this.reportSearch)
      .pipe(first())
      .subscribe({
        next: (x) => {

          this.massageData(x,this.reportSearch);
          this.gridReady = true;
          this.loading = false;
        },
        error: error => {
          this.alertService.error(error)
          this.loading = false;
        }
      })

  }
  onReset() {

  }
  exportToExcel() {
    const params = {
      columnGroups: true,
      allColumns: true,
      fileName: 'excelReport',
      //     columnSeparator: document.querySelector("#columnSeparator").value
    };
    this.aggrid.api.exportDataAsCsv(params);
    // this.aggrid.api.exportDataAsExcel(params);
  }
  massageData(abc, reportSearch) {
    if ((this.reportSearch.aggregationType) && (this.reportSearch.aggregationType == "IssueDate")) {
      var maxIssueDate = abc.reduce((a, b) => a.createdDateString > b.createdDateString ? a.createdDateString : b.createdDateString);
      var minIssueDate = this.issueDateFromSearch;
    }
    this.accountService.getByDealerId(this.user.dealerId)
      .pipe(first())
      .subscribe({
        next: (x) => {
        if (this.certificateReportForm.get("aggregationType").value == "IssueDateHorizontal")
        {
          this.createHorizontalReport(x,abc,maxIssueDate,minIssueDate)
        }
        else
        {
          var abctest = [];
          abctest = abctest.concat(x
            .map(function (x) {
              var abcInternal = [];
              if (reportSearch.aggregationType == "IssueDate"){
              for (var d = new Date(minIssueDate); d <= new Date(maxIssueDate); d.setDate(d.getDate() + 1)) {
                abcInternal.push(
                  {
                    "userName": x.username, "firstName": x.firstName, "lastName": x.lastName,
                    "issueDate": d.getFullYear() + "-" + ('0' + (d.getMonth() + 1)).slice(-2) + "-" + ('0' + d.getDate()).slice(-2)
                    , "totalPolicyNumber": 0
                  }
                )}
              }
              else
              abcInternal.push(
                {
                  "userName": x.username, "firstName": x.firstName, "lastName": x.lastName,
                  "issueDate": ""
                  , "totalPolicyNumber": 0
                });

              return abcInternal;
            }))
          
          this.populateDealerData(abc, abctest)
        }},
        error: error => {
          this.alertService.error(error)
          this.loading = false;
        }
      })
  }
  populateDealerData(policyData, DealerData) {
    if ((this.reportSearch.aggregationType) && (this.reportSearch.aggregationType == "IssueDate"))
    
      policyData = policyData.sort((x, y) => (x.userName + x.createdDateString > y.userName + y.createdDateString) ? 1 : ((x.userName + x.createdDateString < y.userName + y.createdDateString) ? -1 : 0))
      .map(function (x) { return { "userName": x.userName, "issueDate": x.createdDateString, "totalPolicyNumber": x.numberOfPolicySold }; });
    
    else

    policyData = policyData.sort((x, y) => (x.userName  > y.userName ) ? 1 : ((x.userName  < y.userName ) ? -1 : 0))
    .map(function (x) { return { "userName": x.userName, "issueDate": "", "totalPolicyNumber": x.numberOfPolicySold }; });
    // policyData = policyData.map(function (x) { return { "userName": x.userName, "issueDate": x.createdDateString, "totalPolicyNumber": x.numberOfPolicySold }; });

    var abcDistinct = [];
    if (DealerData.length > 0) {
      DealerData.forEach(e => {
        e.forEach(x => {
          abcDistinct.push(x)

        })
      });
      // var abcDistinct = DealerData;
    }
    else {
      abcDistinct = policyData.filter((thing, index, self) =>
        index === self.findIndex((t) => (
          t.userName === thing.userName && t.issueDate === thing.issueDate
        ))
      );
    }
    console.log("abc2: " + JSON.stringify(abcDistinct));
    console.log("policyData: " + JSON.stringify(policyData));
    var total = { issueDate: "Total", "cpaPolicyNumber": 0, "rsaPolicyNumber": 0, "docPolicyNumber": 0, "financePolicyNumber": 0, "totalPolicyNumber": 0 };

    abcDistinct = abcDistinct.map(x => {
      var chkFinance = [];
      // console.log(JSON.stringify(doc));
      chkFinance = policyData.filter(y => (y.userName == x.userName && y.issueDate == x.issueDate));
      // console.log(JSON.stringify(chkFinance));
      // console.log("x: " + JSON.stringify(x));
      if (chkFinance.length > 0) {

        x.totalPolicyNumber = chkFinance.reduce((a, b) => a + (parseInt(b.totalPolicyNumber) || 0), 0);
        total.totalPolicyNumber = total.totalPolicyNumber + x.totalPolicyNumber;
      }
      return x;
    }
    )
    //total.totalPolicyNumber = total.cpaPolicyNumber + total.docPolicyNumber + total.rsaPolicyNumber + total.financePolicyNumber;

    abcDistinct.push(total);
    this.aggrid.api.setRowData(abcDistinct);

    //console.log("abc: " + JSON.stringify(abcDistinct));
  }
  createHorizontalReport(eligibleDealer: User[],abc:any[],maxIssueDate,minIssueDate){
    var OutputArray = [];
    let selectedDate = "";
    OutputArray = eligibleDealer.map(
      x => {
        let outputObject = {};
        outputObject['userName'] = x.username; 
        outputObject['firstName'] = x.firstName;
        outputObject['lastName'] = x.lastName;
        for (var d = new Date(minIssueDate); d <= new Date(maxIssueDate); d.setDate(d.getDate() + 1)) {
          selectedDate = d.getFullYear() + "-" + ('0' + (d.getMonth() + 1)).slice(-2) + "-" + ('0' + d.getDate()).slice(-2);

          outputObject[selectedDate] = abc.filter(s => s.userName == x.username && s.createdDateString == selectedDate).reduce((a,b) => {
            return a + (b.numberOfPolicySold? b.numberOfPolicySold : 0)
          },0);          
          // console.log('Output Array2: ' + JSON.stringify(abc.filter(s => s.dealerId == x.dealerId && s.issueDateString == selectedDate)) )
        }
        return outputObject;
      }
    )
    let headerArray = [{headerName: "User Name", field: "userName",sortable: true, filter:true }
  ,{headerName: "First Name", field: "firstName",sortable: true, filter:true }
  ,{headerName: "Last Name", field: "lastName",sortable: true, filter:true }
  ];
    for (var d = new Date(minIssueDate); d <= new Date(maxIssueDate); d.setDate(d.getDate() + 1)) {
      let headerColumn = {headerName: "Certificate Id", field: "certificateNumber",sortable: true, filter:true };
      selectedDate = d.getFullYear() + "-" + ('0' + (d.getMonth() + 1)).slice(-2) + "-" + ('0' + d.getDate()).slice(-2);
      headerColumn.field =selectedDate;
      headerColumn.headerName = selectedDate;
      headerArray.push(headerColumn);
    }
    this.aggrid.api.setColumnDefs(headerArray);
    this.aggrid.api.setRowData(OutputArray);


    return OutputArray;
  }

}
