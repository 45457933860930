import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Dealer } from '@app/_models/dealer';
import { Invoice } from '@app/_models/invoice';
import { environment } from '@environments/environment';
import { catchError, forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  invoices: Invoice[] = [];
  selectedInvoice: Invoice;
  constructor(private http: HttpClient) { }
  getInvoiceByDealerIdAndDealerInfo(id:string) {
    // need to create a rxjs data collector that collects both the dealer and invoice data by calling http endpoints dealerService.getDealerById(id) and invoiceService.getInvoiceByDealerId(id) and then return the data

    return forkJoin({
      dealer: this.http.get<Dealer>(`${environment.apiUrl}/dealer/dealerId/${id}`),
      invoice: this.http.get<Invoice[]>(`${environment.apiUrl}/invoice/getInvoiceByDealerId/${id}`)
    }).pipe(catchError(error => {
      throw error;
    })
  );

     }

     searchInvoice(params:any) {
      return this.http.post<{invoices:Invoice[],dealer:Dealer[]}>(`${environment.apiUrl}/invoice/searchInvoice`, params);
     }
  getInvoiceByDealerId(id:string) {
    return this.http.get<Invoice[]>(`${environment.apiUrl}/invoice/getInvoiceByDealerId/${id}`);
  }
  updateInvoice(params:any) {
    return this.http.post<Invoice>(`${environment.apiUrl}/invoice/updateInvoice/`, params)
  }
}
