import { Injectable } from '@angular/core';
import { Dealer } from '@app/_models/dealer';
import { MakeModel } from '@app/_models/makeModel';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { AlertService } from '@app/_services/alert.service';
import { ConfigDataService } from '@app/_services/config-data.service';
import * as XLSX from 'xlsx/xlsx.mjs';
import { Certificate } from '@app/_models/certificate';
import { PlanDetails } from '@app/_models/planDetails';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { StateCity } from '@app/_models/stateCity';


@Injectable({
  providedIn: 'root'
})
export class ExcelBulkIndvTemplateService {
  //  workbook = XLSX.readFile('@app/assets/bulkIndividual.xlsx');
  modelNames: String[] = [];
  fileName: string;
  excelData: any;
  dealer: Dealer;
  errorString: string;
  phoneTestRegexP = new RegExp(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im);
  error=false;


  selectedMakeModel: MakeModel[];
  certificates: Certificate[];
  makeList: string[];
  stateList:string[];
  cityList:string[];
  possibleModels: MakeModel[];
  stateCityList: StateCity[];
  heroDsLTemplate: boolean;
  

  constructor(
    private alertService: AlertService,
    private configDataService: ConfigDataService,
    private http: HttpClient

  ) { }

  downloadTemplateFile(dealer: Dealer) {
    this.fileName = dealer.dealerId + ".xlsx";
    this.http.get(`${environment.apiUrl}/certificate/getExcelTemplate`, { responseType: 'blob' })
      .subscribe(a => {
        // const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(a);
        // const blob = new Blob([a], { type: this.fileType });
        // const workbook = XLSX.read(a,{type: "array"});
        // // const ws = XLSX.utils.json_to_sheet(a);
        // console.log(workbook);
        // const wb = { Sheets: { data: ws }, SheetNames: ["CertificateUpload", "List"] };
        // console.log(wb);
        // var workbook = XLSX.read(blob);
        // fs.saveAs(blob, this.fileName);
        // const data = window.URL.createObjectURL(blob);
        // window.open(data);

        // var link = document.createElement('a');
        // link.href = data;
        // link.download = this.fileName;

        /* generate workbook and add the worksheet */
        // const wb: XLSX.WorkBook = XLSX.utils.book_new();
        // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        /* save to file */
        // console.log(a);
        XLSX.writeFile(a, this.fileName);


        // console.log(a);
      });

    // this.configDataService.MakeModel$.subscribe(x => {
    //   this.selectedMakeModel = x.filter(a => dealer.dealerMake.find(b => b.make == a.make));
    //   this.selectedMakeModel.forEach(x => {this.modelNames = this.modelNames.concat(x.model)});
    //   console.log(JSON.stringify(this.selectedMakeModel));
    //   console.log(JSON.stringify(this.modelNames));

    // });

  }
   async uploadFile(fileData: any, dealer: Dealer, possibleModels: MakeModel[], selectedPlan: PlanDetails,stateCityList:StateCity[]) {
    this.certificates = [];
    if (fileData.target.files.length !== 1) throw new Error('Cannot use multiple files');
    // console.log(fileData);
    const file = fileData.target.files[0];
    const data =  await file.arrayBuffer();
    const workbook = XLSX.read(data);
    // console.log(workbook);
    this.excelData = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {
      raw: false,
      header: 2, cellStyles: false
    }).filter(a => a.CustomerName)
    this.dealer = new Dealer();
    this.makeList = possibleModels.map(x => x.make);
    this.possibleModels = possibleModels;
    possibleModels.forEach(x => { this.modelNames = this.modelNames.concat(x.model) });
     this.stateCityList = stateCityList;
      // console.log("modelNames: " + JSON.stringify(this.stateCityList));
      this.error = false;
      this.validateExcelCertificates();

  }

  validateExcelCertificates() {
    // Validate all rows for certificate data and create certificates if valid
    // console.log(JSON.stringify(this.excelData));
    this.excelData.map(x => {
      if(x.CustomerType)
      {
        this.heroDsLTemplate = true;
        x = this.convertDslTemplate(x);
      }
      else
      {
        this.heroDsLTemplate = false;
      }
      x.FirstName = x.CustomerName;
  
      var currentCertificate = new Certificate();
      this.errorString = "<b>Error on Line No " + x.SerialNo + ": </b><ol>";
      //Validate Make
      if (x.Make ) {
          currentCertificate.vehicleMake = x.Make
        //   this.modelNames = this.possibleModels.find(r => r.make == x.Make).model;
        // }
        // else {
        //   this.errorString += "<li>  Invalid Make Value : " + x.Make + " Possible Values are : " + this.makeList + "</li>";
        // }
      }
      else {
        this.errorString += "<li>Missing Make Value </li>"
      }
      //Validate Model
      if (x.Model) {
        // if (this.modelNames.find(a => a == x.Model)) {
          currentCertificate.model = x.Model
        // }
        // else {
        //   this.errorString += "<li>  Invalid Model Value : " + x.Model + " Possible Values are : " + this.modelNames + "</li>";
        // }
      }
      else {
        this.errorString += "<li>Missing Model Value </li>"
      }

      //Validate Variant
      if (x.Model == "New") {
        if (x.Variant) {
          currentCertificate.variant = x.Variant;
          currentCertificate.model = x.Variant;
        }
        else {

          this.errorString += "<li>Missing Variant Value when Model selected is 'New' </li>"
        }
      }
      else
      {
        if (x.Variant) {
          currentCertificate.variant = x.Variant;
        }

      }
      //Validate ChasisNumber

      if (x.ChasisNumber) {
        if (this.excelData.filter(a => a.ChasisNumber == x.ChasisNumber).length > 1)
        {
          this.errorString += "<li>Duplicate ChasisNumber within this sheet </li>"

        }
        else
        {
        currentCertificate.chasisNumber = x.ChasisNumber;
      }
    }
      else {
        this.errorString += "<li>Missing ChasisNumber Value </li>"
      }

      //Validate EngineNumber
      if (x.EngineNumber) {
        currentCertificate.engineNumber = x.EngineNumber;
      }
      else {

        this.errorString += "<li>Missing EngineNumber Value </li>"
      }

      //Validate RegistrationNumber
      if (x.RegistrationNumber) {
        currentCertificate.registrationNumber = x.RegistrationNumber;
      }
      //Validate Email
      if (x.Email) {
        currentCertificate.email = x.Email;
      }
      //Validate FirstName
      if (x.FirstName) {
        currentCertificate.firstName = x.FirstName;
      }
      else {

        this.errorString += "<li>Missing FirstName Value </li>"
      }
      //Validate LastName
      // if (x.LastName) {
      //   currentCertificate.lastName = x.LastName;
      // }
      // else {

      //   this.errorString += "<li>Missing LastName Value </li>"
      // }
      //Validate ContactNumber
      if (x.ContactNumber) {
        if (this.phoneTestRegexP.test(x.ContactNumber)) {
          currentCertificate.contactNumber = x.ContactNumber;
        }
        else {

          this.errorString += "<li>Invalid ContactNumber Value </li>"
        }
      }
      else {

        this.errorString += "<li>Missing ContactNumber Value </li>"
      }

      if (x.Age) {
        console.log(x.Age);
        if (parseInt(x.Age) < 15 || parseInt(x.Age) > 80)
        {
          this.errorString += "<li>  Invalid Age : " + x.Gender + " Age should be between 15 to 80 years </li>";
        } 
        else
        {
        currentCertificate.age = x.Age; 
        currentCertificate.ageOrDob = "age"; 

      }      
      }
      else
      {
              //Validate DateOfBirth(DD-MM-YYYY)
      if (x['DateOfBirth(DD-MM-YYYY)'] ) {
        currentCertificate.dob = x['DateOfBirth(DD-MM-YYYY)'].substring(6,10) + "-" + x['DateOfBirth(DD-MM-YYYY)'].substring(3,5) + "-" + x['DateOfBirth(DD-MM-YYYY)'].substring(0,2);
        if(parseInt(x['DateOfBirth(DD-MM-YYYY)'].substring(6,10)) > 1900)
        {
          var inputDate = new Date(currentCertificate.dob);
          if ( inputDate instanceof Date &&  !isNaN(inputDate.valueOf())) {
            currentCertificate.dob = x['DateOfBirth(DD-MM-YYYY)'].substring(6,10) + "-" + x['DateOfBirth(DD-MM-YYYY)'].substring(3,5) + "-" + x['DateOfBirth(DD-MM-YYYY)'].substring(0,2);
            currentCertificate.ageOrDob = "dob"; 
          }
          else {
  
            this.errorString += "<li>Invalid DateOfBirth(DD-MM-YYYY) Value </li>"
          }
  
        }
        else
        {
          this.errorString += "<li>Invalid DateOfBirth(DD-MM-YYYY) Value </li>. If format is correct, Please update the date column format to Text by selecting the column, right-clicking, choosing Format Cells, selecting Text under the Number tab, clicking OK, and re-entering any dates if they appear as numbers, then save the file."
        }
      }
      else {

        this.errorString += "<li>Missing DateOfBirth(DD-MM-YYYY) Value </li>"
      }

      }


      //Validate Gender
      if (x.Gender) {
        if (['male','female'].find(a => a == x.Gender.toLowerCase())) {
          currentCertificate.gender = x.Gender
        }
        else {
          this.errorString += "<li>  Invalid Gender Value : " + x.Gender + " Possible Values are : " + ['Male','Female'] + "</li>";
        }
      }
      else {
        this.errorString += "<li>Missing Gender Value Possible Values are :  [Male,Female] </li>"
      }
      //Validate NomineeGender
      if (x.NomineeGender) {
        if (['male','female','na'].find(a => a == x.NomineeGender.toLowerCase())) {
          currentCertificate.nomineeGender = x.NomineeGender
        }
        else {
          this.errorString += "<li>  Invalid NomineeGender Value : " + x.NomineeGender + " Possible Values are : " + ['Male','Female'] + "</li>";
        }
      }
      else {
        this.errorString += "<li>Missing NomineeGender Value </li>"
      }
      //Validate NomineeRelationship
      if (x.NomineeRelationship) {
        if (["spouse","husband","wife", "father", "mother", "son", "daughter", "brother", "sister","grandson","granddaughter", "other","uncle","nephew","niece"].find(a => a == x.NomineeRelationship.toLowerCase())) {
          currentCertificate.nomineeRelationship = x.NomineeRelationship
        }
        else {
          this.errorString += "<li>  Invalid NomineeRelationship Value : " + x.NomineeRelationship + " Possible Values are : " + ["Spouse","Husband","Wife", "Father", "Mother", "Son", "Daughter", "Brother", "Sister","Grandson","Granddaughter","Uncle","Nephew","Niece", "Other"] + "</li>";
        }
      }
      else {
        this.errorString += "<li>Missing NomineeRelationship Value </li>"
      }

      //Validate VehicleManufacturingYear
      if (x.VehicleManufacturingYear) {
          if ([new Date().getFullYear().toString(),(new Date().getFullYear()-1).toString(),(new Date().getFullYear()-2).toString() ].find(a => a == x.VehicleManufacturingYear)) {
                currentCertificate.vehicleYear = x.VehicleManufacturingYear
              }
              else {
                this.errorString += "<li>  Invalid vehicle manufacturing year Value : " + x.VehicleManufacturingYear + " Possible Values are : " + [new Date().getFullYear().toString(),(new Date().getFullYear()-1).toString(),(new Date().getFullYear()-2).toString() ] + "</li>";
              }
            }
            else {
              this.errorString += "<li>Missing VehicleManufacturingYear Value </li>"
            }
      
      //Validate Address
      if (x.Address) {
        currentCertificate.address = x.Address;
      }
      else {

        this.errorString += "<li>Missing Address Value </li>"
      }

      //Validate State
      if (x.State) {
        // if (this.stateCityList.find(a => a.state.toLowerCase() == x.State.toLowerCase())) {
          currentCertificate.state = x.State
        //   this.cityList = this.stateCityList.find(r => r.state.toLowerCase() == x.State.toLowerCase()).city;
          
        // }
        // else {
        //   this.stateList = this.stateCityList.map(a => a.state);
        //   this.cityList = [];
        //   this.errorString += "<li>  Invalid State Value : " + x.State + " Possible Values are : " + this.stateList + "</li>";
        // }
      }
      else {
        this.errorString += "<li>Missing State Value </li>"
        this.cityList = [];
      }
      //Validate City
      if (x.City) {
        // if (this.cityList.find(a => a.toLowerCase() == x.City.toLowerCase())) {
          currentCertificate.city = x.City
        // }
        // else {
        //   this.errorString += "<li>  Invalid City Value : " + x.City + " Possible Values are : " + this.cityList + "</li>";
        // }
      }
      else {
        this.errorString += "<li>Missing City Value </li>"
      }

      //Validate NomineeName
      if (x.NomineeName) {
        currentCertificate.nomineeName = x.NomineeName;
      }
      else {

        this.errorString += "<li>Missing NomineeName Value </li>"
      }
      //Validate NomineeAge
      if (x.NomineeAge) {
        if (!isNaN(x.NomineeAge) || x.NomineeAge == "NA") {
          currentCertificate.nomineeAge = x.NomineeAge;
        }
        else {
          this.errorString += "<li>  Invalid NomineeAge Value : " + x.NomineeAge + "</li>";
        }
      }
      else {
        this.errorString += "<li>Missing NomineeAge Value </li>"
      }







      if (this.errorString != "<b>Error on Line No " + x.SerialNo + ": </b><ol>") {
        this.errorString += "</ol>"
        this.alertService.info(this.errorString);
        this.error = true;
      }
      else
      {
        this.certificates.push(currentCertificate);
      }
    })

  }
  convertDslTemplate(x: any): any {
    var addressArray = x.Address.split(",");
    x.State = addressArray[addressArray.length - 2].trim();
    x.City = addressArray[addressArray.length - 3].trim();
    x.Address = addressArray[0] +',' + addressArray[1] +',' + addressArray[2];
    x.VehicleManufacturingYear = new Date().getFullYear().toString();
    x.Make = 'Hero Motocorp Limited';
    x.NomineeName = "Legal Heir";
    x.NomineeGender = "NA";
    x.NomineeRelationship = "Other";
    x.NomineeAge = "NA";
    return x;

  }
}
