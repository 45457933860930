import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';

interface DialogData {
  employee: {
    firstName: string;
    lastName: string;
    username: string;
    employeeDealerList: string[];
  };
  dealers: Array<{
    dealerId: string;
    dealerName: string;
    dealerCity: string;
    dealerState: string;
  }>;
}

@Component({
  selector: 'edit-employee-dealers-dialog',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatButtonModule,
    MatChipsModule,
    MatSelectModule,
    MatIconModule
  ],
  template: `
    <h1 mat-dialog-title>Edit Dealer Assignments for {{data.employee.firstName}} {{data.employee.lastName}}</h1>
    <div mat-dialog-content>
      <mat-form-field style="width: 100%;">
        <mat-label>Search Dealers</mat-label>
        <input matInput placeholder="Type dealer name, city or state" [formControl]="dealerFilterCtrl">
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
      
      <h3>Selected Dealers: {{selectedDealerIds.length}}</h3>
      <div class="selected-dealers">
        <mat-chip-set>
          <mat-chip *ngFor="let dealerId of selectedDealerIds" 
                   [removable]="true" 
                   (removed)="removeDealer(dealerId)">
            {{getDealerName(dealerId)}}
            <button matChipRemove>✕</button>
          </mat-chip>
        </mat-chip-set>
        <p *ngIf="selectedDealerIds.length === 0" class="no-dealers">No dealers selected</p>
      </div>
      
      <h3>Available Dealers:</h3>
      <mat-form-field style="width: 100%;">
        <mat-label>Select dealers to add</mat-label>
        <mat-select (selectionChange)="addDealer($event.value)" [value]="">
          <mat-option value="" disabled>-- Select a dealer to add --</mat-option>
          <mat-option *ngFor="let dealer of filteredDealers" 
                      [value]="dealer.dealerId"
                      [disabled]="isSelected(dealer.dealerId)">
            {{dealer.dealerName}} ({{dealer.dealerCity}}, {{dealer.dealerState}})
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div class="dealer-list" style="max-height:300px; overflow: auto;">
        <div *ngFor="let dealer of filteredDealers" class="dealer-item">
          <div class="dealer-info">
            {{dealer.dealerName}} ({{dealer.dealerCity}}, {{dealer.dealerState}})
          </div>
          <button mat-icon-button color="primary" 
                  [disabled]="isSelected(dealer.dealerId)"
                  (click)="addDealer(dealer.dealerId)">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div mat-dialog-actions align="end">
      <button mat-button (click)="cancel()">Cancel</button>
      <button mat-raised-button color="primary" (click)="save()">Save</button>
    </div>
  `,
  styles: [`
    .selected-dealers {
      margin-bottom: 16px;
      min-height: 48px;
    }
    mat-chip {
      margin-right: 8px;
      margin-bottom: 8px;
    }
    .no-dealers {
      color: rgba(0,0,0,0.5);
      font-style: italic;
    }
    .dealer-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 0;
      border-bottom: 1px solid #eee;
    }
    .dealer-info {
      flex-grow: 1;
    }
  `]
})
export class EditEmployeeDealersDialog implements OnInit {
  // Control for filtering the dealer list
  dealerFilterCtrl = new FormControl('');
  
  // Array to store selected dealer IDs
  selectedDealerIds: string[] = [];
  
  // Filtered dealers based on search
  filteredDealers: Array<any> = [];

  constructor(
    public dialogRef: MatDialogRef<EditEmployeeDealersDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit() {
    // Initialize selected dealers from employee data
    this.selectedDealerIds = this.data.employee.employeeDealerList ? 
      [...this.data.employee.employeeDealerList] : [];
    
    // Initialize filtered dealers with all dealers
    this.updateFilteredDealers();
    
    // Subscribe to filter changes
    this.dealerFilterCtrl.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe(filterValue => {
        this.updateFilteredDealers(filterValue);
      });
  }
  
  updateFilteredDealers(filterValue: string = '') {
    // Start with all dealers
    let dealers = [...this.data.dealers];
    
    // Apply text filter if provided
    if (filterValue && filterValue.trim() !== '') {
      filterValue = filterValue.toLowerCase();
      dealers = dealers.filter(dealer => 
        dealer.dealerName.toLowerCase().includes(filterValue) ||
        dealer.dealerCity.toLowerCase().includes(filterValue) ||
        dealer.dealerState.toLowerCase().includes(filterValue) ||
        dealer.dealerId.toLowerCase().includes(filterValue)
      );
    }
    
    this.filteredDealers = dealers;
  }
  
  isSelected(dealerId: string): boolean {
    return this.selectedDealerIds.includes(dealerId);
  }
  
  addDealer(dealerId: string) {
    if (dealerId && !this.selectedDealerIds.includes(dealerId)) {
      this.selectedDealerIds.push(dealerId);
    }
  }
  
  removeDealer(dealerId: string) {
    const index = this.selectedDealerIds.indexOf(dealerId);
    if (index >= 0) {
      this.selectedDealerIds.splice(index, 1);
    }
  }
  
  getDealerName(dealerId: string): string {
    const dealer = this.data.dealers.find(d => d.dealerId === dealerId);
    return dealer ? dealer.dealerName : 'Unknown Dealer';
  }

  cancel(): void {
    this.dialogRef.close();
  }

  save(): void {
    // Return the selected dealer IDs in the correct format
    this.dialogRef.close({ dealerIds: this.selectedDealerIds });
  }
}