import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { Employee } from './models/employee-dealer.models';
import { Dealer } from '@app/_models/dealer';

interface DialogData {
  title: string;
  type: 'employee' | 'dealer';
  itemCount: number;
  items: string[];
  employee?: Employee;
  dealer?: Dealer;
  dealers?: Dealer[];
  employees?: Employee[];
  onAssign?: (employeeUsername: string) => void;
}

@Component({
  selector: 'app-detail-popup',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatDialogModule,
    MatButtonModule,
    MatChipsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    MatDividerModule
  ],
  template: `
    <h2 mat-dialog-title>{{data.title}}</h2>
    
    <mat-dialog-content>
      <!-- Dealer details section -->
      <div *ngIf="data.type === 'dealer'" class="dealer-details">
        <div class="section">
          <h3>Dealer Information</h3>
          <div class="info-grid">
            <div class="info-row">
              <div class="label">ID:</div>
              <div class="value">{{data.dealer.dealerId}}</div>
            </div>
            <div class="info-row">
              <div class="label">Address:</div>
              <div class="value">{{data.dealer.dealerAddress}}</div>
            </div>
            <div class="info-row">
              <div class="label">City/State:</div>
              <div class="value">{{data.dealer.dealerCity}}, {{data.dealer.dealerState}}</div>
            </div>
            <div class="info-row">
              <div class="label">Group:</div>
              <div class="value">{{data.dealer.dealerGroupName || 'N/A'}}</div>
            </div>
            <div class="info-row">
              <div class="label">Type:</div>
              <div class="value">{{data.dealer.dealerType || 'N/A'}}</div>
            </div>
          </div>
        </div>
        
        <mat-divider class="divider"></mat-divider>
        
        <div class="section">
          <h3>Assigned Employees ({{data.itemCount}})</h3>
          <div *ngIf="data.itemCount > 0" class="chips-container">
            <mat-chip-set>
              <mat-chip *ngFor="let item of data.items" color="accent">{{item}}</mat-chip>
            </mat-chip-set>
          </div>
          <p *ngIf="data.itemCount === 0" class="no-data">No employees assigned to this dealer</p>
        </div>
        
        <mat-divider class="divider"></mat-divider>
        
        <div class="section">
          <h3>Quick Assign</h3>
          <div class="quick-assign-form">
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Select Employee</mat-label>
              <mat-select [(ngModel)]="selectedEmployee">
                <mat-option *ngFor="let emp of data.employees" [value]="emp.username">
                  {{emp.firstName}} {{emp.lastName}} ({{emp.username}})
                </mat-option>
              </mat-select>
            </mat-form-field>
            
            <button mat-raised-button color="primary" 
                    [disabled]="!selectedEmployee"
                    (click)="assignEmployee()">
              Assign
            </button>
          </div>
        </div>
      </div>
      
      <!-- Employee details section -->
      <div *ngIf="data.type === 'employee'" class="employee-details">
        <div class="section">
          <h3>Employee Information</h3>
          <div class="info-grid">
            <div class="info-row">
              <div class="label">Username:</div>
              <div class="value">{{data.employee.username}}</div>
            </div>
            <div class="info-row">
              <div class="label">Name:</div>
              <div class="value">{{data.employee.firstName}} {{data.employee.lastName}}</div>
            </div>
            <div class="info-row">
              <div class="label">Role:</div>
              <div class="value">{{data.employee.role || 'N/A'}}</div>
            </div>
            <div class="info-row">
              <div class="label">Assigned Dealers:</div>
              <div class="value">{{data.itemCount}}</div>
            </div>
          </div>
        </div>
        
        <mat-divider class="divider"></mat-divider>
        
        <div class="section">
          <h3>Assigned Dealers ({{data.itemCount}})</h3>
          <div *ngIf="data.itemCount > 0" class="chips-container">
            <mat-chip-set>
              <mat-chip *ngFor="let item of data.items">{{item}}</mat-chip>
            </mat-chip-set>
          </div>
          <p *ngIf="data.itemCount === 0" class="no-data">No dealers assigned to this employee</p>
        </div>
      </div>
    </mat-dialog-content>
    
    <mat-dialog-actions align="end">
      <button mat-button color="primary" mat-dialog-close>Close</button>
    </mat-dialog-actions>
  `,
  styles: [`
    mat-dialog-content {
      max-height: 70vh;
      min-height: 200px;
    }
    
    .section {
      margin-bottom: 20px;
    }
    
    h3 {
      margin-top: 0;
      color: #3f51b5;
      font-size: 18px;
      font-weight: 500;
    }
    
    .divider {
      margin: 20px 0;
    }
    
    .info-grid {
      display: grid;
      row-gap: 8px;
    }
    
    .info-row {
      display: grid;
      grid-template-columns: 100px 1fr;
      align-items: center;
    }
    
    .label {
      font-weight: 500;
      color: rgba(0, 0, 0, 0.7);
    }
    
    .value {
      color: rgba(0, 0, 0, 0.87);
    }
    
    .chips-container {
      margin: 10px 0;
    }
    
    mat-chip {
      margin: 4px;
    }
    
    .no-data {
      color: rgba(0, 0, 0, 0.5);
      font-style: italic;
      margin: 10px 0;
    }
    
    .quick-assign-form {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    
    .full-width {
      width: 100%;
    }
    
    mat-dialog-actions {
      padding: 12px 0;
    }
  `]
})
export class DetailPopupComponent {
  selectedEmployee: string = '';
  
  constructor(
    public dialogRef: MatDialogRef<DetailPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}
  
  assignEmployee() {
    if (this.data.onAssign && this.selectedEmployee) {
      this.data.onAssign(this.selectedEmployee);
      this.dialogRef.close();
    }
  }
}
