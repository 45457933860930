import { Component, OnInit } from '@angular/core';
import { Dealer } from '@app/_models/dealer';
import { DealerSummary } from '@app/_models/dealerSummary';
import { Invoice } from '@app/_models/invoice';
import { AlertService } from '@app/_services/alert.service';
import { DealerService } from '@app/_services/dealer-service';
import { InvoiceService } from '@app/_services/invoice.service';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-print-invoice',
  templateUrl: './print-invoice.component.html',
  styleUrls: ['./print-invoice.component.less']
})
export class PrintInvoiceComponent implements OnInit {
  isLoading = false;
  dealer : Dealer;
  printInvoice: Invoice;
  cGstAmount:number;
  sGstAmount:number;
  iGstAmount:number;
  totalAmount:number;
  dealerSummaries:DealerSummary[];
  constructor(private dealerService:DealerService,
    private alertService:AlertService, private invoiceService:InvoiceService

    ) { }

  ngOnInit(): void {
    this.dealer = this.dealerService.PrintDealer;
    this.dealerSummaries = this.dealerService.PrintDealerSummary;
    // this.dealer = {"dealerMake":[{"make":"Hero Motocorp Limited","model":["Deluxe","Destini 125","Glamour","Hf Deluxe","Maestro Edge 110","Maestro Edge 125","New","Passion Pro","Pleasure","Splendor Ismart","Splendor+","Splendor Pro","Super Splendor","Xpulse 200","Xpulse 200t","Xtreme 160r","Xtreme 200s"]}],"dealerPlan":[{"planId":"Plan348","planAmount":295,"planGstAmount":53,"planNetAmount":205,"planTotalAmount":348,"planMaximumAmount":348,"policyType":"PA1"},{"planId":"Plan690","planAmount":585,"planGstAmount":105,"planNetAmount":290,"planTotalAmount":690,"planMaximumAmount":585,"policyType":"CPA + RSA"},{"planId":"Flexible","planAmount":0,"planGstAmount":0,"planNetAmount":459,"planTotalAmount":0,"planMaximumAmount":2000,"policyType":"CPA + RSA + DOC"},{"planId":"Plan1000","planAmount":847,"planGstAmount":153,"planNetAmount":459,"planTotalAmount":1000,"planMaximumAmount":847,"policyType":"CPA + RSA + DOC"}],"dealerType":"Dealer","dealerBankAccountOwner":"","dealerBankAccountNumber":"","dealerBankIfscCode":"","dealerBankOwnerPan":"","dealerGstId":"","dealerVpaId":"","dealerId":"DLR-10000003","dealerName":"Lokesh kharbash","dealerAddress":"Flat No 1408, D Wing, Palm Spring, Link Road, Malad west","dealerState":"Maharashtra","dealerCity":"Amalner","dealerContactNumber":7738183366,"dealerEmail":"lokesh.kharbash@gmail.com","dealerBalance":9304,"dealerBalanceLimit":-50000,"dealerPlanType":"FP","dealerTotalPayment":590,"dealerTotalGstPayment":106,"dealerTotalRefund":0,"dealerTotalGstRefund":0,"dealerPendingRefund":180,"dealerGstPendingRefund":32.2,"id":"60371e3c59e9e54c10c1c9e1","dealerInvoice":new Map(),"printInvoiceKey":"2021-02-NotSubmitted"}
    console.log( "heare: " +  JSON.stringify(this.dealerSummaries));
    this.printInvoice = this.invoiceService.selectedInvoice;
    // this.printInvoice = new Invoice({"dealerLastBalance":9304.00,"dealerTotalPayment":180.00,"dealerTotalGstPayment":32.20,"dealerTotalRefund":0.00,"dealerGstPendingRefund":32.20,"dealerPendingRefund":180.00,"dealerTotalGstRefund":0,"invoiceNumber":"INV-1000000067","invoiceStatus":"Ready for Print","invoiceGeneratedDate":"Fri Feb 26 2021 18:42:40 GMT+0530 (India Standard Time)","invoicePaidDate":"","invoiceYear":2021,"invoiceMonth":"02"});
    this.totalAmount = parseFloat((parseFloat(this.printInvoice.dealerPendingRefund) + parseFloat(this.printInvoice.dealerTotalRefund)).toFixed(2));
    this.cGstAmount = this.printInvoice.invoiceType == "Personal" ? 0 : (this.dealer.dealerState == "Haryana")? parseFloat((this.totalAmount * .09).toFixed(2)) : 0;
    this.iGstAmount = this.printInvoice.invoiceType == "Personal" ? 0 : (this.dealer.dealerState != "Haryana")? parseFloat((this.totalAmount * .18).toFixed(2)) : 0;

  }

  invoiceGenerate(){
    this.isLoading = true;
    this.printInvoice.invoiceStatus = "Submitted For Payment";
    // this.dealer.dealerInvoice[this.dealer.printInvoiceKey] = this.printInvoice;
    // this.dealerService.dealerInvoiceUpdate(this.dealer.dealerId,this.dealer)
    this.invoiceService.updateInvoice(this.printInvoice)
    .pipe(first())
    .subscribe({
        next: () => {
            this.printPage()
        },
        error: error => {
            this.alertService.error(error);
            this.isLoading = false;

        }
    });
  }

  printPage(){
    var data = document.getElementById('printPage'); 
    html2canvas(data,{scale:2,height:1584,width:1122,windowWidth:1122}).then(canvas =>{
      let margin = 5;
      let imgWidth = 210 - margin * 2;
      console.log("canvas.height: " + canvas.height + " canvas.width: " + canvas.width) 
      // let imgHeight = canvas.height;  
      let imgHeight = canvas.height * imgWidth / canvas.width;  
      if (imgHeight >= 290)
        imgHeight = 290;
      console.log("imgHeight: " + imgHeight ) 
      let heightLeft = imgHeight;  
      const contentDataURL = canvas.toDataURL('image/jpeg')  
      let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF  
      let position = 30;  
      pdf.addImage(contentDataURL, 'JPEG', 5, position, imgWidth, imgHeight);
      pdf.save(this.printInvoice.invoiceNumber + ".pdf")
      this.isLoading=false;
})
}

}
