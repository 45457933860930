<h1 >Dealer Plan Report</h1>
<form [formGroup]="certificateReportForm" (ngSubmit)="onSubmit()">
    <div class="form-row">
        <div class="form-group col">
            <label for="issueDateFrom">Issue Date From</label>
            <div class="input-group">
                <input class="form-control" formControlName="issueDateFrom"   ngbDatepicker #d="ngbDatepicker" [ngClass]="{ 'is-invalid': submitted && f.issueDateFrom.errors }">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary fa fa-calendar" (click)="d.toggle()" type="button"></button>
                </div>
              </div>            
              <div *ngIf="submitted && f.issueDateFrom.errors" class="invalid-feedback">
                <div *ngIf="f.issueDateFrom.errors.required">Issue Date From</div>
            </div>
        </div>
        <div class="form-group col">
            <label for="issueDateTo">Issue Date To</label>
            <div class="input-group">
                <input class="form-control" formControlName="issueDateTo"   ngbDatepicker #issueDateTo="ngbDatepicker" [ngClass]="{ 'is-invalid': submitted && f.issueDateTo.errors }">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary fa fa-calendar" (click)="issueDateTo.toggle()" type="button"></button>
                </div>
              </div>            
              <div *ngIf="submitted && f.issueDateTo.errors" class="invalid-feedback">
                <div *ngIf="f.issueDateTo.errors.required">Issue Date From</div>
            </div>
        </div>
        <div class="form-group col">
            <label for="aggregationType">Aggregation Type</label>
            <select class="form-select" formControlName="aggregationType" [ngClass]="{ 'is-invalid': submitted && f.aggregationType.errors }">
                <option *ngFor="let aggregationType of ['','IssueDate','IssueDateHorizontal']" [ngValue]="aggregationType">
                  {{ aggregationType }}
                </option>
              </select>
        </div>    
        <!-- <div class="form-group col" *ngIf='user.role == "admin" || user.role == "employee"'>
            <label for="dealerId">Dealer Id</label>
            <input type="text" formControlName="dealerId" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dealerId.errors }" />
        </div>
        <div class="form-group col" *ngIf='user.role == "admin" || user.role == "employee"'>
            <label for="dealerGroupName">Dealer Group Name</label>
            <input type="text" formControlName="dealerGroupName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dealerGroupName.errors }" />
        </div>
        <div class="form-group col" *ngIf='user.role == "admin" || user.role == "employee"'>
            <label for="dealerState">Dealer State</label>
            <input type="text" formControlName="dealerState" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dealerState.errors }" />
        </div>
        <div class="form-group col">
            <label for="cpaPolicyCompany">CPA Policy Company</label>
            <select class="form-select" formControlName="cpaPolicyCompany" [ngClass]="{ 'is-invalid': submitted && f.cpaPolicyCompany.errors }">
                <option *ngFor="let cpaPolicyCompny of ['','IFCO Tokio','New India','None']" [ngValue]="cpaPolicyCompny">
                  {{ cpaPolicyCompny }}
                </option>
              </select>
        </div>     -->

    </div>
    <div class="form-row">
        <div class="form-group">
        <button [disabled]="loading" class="btn btn-primary" >
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Search
        </button><a>&nbsp;&nbsp;</a>
        <button  class="btn btn-secondary mr-1" type="reset" (click)="onReset()">
            Reset
        </button>
        <button (click)="exportToExcel()" class="btn btn-primary" >
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Export to Excel
        </button><a>&nbsp;&nbsp;</a>
    </div>
    </div>
    <div class="form-row">
        <div class="form-group col">
        
        <ag-grid-angular #aggrid
    style="width: 100%; height: 600px;"
    [gridOptions] = "gridOptions"
    class="ag-theme-alpine"
>
</ag-grid-angular>
</div>
    </div>

</form>


