import { MakeModel } from "./makeModel";
import { PlanDetails } from "./planDetails";
import { Invoice } from "./invoice";

export class Dealer {
    id:string;
    dealerId:string; 
    dealerName: string;
    dealerAddress: string;
    dealerState: string;
    dealerCity:string;
    dealerContactNumber: number;
    dealerEmail:string;
    dealerBalance: number;
    dealerBalanceLimit: number;
    dealerMake: MakeModel[];
    dealerPlan: PlanDetails[];
    dealerPlanType: string;
    dealerTotalPayment:number;
    dealerTotalGstPayment:number;
    dealerTotalRefund:number;
    dealerTotalGstRefund:number;
    dealerPendingRefund:number;
    dealerGstPendingRefund:number;
    dealerType: string;
    dealerBankAccountOwner: string;
    dealerBankAccountNumber: string;
    dealerBankIfscCode: string;
    dealerBankOwnerPan: string;
    dealerGstId: string;
    dealerVpaId: string;
    printInvoiceKey: string;
    dealerInvoice: Map<String,Object>;
    dealerGroupName?: string;
    dealerInvoiceShow?: string;
    dealerPaymentShow?: string;
    dealerPolicyBalance?: number = 0;
    dealerTotalCertSold?: number;
    invoiceType? = "Commission";
    dealerGst = "Full";
    dealerCreatePackage?:boolean = false;
    onePager?:boolean = false;



    public constructor(init?: Partial<Dealer>) {
        Object.assign(this, init);
    }
}